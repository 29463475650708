import React, { useEffect } from 'react'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { EfformanceVendorIDSelect, MailingHistoryCategoryIDSelect } from './GeneralDropDowns'
import { ConstantCodeEfformanceVendorID,
  ConstantCodeMailingHistoryCategoryID,
  ConstantCodeMailingHistoryCategoryEnabled,
  ConstantCodeMailFuzeFixDocumentDimensions,
  useSettingsService
  } from '../../services/SettingsService'
import useSettingsContext from './SettingsContext'
import { doRegisterInputCheckbox, doRegisterSelect } from './FormHookRegisters'
import { useRef } from 'react'
import { Blank } from '../dropdowns/DefaultSelectOptions'
import { toast } from 'react-toastify'

export const GeneralSettingsState = {
  mailingHistoryCategoryID: {
    constant: ConstantCodeMailingHistoryCategoryID,
    code: undefined,
    type: 'Integer',
    value: Blank.value
  },
  mailingHistoryCategoryEnabled: {
    constant: ConstantCodeMailingHistoryCategoryEnabled,
    code: undefined,
    type: 'Boolean',
    value: false
  },
  mailFuzeFixDocumentDimensions: {
    constant: ConstantCodeMailFuzeFixDocumentDimensions,
    code: undefined,
    type: 'Boolean',
    value: false
  },
  efformanceVendorID: {
    constant: ConstantCodeEfformanceVendorID,
    code: undefined,
    type: 'Integer',
    value: Blank.value
  }
}

export const GeneralSchema = {//yup.object({
  efformanceVendorID: yup.object().shape({
    value: yup.number().integer().moreThan(0, 'Please select or create the Efformance Vendor.')
  })
}//)//.required();

const InternalSchema = yup.object({
  ...GeneralSchema
})

const GeneralSettings = ({onChange}) => {

  const formRef = useRef(null)
  const {context: state, contextReducer: dispatchSecond} = useSettingsContext()
  console.log('Loading General Tab with context', state)

  const {
    //setFocus,
    register,
    setValue,
    //getFieldState,
    handleSubmit,
    trigger,
    reset,
    //control
    formState: { errors, isSubmitted }
  } = useForm({
    defaultValues: {
      ...state
    },
    // defaultValues: useMemo(() => {
    //   return {...state}
    // }, [state]),
    resolver: yupResolver(InternalSchema),
    mode: "onChange", //not sure this is necessary
    context: { state } //not sure this is necessary try to remove after stability achieved.
  });

  const dispatch = (action) => {
    action.setValue = setValue
    dispatchSecond(action)
    if (state.isSubmitted || (action.name === 'isSubmitted' && action.value === true)) {
      trigger()
    }
  }

  useEffect(() => {
    reset({
      ...state
    });
    if (state.isSubmitted){
      trigger();
    }
      
    }, [state]);

  const settingsService = useSettingsService()

  useEffect(() => {
    if (state.isSubmitted === false && isSubmitted) {
      dispatch({ type: 'SET', name: 'isSubmitted', value: isSubmitted })
    }

  }, [state.isSubmitted, isSubmitted])

  //trigger will change on the first load. It's only way to run the validation on the default values.
  useEffect(() => {
    if (state.isSubmitted) {
      trigger()
    }
  }, [trigger, state.isSubmitted, state.currentTabIndex])

  const createEfformanceVendor = async () => {
    const { isSuccess, vendorName, message } = await settingsService.CreateEfformanceVendor()
    let refreshAndSelect = false;

    const toastDefault = {
      toastId: 'fetch-wrapper-toast-id',
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    }

    if (isSuccess) {
      refreshAndSelect = true;
      toast.success(`Vendor '${vendorName}' added; attempting to select it.`, toastDefault)
    }
    else {
      if (vendorName) {
        refreshAndSelect = true
        toast.warning(`Vendor '${vendorName}' already exists; attempting to select it.`, toastDefault)
      }
      else {
        toast.error(message, toastDefault)
      }

      if (refreshAndSelect) {
        console.log('refreshAndSelect', refreshAndSelect)
        const options = await settingsService.GetEfformanceVendorIDSelectOptions()
        const vendorOption = options.find(option => option.label === vendorName && option.value !== -1)
        if (vendorOption) {
          console.log('vendorOption', vendorOption)
          
          //newState.property.nestedProperty = "new value";
          //this.setState(newState);

          const newEfformanceVendorID = state.efformanceVendorID
          newEfformanceVendorID.options = options
          newEfformanceVendorID.value = vendorOption.value
          console.log('newEfformanceVendorID', newEfformanceVendorID)
          dispatch({ type: 'SET', name: 'efformanceVendorID', value: newEfformanceVendorID })
        }
        else {
          toast.warning(`Could not find '${vendorName}'.`, toastDefault)
        }
      }
    }
  }

  const save = async (e) => {
    e.target.blur();
//     let error = {}
//     await state.schema.validate(state, { abortEarly: true }).catch(e => {
//       console.log('e', e)
//       error = {
//         name: e.name,
//         value: e.value,
//         type: e.type,
//        message: e.message,
//        field: e.path,
//        params: e.params
//       };
//      });
// console.log(error)
    //const validationResult = await state.schema.validate(state);
    //console.log(validationResult)
    formRef.current.props.onSubmit(e)
    //settings.saveSettingsConstants(state, dispatch, 'Other settings saved!')
  }

  return (
    <div>
      <React.Fragment>
        <Form ref={formRef}
          onSubmit={handleSubmit(state.onSubmit, state.onError)}
        >
          <Row>
            <Col xs={12} md={7} lg={5} xl={4}>
              <Label>Default History Category:</Label>
            </Col>
            <Col xs={12} md={5} lg={4} xl={3}>
              <MailingHistoryCategoryIDSelect
                options={state.mailingHistoryCategoryID.options}
                {...doRegisterSelect(register, dispatch, 'mailingHistoryCategoryID', state.isSubmitted, errors, state, '', true)}
              />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col xs={12} md={{ size: 12 }} lg={{ size: 7, offset: 5 }} xl={{ size: 8, offset: 4 }}>
              <FormGroup check inline>
                <div style={{paddingLeft: '2px'}}>
                  <Input
                    {...doRegisterInputCheckbox(register, dispatch, 'mailingHistoryCategoryEnabled', state.isSubmitted, errors, state)}
                    style={{transform: 'scale(1.3)'}}
                  />
                  {' '}
                  <Label check for='mailingHistoryCategoryEnabled'>
                    Allow users to change History Category for each mailing batch
                  </Label>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row className='mt-1'>
            <Col xs={12} md={12} lg={11} xl={10}>
              <hr />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col xs={12} md={7} lg={5} xl={4}>
              <Label>Efformance Vendor:</Label>
            </Col>
            <Col xs={12} md={5} lg={4} xl={3}>
              <EfformanceVendorIDSelect
                options={state.efformanceVendorID.options}
                {...doRegisterSelect(register, dispatch, 'efformanceVendorID', state.isSubmitted, errors, state, '', true)}
              />
            </Col>
            <Col xs={12} md={12} lg={4} xl={3}>
              <Button color="secondary" onClick={createEfformanceVendor}>Create Efformance Vendor</Button>
            </Col>
          </Row>
          <Row className='mt-1'>
            <Col xs={12} md={12} lg={11} xl={10}>
              <hr />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col xs={12} md={12} lg={5} xl={4}>
              <Label>Letter Page Dimensions:</Label>
            </Col>
            <Col xs={12} md={12} lg={7} xl={8}>
              <FormGroup check inline>
                <div style={{paddingLeft: '2px'}}>
                  <Input
                    {...doRegisterInputCheckbox(register, dispatch, 'mailFuzeFixDocumentDimensions', state.isSubmitted, errors, state)}
                    style={{transform: 'scale(1.3)'}}
                  />
                  {' '}
                  <Label check for='mailFuzeFixDocumentDimensions'>
                    I want MailFuze to automatically resize any pages that do not match the letter type dimensions
                  </Label>
                </div>
              </FormGroup>
            </Col>
          </Row>
         
          {/* <Row>
            <Col md={11} lg={8} xl={7} className="d-flex justify-content-end mb-3 mt-3">
              <Button color="dark" onClick={save}>Save</Button>
            </Col>
          </Row> */}
        </Form>
      </React.Fragment>
    </div>
  )
}

export default GeneralSettings
