import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { useClientService } from '../../services/ClientService'
import { useSettingsService } from '../../services/SettingsService'
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Setting from './Setting'

const FlushSettings = ({showResetAll, onChange}) => {

  const clientService = useClientService()
  const settingsService = useSettingsService()
  const MySwal = withReactContent(Swal)

  const [state, setState] = useState({
    isLoading: true,
    myClient: null
  })

  useEffect(() => {
    populateState()
  }, [])

  const PlainSwal = MySwal.mixin({
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    }
  })

  const populateState = async () => {
    var client = await clientService.GetMyClient()
    setState({
      isLoading: false,
      myClient: client
    })
  }

  const onFlushAll = () => {
    PlainSwal.fire(
      flushConfirmSetting('Refresh All', '<p class="fw-bold">Are you sure you want to refresh the settings for all clients and locations?</p>', 'Refresh All')
    ).then(async (result) => {
      if (result.isConfirmed) {
        await settingsService.FlushAllSettings()
        flushCompleteToast('Refresh All Complete')
      }
    }) 
  }

  const onFlushMyClient = () => {
    PlainSwal.fire(
      flushConfirmSetting('Refresh My Settings', '<p class="fw-bold">Are you sure you want to refresh your client settings?</p>', 'Refresh')
    ).then(async (result) => {
      if (result.isConfirmed) {
        await settingsService.FlushMySettings()
        flushCompleteToast('Refresh Complete')
      }
    }) 
  }

  const flushConfirmSetting = (title, html, buttonText) => {
    return {
      title: title,
      html:  html,
      showCloseButton: true,
      showCancelButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary btn-lg me-1',
        cancelButton: 'btn btn-secondary btn-lg ms-1'
      },
      confirmButtonText: buttonText,
    }
  }

  const flushCompleteToast = (text) => {
    toast.success(text, {
      position: "bottom-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  }

  return (
    <React.Fragment>
      {!state.isLoading && (
        <Row>
          <Col md={11} lg={8} xl={7}>
            <h4 className="mt-2">Refresh Settings</h4>
            <hr />
            <Setting label={`Refresh Settings for ${state.myClient.CompanyName}`} description="Refresh cached Rent Manager and MailFuze settings.">
              <Button color="dark" onClick={onFlushMyClient}>Refresh My Settings</Button>
            </Setting>
            {showResetAll && (
              <Setting label="Refresh Settings for All Clients" description="Refresh cached Rent Manager and MailFuze settings for all clients and client locations.">
                <Button color="danger" onClick={onFlushAll}>Refresh All</Button>
              </Setting>
            )}
          </Col>
        </Row>
        // <Row>
        //   <Col md={11} lg={8} xl={7} className="d-flex justify-content-end mb-3 mt-3">
        //     <Button color="dark" onClick={save}>Save</Button>
        //   </Col>
        // </Row>
      )}
  </React.Fragment>
  )
}

export default FlushSettings
