import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { CustomLoadingOverlay } from '../widget/AGGridCustomOverlay'
import CustomHeaderGroup from './myCustomGroupHeader'
import { useSendMailGridCommon } from './SendMailGridCommon'
import NotAddressGroupHeader from './NotAddressGroupHeader'
import AGGridCheckmarkRenderer from '../widget/AGGridCheckmarkRenderer';
import AGGridBooleanFilter from '../widget/AGGridBooleanFilter';

const SendMailProspectsGrid = forwardRef(({listingData,
    addressTypes,
    currentAddressType,
    useContacts,
    onUseContactsChanged,
    contactEnvelopOption,
    onContactEnvelopOptionChanged,
    useContactAddress,
    onUseContactAddressChanged,
    contactTypes,
    currentContactType,
    onContactTypeChanged,
    isLoading,
    onAddressTypeChanged,
    onSelectionChanged}, ref
  ) => {
  //const [selection, setSelection] = useState([])
  const gridRef = useRef()
  const sendMailGridCommon = useSendMailGridCommon({addressTypes, currentAddressType, onAddressTypeChanged})

  useEffect(() => {
    console.log('use effect triggered on isLoading changed to ' + isLoading)
    if (isLoading) {
      onShowLoading()
    }
    else {
      onHideOverlay()
    }
  }, [isLoading])

  useEffect(() => {
    console.log('CURRENT ADDRESS TYPE CHANGED')
    const colDefs = gridRef.current.props.columnDefs
    let lookIn = colDefs
    console.log(colDefs)
    if (colDefs.length === 1 && colDefs[0].children && colDefs[0].children.length > 0) {
      lookIn = colDefs[0].children
    }
    //const indexOf = gridRef.current.props.columnDefs.findIndex(colDef => colDef.headerName === "Address")
    const indexOf = lookIn.findIndex(colDef => colDef.headerName === "Address")
    //gridRef.current.props.columnDefs[indexOf].headerGroupComponentParams.currentAddressType = currentAddressType
    lookIn[indexOf].headerGroupComponentParams.currentAddressType = currentAddressType
    gridRef.current.api.refreshHeader();
  }, [currentAddressType])

  useEffect(() => {
    console.log('CURRENT CONTACT TYPE CHANGED')
    const indexOf = gridRef.current.props.columnDefs.findIndex(colDef => colDef.headerName === "NotAddressGroup")
    const headerParams = gridRef.current.props.columnDefs[indexOf].headerGroupComponentParams
    headerParams.currentContactType = currentContactType
    headerParams.useContacts = useContacts
    headerParams.contactEnvelopOption = contactEnvelopOption
    headerParams.useContactAddress = useContactAddress
    gridRef.current.api.refreshHeader();
  }, [currentContactType, useContacts, contactEnvelopOption, useContactAddress])

  useEffect(() => {
    gridRef.current.columnApi.setColumnsVisible(['contactType'], useContacts)
  }, [useContacts])

  const [defaultColumnDefs, setDefaultColumnDefs] = useState({
      sortable: true,
      filter: true,
      lockVisible: true,
      //suppressMovable: true,
  })

  useImperativeHandle(ref, () => {
    return {
      async unselectRows(prospectIDs) {
        //console.log('unselectRow called', prospectIDs)
        let removedCount = 0;

        if (gridRef.current && prospectIDs && prospectIDs.length !== 0) {
          const gridApi = gridRef.current.api
          const selectedNodes = gridApi.getSelectedNodes()
          
          selectedNodes.forEach((rowNode) => {
            //console.log(rowNode.data);
            if(prospectIDs.filter(prospectID => prospectID === rowNode.data.prospectID).length !== 0) {
              //console.log('unselecting row node:', rowNode.data.importRowNum)
              rowNode.setSelected(false)
              removedCount++
            }
          })
        }

        return removedCount
      }
    }
  }, [gridRef])

  const [columnDefs, setColumnDefs] = useState([
    {
      // headerName: 'Prospects',
      // columnGroupShow: null,
      // marryChildren: true,
      // suppressMovable: true,
      // cellClass: 'suppress-movable-col',
      // sortable: false,
      // filter: false,
      colId: 'NotAddressGroup',
      headerGroupComponent: "notAddressHeaderGroup",
      headerGroupComponentParams: {
        useContacts: useContacts,
        onUseContactsChanged: onUseContactsChanged,
        useContactAddress: useContactAddress,
        onUseContactAddressChanged: onUseContactAddressChanged,
        contactTypes: contactTypes,
        currentContactType: currentContactType,
        onContactTypeChanged: onContactTypeChanged,
        contactEnvelopOption: contactEnvelopOption,
        onContactEnvelopOptionChanged: onContactEnvelopOptionChanged
      },
      headerClass: "ag-form-header",
      headerName: 'NotAddressGroup',
      children: [
        { field: 'property', minWidth: 140, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true },
        { field: 'contactProspect', headerName: 'Prospect', minWidth: 150 },
        { field: 'prospect', minWidth: 150 },
        { field: 'contactType', minWidth: 150 },
        {
          field: 'contactIsOnStatement',
          headerName: 'On Statement',
          maxWidth: 150,
          suppressSizeToFit: true,
          cellRenderer: "AGGridCheckmarkRenderer",
          filter: 'AGGridBooleanFilter',
          filterParams: {
            title: 'Is Contact On Statement',
            allText: 'All',
            trueText: 'Yes',
            falseText: 'No'
          }
        },
        {
          field: 'contactIsPrimary',
          headerName: 'Primary',
          maxWidth: 130,
          suppressSizeToFit: true,
          cellRenderer: "AGGridCheckmarkRenderer",
          filter: 'AGGridBooleanFilter',
          filterParams: {
            title: 'Is Primary Contact',
            allText: 'All',
            trueText: 'Yes',
            falseText: 'No'
          }
        },
        {
          field: 'contactIsActive',
          headerName: 'Active',
          maxWidth: 130,
          suppressSizeToFit: true,
          cellRenderer: "AGGridCheckmarkRenderer",
          filter: 'AGGridBooleanFilter',
          filterParams: {
            title: 'Is Contact Active',
            allText: 'All',
            trueText: 'Yes',
            falseText: 'No'
          }
        }
      ]
    },
    sendMailGridCommon.getAddressColumnDef()
  ]);

  const contactColumnFields = useMemo(() => {
    return ['contactType', 'contactProspect', 'contactIsOnStatement', 'contactIsPrimary', 'contactIsActive']
  }, [])

  useEffect(() => {
    console.log('useContacts changed, refresh headers')
    if (gridRef && gridRef.current) {
      const residentHeaderName = useContacts ? 'Contact' : 'Prospect'
      const newColumnDefs = gridRef.current.api.getColumnDefs()
      const notAddressGroupIndexOf = newColumnDefs.findIndex(colDef => colDef.headerName === "NotAddressGroup")
      let currentContactFieldIndex = -1;
      if (notAddressGroupIndexOf !== -1) {
        const residentIndexOf = newColumnDefs[notAddressGroupIndexOf].children.findIndex(colDef => colDef.field === 'prospect')
        if (residentIndexOf !== -1) {
          newColumnDefs[notAddressGroupIndexOf].children[residentIndexOf].headerName = residentHeaderName

          newColumnDefs[notAddressGroupIndexOf].children.forEach(colDef => {
            currentContactFieldIndex = contactColumnFields.findIndex(field => field === colDef.field)
            if (currentContactFieldIndex !== -1) {
              colDef.hide = !useContacts
            }
          })

          setColumnDefs(newColumnDefs)
        }
      }
    }
  }, [useContacts, contactColumnFields, gridRef])

  const setGridSelection = () => {
    if (gridRef.current) {
      // const selectedNodes = gridRef.current.api.getSelectedNodes()
      // const selectedData = selectedNodes.map(node => node.data)
      const selectedData = []
      gridRef.current.api.forEachNodeAfterFilterAndSort(node => {
        if (node.isSelected()) {
          selectedData.push(node.data)
        }
      });
      onSelectionChanged(selectedData)
    }
    else {
      onSelectionChanged([])
    }
  }

  const onShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, [])

  const onHideOverlay = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  return (
    <React.Fragment>
      <div className="ag-theme-alpine" style={{height: '95vh', minHeight: '400px', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={listingData}
          rowSelection="multiple"
          paginationPageSize={30}
          columnDefs={columnDefs}
          loadingOverlayComponent="customLoadingOverLoad"
          loadingOverlayComponentParams={{
            loadingMessage: 'Loading...'
          }}
          defaultColDef={defaultColumnDefs}
          pagination={true}
          frameworkComponents={{
            customHeaderGroup: CustomHeaderGroup,
            notAddressHeaderGroup: NotAddressGroupHeader,
            customLoadingOverLoad: CustomLoadingOverlay,
            AGGridCheckmarkRenderer: AGGridCheckmarkRenderer,
            AGGridBooleanFilter: AGGridBooleanFilter,
          }}
          onRowDataChanged={(event) => {
            console.log('onRowDataChanged')
            setGridSelection()
          }}
          onGridReady={(event) => { 
            //console.log('onGridReady')
            event.api.sizeColumnsToFit()
            event.columnApi.applyColumnState({
              state: [
                {
                  colId: 'Property',
                  sort: 'desc'
                }
              ]
            })
          }}
          onSelectionChanged={(event) => {
            setGridSelection()
          }}
        >
        </AgGridReact>
      </div>
    </React.Fragment>
  )
})

export default SendMailProspectsGrid