import { useFetchWrapper } from '../services/fetch-wrapper'

export { useProspectAddressTypeService };

const useProspectAddressTypeService = () => {

  const fetchWrapper = useFetchWrapper()

  const GetProspectAddressTypes = async () => {
    const params = new URLSearchParams({ addDefault: true}).toString();
    return fetchWrapper.get(`prospect/prospectaddresstype?${params}`)
  }

  return { GetProspectAddressTypes }
}