import authService from '../components/api-authorization/AuthorizeService';
import { useFetchWrapper } from '../services/fetch-wrapper'

export { useUserService };

const useUserService = () => {

  const fetchWrapper = useFetchWrapper()

  const GetUserListingData = async () => {
    return fetchWrapper.get(`user`)
    // const token = await authService.getAccessToken();

    // const response = await fetch(`user`,
    //   { headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    //   }).catch((error) => { console.log(error); return error  })

    // if (response.ok) {
    //   var data = await response.json();
    //   return data;
    // }

    // return [];
  }

  const GetUserPreferredLocation = async () => {
    return fetchWrapper.get(`user/preferredlocation`)
  }

  const UpdateUserPreferredLocation = async (locationId) => {
    return fetchWrapper.put(`user/preferredlocation/${locationId}`)
  }

  const GetUpdatedUserListingData = async (userId) => {
    return fetchWrapper.get(`user/${userId}`)
  }

  const UpdateUserRole = async (userId, roleId) => {
    return fetchWrapper.put(`user/${userId}/role/${roleId}`)
  }

  const UpdateUserStatus = async (userId, disable) => {
    return fetchWrapper.put(`user/${userId}/status/${disable}`)
  }

  const ResetUserPassword = async (userId) => {
    return fetchWrapper.put(`user/${userId}/resetpassword`)
  }

  const GetUserStatus = async () => {
    //we don't use fetchWrapper here because we need to allow redirect follow.
    const token = await authService.getAccessToken();
    const authHeader = !token ? {} : { 'Authorization': `Bearer ${token}` }

    const requestOptions = {
      headers: authHeader,
      redirect: 'follow'
    }

    fetch(`user/status`, requestOptions
    ).then(response => {
      // HTTP 301 response
      if (response.redirected) {
        window.location.href = response.url;
      }

      //console.log(response)
      if (response.ok) {
        return false;
      }
    }).catch((error) => { console.log(error); return error  })

    return false;
  }

  return { GetUserListingData, GetUserPreferredLocation, GetUserStatus,
    GetUpdatedUserListingData, ResetUserPassword, UpdateUserRole, UpdateUserStatus,
    UpdateUserPreferredLocation
  }
}