import React, { useEffect, useReducer, useRef } from 'react'
import { Alert, Badge, Button, Col, Input, Label, ListGroup, ListGroupItem, Offcanvas, OffcanvasBody, Row } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { BinocularsFill, Check2Circle, Compass, CompassFill, Download, Geo, GeoAlt, GeoAltFill, GeoFill, JournalArrowDown, PinMap, PinMapFill, ReceiptCutoff, Save, Save2, SendPlus, X } from 'react-bootstrap-icons';
import { EmbedTrackingNumber, MailStatusAttachErr, MailStatusAttachLetter } from './MailStatusActionsOptions';
import { MailStatusConstants } from '../../services/MailStatusService';

const MailStatusActionsElectronicReceipts = ({selectedMailingData, isIntegrationPartner, showMenu, onClose, onGenerate}) => {

  const initialState = {
    showActions: false
  }

  const stateReducer = (state, action) => {
    switch (action.type) {
      case 'SET': {
        return {
          ...state,
          [action.name]: action.value
        }
      }
      case 'MERGE': {
        return {
          ...state,
          ...action.value
        }
      }
    }

    return state
  }

  const [state, dispatch] = useReducer(stateReducer, initialState)
  const MySwal = withReactContent(Swal)

  const PlainSwal = MySwal.mixin({
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    }
  })

  const toggleActions = () => {
    setShowActions(!state.showActions)
  }

  const setShowActions = (show) => {
    dispatch({ type: 'SET', name: 'showActions', value: show })
    if (!show) {
      onClose()
    }
  }

  useEffect(() => {
    //if ((showMenu || showMenu !== state.showActions) && selectedMailingData && selectedMailingData.length !== 0) {
    if ((showMenu || showMenu !== state.showActions)) {
      setShowActions(true)
    } else {
      setShowActions(false)
    }
  }, [selectedMailingData, showMenu])

  const onGenerateClick = () => {
    const { showActions, ...other } = state;
    console.log(other)
    onGenerate(other)
  }

  const GenerateProcessList = (included) => {
    if (selectedMailingData) {

      const color = included ? 'success' : 'warning'
      const errMaiLClass = MailStatusConstants.ElectronicReturnReceiptMaiLClass
      const filtered = selectedMailingData.filter(item => ((included && item.mailClass === errMaiLClass) || (!included && item.mailClass !== errMaiLClass)) )
      if (filtered.length !== 0) {
        return (
          <React.Fragment>
            {included ?
              <p>Getting Electronic Return Receipts for the following items:</p>
            :
              <p>The following items will not be processed because they are not expecting Electronic Return Receipts:</p>
            }
            <ListGroup style={{maxHeight: '10em', marginBottom: '1em', overflowY: 'auto'}}>
              {filtered.map(item => <ListGroupItem color={color} key={item.id}>{item.id} - {item.RecipientName}</ListGroupItem>)}
            </ListGroup>
          </React.Fragment>
        )
      }
    }
  }

  return (
    <Offcanvas
      scrollable
      direction="end"
      backdrop={true}
      isOpen={state.showActions}
      toggle={toggleActions}
      returnFocusAfterClose={false}
      //style={{minHeight: '30vh', display: 'flex', height: 'auto', width: '100%'}}
    >
      {/* <OffcanvasHeader toggle={toggleDetails}>
        
      </OffcanvasHeader> */}
      <Button color="dark-outline" className="btn-close-off-canvas align-self-start" style={{float: 'right', marginRight: '-25px', marginBottom: '-20px', zIndex: 1001}} onClick={toggleActions}><X size={25} /></Button>
      <OffcanvasBody style={{minHeight: '100%', display: 'flex', width: '100%' }}>
        <Row className="flex-fill">
          {/* <Col xs={{ size: 12, order: 2}} lg={{ size: 4, order: 1 }}>
            ...1
          </Col>
          <Col xs={{ size: 12, order: 1}} lg={{ size: 8, order: 2 }}>
            <Row>
              <Col>
                ...2
              </Col>
            </Row>
            <Row>
              ...3
            </Row>
          </Col> */}
          <Col>
            <div className="border ml-3 p-3" style={{flex: '0 0 auto', maxWidth: '100%', height: '100%'}}>
              <Row>
                <Col>
                  <fieldset className="mt-3">
                    <legend><Badge><ReceiptCutoff /></Badge> Electronic Return Receipts</legend>
                    <hr />

                    <Row className="mt-3">
                      <Col>
                        {GenerateProcessList(true)}
                        {GenerateProcessList(false)}
                      </Col>
                    </Row>

                    {isIntegrationPartner && (
                      <Row className="mt-3">
                        <Col>
                          <MailStatusAttachErr
                            onChange={value => dispatch({ type: 'MERGE', value })}
                          />
                        </Col>
                      </Row>
                    )}
                  </fieldset>
                </Col>
              </Row>
              <Row>
                <Col>
                  <hr />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Button color="dark" className="w-100" onClick={ onGenerateClick }><JournalArrowDown /> View{state.attachElectronicReturnReceipt ? " & Attach" : ""}  Receipt{selectedMailingData && selectedMailingData.length > 1 ? "s" : ""}</Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default MailStatusActionsElectronicReceipts