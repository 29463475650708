import React, { useEffect, useState } from 'react'
import { ArrowRight } from 'react-bootstrap-icons';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

const AddressTypeDropDown = React.memo(({addressTypeOptions, onChange}) => {

  const onAddressTypeClick = (e) => {
    const newAddressTypeId = parseInt(e.currentTarget.getAttribute("addresstype"))
    const selected = addressTypeOptions.find(addressType => addressType.AddressTypeID === newAddressTypeId)
    onChange(selected)
  }

  let newOptions = []
  if (addressTypeOptions.length > 1) {
    newOptions = [
      ...addressTypeOptions.slice(0, 1),
      { isDivider: true, AddressTypeID: 9999 },
      ...addressTypeOptions.slice(1),
    ]
  } else {
    newOptions = addressTypeOptions
  }

  return (
    newOptions.map((addressType, index) => 
      addressType.isDivider ? (
        <DropdownItem key={addressType.AddressTypeID} divider={true} />
      ) : (
        <DropdownItem key={addressType.AddressTypeID} onClick={onAddressTypeClick} addresstype={addressType.AddressTypeID}>
          {addressType.Name}
        </DropdownItem>
      )
    )
  )
})

export default (props) => {
  //console.log(props)

  const { addressTypes, currentAddressType, onAddressTypeChanged } = props

  const [expandState, setExpandState] = useState('collapsed');

  const expandOrCollapse = (e) => {
    console.log(e.target)
    e.stopPropagation()
    e.target.parentElement.blur()
    let currentState = props.columnGroup.getOriginalColumnGroup().isExpanded();
    props.setExpanded(!currentState);
  };

  const syncExpandButtons = () => {
    setExpandState(
      props.columnGroup.getOriginalColumnGroup().isExpanded()
        ? 'expanded'
        : 'collapsed'
    );
  };

  useEffect(() => {
    //console.log(props)
    //console.log(props.columnGroup)
    //console.log(props.columnGroup.getOriginalColumnGroup())
    props.columnGroup
      .getOriginalColumnGroup()
      .addEventListener('expandedChanged', syncExpandButtons);
    syncExpandButtons();

    return () => {
      props.columnGroup
        .getOriginalColumnGroup()
        .removeEventListener('expandedChanged', syncExpandButtons);
    };
  }, []);

  return (
    <div className="ag-header-group-cell-label" style={{overflow: 'visible'}}>
      <UncontrolledDropdown>
          <DropdownToggle caret color="white-outline" className="ag-header-cell-text">
            <div className="d-flex">
              <div className="flex-grow-0 text-start">
                <div className="d-flex">
                  <div className="flex-grow-0 customHeaderLabel">{props.displayName}</div>
                  <div
                    className={`flex-grow-0 customExpandButton ${expandState}`}
                    onClick={expandOrCollapse}
                  >
                    <ArrowRight size={15} style={{pointerEvents: 'none'}} />
                  </div>
                </div>
              </div>
              {
              //<div className="flex-grow-1 text-end dropdown-selection">(showing <span ref={addressTypeSpanRef}></span>)</div>
              }
              {currentAddressType && (
                <div className="flex-grow-1 text-end dropdown-selection">
                  (showing {currentAddressType.Name})
                </div>
              )}
            </div>
          </DropdownToggle>
          <DropdownMenu end>
            <AddressTypeDropDown
              addressTypeOptions={addressTypes}
              onChange={onAddressTypeChanged}
            >
            </AddressTypeDropDown>
          </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}