import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch, withRouter, useLocation } from 'react-router-dom'
import { Layout } from './components/Layout'
import Forbidden from './components/Forbidden'
import Error from './components/Error'
import SendMailTenants from './components/send-mail/SendMailTenants'
import SendMailProspects from './components/send-mail/SendMailProspects'
import SendMailCustom from './components/send-mail-custom/SendMailCustom'
import SendMailAddressWizard from './components/send-mail-address-wizard/SendMailAddressWizard'
import SendMailAddressWizardManager from './components/send-mail-address-wizard/SendMailAddressWizardManager'
import SendMailHistory from './components/send-mail-history/SendMailHistory'
import MailStatus from './components/mail-status/MailStatus'
//import { Home } from './components/Home'
import Admin from './components/admin/Admin'
import AdminSettings from './components/admin/Settings'
import AdminStandardSettings from './components/admin/StandardSettings'
import AdminUsers from './components/admin/Users'
import AdminSendMailLogs from './components/admin/SendMailLogs'
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute'
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes'
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants'
import { ToastContainer, toast } from 'react-toastify';
import { Roles } from './services/RoleService'

// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-toastify/dist/ReactToastify.css';
import './custom.scss'
import authService from './components/api-authorization/AuthorizeService';
import { useUserService } from './services/UserService'
//import SendMailHistoryResult from './components/send-mail-history/SendMailHistoryResult'
import { useClientService } from './services/ClientService'
// import AuthContextProvider from './context/AuthContext'
// import UserPreferencesContextProvider from './context/UserPreferencesContext'

const App = ({ history }) => {
  
  let _subscription = null

  const [state, setState] = useState({
    isLoading: true,
    isAuthenticated: false,
    isIntegrationPartner: false
  })

  const userService = useUserService()
  const clientSevice = useClientService()

  const location = useLocation();

  useEffect(() => {
    App.displayName = App.name
    toast.configure()
    history.listen((listenerLocation, action) => {
      // do something on route change
      // for my example, close a drawer
      console.log(listenerLocation)
      console.log(listenerLocation.pathname)
      console.log(action)

      callRouteChanged(listenerLocation.pathname)
    })

    callRouteChanged(location.pathname)
  }, [])

  useEffect(() => {
    _subscription = authService.subscribe(() => populateState());
    populateState();

    // Specify how to clean up after this effect:
    return function cleanup() {
        authService.unsubscribe(_subscription);
    };
  }, []);

  const populateState = async () => {
    let isIntegrationPartner = false
    const isAuthenticated = await authService.isAuthenticated()

    if (isAuthenticated) {
      const myClient = await clientSevice.GetMyClient()
      isIntegrationPartner = myClient && myClient.IsIntegrationPartner
    }

    console.log('app isIntegrationPartner', isIntegrationPartner)

    setState({
        isLoading: false,
        isAuthenticated,
        isIntegrationPartner
    });
  }


  const onRouteChanged = async () => {
    console.log("ROUTE CHANGED")

    await userService.GetUserStatus()
  }

  const callRouteChanged = (pathname) => {
    switch(pathname) {
      case ApplicationPaths.Login:
      case ApplicationPaths.LoginFailed:
      case ApplicationPaths.LoginCallback:
      case ApplicationPaths.LogOut:
      case ApplicationPaths.LogOutCallback:
      case ApplicationPaths.LoggedOut:
        break;
      default:
        onRouteChanged()
    }    
  }

  return (
    <React.Fragment>
      {/* <AuthContextProvider>
        <UserPreferencesContextProvider> */}

          <Layout>
            <Switch>
              <Route path="/forbidden" component={Forbidden} />
              <Route path="/error" component={Error} />
              {/* <Route exact path='/'>
                <Redirect to="/send-mail-tenants" />
              </Route>
              <Route path='/send-mail'>
                <Redirect to="/send-mail-tenants" />
              </Route> */}
              <Route exact path='/' render={() => {
                //console.log('at this moment 1: ', state.isIntegrationPartner)
                  return (
                    state.isLoading ? "" :
                    state.isAuthenticated && state.isIntegrationPartner ?
                      <Redirect to="/send-mail-tenants" /> :
                      <Redirect to="/send-mail-custom" /> 
                  )
                }}
              />
              <Route exact path='/send-mail' render={() => {
                //console.log('at this moment 2: ', state.isIntegrationPartner)
                  return (
                    state.isLoading ? "" :
                    state.isAuthenticated && state.isIntegrationPartner ?
                      <Redirect to="/send-mail-tenants" /> :
                      <Redirect to="/send-mail-custom" /> 
                  )
                }}
              />
              <AuthorizeRoute path='/send-mail-tenants' component={SendMailTenants} />
              <AuthorizeRoute path='/send-mail-prospects' component={SendMailProspects} />
              <AuthorizeRoute path='/send-mail-custom' component={SendMailCustom} />
              <AuthorizeRoute exact path='/send-mail-address-wizard' component={SendMailAddressWizard} />
              <AuthorizeRoute exact path='/send-mail-address-wizard/manager' component={SendMailAddressWizardManager} role={Roles.Administrator} />
              <AuthorizeRoute exact path='/send-mail-history' component={SendMailHistory} />
              <AuthorizeRoute exact path='/send-mail-history/:sendMailId' component={SendMailHistory} />
              <AuthorizeRoute path='/mailing-status' component={MailStatus} />
              <AuthorizeRoute exact path='/admin' component={Admin} role={Roles.Administrator} />
              <Route exact path='/admin/settings' render={() => {
                return (
                  state.isLoading ? "" :
                  state.isIntegrationPartner ? (
                    <AuthorizeRoute exact path='/admin/settings' component={AdminSettings} role={Roles.Administrator} />
                  ) : (
                    <AuthorizeRoute exact path='/admin/settings' component={AdminStandardSettings} role={Roles.Administrator} />
                  )
                )
              }} />
              <AuthorizeRoute exact path='/admin/users' component={AdminUsers} role={Roles.Administrator} />
              <AuthorizeRoute exact path='/admin/send-mail-logs' component={AdminSendMailLogs} role={Roles.SystemAdministrator} />
              <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Switch>
            
            <ToastContainer
              position="bottom-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
            />
          </Layout>
        {/* </UserPreferencesContextProvider>
      </AuthContextProvider> */}
    </React.Fragment>
  )
}

export default withRouter(App);