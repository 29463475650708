import React, { useCallback, useRef } from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { FileEarmarkPlusFill } from 'react-bootstrap-icons'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from '@dnd-kit/sortable';
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { nanoid } from 'nanoid';
import { Blank } from '../dropdowns/DefaultSelectOptions'
import SortableMailingPart from './SortableMailingPart';
import { LetterPartTypes } from '../../services/SendMailService';

export const SortableMailingParts = ({letterTemplates, statementTemplates, authToken, onChange, onPreviewMailingPart, onIsUploadingChanged, items, showStatementTemplates = false}) => {
  const mailingPartRefs = useRef([])

  mailingPartRefs.current = items.map(
    (_, i) => mailingPartRefs.current[i] ?? React.createRef()
  );

  const setItems = (newItems) => {
    onChange(newItems)
  }

  // const mouseSensor = useSensor(MouseSensor);
  // const touchSensor = useSensor(TouchSensor);
  // const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // const rentManagerTemplate = useRef([])

  // useEffect(() => {
  //   load()
  // }, [])

  // const load = async () => {
  //   rentManagerTemplate.current = [
  //     Blank,
  //     { value: 1, label: 'Template A'},
  //     { value: 2, label: 'Template G'},
  //     { value: 5, label: 'Template Z'},
  //   ]
  // }

  const handleChange = (id, option) => {
    console.log('handleChange', id, option)
    //const items = state.items
    //console.log(items)

    const modifiedItemIndex = items.findIndex(item => item.id === id)
    
    const newItem = {
      ...items[modifiedItemIndex],
      ...option
    }

    const newItems = [
      ...items.slice(0, modifiedItemIndex),
      newItem,
      ...items.slice(modifiedItemIndex + 1)
    ]

    //dispatch({type: 'SET', name: 'items', value: newItems})
    setItems(newItems)
  }

  const addRentManagerPart = (templates, letterPartType, value) => {
    console.log('addRentManager' + letterPartType + 'Template', templates)

    //filter out templates that are already used
    const unusedTemplates = templates.filter(template => !items.find(item => item.type === letterPartType && item.value === template.value && template.value !== Blank.value))

    const newId = nanoid(8)
    const newItem = {
      id: newId,
      label: 'Item ' + newId,
      type: letterPartType,
      options: unusedTemplates,
      value: value,
    }

    const newItems = [
      ...items,
      newItem
    ]

    console.log('newItems', newItems)

    setItems(newItems)
  }

  const addRentManagerLetterTemplate = () => {
    addRentManagerPart(letterTemplates, LetterPartTypes.RentManager, Blank.value)
  }

  const addRentManagerCustomFormTemplate = () => {
    addRentManagerPart(statementTemplates, LetterPartTypes.RentManagerCustomForm, {
      optionValue:  Blank.value,
      optionLabel:  Blank.label,
      commentValue: '',
      dateSelectionRange: [{
        startDate: new Date(),
        endDate: new Date(), //null will select up to end of month
        key: 'selection'
      }],
      showUnpostedPerDayLateFeesToDay: false,
      showDailyInterestLoans: false,
      showMeterReadingsAndUsage: false,
      showCalculationDetailsMUPlus: false
    })
  }

  const addStaticPdf = () => {
    const newId = nanoid(8)
    const newItem = {
      id: newId,
      label: 'Item ' + newId,
      type: LetterPartTypes.Fixed,
      //options: rentManagerTemplate.current,
      //value: All.value,
    }

    const newItems = [
      ...items,
      newItem
    ]

    console.log('newItems', newItems)

    //dispatch({type: 'SET', name: 'items', value: newItems})
    setItems(newItems)
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active?.id !== over?.id) {

      //const activeIndex = state.items.findIndex((item) => item.id === active?.id);
      //const overIndex = state.items.findIndex((item) => item.id === over?.id);
      const activeIndex = active.data.current.sortable.index
      const overIndex = over.data.current.sortable.index

      const newItems = arrayMove(items, activeIndex, overIndex);

      //dispatch({type: 'SET', name: 'items', value: newItems})
      setItems(newItems)

      // setItems((prev) => {
      //   const activeIndex = prev.findIndex((item) => item.id === active?.id);
      //   const overIndex = prev.findIndex((item) => item.id === over?.id);
      //   return arrayMove(prev, activeIndex, overIndex);
      // });
    }
  }

  const handleRemove = useCallback((id) => {

    console.log('onRemoveHandler', id)
    //const items = items

    const index = items.findIndex(item => item.id === id)

    console.log('onRemoveHandler', index)

    const newItems = [
      ...items.slice(0, index),
      ...items.slice(index + 1)
    ]
    
    //dispatch({type: 'SET', name: 'items', value: newItems})
    setItems(newItems)

  }, [items])

  const handleRemoveAll = useCallback(async () => {
    const promisses = mailingPartRefs.current.map((part) => part.current.remove())

    await Promise.all(promisses)

    console.log('all removed')

    setItems([])
  }, [mailingPartRefs])

  return (
    <>
      <div className='d-flex flex-column'>
        <div className='d-flex flex-row align-items-center py-1'>
          <UncontrolledDropdown className='w-100' color='dark'>
            <DropdownToggle color='dark' className='w-100 d-flex flex-row justify-content-between align-items-center' caret>
              <FileEarmarkPlusFill size={20} className='text-white' /> <span className=''>Add Document</span>
            </DropdownToggle>
            <DropdownMenu className='w-100'>
              <DropdownItem onClick={addRentManagerLetterTemplate}>Rent Manager Letter</DropdownItem>
              {showStatementTemplates && (
                <DropdownItem onClick={addRentManagerCustomFormTemplate}>Rent Manager Statement</DropdownItem>
              )}
              <DropdownItem onClick={addStaticPdf}>Static PDF</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {items && items.length !== 0 && (
              <div className='border rounded'>
                <ul className='sortable-list w-100 px-1 py-1 m-0 p-0' style={{ listStyleType: 'none' }}>
                  {items.map((item, index) => (
                    <SortableMailingPart
                      authToken={authToken}
                      ref={mailingPartRefs.current[index]}
                      key={item.id}
                      item={item}
                      onChange={handleChange}
                      onRemove={handleRemove}
                      onPreview={onPreviewMailingPart}
                      onIsUploadingChanged={onIsUploadingChanged}
                    />
                  ))}
                </ul>
                {items && items.length > 1 && (
                  <div className='w-100 text-end'><a className='clear-action me-2 mb-1' onClick={handleRemoveAll}>clear</a></div>
                )}
              </div>
            )}
          </SortableContext>
        </DndContext>
      </div>
    </>
  )
}