import React, { useReducer, useEffect, useState } from 'react'
import { Label, FormGroup, Col, Row } from 'reactstrap'
import { ConfirmationOfMailingOption, DocumentClassSelect, EnvelopesSelect, LayoutsSelect, ProductionTimeSelect, ColorsSelect, PaperTypesSelect, MailClassesSelect, PrintOptionsSelect, EnclosureSelect } from './MailingFieldsDropDowns'
import { AspectRatio, Envelope, EnvelopePaper, FileEarmark, HourglassSplit, Layers, LayoutSplit, Palette, PatchCheck } from 'react-bootstrap-icons'

const MailingFields = ({onChange, documentClasses, documentClassOptions}) => {

  const [displayOptions, setDisplayOptions] = useState('none')

  const initialState = {
    documentClassLabel: 'Mailing Type',
    documentClass: 0,
    documentClassText: '',
    documentClassLoaded: false,
    documentClassHasOptions: false,
    mailClassLabel: 'Mail Class',
    mailClass: 0,
    mailClassText: '',
    mailClassLoaded: false,
    mailClassHasOptions: false,
    mailClassVisible: true,
    envelopeLabel: 'Envelope',
    envelope: 0,
    envelopeText: '',
    envelopeLoaded: false,
    envelopeHasOptions: false,
    envelopeVisible: true,
    colorLabel: 'Color',
    color: 0,
    colorText: '',
    colorLoaded: false,
    colorHasOptions: false,
    colorVisible: true,
    paperTypeLabel: 'Paper Type',
    paperType: 0,
    paperTypeText: '',
    paperTypeLoaded: false,
    paperTypeHasOptions: false,
    paperTypeVisible: true,
    layoutLabel: 'Layout',
    layout: 0,
    layoutText: '',
    layoutLoaded: false,
    layoutHasOptions: false,
    layoutVisible: true,
    printOptionLabel: 'Simplex/Duplex',
    printOption: 0,
    printOptionText: '',
    printOptionLoaded: false,
    printOptionHasOptions: false,
    printOptionVisible: true,
    productionTimeLabel: 'Production Time',
    productionTime: 0,
    productionTimeText: '',
    productionTimeLoaded: false,
    productionTimeHasOptions: false,
    productionTimeVisible: true,
    enclosureLabel: 'Reply Envelope',
    enclosure: 0,
    enclosureText: '',
    enclosureLoaded: false,
    enclosureHasOptions: false,
    enclosureVisible: true,
    includeConfirmationOfMailingVisible: true
  }  

  //const [mailingFields, setMailingFields] = useReducer(})
  const mailingFieldsReducer = (state, action) => {
    //console.log(action)
    //console.log(action.selected)
    switch (action.type) {
      case 'LOADED':
        //console.log('LOADED', action)
        return {
          ...state,
          [action.name + 'Loaded']: true,
          [action.name + 'HasOptions']: action.value.hasOptions
        }
      case 'SET': {
        return {
          ...state,
          [action.name]: action.selected.value,
          [action.name + 'Text']: action.selected.label
        }
      }
      case 'SETVALUE': {
        return {
          ...state,
          [action.name]: action.value,
        }
      }
      case 'RESET': {
        setDisplayOptions('none')
        const newState = {
          ...state,
          [action.name]: action.selected.value,
          [action.name + 'Text']: action.selected.label,
          [action.name + 'Loaded']: true,
          [action.name + 'HasOptions']: true
        };
        Object.entries(newState).forEach(([key, value]) => {
          if (key.endsWith('Loaded') && key !== action.name + 'Loaded') {
            newState[key] = false
          }
        })

        const documentClass = documentClasses.find(documentClass => documentClass.C2MDocumentClassID.toString() === action.selected.value.toString())
        console.log('documentClass', documentClass)
        console.log('documentClassOptions', documentClassOptions)
        documentClassOptions.forEach((option) => {
          //console.log('option', option.Code, documentClass.HiddenOptions & option.C2MDocumentClassOptionID)
          //console.log('documentClass.HiddenOptions', documentClass.HiddenOptions, option.C2MDocumentClassOptionID)
          //console.log(option.Code + 'Visible', !(documentClass.HiddenOptions & option.C2MDocumentClassOptionID === option.C2MDocumentClassOptionID))
          //console.log(option.Code + 'Visible 64', !((documentClass.HiddenOptions & option.C2MDocumentClassOptionID) === 64))

          newState[option.Code + 'Visible'] = !((documentClass.HiddenOptions & option.C2MDocumentClassOptionID) === option.C2MDocumentClassOptionID)
          //console.log('+++++++++++++++' + option.Code + ": ", newState[option.Code + 'Visible'])
        })

        return newState;
      }
    }

    return state
  }

  const [state, dispatch] = useReducer(mailingFieldsReducer, initialState)

  useEffect(() => {
    let ready = true

    Object.entries(state).forEach(([key, value]) => {
      if (key.endsWith('Loaded') && value !== true) {
        ready = false
      }
    });

    if (ready) {
      console.log('ALL ARE READY!!')
      let result = {}
      Object.entries(state).forEach(([key, value]) => {
        if (!key.endsWith('Loaded')) {
          result[key] = value
        }
      })

      setDisplayOptions('block')
      onChange(result)
    }
  }, [state])

  const documentClassVisiblityStyle = (isVisible) => {
    if (isVisible) {
      return {
        visibility: 'visible',
        height: 'auto'
      }
    } else {
      return {
        visibility: 'hidden',
        height: '0px'
      }
    }
  }

  // const updateMailingFields = async (name, value) => {
  //   console.log(`update ${name} to ${value}`)
  //   await setMailingFields({
  //     ...mailingFields,
  //     [name]: value
  //   })
  // }

  // const onDocumentClassChange = (selectedMailingGroup) => {
  //   updateMailingFields('documentClass', selectedMailingGroup.value)
  // }

  // const onOptionChange = (optionName, selected) => {
  //   updateMailingFields(optionName, selected.value)
  // }

  // useEffect(() => {
  //   console.log('useEffect triggered by mailing fields change')
  //   onChange(mailingFields)
  // }, [mailingFields])

  return (
    <div className="mailing-type-options">
      <FormGroup row>
        <Label><Envelope />{ state.documentClassLabel }</Label>
        <Col>
          {/* <DocumentClassSelect onChange={val => onOptionChange('documentClass', val)} /> */}
          <DocumentClassSelect
                onLoaded={value => dispatch({ type: 'LOADED', name: 'documentClass', value })}
                onChange={opt => dispatch({ type: 'RESET', name: 'documentClass', selected: opt })}
                placeholder="Select Mailing Type"
              />
        </Col>
      </FormGroup>
      {
        state.documentClass !== 0 && (
          <div style={{display: displayOptions}}>
            <div style={documentClassVisiblityStyle(state.mailClassVisible)}>
              <FormGroup row>
                <Label><PatchCheck />{ state.mailClassLabel }</Label>
                <MailClassesSelect documentClass={state.documentClass}
                  onLoaded={value => dispatch({ type: 'LOADED', name: 'mailClass', value })}
                  onChange={opt => dispatch({ type: 'SET', name: 'mailClass', selected: opt })}
                />
              </FormGroup>
            </div>
            <div style={documentClassVisiblityStyle(state.envelopeVisible)}>
              <FormGroup row>
                <Label><AspectRatio />{ state.envelopeLabel }</Label>
                <EnvelopesSelect documentClass={state.documentClass}
                  onLoaded={value => dispatch({ type: 'LOADED', name: 'envelope', value })}
                  onChange={opt => dispatch({ type: 'SET', name: 'envelope', selected: opt })}
                />
              </FormGroup>
            </div>
            <div style={documentClassVisiblityStyle(state.colorVisible)}>
              <FormGroup row>
                <Label><Palette />{ state.colorLabel }</Label>
                <ColorsSelect documentClass={state.documentClass}
                  orderByName={true}
                  onLoaded={value => dispatch({ type: 'LOADED', name: 'color', value })}
                  onChange={opt => dispatch({ type: 'SET', name: 'color', selected: opt })}
                />
              </FormGroup>
            </div>
            <div style={documentClassVisiblityStyle(state.paperTypeVisible)}>
              <FormGroup row>
                <Label><FileEarmark />{ state.paperTypeLabel }</Label>
                <PaperTypesSelect documentClass={state.documentClass}
                  onLoaded={value => dispatch({ type: 'LOADED', name: 'paperType', value })}
                  onChange={opt => dispatch({ type: 'SET', name: 'paperType', selected: opt })}
                />
              </FormGroup>
            </div>
            <div style={documentClassVisiblityStyle(state.layoutVisible)}>
              <FormGroup row>
                <Label><Layers />{ state.layoutLabel }</Label>
                <LayoutsSelect documentClass={state.documentClass}
                  onLoaded={value => dispatch({ type: 'LOADED', name: 'layout', value })}
                  onChange={opt => dispatch({ type: 'SET', name: 'layout', selected: opt })}
                />
              </FormGroup>
            </div>
            <div style={documentClassVisiblityStyle(state.printOptionVisible)}>
              <FormGroup row>
                <Label><LayoutSplit />{ state.printOptionLabel }</Label>
                <PrintOptionsSelect documentClass={state.documentClass}
                  onLoaded={value => dispatch({ type: 'LOADED', name: 'printOption', value })}
                  onChange={opt => dispatch({ type: 'SET', name: 'printOption', selected: opt })}
                />
              </FormGroup>
            </div>
            <div style={documentClassVisiblityStyle(state.productionTimeVisible)}>
              <FormGroup row>
                <Label><HourglassSplit />{ state.productionTimeLabel }</Label>
                <ProductionTimeSelect documentClass={state.documentClass}
                  onLoaded={value => dispatch({ type: 'LOADED', name: 'productionTime', value })}
                  onChange={opt => dispatch({ type: 'SET', name: 'productionTime', selected: opt })}
                />
              </FormGroup>
            </div>
            <div className="mb-3">
              <div style={documentClassVisiblityStyle(state.enclosureVisible)}>
                <EnclosureSelect documentClass={state.documentClass}
                      onLoaded={value => dispatch({ type: 'LOADED', name: 'enclosure', value })}
                      onChange={retVal => {
                        dispatch({ type: 'SET', name: 'enclosure', selected: retVal.enclosureOption })
                        dispatch({ type: 'SETVALUE', name: 'includeEnclosure', value: retVal.includeEnclosure })
                      }}
                    />
              </div>
            </div>

            <div className="mb-3">
              <div style={documentClassVisiblityStyle(state.includeConfirmationOfMailingVisible)}>
              <div className="primary">
                <ConfirmationOfMailingOption documentClass={state.documentClass}
                  onChange={retVal => {
                    dispatch({ type: 'SETVALUE', name: 'includeConfirmationOfMailing', value: retVal.includeConfirmationOfMailing })
                  }}
                />
              </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default MailingFields
