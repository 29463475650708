import { useSettingsService } from '../../services/SettingsService'
import { toast } from 'react-toastify';
import { useClientService } from '../../services/ClientService';

export { useSettings }

const useSettings = () => {

  const settingsService = useSettingsService()
  const clientService = useClientService()

  const settingsReducer2 = (state, action) => {
    console.log('action: ', action.type, action.name, action.value)

    let newState = state

    switch (action.type) {
      case 'SET':
        newState = {
          ...state,
          [action.name]: action.value
        }
        break
      case 'SET_OPT_VALUE':
        newState = {
          ...state,
          [action.name]: {
            ...state[action.name],
            value: action.value.value
          }
        }
        break
      case 'SET_CODE': {
        newState = {
          ...state,
          [action.name]: {
            ...state[action.name],
            code: action.value
          }
        }
        break
      }
      case 'SET_VALUE':
        newState = {
          ...state,
          [action.name]: {
            ...state[action.name],
            value: action.value
          }
        }
        break
      case 'SET_OPTIONS':
        newState = {
          ...state,
          [action.name]: {
            ...state[action.name],
            options: action.value
          }
        }
        break
      case 'SET_ALL_OPTIONS':
        newState = {
          ...state,
          [action.name]: {
            ...state[action.name],
            allOptions: action.value
          }
        }
        break
    }

    // eslint-disable-next-line no-undef
    if (isFunction(action.setValue)) {
      action.setValue(action.name, newState[action.name], { shouldValidate: newState.isSubmitted })
    }    

    return newState
  }

  // const settingsReducer = (state, action) => {
  //   switch (action.type) {
  //     case 'LOADED':
  //       return {
  //         ...state,
  //         [action.name + 'Loaded']: true,
  //         [action.name + 'HasOptions']: action.value.hasOptions
  //       }
  //     case 'INIT':
  //       console.log(action)
  //       return {
  //         ...state,
  //         [action.name]: action.init.initialValue,
  //         [action.name + 'InitialValue']: action.init.initialValue,
  //         [action.name + 'Description']: action.init.description,
  //         [action.name + 'ConstantCode']: action.init.constantCode,
  //         [action.name + 'HasChanged']: false
  //       }
  //     case 'SETOPT':

  //       // eslint-disable-next-line no-undef
  //       if (isFunction(state.setValue)) {
  //         state.setValue(action.name, action.value, { shouldValidate: state.isSubmitted })
  //       }

  //       console.log(action)

  //       return {
  //         ...state,
  //         [action.name]: action.value.value,
  //         [action.name + 'InitialValue']: action.value.value,
  //         [action.name + 'Text']: action.value.label,
  //         [action.name + 'HasChanged']: (action.value.value.toString() !== state[action.name + 'InitialValue'].toString())
  //       }

  //     case 'SET':

  //       console.log('action: ', action.name, action.value)

  //       // eslint-disable-next-line no-undef
  //       if (isFunction(state.setValue)) {
  //         state.setValue(action.name, action.value, { shouldValidate: state.isSubmitted })
  //       }

  //       console.log(action)
  //       return {
  //         ...state,
  //         [action.name]: action.value,
  //         [action.name + 'InitialValue']: action.value,
  //         //[action.name + 'Text']: action.selected.label,
  //         [action.name + 'HasChanged']: (action.value.toString() !== state[action.name + 'InitialValue'].toString())
  //       }
  //     case 'SETVALUEONLY':
  //       console.log(action)
  //       return {
  //         ...state,
  //         [action.name]: action.value,
  //         [action.name + 'InitialValue']: action.value,
  //       }
  //   }

  //   return state
  // }

  // const notifyChangeWhenReady = (onChange, state) => {
  //   let ready = true

  //   Object.entries(state).forEach(([key, value]) => {
  //     if (key.endsWith('Loaded') && value !== true) {
  //       ready = false
  //     }
  //   });

  //   if (ready) {
  //     let result = {}

  //     console.log('ALL ARE READY!!')
  //     Object.entries(state).forEach(([key, value]) => {
  //       if (!key.endsWith('Loaded')) {
  //         result[key] = value
  //       }
  //     })

  //     onChange(result)
  //   }
  // }

  //const loadSettingsConstants = (state, constants) => new Promise(async (resolve, reject) => {
  const loadSettingsConstants = (state) => new Promise(async (resolve, reject) => {

    console.log('loadSettingsConstants called') 

    const constants = []
    Object.entries(state).forEach(([ctxKey, ctxValue]) => {
      if (ctxValue.constant) {
        constants.push(ctxValue.constant)
      }
    })

    console.log('get constants', constants)  
    const dispatches = []
    const constantsResults = await settingsService.GetConstants(constants)

    console.log('constantsResults', constantsResults)

    var constantCodes = constantsResults.map(constant => constant.value)
    
    //return await settingsService.GetClientSettings(constantCodes)
    var clientSettings = await settingsService.GetClientSettings(constantCodes)

    console.log('clientSettings', clientSettings)

    Object.entries(state).forEach(([ctxKey, ctxValue]) => {
      if (ctxValue.constant) {
        console.log(ctxKey, ctxValue)
        const constantResultMatch = constantsResults.find(code => code.name === ctxValue.constant)
        if (constantResultMatch) //otherwise the code belongs to another settings type (not a constant)
        {
          console.log('constantResultMatch', constantResultMatch)
          const constantCode = constantResultMatch.value
          const setting = clientSettings.find(setting => setting.constantCode === constantCode)

          console.log('setting found', setting)

          dispatches.push({ type: 'SET_CODE', name: ctxKey, value: setting.constantCode })

          const type = ctxValue.type
          let value = setting.constantValue //string
          if (type === 'Boolean') {
            if (value.toLowerCase() === "true") {
              value = true;
            }
            else {
              value = false;
            }
          }
          else if (type === 'Integer') {
            if (!isNaN(value) && value) {
              value = parseInt(value);
            }
          }
          else if (type === 'Float') {
            if (!isNaN(value) && value) {
              value = parseFloat(value);
            }
          }

          dispatches.push({ type: 'SET_VALUE', name: ctxKey, value: value })
          
          // dispatchNotifications.push({ type: 'INIT', name: valueKey, init: 
          // { 
          //   initialValue: setting.constantValue,
          //   description: setting.constantDescription,
          //   constantCode: constantCode.value
          // }})

        }
      }
    })


    resolve(dispatches)
      
      // var clientSettings = await settingsService.GetClientSettings(constantCodes)
      // console.log('clientSettings');
      // console.log(clientSettings);

      // Object.entries(state).forEach(([key, value]) => {
      //   if (key.endsWith('Constant')) {
      //     const constantCode = constantsResults.find(code => code.name === value)
      //     if (constantCode) //otherwise the code belongs to another settings group
      //     {
      //       //console.log('constantCode:', constantCode)
      //       const setting = clientSettings.find(setting => setting.constantCode === constantCode.value)
      //       const valueKey = key.substring(0, key.length - 'Constant'.length)
  
      //       dispatchNotifications.push({ type: 'INIT', name: valueKey, init: 
      //       { 
      //         initialValue: setting.constantValue,
      //         description: setting.constantDescription,
      //         constantCode: constantCode.value
      //       }})
      //     }
      //   }
      // })

      // console.log('setLoadingConstants done');
      // console.log(dispatchNotifications);
      // resolve(dispatchNotifications);
    }
  )

  const settingsValueToString = (type, value) => {
    switch (type) {
      case 'Integer':
        return !value || isNaN(value) ? '0' : value.toString()
      case 'Float':
        return !value || isNaN(value) ? '0.00' : value.toString()
      default:
        return value?.toString()
    }
  }

  const saveSettingsConstants = async (state, message) => {
    const savables = Object.entries(state).filter(([key, value]) => value.hasOwnProperty('constant'))
      .map(([k,v]) => { return {constantCode: v.code, value: settingsValueToString(v.type, v.value) }})

    console.log('savables', savables)

    let isSuccess = false
    if (savables.length !== 0) {

      console.log('saving')
      if(await saveSettingsAddress(state)) {
        isSuccess = await settingsService.UpdateClientSettings(savables)
      }

      if (isSuccess) {
        resultToast('success', message)
      }
      else {
        resultToast('error', 'An unexpected error occured.')
      }
    }
    else {
      resultToast('warning', 'No changes to save.')
    }

    return isSuccess
  }

  const saveSettingsAddress = async (state) => {
    const saveAddress = {
      companyName : state.mailFuzeReturnAddressCustomCompanyName?.value,
      street1 : state.mailFuzeReturnAddressCustomStreet1?.value,
      city: state.mailFuzeReturnAddressCustomCity?.value,
      stateId: state.mailFuzeReturnAddressCustomStateID?.value,
      zip: state.mailFuzeReturnAddressCustomZip?.value,
    }

    return clientService.UpdateMyClientAddress(saveAddress)
  }

  const resultToast = (type, message) => {

    const params = {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    }

    switch(type){
      case 'success':
        toast.success(message, params)
        break
      case 'error':
        toast.error(message, params)
        break
      case 'warning':
        toast.warning(message, params)
        break
    }
  }

  return { settingsReducer2, loadSettingsConstants, saveSettingsConstants }
}