export const doRegister = (register, fieldName, isSubmitted, errors, registerMe = false) => {
  let registerAttr = {}
  if (registerMe) {
    registerAttr = {registerMe: () => register(fieldName)}
  }
  else {
    registerAttr = {...register(fieldName)}
  }

  return {
    ...registerAttr,
    valid: isSubmitted && (!errors?.[fieldName]),
    invalid: !!errors?.[fieldName],
    "aria-invalid": errors?.[fieldName] ? "true" : "false"        
  }
}

export const doRegisterSelect = (register, dispatch, fieldName, isSubmitted, errors, context, placeholder, applyInvalidClass = false) => {
  let applyClass = {}
  if (applyInvalidClass) {
    applyClass = {
      className: `${!!errors?.[fieldName] ? 'is-invalid': ''}`
    }
  }

  return {
    registerMe: () => register(fieldName),
    value: context[fieldName].value,
    valid: isSubmitted && (!errors?.[fieldName]),
    invalid: !!errors?.[fieldName],
    "aria-invalid": errors?.[fieldName] ? "true" : "false",
    onChange: (opt) => dispatch({ type: 'SET_OPT_VALUE', name: fieldName, value: opt }),
    placeholder: placeholder,
    message: errors?.[fieldName]?.value?.message,
    ...applyClass
  }
}

export const doRegisterInput = (register, dispatch, fieldName, isSubmitted, errors, context, idSuffix = '') => {
  return {
    ...doRegister(register, fieldName, isSubmitted, errors),
    value: context[fieldName].value ?? '',
    id: `${fieldName}${idSuffix}`,
    onChange: (e) => dispatch({ type: 'SET_VALUE', name: e.target.name, value: context[fieldName].type === 'Integer' ? (/^(?:[1-9][0-9]*|0)$/.test(e.target.value) ? e.target.value : '') : context[fieldName].type === 'Float' ? (/^\d*[\.{1}\d*]\d*$/.test(e.target.value) ? e.target.value : '') : e.target.value })
  }
}

export const doRegisterInputRadio = (value, register, dispatch, fieldName, isSubmitted, errors, context, idSuffix = '') => {
  return {
    ...doRegister(register, fieldName, isSubmitted, errors),
    type: "radio",
    value: value,
    id: `${fieldName}${value}${idSuffix}`,
    checked: context[fieldName].value == value ? 'checked' : '',
    onChange: (e) => dispatch({ type: 'SET_VALUE', name: e.target.name, value: context[fieldName].type === 'Integer' ? parseInt(e.target.value) : e.target.value })
  }
}

export const doRegisterInputCheckbox = (register, dispatch, fieldName, isSubmitted, errors, context, idSuffix = '') => {
  //console.log('context[' + fieldName + ']: ', context[fieldName])
  return {
    ...doRegister(register, fieldName, isSubmitted, errors),
    type: "checkbox",
    id: `${fieldName}${idSuffix}`,
    checked: context[fieldName].value == true ? 'checked' : '',
    value: context[fieldName].value,
    onChange: (e) => dispatch({ type: 'SET_VALUE', name: e.target.name, value: e.target.checked })
  }
}