import React, { Component } from 'react';
import NumberFormat from 'react-number-format'
import { ChargeOwnerMethods } from '../../services/SendMailService';

export default class CustomChargeOwnerCellRenderer extends Component {
  render() {
    {
      // if (this.props.format === 'currency') {
      //   return (<NumberFormat value={this.props.value} defaultValue={0} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}  prefix={'$'} />)
      // }
      // else {
      //   return (<span style={this.props.style}>{this.props.value}</span>)
      // }
      //console.log(this.props.data.Request)

      const request = JSON.parse(this.props.data.Request)
      let chargeMethod = ChargeOwnerMethods.None.toLowerCase()
      if (request.hasOwnProperty('chargeOwnerMethod')) {
        chargeMethod = request.chargeOwnerMethod.toLowerCase()
      } else {
        if (request.chargeOwner) {
          chargeMethod = ChargeOwnerMethods.FixedAmount.toLowerCase()
        }
      }

      //const chargeMethod = request.chargeOwnerMethod.toLowerCase()
      if (chargeMethod === ChargeOwnerMethods.None.toLowerCase()) {
        //return (<NumberFormat value={0} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}  prefix={'$'} />)
        return (<>-</>)
      } else if (chargeMethod === ChargeOwnerMethods.FixedAmount.toLowerCase()) {
        return (<NumberFormat value={request.chargeOwnerAmount} defaultValue={0} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}  prefix={'$'} />)
      } else if (chargeMethod === ChargeOwnerMethods.Cost.toLowerCase()) {
        return (<>Cost</>)
      } else if (chargeMethod === ChargeOwnerMethods.CostAmountMarkup.toLowerCase()) {
        return (<>Cost + <NumberFormat value={request.chargeOwnerCostAmountMarkup} defaultValue={0} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}  prefix={'$'} /></>)
      } else if (chargeMethod === ChargeOwnerMethods.CostPercentMarkup.toLowerCase()) {
        return (<>Cost + <NumberFormat value={request.chargeOwnerCostPercentMarkup} defaultValue={0} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} suffix={'%'} /></>)
      } else {
        return (<>--</>)
      }

      costAmountMarkup

      return ""
    }
  }
}