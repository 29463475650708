import React, { useReducer, useEffect } from 'react'
import { Row, Col } from 'reactstrap'

const ChargeAttachLetter = ({onChange, initialValue}) => {

  const initialState = {
    attachLetter: initialValue
  }

  const actionReducer = (state, action) => {
    switch (action.type) {
      case 'SET': {
        return {
          ...state,
          [action.name]: action.value
        }
      }
    }

    return state
  }

  const [state, dispatch] = useReducer(actionReducer, initialState)

  useEffect(() => {
    onChange(state)
  }, [state])

  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col sm={8}>Attach Letter to History</Col>
        <Col sm={4} className="pe-4">
          <div className="form-check form-switch d-flex align-items-end flex-column pe-1">
            <input
              className="form-check-input"
              type="checkbox"
              id="attachLetter"
              name="attachLetter"
              onChange={e => dispatch({ type: 'SET', name: e.target.name, value: e.target.checked })}
              checked={state.attachLetter ? 'checked' : ''}
              style={{transform: 'scale(1.6)'}}/>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ChargeAttachLetter