import { useFetchWrapper } from '../services/fetch-wrapper'

export { useAddressStateService };

const useAddressStateService = () => {

  const fetchWrapper = useFetchWrapper()

  const GetStates = async () => {
    const params = new URLSearchParams({addBlank: true}).toString();
    return await fetchWrapper.fetchDropDown(`addressstate?${params}`, "StateID", "StateName")
  }

  const GetStateCodes = async () => {
    const params = new URLSearchParams({addBlank: true}).toString();
    return await fetchWrapper.fetchDropDown(`addressstate/codes?${params}`, "StateID", "StateCode")
  }

  return { GetStates, GetStateCodes }
}
