import React from 'react'

const WarningInvalidThisPropertyAddress = ({result: { isValid }}) => {

  if (!isValid)
    {
      return (
        <div className='d-flex flex-fill flex-column justify-content-between'>
          <div>
            <div className='fw-bold d-flex justify-content-center'>Invalid Property Return Address</div>
            <hr />
            <p>MailFuze requires a valid Return Address. Please set the "Use Address From This Property" in the settings "Return Address" tab to a property with a valid address.</p>
          </div>
        </div>
      )
    }
    else
    {
      return (<></>)
    }
}

export default WarningInvalidThisPropertyAddress