import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { Link } from 'react-router-dom'
import { Button, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, NavLink } from 'reactstrap'
import { useUserService } from '../../services/UserService'
import { ApplicationPaths, QueryParameterNames } from '../api-authorization/ApiAuthorizationConstants'
import AdminMenu from './Menu'
import authService from '../api-authorization/AuthorizeService';
import { Roles } from '../../services/RoleService'
import { MultiLineCellRenderer } from '../../services/AGGridColumnFormatter'
import { UserCellMenuRenderer } from './UserCellMenu'
import BaseSelect from '../dropdowns/BaseSelect'
import { CustomLoadingOverlay } from '../widget/AGGridCustomOverlay'
import { toast } from 'react-toastify';

const Users = () => {
  const userService = useUserService()
  const registerPath = `${ApplicationPaths.Register}`
  const maxLines = 2; // This is key! Defines the number of lines that will be visible in each cell row. The last line will be truncated with ellipses.

  //const [loading, setLoading] = useState(true)
  const [isSystemAdmin, setIsSystemAdmin] = useState(false)
  const [userID, setUserID] = useState(null)
  const [userData, setUserData] = useState([])
  //const [gridApi, setGridApi] = useState()
  //const [gridColumnApi, setGridColumnApi] = useState()
  const [roleModalInfo, setRoleModalInfo] = useState([])
  //const [showModal, setShowModal] = useState(false)
  const [modifyingRow, setModifyingRow] = useState({})
  const [selectedRole, setSelectedRole] = useState({})
  const [showRoleModal, setShowRoleModal] = useState(false)
  const handleCloseRoleModal = () => setShowRoleModal(false)
  const handleShowRoleModal = () => setShowRoleModal(true)
  const toggleRoleModal = () => setShowRoleModal(!show)
  const gridRef = useRef()
  const canvasRef = useRef(document.getElementById('measurementCanvas') || document.createElement("canvas"))

  useEffect(() => {
    populateState()
    loadUsersGrid()
  }, [])

  const populateState = async () => {
    const [isAuthenticated, role, user] = await Promise.all([
      authService.isAuthenticated(),
      authService.getRole(),
      authService.getUser()])

    setIsSystemAdmin(role === Roles.SystemAdministrator || (role && role.includes(Roles.SystemAdministrator)))
    setUserID(user.sub)
  }

  const loadUsersGrid = async () => {
    onShowLoading()
    // if (!loading)
    // {
    //   setLoading(true)
    // }

    var data = await userService.GetUserListingData()
    console.log(data)
    setUserData(data)
    //setLoading(false)
    onHideOverlay()
    gridRef.current.api.sizeColumnsToFit()
  }

  const onChangeUserRole = (newRole) => {
    console.log(newRole)
    setSelectedRole(newRole)
  }

  const onSaveUserRole = async () => {
    await userService.UpdateUserRole(modifyingRow.data.userID, selectedRole.value)
    var updatedRow = await userService.GetUpdatedUserListingData(modifyingRow.data.userID)
    modifyingRow.setData(updatedRow[0])
    handleCloseRoleModal()
  }

  const onSetUserStatus = async (node, disabled) => {
    await userService.UpdateUserStatus(node.data.userID, disabled)
    var updatedRow = await userService.GetUpdatedUserListingData(node.data.userID)
    node.setData(updatedRow[0])
  }

  const onResetUserPassword = async (node) => {
    await userService.ResetUserPassword(node.data.userID)
    
    toast.success('Password Reset Successful', {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })
  }

  const onShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, [])

  const onHideOverlay = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  return (
    <div>
      <AdminMenu tab="users" />
      <div className="m-3">
        <h2 className="text-primary">Manage Users</h2>
        <hr />
        <div className="d-flex justify-content-end">
          <NavLink tag={Link} to={{pathname: registerPath, search: `${QueryParameterNames.ReturnUrl}=${window.location.href}`}}><Button color="secondary">Add User</Button></NavLink>
        </div>
      </div>
      <div className="m-3">
        {//!loading && (
          <div className="ag-theme-alpine" style={{minHeight: '150px', width: '100%' }}>
            <AgGridReact
              ref={gridRef}
              rowData={userData}
              domLayout="autoHeight"
              defaultColDef={{
                //resizable: true,
                sortable: true,
                filter: 'agTextColumnFilter',
                autoHeight: true,
                //cellRenderer: CellRenderer(canvas, maxLines)
              }}
              frameworkComponents={{
                btnCellRenderer: UserCellMenuRenderer,
                multiLineCellRenderer: MultiLineCellRenderer,
                customLoadingOverLoad: CustomLoadingOverlay
              }}
              pagination={false}
              loadingOverlayComponent="customLoadingOverLoad"
              loadingOverlayComponentParams={{
                loadingMessage: 'Loading...'
              }}
              suppressCellSelection={true}
              rowHeight={4+(1+maxLines*19)}

              // onColumnResized = {(event) => {
              //   console.log(event)
              //   if(event.column) {
              //     event.column.colDef.width = event.column.actualWidth
              //   }
              //   else if (event.columns){
              //     event.columns.forEach((column) => {
              //       column.colDef.width = column.actualWidth
              //     });
              //   }
              //   //gridApi.refreshView(); //depricated
              //   //gridApi.refreshCells() //causes my line breaks to disappear
              //   gridApi.redrawRows()                
              // }}
              onGridReady={(event) => { 
                //setGridApi(event.api)
                //setGridColumnApi(event.columnApi)
                event.api.sizeColumnsToFit()
            }}>
              <AgGridColumn field="username" headerName="User" tooltipField="username" maxWidth={300}></AgGridColumn>
              <AgGridColumn field="companyName" headerName="Company" tooltipField="companyName" hide={!isSystemAdmin}></AgGridColumn>
              <AgGridColumn field="city" headerName="City" tooltipField="city" hide={!isSystemAdmin} maxWidth={150}></AgGridColumn>
              <AgGridColumn field="stateCode" headerName="State" hide={!isSystemAdmin} suppressSizeToFit={true} maxWidth={100}></AgGridColumn>
              <AgGridColumn
                field="roles"
                cellRenderer="multiLineCellRenderer"
                cellRendererParams={{ canvasRef, maxLines }}
              ></AgGridColumn>
              <AgGridColumn
                field="disabled"
                headerName="Status"
                suppressSizeToFit={true}
                maxWidth={100}
                cellRenderer={params => {
                return '<span class="' + (params.value ? 'text-danger' : 'text-success') + '">' + (params.value ? 'Inactive' : 'Active') + '</span>';
              }}>
              </AgGridColumn>
              <AgGridColumn
                headerName=""
                filter={false}
                resizable={false}
                suppressSizeToFit={true}
                maxWidth={80}
                cellRenderer="btnCellRenderer"
                cellRendererParams={{
                  isSystemAdmin,
                  userID,
                  disableUserClicked(node) {
                    onSetUserStatus(node, true)
                  },
                  enableUserClicked(node) {
                    onSetUserStatus(node, false)
                  },
                  modifyRolesClicked(node) {
                    const highestRole = node.data.highestRole[0]
                    setSelectedRole({value: highestRole.id, label: highestRole.name})
                    setModifyingRow(node)
                    handleShowRoleModal()
                  },
                  resetPasswordClicked(node) {
                    onResetUserPassword(node)
                  }
                }
              }></AgGridColumn>
            </AgGridReact>
          </div>
        //)
        }
      </div>

      <Modal toggle={toggleRoleModal} isOpen={showRoleModal}>
        <ModalHeader
          close={<button className="close btn btn-white" onClick={handleCloseRoleModal}>×</button>}
          toggle={toggleRoleModal}
        >
          Change Role
        </ModalHeader>
        <ModalBody>
          <Form>
            <Label>Select Highest Authorization Role</Label>
            <BaseSelect
              selectFirst={false}
              initialValue={selectedRole}
              serviceUrl="role/assignable"
              valueName="RoleID"
              labelName="RoleName"
              onChange={onChangeUserRole}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={onSaveUserRole}
          >
            Save
          </Button>
          {' '}
          <Button onClick={handleCloseRoleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
  
    </div>
  )
}

export default Users
