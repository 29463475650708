import React, { useEffect, useRef } from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import { MailFuzeReturnAddressStateIDSelect } from './ReturnAddressDowns'
import { useForm, useFormState } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { doRegisterInput,  doRegisterInputRadio, doRegisterSelect } from './FormHookRegisters' 
import { Blank } from '../dropdowns/DefaultSelectOptions'
import { ConstantCodeMailFuzeReturnAddressMethod
} from '../../services/SettingsService'
import useSettingsContext from './SettingsContext'

export const ReturnAddressStandardSettingsState = {
  mailFuzeReturnAddressMethod: {
    constant: ConstantCodeMailFuzeReturnAddressMethod,
    code: undefined,
    type: 'Integer',
    value: 0
  },
  mailFuzeReturnAddressCustomCompanyName: '',
  mailFuzeReturnAddressCustomStreet1: '',
  mailFuzeReturnAddressCustomCity: '',
  mailFuzeReturnAddressCustomStateID: {
    type: 'Integer',
    value: Blank.value
  },
  mailFuzeReturnAddressCustomZip: ''
}

export const ReturnAddressStandardSettingsSchema = {
  mailFuzeReturnAddressCustomCompanyName: yup.object({
    value: yup.string().required('Name required')
  }),
  mailFuzeReturnAddressCustomStreet1: yup.object({
    value: yup.string().required('Address required')
  }),
  mailFuzeReturnAddressCustomCity: yup.object({
    value: yup.string().required('City required')
  }),
  mailFuzeReturnAddressCustomStateID: yup.object({
    value: yup.number().integer().moreThan(0, 'State required')
  }),
  mailFuzeReturnAddressCustomZip: yup.object({
    value: yup.string().required('Zip required').matches(/^\d{5,10}(?:[-\s]\d{4})?$/, 'Invalid Zip')
  })
}

const InternalSchema = yup.object({
  ...ReturnAddressStandardSettingsSchema
})

const ReturnAddressStandardSettings = () => {
  const formRef = useRef(null)
  const {context: state, contextReducer: dispatchSecond} = useSettingsContext()

  console.log('Loading Return Address Tab with context', state)

  const {
    //setFocus,
    register,
    setValue,
    //getFieldState,
    handleSubmit,
    trigger,
    reset,
    //control
    formState: { errors, isSubmitted }
  } = useForm({
    defaultValues: {
      ...state
    },
    resolver: yupResolver(InternalSchema),
    mode: "onChange",
    context: { state }
  });

  const dispatch = (action) => {
    action.setValue = setValue
     
    dispatchSecond(action)
    if (state.isSubmitted || (action.name === 'isSubmitted' && action.value === true)) {
      trigger()
    }
  }
  
  useEffect(() => {
    reset({
      ...state
    });
    if (state.isSubmitted){
      trigger();
    }
      
    }, [state]);

  useEffect(() => {
    console.log('useEffect triggered by state.isSubmitted', state.isSubmitted, 'isSubmitted', isSubmitted)

    if (state.isSubmitted === false && isSubmitted) {
      dispatch({ type: 'SET', name: 'isSubmitted', value: isSubmitted })
    }
  }, [state.isSubmitted, isSubmitted])

  //trigger will change on the first load. It's only way to run the validation on the default values.
  useEffect(() => {
    if (state.isSubmitted) {
      trigger()
    }
  }, [trigger, state.isSubmitted, state.currentTabIndex])

  return (
    <div>
      <React.Fragment>
        <Form ref={formRef} 
          onSubmit={handleSubmit(state.onSubmit, state.onError)}
        >
          <Row>
            <Col md={11} lg={10} xl={9}>
              <Row className="mt-3">
                <Col xs={12}>
                  <FormGroup
                    tag="fieldset"
                    className="border p-2 mt-3"
                  >
                    <legend className="float-none w-auto ps-1 pe-1 fs-6 text-muted" style={{fontWeight: '500'}}>Return Address To Use On Mailings</legend>

                    <div className='ps-3 pe-3 mt-3 d-flex flex-column'>

                      <div className='mt-2 d-flex flex-row align-items-baseline pb-1'>
                        <Row className='ps-4 d-flex flex-row flex-grow-1'>
                          <Col>
                            <Row>
                              <Col md={8}>
                                <FormGroup>
                                  <Input
                                    {...doRegisterInput(register, dispatch, 'mailFuzeReturnAddressCustomCompanyName', state.isSubmitted, errors, state)}
                                  />
                                  {errors?.mailFuzeReturnAddressCustomCompanyName?.value && (
                                    <FormFeedback className='ms-1'>{errors?.mailFuzeReturnAddressCustomCompanyName?.value?.message}</FormFeedback>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={8}>
                                <FormGroup>
                                  <Input
                                    {...doRegisterInput(register, dispatch, 'mailFuzeReturnAddressCustomStreet1', state.isSubmitted, errors, state)}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={8}>
                                <Row>
                                  <Col md={5}>
                                    <FormGroup>
                                      <Input
                                        {...doRegisterInput(register, dispatch, 'mailFuzeReturnAddressCustomCity', state.isSubmitted, errors, state)}
                                      />
                                      {errors?.mailFuzeReturnAddressCustomCity?.value && (
                                        <FormFeedback className='ms-1'>{errors?.mailFuzeReturnAddressCustomCity?.value?.message}</FormFeedback>
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <MailFuzeReturnAddressStateIDSelect
                                        options={state.mailFuzeReturnAddressCustomStateID.options}
                                        {...doRegisterSelect(register, dispatch, 'mailFuzeReturnAddressCustomStateID', state.isSubmitted, errors, state, '', true)}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Input
                                        {...doRegisterInput(register, dispatch, 'mailFuzeReturnAddressCustomZip', state.isSubmitted, errors, state)}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    </div>
  )
}

export default ReturnAddressStandardSettings