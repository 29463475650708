import { useFetchWrapper } from '../services/fetch-wrapper'

export { useJobService };

const useJobService = () => {

  const fetchWrapper = useFetchWrapper()

  const GetJobTrackingData = async (jobId) => {
    return fetchWrapper.get(`job/${jobId}`)
  }

  const GetJobDocumentProof = async (jobId) => {
    return fetchWrapper.get(`job/${jobId}/documentproof`)
  }

  return { GetJobTrackingData, GetJobDocumentProof }
}