import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import * as XLSX from "xlsx";
import { toast } from 'react-toastify';

import {
  usePapaParse
} from 'react-papaparse';
import { nanoid } from 'nanoid';


const SendMailCustomUpload = ({onAppend, onChange, onRemove, onRemoved, initialValues}) => {

  // Register the FilePond plugins
  registerPlugin(
    FilePondPluginFileValidateType
  );

  const { readString } = usePapaParse();
  const filePondRef = useRef(null)

  const [maxColumns] = useState({ WithoutReturnAddress: 7, WithReturnAddress: 12})

  // const initialState = initialValues ?? {
  //   files: [],
  //   fileUploading: false
  // }

  // const stateReducer = (state, action) => {
  //   switch (action.type) {
  //     case 'SET': {
  //       return {
  //         ...state,
  //         [action.name]: action.value,
  //       }
  //     }
  //   }

  //   return state
  // }

  // const [state, dispatch] = useReducer(stateReducer, initialState)

  // useEffect(() => {
  //   //onChange(state)
  // }, [state])

  const extractItem = useCallback((targetIndex, item) => {
    let hasCostCode = false
    if (item.length === maxColumns.WithoutReturnAddress || item.length === maxColumns.WithReturnAddress) {
      hasCostCode = true
    }

    let hasMoreColumns = false
    if (item.length >= targetIndex + (hasCostCode ? 1 : 0)) {
      hasMoreColumns = true
    }

    let adjustedTargetIndex = targetIndex
    if (hasMoreColumns && !hasCostCode) {
      adjustedTargetIndex--
    }

console.log('targetIndex:', targetIndex, hasCostCode, hasMoreColumns, adjustedTargetIndex)

    if (adjustedTargetIndex < item.length) {
      if (!hasCostCode && targetIndex === 6) {
        return null
      } else {
        return item[adjustedTargetIndex]
      }
    } else {
      return null
    }
  }, [maxColumns])

  return (
    <>
      <FilePond
        ref={filePondRef}
        files={initialValues.files}
        allowMultiple={true}
        allowReorder={false}
        itemInsertLocation='after'
        acceptedFileTypes={[
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'application/vnd.oasis.opendocument.spreadsheet',
        'text/csv'
        ]}
        fileValidateTypeLabelExpectedTypes='Expects Comma Separated Values (CSV) or Excel File'
        //maxFiles={1}
        name="recipients"
        allowMinimumUploadDuration={false}
        itemInsertInterval={15}
        server={{
          process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
            // fieldName is the name of the input field
            // file is the actual file object to send
            //const formData = new FormData();
            //formData.append(fieldName, file, file.name);

            //const request = new XMLHttpRequest();
            //request.open('POST', 'url-to-api');

            // Should call the progress method to update the progress to 100% before calling load
            // Setting computable to false switches the loading indicator to infinite mode
            /*request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
            };*/
            
            //progress(true, Math.ceil((file.size / third)), file.size)

            let factor = 3
            let interval = setInterval(() => {
              console.log(factor)
              progress(true, Math.ceil((file.size / factor)), file.size)
              factor--
              if (factor === 0) {
                clearInterval(interval)
                load(nanoid())
              }
            }, 200)

            // Should call the load method when done and pass the returned server file id
            // this server file id is then used later on when reverting or restoring a file
            // so your server knows which file to return without exposing that info to the client
            //request.onload = function () {
                //if (request.status >= 200 && request.status < 300) {
                    // the load method accepts either a string (id) or an object
                    //load(request.responseText);
              //      load(nanoid())
                //} else {
                    // Can call the error method if something is wrong, should exit after
                  //  error('oh no');
                //}
            //};

           //request.send(formData);

            // Should expose an abort method so the request can be cancelled
            return {
                abort: () => {
                    // This function is entered if the user has tapped the cancel button
                    //request.abort();

                    // Let FilePond know the request has been cancelled
                    abort();
                },
            };
          },
          revert: (uniqueFileId, load, error) => {
            // Should remove the earlier created temp file here
            // ...

            // Can call the error method if something is wrong, should exit after
            //error('oh my goodness');
            //onRemoved(uniqueFileId);

            // Should call the load method when done, no parameters required
            load();

            //onRemoved(uniqueFileId);
          },
        }}
        labelFileProcessing='Importing'
        labelFileProcessingComplete='Import Complete'
        beforeRemoveFile={async (item) => {
          console.log(item)
          console.log(item.serverId)
          return await onRemove(item.serverId);
        }}
        onremovefile={(error, file) => {
          console.log(error)
          onRemoved(file);
        }}
        onload={() => {
          console.log('on load called')
        }}
        //oninit={() => this.handleInit()}
        oninitfile={async (file) => {
          // console.log('Init File', file);
        }}
        onLoad={(file) => {
          console.log('On Load')
        }}
        onaddfile={() => console.log('Add File')}
        //onAddFile={() => console.log('Add File')}
        onprocessfile={async (error, file)  => {


          /* f is a File */
          let csvString = ''
          var reader = new FileReader();
          reader.onload = function(e) {
            var data = e.target.result;
            /* reader.readAsArrayBuffer(file) -> data will be an ArrayBuffer */
            var workbook = XLSX.read(data);
            var worksheet = workbook.Sheets[workbook.SheetNames[0]]

            console.log(worksheet['!cols'])
            console.log(worksheet['!ref'])
            var a1Range = worksheet['!ref']
            var range = XLSX.utils.decode_range(a1Range);
            console.log(range)
            
            const columnCount = Math.abs(range.s.c - range.e.c) + 1

            //console.log('columnCount', columnCount)
            //console.log('maxColumns.WithoutReturnAddress', maxColumns.WithoutReturnAddress)
            //console.log('Math.abs(range.s.c - range.e.c) - 1', Math.abs(range.s.c - range.e.c) - 1)

            let hasCostCode = false
            if (columnCount === maxColumns.WithoutReturnAddress || columnCount === maxColumns.WithReturnAddress) {
              hasCostCode = true
            }

            let hasReturnAddress = false
            if (hasCostCode && columnCount === maxColumns.WithReturnAddress || columnCount === maxColumns.WithReturnAddress - 1) {
              hasReturnAddress = true
            }

            //console.log('hasCostCode', hasCostCode)
            //console.log('hasReturnAddress', hasReturnAddress)

            if ((hasCostCode && (columnCount < maxColumns.WithoutReturnAddress - 1)) || (hasReturnAddress && columnCount < maxColumns.WithReturnAddress - 1)) { //-1 because costCode is optional
              toast.error('File has insufficient columns.', {
                position: "bottom-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
              })
            } else if ((!hasReturnAddress && columnCount > maxColumns.WithoutReturnAddress) || (hasReturnAddress && columnCount > maxColumns.WithReturnAddress)) {

              toast.warning('File has extra columns.', {
                position: "bottom-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
              })
            }

            //var opts = { header: 1 }
            var opts = { header: 1 }
            //var jsa = XLSX.utils.sheet_to_json(worksheet, opts);
            csvString = XLSX.utils.sheet_to_csv(worksheet, opts);
            console.log(csvString)
            
            // readString(csvString, {
            //   worker: true,
            //   //header: true,
            //   skipEmptyLines: true,
            //   preview: 1,
            //   complete: (results) => {
            //     //console.log('line 1 width: ' , results && results.data && results.data.length !== 0 && results.data[0].length)
            //     columCount = results && results.data && results.data.length !== 0 && results.data[0].length
            //   }
            // })

            
            readString(csvString, {
              worker: true,
              //header: true,
              skipEmptyLines: true,
              error: (error, file) => {
                console.log(error)
              },
              //fields: fields, not sure this has any effect
              complete: (results) => {
                console.log('---------------------------');
                console.log(results);
                //onChange({recipients: results});
  
                console.log('file.serverId', file.serverId)
  
                //results.data.
  
                //if (likelyIsHeaderScore <= 3) {
                //  results.data.shift() //remove first item
                //}
                  const resultsMapped = results.data.map((item, index) => {
                  return {
                    importRowNum: index + 1,
                    importId: file.serverId,
                    importSource: 'file',
                    recipientId: nanoid(),
                    firstName: item[0],
                    lastName: item[1],
                    street: item[2],
                    city: item[3],
                    state: item[4],
                    zip: item[5],
                    costCode: extractItem(6, item),
                    returnName: extractItem(7, item),
                    returnStreet: extractItem(8, item),
                    returnCity: extractItem(9, item),
                    returnState: extractItem(10, item),
                    returnZip: extractItem(11, item),

                    //userDefined: item[6]
                    //isValid: (item.length === maxColumns.WithoutReturnAddress || item.length === maxColumns.WithReturnAddress) && (item.filter((value, index) => ((index < maxColumns.WithoutReturnAddress && index < maxColumns.WithReturnAddress && value === ''))).length === 0),
                    isValid: item.length === columnCount && (item.filter((value, index) => index < columnCount && (value === '' && index !== 6)).length === 0),
                    isEdited: false
                  }
                })
                console.log(resultsMapped);
                onAppend(file.serverId, resultsMapped);
                console.log('---------------------------');
              },
            })
          };
          reader.readAsArrayBuffer(file.file);

          //let csvString = await file.file.text();

          // const fields = [
          //   "First Name",
          //   "Last Name",
          //   "Street Address",
          //   "City",
          //   "State",
          //   "Postal Code",
          //   "User Defined"
          // ]

          // const startOfFile = csvString.substring(0, Math.min(csvString.length, 50)).toLowerCase()
          // let likelyIsHeaderScore = 0
          // if (startOfFile.indexOf('name') !== -1) likelyIsHeaderScore++
          // if (startOfFile.indexOf('first') !== -1) likelyIsHeaderScore++
          // if (startOfFile.indexOf('last') !== -1) likelyIsHeaderScore++
          // if (startOfFile.indexOf('city') !== -1) likelyIsHeaderScore++
          // if (startOfFile.indexOf('state') !== -1) likelyIsHeaderScore++
          // if (startOfFile.indexOf('zip') !== -1) likelyIsHeaderScore++
          // if (startOfFile.indexOf('postal') !== -1) likelyIsHeaderScore++

          // if (likelyIsHeaderScore <= 3) {
          //   csvString = fields.join(',') + '\r\n' + csvString
          // }


        }}
        //onprocessfiles={(files) => dispatch({ type: 'SET', name: 'files', value: files })}
        onupdatefiles={files => {
          // Set currently active file objects to this.state
          //dispatch({ type: 'SET', name: 'recipientsSourceFiles', value: fileItems.map(fileItem => fileItem.file) })
          //dispatch({ type: 'SET', name: 'files', value: files })
          //setRecipientsSourceFiles(files)
          //dispatch({ type: 'SET', name: 'files', value: files })
          onChange(files)

          //var fileMap = files.map(fileItem => fileItem.file)
          //console.log(fileMap)
          //dispatch({ type: 'SET', name: 'recipientsSourceFiles', value: files })
          //fileItems.map(fileItem => fileItem.file)
        }}
        labelIdle='Drag & Drop your Recipient files or <span class="filepond--label-action">Browse</span>'
      />
    </>
  )
}

export default SendMailCustomUpload