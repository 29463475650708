import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

export const PdfUploader = forwardRef(({/*files,*/ authToken, onChange, onIsUploadingChanged, onRemove, onRemoved, onBlobUrlReady, labelIdle, labelFileProcessing, labelFileProcessingComplete }, ref) => {
  const filePondRef = useRef(null)

  useImperativeHandle(ref, () => {
    return {
      async flush() {
        console.log('flush called')

        if (filePondRef && filePondRef.current) {
          console.log('removeFiles')
          await filePondRef.current.removeFiles({ revert: true })
          onRemoved([]);
        }
      }
    }
  }, [filePondRef])

  registerPlugin(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateSize
  );  

  const isLoadingCheck = () => {
    if (filePondRef && filePondRef.current) {
      const files = filePondRef.current.getFiles()
      var isLoading = files.filter(x=>x.status !== 5).length !== 0;
      onIsUploadingChanged(isLoading)
    }
  }

  return (
    <FilePond
      ref={filePondRef}
      className='mb-1'
      allowFileSizeValidation={true}
      maxFileSize="29MB"
      allowMultiple={false}
      //files={files}
      acceptedFileTypes={['application/pdf']}
      server={{
        url: '/attachment',
        //timeout: 7000,
        process: {
            headers: {
              Authorization: 'Bearer ' + authToken,
            }
        },
        // process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        //   // fieldName is the name of the input field
        //   // file is the actual file object to send
        //   const formData = new FormData();
        //   formData.append(fieldName, file, file.name);

        //   const request = new XMLHttpRequest();
        //   request.open('POST', '/attachment');
        //   request.setRequestHeader('Authorization', 'Bearer ' + authToken);

        //   // Should call the progress method to update the progress to 100% before calling load
        //   // Setting computable to false switches the loading indicator to infinite mode
        //   request.upload.onprogress = (e) => {
        //       progress(e.lengthComputable, e.loaded, e.total);
        //   };

        //   // Should call the load method when done and pass the returned server file id
        //   // this server file id is then used later on when reverting or restoring a file
        //   // so your server knows which file to return without exposing that info to the client
        //   request.onload = function () {
        //       if (request.status >= 200 && request.status < 300) {
        //           // the load method accepts either a string (id) or an object
        //           load(request.responseText);
        //       } else {
        //           // Can call the error method if something is wrong, should exit after
        //           error('oh no');
        //       }
        //   };

        //   request.send(formData);

        //   // Should expose an abort method so the request can be cancelled
        //   return {
        //       abort: () => {
        //           // This function is entered if the user has tapped the cancel button
        //           request.abort();

        //           // Let FilePond know the request has been cancelled
        //           abort();
        //       },
        //   };
        // },
        revert: {
          headers: {
            Authorization: 'Bearer ' + authToken,
          }
        },
        fetch: null,
        load: null,
        restore: null
        // restore: (uniqueFileId, load, error, progress, abort, headers) => {
        //   console.log('restore source', uniqueFileId)
        // },
        // fetch: (url, load, error, progress, abort, headers) => {
        //   console.log('fetch source', url)
        // },
        // load: (source, load, error, progress, abort, headers) => {
        //   /*var myRequest = new Request(source);
        //   fetch(myRequest).then(function(response) {
        //     response.blob().then(function(myBlob) {
        //       load(myBlob)
        //     });
        //   });*/
        //   console.log('load source', source)
        // },
      }}
      labelFileProcessing={labelFileProcessing}
      labelFileProcessingComplete={labelFileProcessingComplete}
      // beforeRemoveFile={async (item) => {
      //   console.log(item)
      //   console.log(item.serverId)
      //   return await onRemove(item.serverId);
      // }}
      beforeRemoveFile={(file) => {
        console.log(file)
        if (onRemove) {
          onRemove(file)
        }
      }}
      onremovefile={(error, removedFile) => {
        console.log(error)
        console.log('onremovefile do loading check')
        if (onRemoved && filePondRef && filePondRef.current) {
          console.log('onRemoved', removedFile.id)
          const currentFiles = filePondRef.current.getFiles()
          console.log('onRemoved currentFiles', currentFiles)
          const removedFileIndex = currentFiles.findIndex(file => file.id === removedFile.id)
          if (removedFileIndex !== -1) {
            console.log('onRemoved index', removedFileIndex)
            const newFiles = [
              ...currentFiles.slice(0, removedFileIndex),
              ...currentFiles.slice(removedFileIndex + 1)
            ]
            onRemoved(newFiles);
          } else {
            onRemoved(currentFiles);
          }
        }
        
        isLoadingCheck()
      }}
      onload={() => {
        console.log('on load called')
      }}
      oninitfile={async (file) => {
        // console.log('Init File', file);
      }}
      // onLoad={(file) => {
      //   console.log('On Load')
      //   if (files && files.length !== 0) {
      //     onBlobUrlReady(null, [])
      //   }
      // }}
      onaddfile={() => console.log('Add File')}
      onprocessfile={async (error, file)  => {
        if (file) {
          console.log(file)
          const url = URL.createObjectURL(file.source)
          console.log(url)
          onBlobUrlReady(url, filePondRef.current.getFiles())
        }
      }}
      onupdatefiles={newFiles => {
        //onChange(newFiles)
      }}
      onaddfilestart={(file) => {
        console.log('onaddfilestart do loading check')
        isLoadingCheck()
      }}
      onprocessfiles={() => {
        console.log('onprocessfiles do loading check')
        isLoadingCheck()
      }}
      onprocessfileabort={() => {
        console.log('onprocessfileabort do loading check')
        isLoadingCheck()
      }}
      onprocessfilerevert={() => {
        console.log('onprocessfilerevert do loading check')
        isLoadingCheck()
      }}
      labelIdle={labelIdle}
    />

  )
})