import { useFetchWrapper } from '../services/fetch-wrapper'

export { useTenantContactTypeService };

const useTenantContactTypeService = () => {

  const fetchWrapper = useFetchWrapper()

  const GetTenantContactTypes = async () => {
    const params = new URLSearchParams({ addAll: true}).toString();
    return fetchWrapper.get(`contacttype/Tenant?${params}`)
  }

  return { GetTenantContactTypes }
}