import { useFetchWrapper } from './fetch-wrapper'

export { useSendMailAddressWizardService };

export const AddressWizardPageOption = {
  PageMarker: 'PageMarker',
  FixedPageNum: 'FixedPageNum',
};

const useSendMailAddressWizardService = () => {

  const fetchWrapper = useFetchWrapper()

  const DeleteTemplate = async (id) => {
    return fetchWrapper.delete(`addresswizardmanager/${id}`)
  }

  const GetAddressData = async (fileid, x, y, w, h) => {
    const params = new URLSearchParams({ fileid, x, y, w, h }).toString();
    return fetchWrapper.get(`addresswizardmanager/address?${params}`)
  }

  const GetAddresses = async (fileid, ax, ay, aw, ah, numPages) => {
    const params = new URLSearchParams({ fileid, ax, ay, aw, ah, numPages }).toString();
    return fetchWrapper.get(`addresswizardmanager/addresses?${params}`)
  }

  const GetAddressesByPageMarker = async (fileid, ax, ay, aw, ah, px, py, pw, ph) => {
    const params = new URLSearchParams({ fileid, ax, ay, aw, ah, px, py, pw, ph }).toString();
    return fetchWrapper.get(`addresswizardmanager/addressesbypage?${params}`)
  }

  const GetData = async (fileid, markers, numPages) => {
    const body = {fileid, markers, numPages}
    return fetchWrapper.post('addresswizardmanager/data', body)
  }

  const GetDataByPageMarkers = async (fileid, markers) => {

    const body = {fileid, markers}
    //const params = new URLSearchParams({ fileid, ax, ay, aw, ah, px, py, pw, ph }).toString();
    return fetchWrapper.post('addresswizardmanager/databypage', body)
  }

  const GetAddressesByTemplate = async (fileid, templateId) => {
    const params = new URLSearchParams({ fileid, templateId }).toString();
    return fetchWrapper.get(`addresswizardmanager/addressesbytemplate?${params}`)
  }

  const GetTemplateHighRes = async (templateId) => {
    const arrayBuffer = await fetchWrapper.getBlob(`addresswizardmanager/template/${templateId}/highres`, null, false)

    console.log(arrayBuffer)

    return arrayBuffer
  }

  const GetTemplates = async () => {
    return fetchWrapper.get(`addresswizardmanager/templates`)
  }

  const GetTemplateItems = async () => {
    return fetchWrapper.fetchDropDown(`addresswizardmanager/templates/items`, "id", "name")
  }

  const GetTextData = async (fileid, x, y, w, h) => {
    const params = new URLSearchParams({ fileid, x, y, w, h }).toString();
    return fetchWrapper.get(`addresswizardmanager/text?${params}`)
  }

  const SaveTemplate = async (templateData) => {
    return fetchWrapper.post(`addresswizardmanager/template`, templateData)
  }

  return { DeleteTemplate, GetAddressData, GetAddresses, GetAddressesByPageMarker, GetData, GetDataByPageMarkers, GetAddressesByTemplate, GetTemplateHighRes, GetTemplateItems, GetTemplates, GetTextData, SaveTemplate }
}