import React from 'react'

const WarningHistoryCategory = ({ result: {exists, isValid, isActive, name}}) => {
  if (!isValid)
  {
    return (
      <div className='d-flex flex-fill flex-column justify-content-between'>
        <div>
          <div className='fw-bold d-flex justify-content-center'>Invalid History Category</div>
          <hr />
          {!exists && (
            <p>History Category does not exist. Choose a different History Category.</p>
          )}
          {exists && !isActive && (
            <p>History Category "{name}" is inactive. Choose a different History Category.</p>
          )}
        </div>
      </div>
    )
  }
  else
  {
    return (<></>)
  }
}

export default WarningHistoryCategory