import React, { useContext } from 'react'
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

const LocationMenu = ({userLocation, userLocations, setUserLocation}) => {

  const onSelectLocation = (e) => {
    const newLocationId = e.currentTarget.getAttribute("location")
    console.log('Setting location to ' + newLocationId)
    setUserLocation(newLocationId)
  }

  return (
    <React.Fragment>
      <div className="menu-item-separator">
          &nbsp;
      </div>
      <UncontrolledDropdown>
          <DropdownToggle caret color="white-outline">
              Location ({userLocation && (userLocation.LocationFriendlyName)})
          </DropdownToggle>
          <DropdownMenu end>
              {userLocations.map((location, index) => {
                  return (
                      <DropdownItem key={location.LocationID} onClick={onSelectLocation} location={location.LocationID}>
                          {location.LocationFriendlyName}
                      </DropdownItem>
                  )
              })}
          </DropdownMenu>
      </UncontrolledDropdown>
      <div className="menu-item-separator">
          &nbsp;
      </div>
    </React.Fragment>
  )
}

export default LocationMenu
