import React from 'react'
import { Check, ReceiptCutoff } from 'react-bootstrap-icons'

const AGGridColorStatusRenderer = ({statusStyleNameGetter, statusStyles, node, value, ...rest}) => {

  const basicStyle = {
    borderRadius: '5px',
    lineHeight: '24px',
    fontWeight: 500,
    //padding: '2px 10px',
    flex: '1 1 100%'
  }

  const styleStatusName = statusStyleNameGetter(node)
  
  //const styleKey = value.replace(/[^A-Z0-9]/ig, "_")
  const styles = { ...basicStyle, ...statusStyles[styleStatusName] }
  
  return (
    <div style={{display: 'flex', alignItems: 'center', height: '40px', alignItems: 'center', height: '40px'}}>
      <div className={`text-center`} style={styles}>
        {value && (
          value
        )}
      </div>
    </div>
  )
}

export default AGGridColorStatusRenderer