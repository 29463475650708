import { AgGridReact } from "ag-grid-react"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { DateTimeFormatter } from "../../services/DateFormatter"
import AGGridColorStatusRenderer from "../widget/AGGridColorStatusRenderer"
import { CustomLoadingOverlay } from "../widget/AGGridCustomOverlay"
import { SendMailHistoryGridCellMenuRenderer } from "./SendMailHistoryGridCellMenu"
import SendMailResults from '../send-mail/SendMailResults';
import { useSendMailService } from "../../services/SendMailService"
import custom from '../../custom.scss'
import { nanoid } from 'nanoid';
import { SendMailHistoryMailingsGridCell } from "./SendMailHistoryMailingsGridCell"
import AGGridCheckmarkRenderer from "../widget/AGGridCheckmarkRenderer"
import AGGridBooleanFilter from "../widget/AGGridBooleanFilter"
import CustomPinnedRowRenderer from "../send-mail/CustomPinnedRowRenderer"
import CustomChargeOwnerCellRenderer from "./CustomChargeOwnerCellRenderer"
//import SendMailHistoryGroupCellRenderer from "./SendMailHistoryGroupCellRenderer"

const SendMailHistoryGrid = ({onSelectionChanged, onShowSendMailDetails, perPage, columnSettings, sendMailData, isLoading }) => {
  const gridRef = useRef()
  const sendMailService = useSendMailService()

  const [sendMailResults, setSendMailResults] = useState({ showModal: false, results: []})

  useEffect(() => {
    let newColDef = initialColumnDef
    const usernameField = newColDef.find(col => col.field === 'UserName')
    usernameField.hide = !(columnSettings.isAdmin || columnSettings.isSystemAdmin)
    const companyField = newColDef.find(col => col.field === 'CompanyName')
    companyField.hide = !columnSettings.isSystemAdmin
    const costCodesField = newColDef.find(col => col.field === 'CostCodes')
    costCodesField.hide = !columnSettings.costCodeEnabled

    setColumnDefs(newColDef)
    
    gridRef.current.api.sizeColumnsToFit()
  }, [columnSettings])

  useEffect(() => {
    //console.log('use effect triggered on isLoading changed to ' + isLoading)
    if (isLoading) {
      onShowLoading()
    }
    else {
      onHideOverlay()
    }
  }, [isLoading])

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.api.paginationSetPageSize(perPage.value);
    }
  }, [perPage])

  const onShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, [])

  const onHideOverlay = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  const setGridSelection = () => {
    if (gridRef.current) {
      const selectedNodes = gridRef.current.api.getSelectedNodes()
      const selectedData = selectedNodes.map(node => node.data)
      console.log('selectedData', selectedData)
      //onSelectionChanged(selectedData)
    }
    else {
      //onSelectionChanged([])
    }
  }

  const dateTimeFormatter = (params) => {
    try
    {
      if (params && params.value)
      {
        return DateTimeFormatter.format(new Date(params.value))
      }
      else {
        return ''
      }
    }
    catch
    {
      return ''
    }
  }

  const showHandleEvent = (e) => {
    e.stopPropagation()
    e.target.parentElement.blur()
    e.target.blur()
    gridRef.current.api.clearFocusedCell();
  }

  const showDetails = (nodeData) => {
    console.log('showDetails')
    onShowSendMailDetails({uniqueKey: nanoid(), nodeData})
  }

  const showResults = (sendMailId) => {
    sendMailService.PollSendMailToRentManagerTenants(sendMailId).then(sendMailResults => {

      if (sendMailResults.isComplete)
      {
        setSendMailResults({
            showModal: true,
            success: sendMailResults.results.isSuccess,
            transactionLog: sendMailResults.sendMailId,
            results: sendMailResults.results,
            sendMailService
        })
      }
      else
      {
        alert('Transaction log is not complete, cannot show.')
      }

      console.log('send mail results: ', sendMailResults)
    }).catch(error => { console.log(error); PlainSwal.close(); setSendMailPollingData(null, null); });
  }

  const onCloseResults = () => {
    setSendMailResults({showModal: false, results: []})
  }

  const [defaultColumnDefs, setDefaultColumnDefs] = useState({
    sortable: true,
    filter: true,
    resizable: true
  })

  const initialColumnDef = [
    {
      field: "MailingCount",
      filter: 'agNumberColumnFilter',
      headerClass: 'ag-right-aligned-header',
      cellClass: 'text-end',
      headerName: "Mailings",
      suppressSizeToFit: true,
      maxWidth: 150,
      cellRenderer: 'SendMailHistoryMailingsGridCell',
    },
    {
      field: "LetterTemplate",
      headerName: "Letter",
      suppressSizeToFit: false,
      tooltipField: "LetterTemplate",
      minWidth: 290,
      // checkboxSelection: true,
      // headerCheckboxSelection: true,
      // headerCheckboxSelectionFilteredOnly: true,
      // valueGetter: (params) => {
      //   if (!params.node.rowPinned) {
      //       return '(' + params.data.RecipientCount + ') ' + params.data.LetterTemplate
      //   }
      // }
    },
    {
      field: "CreatedOnUTC",
      headerName: "Date / Time Submitted",
      tooltipField: "CreatedOnUTC",
      filter: 'agDateColumnFilter',
      headerClass: 'ag-right-aligned-header',
      cellClass: 'text-end',
      suppressSizeToFit: true,
      minWidth: 230,
      valueFormatter: dateTimeFormatter,
    },
    {
      field: "Status",
      headerName: "Status",
      suppressSizeToFit: true,
      tooltipField: "Status",
      maxWidth: 135,
      cellRenderer: "AGGridColorStatusRenderer",
      cellRendererParams: {
        statusStyleNameGetter: function(node) {
          if (node.data.Status === 'Complete') {
            if (node.data.IsSuccess) {
              return 'CompleteSuccess'
            }
            else {
              return 'CompleteFail'
            }
          } else if (node.data.Status === 'Pending') {
            return 'Pending'
          }
        },
        statusStyles: {
          CompleteSuccess: {
            color: custom.success
            //color: custom.successSwatch,
            //backgroundColor: custom.success
          },
          CompleteFail: {
            color: custom.danger
            //color: custom.dangerSwatch,
            //backgroundColor: custom.danger,
          },
          Pending: {
            color: custom.info
            //color: custom.infoSwatch,
            //backgroundColor: custom.info,
          }
        }
      }
    },
    // {
    //   field: "DocumentClass",
    //   headerName: "Document Class",
    //   suppressSizeToFit: true,
    //   tooltipField: "DocumentClass"
    // },
    {
      sortable: false,
      filter: false,
      headerName: "",
      suppressSizeToFit: true,
      maxWidth: 110,
      cellRenderer: "SendMailHistoryGridCellMenuRenderer",
      cellRendererParams: {
        onShowDetails: function(e, nodeData) {
          showHandleEvent(e)
          showDetails(nodeData)
        },
        onShowResults: function(e, nodeData) {
          showHandleEvent(e)
          console.log(nodeData)
          showResults(nodeData.SendMailID)
        },
      }
    },
    {
      field: "UserName",
      headerName: "User",
      tooltipField: "UserName",
      suppressSizeToFit: false,
      minWidth: 130,
      maxWidth: 300,
      hide: !(columnSettings.isAdmin || columnSettings.isSystemAdmin)
    },
    {
      field: "CompanyName",
      headerName: "Company",
      tooltipField: "CompanyName",
      suppressSizeToFit: false,
      minWidth: 130,
      hide: !columnSettings.isAdmin
    },
    {
      field: "CostCodes",
      tooltipField: "CostCodes",
      maxWidth: 150,
      suppressSizeToFit: true,
    },
    {
      field: "SendMailSource",
      headerName: "Recipient Type",
      tooltipField: "SendMailSource",
      maxWidth: 190,
      suppressSizeToFit: true,
    },
    {
      field: "DocumentClass",
      headerName: "Product",
      tooltipField: "DocumentClass",
      minWidth: 150,
      suppressSizeToFit: false,
    },
    {
      field: "Layout",
      headerName: "Layout",
      tooltipField: "Layout",
      minWidth: 150,
      suppressSizeToFit: false,
    },
    {
      field: "Envelope",
      headerName: "Envelope",
      tooltipField: "Envelope",
      minWidth: 150,
      suppressSizeToFit: false,
    },
    {
      field: "Color",
      headerName: "Color",
      tooltipField: "Color",
      minWidth: 150,
      suppressSizeToFit: false,
    },
    {
      field: "PaperType",
      headerName: "Type",
      tooltipField: "PaperType",
      minWidth: 150,
      suppressSizeToFit: false,
    },
    {
      field: "MailClass",
      headerName: "Mail Class",
      tooltipField: "MailClass",
      minWidth: 150,
      suppressSizeToFit: false,
    },
    {
      field: "PrintOption",
      headerName: "Printing",
      tooltipField: "PrintOption",
      minWidth: 150,
      suppressSizeToFit: false,
    },
    {
      field: "ProductionTime",
      headerName: "Production Time",
      tooltipField: "ProductionTime",
      minWidth: 150,
      suppressSizeToFit: false,
    },
    {
      field: "ChargeResidentAmount",
      headerName: "Charge Resident",
      tooltipField: "ChargeResidentAmount",
      headerClass: 'ag-right-aligned-header',
      cellClass: 'text-end',
      filter: 'agNumberColumnFilter',
      minWidth: 190,
      suppressSizeToFit: false,
      cellRenderer: 'customPinnedRowRenderer',
      cellRendererParams: { format: 'currency' },
      valueGetter: (params) => {
        if (!params.node.rowPinned) {
          return params.data.ChargeResidentAmount || 0
        }
      }
    },
    {
      //field: "ChargeOwnerAmount",
      headerName: "Charge Property",
      //tooltipField: "ChargeOwnerAmount",
      headerClass: 'ag-right-aligned-header',
      cellClass: 'text-end',
      //filter: 'agNumberColumnFilter',
      minWidth: 250,
      suppressSizeToFit: false,
      cellRenderer: 'customChargeOwnerCellRenderer',
      // cellRendererParams: { format: 'currency' },
      // valueGetter: (params) => {
      //   if (!params.node.rowPinned) {
      //     return params.data.ChargeOwnerAmount || 0
      //   }
      // }
    },
    {
      field: "AttachLetter",
      headerName: "Attach Letter",
      minWidth: 170,
      suppressSizeToFit: false,
      cellRenderer: "AGGridCheckmarkRenderer",
      filter: 'AGGridBooleanFilter',
      filterParams: {
        title: 'Attach Letter to Notes',
        allText: 'All',
        trueText: 'Attach',
        falseText: 'Do Not Attach',
      },
    },
    // {
    //   field: "AttachAffidavit",
    //   headerName: "Attach Affidavit",
    //   minWidth: 200,
    //   suppressSizeToFit: false,
    //   cellRenderer: "AGGridCheckmarkRenderer",
    //   filter: 'AGGridBooleanFilter',
    //   filterParams: {
    //     title: 'Attach Affidavit',
    //     allText: 'All',
    //     trueText: 'Attach',
    //     falseText: 'Do Not Attach',
    //   },
    // },
    {
      field: "Enclosure",
      headerName: "Enclosure",
      tooltipField: "Enclosure",
      minWidth: 150,
      suppressSizeToFit: false,
    },
    {
      field: "IncludeConfirmationOfMailing",
      headerName: "Include C.o.M.",
      minWidth: 200,
      suppressSizeToFit: false,
      cellRenderer: "AGGridCheckmarkRenderer",
      filter: 'AGGridBooleanFilter',
      filterParams: {
        title: 'Confirmation of Mailing',
        allText: 'All',
        trueText: 'Included',
        falseText: 'Not Included'
      },
    },
  ]

  // const isFullWidthRow = useCallback((params) => {
  //   // in this example, we check the fullWidth attribute that we set
  //   // while creating the data. what check you do to decide if you
  //   // want a row full width is up to you, as long as you return a boolean
  //   // for this method.
  //   //console.log('isFullWidthRow', params.data.idx)
  //   return false; //params.data.idx === 2
  // }, [])

  // const fullWidthCellRenderer = useMemo(() => {
  //   console.log('fullWidthCellRenderer called')
  //   return SendMailHistoryGroupCellRenderer;
  // }, [])

  // const getRowHeight = useCallback((params) => {
  //   // you can have normal rows and full width rows any height that you want
  //   const isBodyRow = params.node.rowPinned === undefined;
  //   const isFullWidth = params.node.data.idx === 2;
  //   if (isBodyRow && isFullWidth) {
  //     return 42;
  //   }
  // }, []);

  /*SendMailID
  UserName
  CompanyName
  SendMailSource
  CreatedOn
  Status
  LocationID
  request
  LetterTemplate
  ChargeOwner
  ChargeOwnerAmount
  ChargeResident
  ChargeResidentAmount
  DocumentClass
  Layout
  ProductionTime
  Envelope
  Color
  PaperType
  PrintOption
  MailClass
  IncludeEnclosure
  Enclosure
  RecipientCount*/

  const [columnDefs, setColumnDefs] = useState(initialColumnDef);

  return (
    <div>
      <div className="ag-theme-alpine" style={{minHeight: '150px', height: 'calc(90vh - 195px)', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={sendMailData}
          rowSelection="single"
          pagination={true}
          showDisabledCheckboxes={true}
          paginationPageSize={perPage.value}
          frameworkComponents={{
            customLoadingOverLoad: CustomLoadingOverlay,
            AGGridColorStatusRenderer: AGGridColorStatusRenderer,
            SendMailHistoryGridCellMenuRenderer: SendMailHistoryGridCellMenuRenderer,
            SendMailHistoryMailingsGridCell: SendMailHistoryMailingsGridCell,
            AGGridCheckmarkRenderer: AGGridCheckmarkRenderer,
            AGGridBooleanFilter: AGGridBooleanFilter,
            customPinnedRowRenderer: CustomPinnedRowRenderer,
            customChargeOwnerCellRenderer: CustomChargeOwnerCellRenderer,
            //fullWidthCellRenderer: SendMailHistoryGroupCellRenderer
          }}
          loadingOverlayComponent="customLoadingOverLoad"
          loadingOverlayComponentParams={{
            loadingMessage: 'Loading...'
          }}
          defaultColDef={defaultColumnDefs}
          columnDefs={columnDefs}
          onRowDataChanged={(event) => {
            setGridSelection()
          }}
          onSelectionChanged={(event) => {
            setGridSelection()
          }}
          onGridReady={(event) => { 
            //console.log('onGridReady')
            event.api.sizeColumnsToFit()
            event.columnApi.applyColumnState({
              state: [
                {
                  colId: 'CreatedOn',
                  sort: 'desc'
                }
              ]
            })
          }}
          //getRowHeight={getRowHeight}
          //fullWidthCellRenderer={'fullWidthCellRenderer'}
          //</div>isFullWidthCell={isFullWidthRow}
          >
        </AgGridReact>
      </div>
      {sendMailResults.showModal && (
        <SendMailResults onClose={onCloseResults} {...sendMailResults} />
      )}
    </div>
  )
}

export default SendMailHistoryGrid