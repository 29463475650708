import React, { useEffect, useState } from 'react'
import { Button, DropdownItem,  DropdownMenu,  DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { DateRangePicker } from 'react-date-range';
import { DateFormatter } from '../../services/DateFormatter';
import { Calendar3, Calendar3Event } from 'react-bootstrap-icons';

const DecoratedDateRangePicker = ({onChange, display = 'fit'}) => {

  const [showCalendar, setShowCalendar] = useState(false)
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(), //null will select up to end of month
      key: 'selection'
    }
  ]);

  // useEffect(() => {
  //   console.log('////////////////////////////////////////////////')
  //   console.log(DateFormatter.format(state[0].startDate) + ' - ' + DateFormatter.format(state[0].endDate))
  //   console.log('////////////////////////////////////////////////')
  // }, [state])

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar)
  }

  const onApply = () => {
    onChange(state)
    toggleCalendar()
  }

  useEffect(() => {
    console.log('State changed')
  }, [state])

  return (
    <UncontrolledDropdown className={`calendar-dropdown ${display}`} toggle={toggleCalendar} isOpen={showCalendar}>
      <div className="form-control border rounded border-grey" style={{padding: 0}}>
      <DropdownToggle caret color="white-outline" style={{border: 'none'}}>
      {
        (state[0].startDate && state[0].endDate && (DateFormatter.format(state[0].startDate) != DateFormatter.format(state[0].endDate))) ?
          (
            <span style={{marginleft: '0.5em', marginRight: '1em', color: 'rgba(0, 0, 0, 80%)'}}>
              <Calendar3 style={{margin: '0 0.6em 4px 0.1em', color: 'rgba(0, 0, 0, 80%)'}}/> {DateFormatter.format(state[0].startDate) + ' - ' + DateFormatter.format(state[0].endDate)}
            </span>
          )
         : (
            (state[0].startDate) ? (
              <span style={{marginleft: '0.5em', marginRight: '1em', color: 'rgba(0, 0, 0, 80%)'}}>
              <Calendar3Event style={{margin: '0 0.6em 4px 0.1em', color: 'rgba(0, 0, 0, 80%)'}} /> {DateFormatter.format(state[0].startDate)}
            </span>
           ) : (<span></span>)
        )
      }
      </DropdownToggle>
      </div>
      <DropdownMenu>
        <DropdownItem  tag="div" header>
          <div>
            <DateRangePicker
              inputRanges={[]}
              moveRangeOnFirstSelection={false}
              ranges={state}
              onChange={item => setState([item.selection])}
            />
          </div>
          <div className="p-2 d-flex justify-content-end">
            <Button color="primary" className="me-2" onClick={onApply}>Apply</Button>
            <Button color="dark" onClick={toggleCalendar}>Close</Button>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default DecoratedDateRangePicker
