import { useFetchWrapper } from '../services/fetch-wrapper'

export const Roles = {
  SystemAdministrator: 'System Administrator',
  Administrator: 'Client Administrator',
  MailFuzeUser: 'MailFuze User'
};

export { useRoleService };

const useRoleService = () => {

  const fetchWrapper = useFetchWrapper()

  const GetAssignableRoles = async () => {
    return fetchWrapper.get(`role/assignable`)
  }

  return { GetAssignableRoles }
}