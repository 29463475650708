import { components } from "react-select";
import { FormGroup, Input, Label } from "reactstrap";

const MultiSelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <FormGroup check>
          <Label check style={{marginBottom: 0, pointerEvents: 'none'}}>
            <Input
              type="checkbox"
              checked={props.isSelected}
              aria-label="Press Space to toggle item selection (checked)"
              onChange={() => null} />{' '}
            {props.label}
          </Label>
        </FormGroup>
        {/* <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label> */}
      </components.Option>
    </div>
  );
};

export default MultiSelectOption