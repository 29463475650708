import { useFetchWrapper } from '../services/fetch-wrapper'

export { useCustomFormTemplateService };

const useCustomFormTemplateService = () => {

  const fetchWrapper = useFetchWrapper()

  const GetCustomFormTemplates = async (customFormTemplateType, customFormTemplate) => {
    const params = new URLSearchParams({customFormTemplateType, customFormTemplate, addBlank: true}).toString();
    return await fetchWrapper.fetchDropDown(`customformtemplates?${params}`, "CustomFormID", "Name")
  }

  return { GetCustomFormTemplates }
}