import React from "react"
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Label, Row, UncontrolledDropdown } from "reactstrap"
// import { ContactTypeSelect } from "./SendMailDropDowns"
// import BootstrapSwitchButton from "bootstrap-switch-button-react"
import { MailIconMultipleLetters, MailIconSingleLetter } from "../../images/svg/MailIcons"

const ContactTypeDropDown = React.memo(({contactTypeOptions, onChange}) => {

  //console.log(contactTypeOptions)

  const onContactTypeClick = (e) => {
    const newContactTypeId = parseInt(e.currentTarget.getAttribute("contacttype"))
    const selected = contactTypeOptions.find(contactType => contactType.ContactTypeID === newContactTypeId)
    onChange(selected)
  }

  return (
    contactTypeOptions.map((contactType) => {
      //console.log('Re-rendering')
        return (
          <div key={contactType.ContactTypeID}>
            {contactType.ContactTypeID == '1000000' && (
              <DropdownItem divider />
            )}
            <DropdownItem onClick={onContactTypeClick} contacttype={contactType.ContactTypeID}>
                {contactType.Name}
            </DropdownItem>
          </div>
        )
    }))
})

export default (props) => {
  //console.log(props)

  const { useContacts, onUseContactsChanged, useContactAddress, onUseContactAddressChanged, contactTypes, currentContactType, onContactTypeChanged, contactEnvelopOption, onContactEnvelopOptionChanged } = props

  //const [useContactsValue, setUseContactsValue] = useState(useContacts)

  // useEffect(() => {
  //   console.log('value fed back', useContacts)
  //   setUseContactsValue(useContacts)
  // }, [useContacts])

  // const onChangeUseContact = (e) => {
  //   setUseContactsValue(e.target.checked)
  //   onUseContactsChanged(e)
  // }

  //const [envelopMode, setEnvelopMode] = useState(contactEnvelopOption)

  /*useEffect(() => {
    console.log('contactEnvelopOption changed was detected')
    setEnvelopMode(contactEnvelopOption)
  }, [contactEnvelopOption, setEnvelopMode])*/

  /*const contactEnvelopOptionChanged = useCallback((mode) => {
    onContactEnvelopOptionChanged(mode)
    setEnvelopMode(mode)
  }, [onContactEnvelopOptionChanged, setEnvelopMode])*/

  return (
    <div>
      <Row>
        <Col className='w-100'>
            <div className='d-flex flex-row align-items-center'>
            <div className='d-flex flex-grow-0'>
                <Label htmlFor='useContacts' className='mb-0 ms-2 me-2'>Use Contacts</Label>
              </div>
              <div className='d-flex flex-grow-0 me-3'>
                <div className="form-switch d-flex align-items-end flex-column pe-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="useContacts"
                    name="useContacts"
                    onChange={onUseContactsChanged}
                    checked={useContacts ? 'checked' : ''}
                    style={{transform: 'scale(1.6)'}}/>
                </div>
              </div>
              <div style={{visibility: `${useContacts ? 'visible' : 'hidden'}`}} className='d-flex flex-grow-1'>
                <div className='d-flex flex-grow-1 header-input'>
                  <div className='d-flex flex-grow-1' style={{maxWidth: '15rem'}}>
                    {/* <ContactTypeSelect
                      options={contactTypes}
                      id="contactType"
                      name="contactType"
                      className="w-100"
                      //onChange={selected => dispatch({ type: 'SET', name: 'contactType', value: selected.value })}
                      isDisabled={useContactsValue ? '' : 'disabled'}
                    /> */}
                    <div className="d-flex justify-content-start align-items-center">
                      <UncontrolledDropdown>
                        <DropdownToggle caret color="white-outline" className="ag-header-cell-text">
                          <div className="d-flex">
                            <div className="flex-grow-1 text-end dropdown-selection dropdown-selection-icon">
                              {contactEnvelopOption === 'separate' && (
                                <MailIconMultipleLetters size={10} color="primary" />
                              )}
                              {contactEnvelopOption === 'same' && (
                                <MailIconSingleLetter size={10} color="primary" />
                              )}
                            </div>
                          </div>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => { onContactEnvelopOptionChanged('separate');}}>
                            <MailIconMultipleLetters size={8} color="primary" /> Separate Envelope
                          </DropdownItem>
                          <DropdownItem onClick={() => { onContactEnvelopOptionChanged('same'); onUseContactAddressChanged({ target: { checked: false } })}}>
                            <MailIconSingleLetter size={8} color="primary" /> Same Envelope
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      {/* <Button color="white-outline">
                        <MailIconMultipleLetters />
                        <MailIconSingleLetter />
                      </Button> */}
                      <UncontrolledDropdown>
                        <DropdownToggle caret color="white-outline" className="ag-header-cell-text">
                          <div className="d-flex">
                            {currentContactType && (
                              <div className="flex-grow-1 text-end dropdown-selection">
                                {currentContactType.Name}
                              </div>
                            )}
                          </div>
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ContactTypeDropDown
                            contactTypeOptions={contactTypes}
                            onChange={onContactTypeChanged}
                          >
                          </ContactTypeDropDown>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
                {contactEnvelopOption !== 'same' && (
                  <>
                    <div className='d-flex flex-grow-0 align-items-center'>
                      <Label htmlFor='useContactAddress' className='mb-0 ms-2 me-2'>Use Contacts Address</Label>
                    </div>
                    <div className='d-flex flex-grow-1 align-items-center'>
                      <div className="form-switch d-flex align-items-end flex-column pe-1">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="useContactAddress"
                          name="useContactAddress"
                          onChange={onUseContactAddressChanged}
                          checked={useContactAddress ? 'checked' : ''}
                          style={{transform: 'scale(1.6)'}}/>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
        </Col>
      </Row>
    </div>
  )
}

