import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import { Button, Col, Nav, NavItem, NavLink, Row, TabContent } from 'reactstrap';
import AdminMenu from './Menu'
import SettingsLayout from './SettingsLayout'
import { TabPanel } from './TabPanel'
import * as yup from "yup"
import GeneralStandardSettings, { GeneralStandardSettingsSchema, GeneralStandardSettingsState } from './GeneralStandardSettings';
import ReturnAddressStandardSettings, { ReturnAddressStandardSettingsSchema, ReturnAddressStandardSettingsState } from './ReturnAddressStandardSettings'
import CostCodeSettings, { CostCodeSettingsSchema, CostCodeSettingsState } from './CostCodeSettings';
import { Roles } from '../../services/RoleService'
import authService from '../api-authorization/AuthorizeService'
import { useSettings } from './SettingsReducer'
import { useClientService } from '../../services/ClientService'
import { useAddressStateService } from '../../services/AddressStateService'
import { toast } from 'react-toastify'

const StandardSettings = () => {
  const clientService = useClientService()
  const addressStateService = useAddressStateService()

  const populateState = async () => {
    const dispatchNotifications = []

    const role = await authService.getRole()
    console.log(role)

    dispatchNotifications.push({ type: 'SET', name: 'isClientAdmin', value: (role && (role === Roles.Administrator || role.includes(Roles.Administrator))) })
    dispatchNotifications.push({ type: 'SET', name: 'isSystemAdmin', value: (role && (role === Roles.SystemAdministrator || role.includes(Roles.SystemAdministrator))) })
    
    return dispatchNotifications;
  }

  const load = async () => {
    const [client, statesOptions, populateStateResults] = await Promise.all([
      clientService.GetMyClient(),
      addressStateService.GetStateCodes(),
      loadConstants(),
      populateState()
    ])
  
    console.log('statesOptions', statesOptions)
  
    const dispatches = populateStateResults;
    dispatches.push({ type: 'SET_OPTIONS', name: 'mailFuzeReturnAddressCustomStateID', value: statesOptions })
    dispatches.push({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressCustomCompanyName', value: client.CompanyName })
    dispatches.push({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressCustomStreet1', value: client.Street1 })
    dispatches.push({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressCustomCity', value: client.City })
    dispatches.push({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressCustomStateID', value: client.StateID })
    dispatches.push({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressCustomZip', value: client.PostalCode })
    
    dispatches.push({ type: 'SET', name: 'isLoading', value: false })
  
    console.log('dispatches', dispatches)
  
    return dispatches    
  }

  const loadConstants = async () => {
    return await settings.loadSettingsConstants(settingsContext)
  }

  const schema = useMemo(() => {
    return yup.object().shape({
      ...GeneralStandardSettingsSchema,
      ...ReturnAddressStandardSettingsSchema,
      ...CostCodeSettingsSchema
    })
  }, [])

  const onSubmit = useCallback((data, e) => {
    console.log(data)
    console.log(e)
  }, [])

  const onError = useCallback((errors) => {
  
    toast.error('Please correct all errors.', {
      position: "bottom-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    Object.keys(errors).forEach((field) => {
      toast.warn(errors[field].message, {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false
      })

    });
  }, [])

  const submitAll = async (e) => {
    console.log('Doing Submit all!', e)
    //formRef.current.props.onSubmit(e)
      e.target.blur();
      let error = undefined
      const validationErrors = {}
      await schema.validate(settingsContext, { abortEarly: false }).catch(e => {
        
        console.log(e)
        e.inner.forEach((error) => {
          if (error.path !== undefined) {
            let fieldName = error.path.split('.')[0]
            validationErrors[fieldName] = error.errors[0];
          }
        });
      });

    console.log(validationErrors)

    if (Object.entries(validationErrors).length !== 0) {
      let index = 0
      let found = false
      //let fieldName = error.field.split('.')[0]

      toast.error('Please correct all errors.', {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false
      })

      Object.keys(validationErrors).forEach((error) => {
        console.log('error', error)
        toast.warn(validationErrors[error], {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false
        })
      });

      const fieldName = Object.keys(validationErrors)[0]
      console.log('looking for field:', fieldName)
      while (index < tabs.length && !found) {
        const matchingKey = Object.keys(tabs[index].fields).find(key => key === fieldName)
        if (matchingKey) {
          found = true
          console.log('Mathing key found at index!', index)
          console.log('Culprit from tabs', tabs[index].fields[fieldName]);
          console.log('Culprit from state', settingsContext[fieldName]);
        }

        if (!found) index++
      }
  
      if (!settingsContext.isSubmitted) {
        setSettingsContext({ type: 'SET', name: 'isSubmitted', value: true })
      }
    
      if (settingsContext.currentTabIndex !== index) {
        setSettingsContext({ type: 'SET', name: 'currentTabIndex', value: index })
      }
    } else {
      settings.saveSettingsConstants(settingsContext, 'Settings saved!')
    }
  }

  const initialSettingsContext = useMemo(() => {
    return {
      isSubmitted: false,
      isLoading: true,
      isClientAdmin : false,
      isSystemAdmin: false,
      currentTabIndex: 0,
      settleSettingsOpen: true,
      //schema,
      onSubmit,
      onError,
      ...GeneralStandardSettingsState,
      ...ReturnAddressStandardSettingsState,
      ...CostCodeSettingsState
    }
  }, [])

  const settings = useSettings()
  
  const [settingsContext, setSettingsContext] = useReducer(settings.settingsReducer2, initialSettingsContext);

  useEffect(() => {
    let isActive = true
  
    load().then((dispatches) => {
      if (isActive) {
        dispatches.map(item => setSettingsContext(item))
      }
    })
  
    return () => {isActive = false}
  }, [])

  const tabs = useMemo(() => [
    {
      label: 'General',
      fields: GeneralStandardSettingsState,
      child: <GeneralStandardSettings />
    },
    {
      label: 'Return Address',
      fields: ReturnAddressStandardSettingsState,
      child: <ReturnAddressStandardSettings />
    },
    {
      label: 'Cost Codes',
      fields: CostCodeSettingsState,
      child: <CostCodeSettings />
    },
  ], [settingsContext.isSystemAdmin])

  const setCurrentTabIndex = (index) => {
    setSettingsContext({ type: 'SET', name: 'currentTabIndex', value: index })
  }

  return (
    <div>
      <AdminMenu tab="settings" />
      <div className="m-3">
        <h2 className="text-primary">Settings</h2>
        <hr />
        <SettingsLayout context={settingsContext} contextReducer={setSettingsContext}>
          <Nav tabs>
            {tabs.map((tab, index) => (
              <NavItem key={index}>
                <NavLink
                  className={`${index === settingsContext.currentTabIndex ? 'active' : ''}`}
                  onClick={() => { setCurrentTabIndex(index); }}
                >
                  {tab.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          {!settingsContext.isLoading && (
            <React.Fragment>
              {/* <div className="tab-content"> */}
              <TabContent activeTab={settingsContext.currentTabIndex}>
                {tabs.map((tab, index) => (
                  <TabPanel index={index} key={index}>
                    {tab.child}
                  </TabPanel>
                ))}
              </TabContent>

              <Row>
                <Col md={12} lg={11} xl={10} className="d-flex justify-content-end mb-3 mt-3">
                  <Button color="primary" onClick={submitAll}>Save Settings</Button>
                </Col>
              </Row>
            </React.Fragment>
          )}
          <hr />
        </SettingsLayout>
      </div>
    </div>
  )
}

export default StandardSettings