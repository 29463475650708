import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import Select from 'react-select'
import { customStyles } from '../dropdowns/Styles'
import { Button, Col, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import MailingStatusGrid from './MailStatusGrid'
import DecoratedDateRangePicker from '../widget/DecoratedDateRangePicker'
import { nanoid } from 'nanoid';
import MailStatusDetails from './MailStatusDetails';
import { MailStatusConstants, useMailStatusService } from '../../services/MailStatusService'
import MailStatusActions from './MailStatusActions';
import MailStatusActionsElectronicReceipts from './MailStatusActionsElectronicReceipts';
import MailStatusActionsConfirmationOfMailing from './MailStatusActionsConfirmationOfMailing';
import MailStatusMergeResults from './MailStatusMergeResults';
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { ArrowClockwise, EnvelopeCheck, Download, Layers, ReceiptCutoff, Tools, Truck, Union } from 'react-bootstrap-icons';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { actionButtonStyles, mainButtonStyles } from '../widget/TinyFabStyles';
import authService from '../api-authorization/AuthorizeService';
import { useClientService } from '../../services/ClientService';
import { ConstantCodeMailFuzeCostCodesEnabled, useSettingsService } from '../../services/SettingsService';

const MailStatus = () => {
  
  //const [gridKey, setGridKey] = useState(UUID.v4())
  const mailStatusService = useMailStatusService()
  const clientService = useClientService()
  const settingService = useSettingsService()
  const MySwal = withReactContent(Swal)
  const downLoadIconRef = useRef(React.createRef());
  const [mailStatusMergeResults, setMailStatusMergeResults] = useState({ showModal: false, results: []})

  const initialState = {
    perPage: { value: 30, label: '30' },
    showUspsMenu: false,
    showErrMenu: false,
    showCoMMenu: false,
    showDetails: false,
    selectedMailingData: [],
    detailMailingData: undefined,
    dateSelectionRange: [{
        startDate: new Date().toLocaleDateString() + ' ' + "00:00",
        endDate: new Date().toLocaleDateString() + ' ' + "00:00",
      key: 'selection',
    }],
    //gridKey: nanoid(),
    isLoading: false,
    isIntegrationPartner: false,
    costCodeEnabled: false
  }

  const stateReducer = (state, action) => {
    //console.log(action)
    switch (action.type) {
      case 'SET': {

        if (action.name === 'isLoading' && action.value)
        {
          console.log('updating gridkey!!')
          return {
            ...state,
            [action.name]: action.value,
            //gridKey: nanoid()
          }
        }
        else if (/*action.name === 'perPage' ||*/ action.name === 'dateSelectionRange')
        {
          //console.log('updating gridkey!!')
          //setGridKey(UUID.v4())
          return {
            ...state,
            [action.name]: action.value,
            //gridKey: UUID.v4()
          }
        }
        else {
          return {
            ...state,
            [action.name]: action.value
          }
        }
      }
    }

    return state
  }

  const [state, dispatch] = useReducer(stateReducer, initialState)
  const [mailingsData, setMailingsData] = useState([])

  // useEffect(() => {
  //   console.log('perPage value has changed')
  // }, [state.perPage])

  // useEffect(() => {
  //   console.log('dateSelectionRange value has changed')
  // }, [state.dateSelectionRange])

  // useEffect(() => {
  //   console.log('selectedMailingData value has changed')
  // }, [state.selectedMailingData])

  // useEffect(() => {
  //   console.log('Load mailings will be called because dateSelectionRange changed')
  //   onShowLoading()
  //   loadMailings()
  // }, [/*state.perPage,*/ state.dateSelectionRange])

  const perPageOptionValues = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 150, 200, 300, 400, 500]
  const perPageOptions = perPageOptionValues.map(value => { return { value: value, label: value.toString() }})
  
  // const onMailingSelected = useCallback(
  //   (selectedMailingData) => {
  //     if (selectedMailingData) {
  //       console.log('dispatching onMailingSelected')
  //       dispatch({ type: 'SET', name: 'selectedMailingData', value: selectedMailingData })
  //     }
  //   }
  // )

  useEffect(() => {
    populateState()
  }, [])

  const populateState = async () => {
    const [isAuthenticated, constantsResults] = await Promise.all([
      authService.isAuthenticated(),
      settingService.GetConstants([
        ConstantCodeMailFuzeCostCodesEnabled
      ])
    ])


    const constantCodes = constantsResults.map(constant => constant.value)
    const clientSettings = await settingService.GetClientSettings(constantCodes)

    const mailFuzeCostCodesEnabled = settingService.GetConstantValueFromResults(constantsResults, clientSettings, ConstantCodeMailFuzeCostCodesEnabled, (valStr) => {
      return valStr.toLowerCase() === 'true'
    })

    if (isAuthenticated) {
      const myClient = await clientService.GetMyClient()
      dispatch({ type: 'SET', name: 'isIntegrationPartner', value: myClient && myClient.IsIntegrationPartner })
    }

    dispatch({ type: 'SET', name: 'costCodeEnabled', value: mailFuzeCostCodesEnabled })
  }

  const PlainSwal = MySwal.mixin({
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    }
  })

  const inputWarning = (title, text) => {
    PlainSwal.fire({
      title,
      icon: 'warning',
      text,
      showCloseButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary btn-lg'
      }
    })
  }

  const onShowJobDetails = useCallback(
      (detailMailingData) => {
        if (detailMailingData) {
          console.log('dispatching onShowJobDetails')
          dispatch({ type: 'SET', name: 'detailMailingData', value: detailMailingData })
        }
      }
  )

  const onSelectionChanged = useCallback(
    (selectedMailingData) => {
      if (selectedMailingData) {
        console.log('dispatching onSelectionChanged')
        dispatch({ type: 'SET', name: 'selectedMailingData', value: selectedMailingData })
      }
    }
  )

  const onPageSizeChanged = useCallback((selected) => {
    //if (selected.value !== state.perPage.value) {
      //setPerPage(selected)
      dispatch({ type: 'SET', name: 'perPage', value: selected })
    //}
    console.log(selected)
  })

  const onDateSelect = useCallback((selected) => {
     console.log(selected)
     console.log(state.dateSelectionRange)

     dispatch({ type: 'SET', name: 'dateSelectionRange', value: selected })
  })

  const onCloseResults = () => {
    setMailStatusMergeResults({showModal: false, results: []})
  }

  const onUspsGenerate = useCallback(async (options) => {
    console.log('On USPS Generate', state.selectedMailingData)
    console.log(options)
    if (!state.selectedMailingData || state.selectedMailingData.length === 0) {
      inputWarning('No Mailings Selected', 'You must select at least one mailing status.')
    }
    else {
      const filtered = state.selectedMailingData.filter(item => !!item.barCodeTracking)
      if (filtered.length === 0) {
        inputWarning('No Valid Mailings Selected', 'You must select at least one trackable mailing status.')
      }
      else {
        PlainSwal.fire({
          title: `Merging Mailing Status${(state.selectedMailingData.length === 1 ? '' : 'es')}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
          timerProgressBar: true,
          didOpen: () => {
            PlainSwal.showLoading()
          },
        })
  
        const jobStatuses = filtered.map(job => { return {
          id: job.id,
          DocumentName: job.DocumentName,
          RecipientName: job.RecipientName,
          barCode: job.barCodeTracking,
          trackingType: job.trackingType
        }})
       
        const params = {
          jobStatuses,
          ...options
        }

        const authToken = await authService.getAccessToken()

        mailStatusService.MergeLettersAndStatus(params).then(result => {
          PlainSwal.close()

          console.log(result)

          if (result.returnCode === 0) {
            let transactionId = result.returnDataAsString
            transactionId = transactionId.substring(transactionId.lastIndexOf("=") + 1, transactionId.length)
    
            setMailStatusMergeResults({
              showModal: true,
              success: result.returnCode === 0,
              transactionLog: transactionId,
              downloadUrl: result.returnDataAsString,
              authToken: authToken,
              results: JSON.parse(result.returnDataAsJSON)
            })
          }
          else {
            toast.error(result.errorMessage, {
              toastId: 'fetch-wrapper-toast-id',
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            })
          }
        })
      }
    }
  })

  const onErrGenerate = useCallback(async (options) => {
    console.log('On E.R.R. Generate')
    console.log(options)

    if (!state.selectedMailingData || state.selectedMailingData.length === 0) {
      inputWarning('No Mailings Selected', 'You must select at least one mailing status.')
    } else {
      const filtered = state.selectedMailingData.filter(item => item.mailClass === MailStatusConstants.ElectronicReturnReceiptMaiLClass)
      if (filtered.length === 0) {
        inputWarning('No Valid Mailings Selected', 'You must select at least one mailing expecting an Electronic Return Receipt.')
      }
      else {
        PlainSwal.fire({
          title: `Retrieving Electronic Return Receipt${(filtered.length === 1 ? '' : 's')}`,
          allowEscapeKey: false,
          allowOutsideClick: false,
          timerProgressBar: true,
          didOpen: () => {
            PlainSwal.showLoading()
          },
        })

        const jobStatuses = filtered.map(job => { return {
          id: job.id,
          DocumentName: job.DocumentName,
          RecipientName: job.RecipientName,
          barCode: job.barCodeTracking,
          trackingType: job.trackingType
        }})

        const params = {
          jobStatuses,
          ...options
        }

        const authToken = await authService.getAccessToken()

        mailStatusService.MergeElectronicReturnReceipts(params).then(result => {
          PlainSwal.close()
  
          console.log(result)
  
          if (result.returnCode === 0) {
            let transactionId = result.returnDataAsString
            transactionId = transactionId.substring(transactionId.lastIndexOf("=") + 1, transactionId.length)
    
            setMailStatusMergeResults({
              showModal: true,
              success: result.returnCode === 0,
              transactionLog: transactionId,
              downloadUrl: result.returnDataAsString,
              authToken: authToken,
              results: JSON.parse(result.returnDataAsJSON)
            })
          }
          else {
            toast.error(result.errorMessage, {
              toastId: 'fetch-wrapper-toast-id',
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            })
          }
        })
      }
    }
  })

  const onCoMGenerate = useCallback(async (options) => {
    console.log('On C.o.M. Generate')
    console.log(options)

    if (!state.selectedMailingData || state.selectedMailingData.length === 0) {
      inputWarning('No Mailings Selected', 'You must select at least one mailing status.')
    } else {
      const filtered = state.selectedMailingData.filter(item => item.IncludeConfirmationOfMailing)
      if (filtered.length === 0) {
        inputWarning('No Valid Mailings Selected', 'You must select at least one mailing expecting a Confirmation of Mailing.')
      }
      else {
        PlainSwal.fire({
          title: `Retrieving Confirmation${(filtered.length === 1 ? '' : 's')} of Mailing`,
          allowEscapeKey: false,
          allowOutsideClick: false,
          timerProgressBar: true,
          didOpen: () => {
            PlainSwal.showLoading()
          },
        })

        const jobStatuses = filtered.map(job => { return {
          id: job.id,
          DocumentName: job.DocumentName,
          RecipientName: job.RecipientName,
          barCode: job.barCodeTracing,
          trackingType: job.trackingType
        }})

        const params = {
          jobStatuses,
          ...options
        }

        const authToken = await authService.getAccessToken()

        mailStatusService.MergeConfirmationOfMailing(params).then(result => {
          PlainSwal.close()
  
          console.log(result)
  
          if (result.returnCode === 0) {
            let transactionId = result.returnDataAsString
            transactionId = transactionId.substring(transactionId.lastIndexOf("=") + 1, transactionId.length)
    
            setMailStatusMergeResults({
              showModal: true,
              success: result.returnCode === 0,
              transactionLog: transactionId,
              downloadUrl: result.returnDataAsString,
              authToken: authToken,
              results: JSON.parse(result.returnDataAsJSON)
            })
          }
          else {
            toast.error(result.errorMessage, {
              toastId: 'fetch-wrapper-toast-id',
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            })
          }
        })
      }
    }
  })

  const onShowLoading = () => {
    dispatch({ type: 'SET', name: 'isLoading', value: true })
  }

  const onHideOverlay = () => {
    dispatch({ type: 'SET', name: 'isLoading', value: false })
  }

  const onRefresh = (e) => {
    e.target.parentElement.blur()
    e.target.blur()
    onShowLoading()
    loadMailings()
  };

  const loadMailings = async () => {
    //dispatch({ type: 'SET', name: 'isLoading', value: true })

    const startDate = new Date(state.dateSelectionRange[0].startDate).toISOString()
    const endDate = new Date(state.dateSelectionRange[0].endDate).toISOString()
    const mailingsData = await mailStatusService.GetMailStatusData(startDate, endDate)
    if (mailingsData && mailingsData.length > 1) {
      //mailingsData[0].DocumentName = 'Longer Text Test'
      //mailingsData[1].DocumentName = 'A much much Longer Text Test'
      //console.log(mailingsData)
    }
    
    setMailingsData(mailingsData)
    onHideOverlay()
    //dispatch({ type: 'SET', name: 'isLoading', value: false })
  }

  const showTools = (menuName) => {
    //console.log(`Show ${menuName} Menu!`);
    dispatch({ type: 'SET', name: menuName, value: true })
  }

  const hideTools = (menuName) => {
    //console.log(`Hide ${menuName} Menu!`);
    dispatch({ type: 'SET', name: menuName, value: false })
  }

  const onOpenMailStatusDetails = () => {
    //console.log('ON OPEN')
    dispatch({ type: 'SET', name: 'showDetails', value: true })
  }

  const onCloseMailStatusDetails = () => {
    //console.log('ON Close')
    dispatch({ type: 'SET', name: 'showDetails', value: false })
  }

  return (
    <div>
      <div className="d-none">
        <div ref={downLoadIconRef}>
          <Button color="primary" className="btn-lg" href="#"><Download /> Download Document</Button>
        </div>
      </div>
      <Row>
        <Col xs={12} md={4} lg={3}>
          <div className="d-flex flex-row">
            <Label className="p-2">Mailings/page:</Label>
            <Col>
              <Select
                  styles={customStyles}
                  options={perPageOptions}
                  value={state.perPage}
                  onChange={ onPageSizeChanged }
              />
            </Col>
          </div>
        </Col>
        <Col xs={12} md={8} lg={9}>
          <div className="d-flex flex-row justify-content-start">
            <Label className="p-2">Date:</Label>
            <DecoratedDateRangePicker
              onChange={onDateSelect}
            />
            <Button color="dark" className="mb-2 ms-4" onClick={onRefresh}><ArrowClockwise /> Refresh</Button>
          </div>
        </Col>
      </Row>
      <div className="mb-3">

      </div>
      <MailingStatusGrid
        // key={state.gridKey}
        perPage={state.perPage}
        //dateSelectionRange={state.dateSelectionRange}
        isLoading={state.isLoading}
        /*onSelected={onMailingSelected}*/
        onSelectionChanged={onSelectionChanged}
        onShowJobDetails={onShowJobDetails}
        mailingsData={mailingsData}
        costCodeEnabled={state.costCodeEnabled}
      />
      <div /*style={{marginBottom: '45vh'}}*/>
        <MailStatusDetails
          mailingData={state.detailMailingData}
          onOpen={onOpenMailStatusDetails}
          onClose={onCloseMailStatusDetails}
          //isOpen={openDetails}
        />
      </div>
      <div>
        <MailStatusActions
          showMenu={state.showUspsMenu}
          selectedMailingData={state.selectedMailingData}
          isIntegrationPartner={state.isIntegrationPartner}
          onClose={() => hideTools('showUspsMenu')}
          onGenerate={onUspsGenerate}
        />
      </div>
      <div>
        <MailStatusActionsElectronicReceipts
          showMenu={state.showErrMenu}
          selectedMailingData={state.selectedMailingData}
          isIntegrationPartner={state.isIntegrationPartner}
          onClose={() => hideTools('showErrMenu')}
          onGenerate={onErrGenerate}
        />
      </div>
      <div>
        <MailStatusActionsConfirmationOfMailing
          showMenu={state.showCoMMenu}
          selectedMailingData={state.selectedMailingData}
          isIntegrationPartner={state.isIntegrationPartner}
          onClose={() => hideTools('showCoMMenu')}
          onGenerate={onCoMGenerate}
        />
      </div>
      <div style={{visibility: (state.showUspsMenu || state.showErrMenu || state.showCoMMenu || state.showDetails) ? 'hidden' : 'visible'}}>
        <Fab
          mainButtonStyles={mainButtonStyles}
          style={{top: 100, right: 10}}
          icon={<Tools size={30} />}
          //event={event}
          alwaysShowTitle={false}
          text="Tools"
        >
          <Action
            style={actionButtonStyles}
            text="USPS Tracking"
            onClick={() => showTools('showUspsMenu')}
          >
            <Layers size={20} />
          </Action>
          <Action
            style={actionButtonStyles}
            text="Electronic Return Receipt"
            onClick={() => showTools('showErrMenu')}
          >
            <ReceiptCutoff size={20} />
          </Action>
          <Action
            style={actionButtonStyles}
            text="Confirmation of Mailing"
            onClick={() => showTools('showCoMMenu')}
          >
            <EnvelopeCheck size={20} />
          </Action>
        </Fab>
      </div>

      {mailStatusMergeResults.showModal && (
        <MailStatusMergeResults onClose={onCloseResults} {...mailStatusMergeResults} />
      )}
    </div>
  )
}

export default MailStatus
