import { useFetchWrapper } from '../services/fetch-wrapper'

export const DefaultLocationID = -1;

export { useLocationService };

const useLocationService = () => {

  const fetchWrapper = useFetchWrapper()

  const GetLocation = async (locationId) => {
    return fetchWrapper.get(`location/${locationId}`)
  }

  const GetLocations = async () => {
    return fetchWrapper.get(`location`)
  }

  return { GetLocation, GetLocations }
}