import BaseSelect from "../dropdowns/BaseSelect";
import BaseSelect2 from "../dropdowns/BaseSelect2";

// export const LetterTemplateSelect = ({onChange, onError, letterTemplateType, ...rest}) => {
//   return (
//     <BaseSelect 
//       serviceUrl={`lettertemplate?letterTemplateType=${letterTemplateType}`}
//       valueName="LetterTemplateID"
//       labelName="Name"
//       onChange={onChange}
//       onError={onError}
//       {...rest}
//     />
//   )
// }

// export const LetterTemplateForTenantsSelect = (props) => LetterTemplateSelect({...props, letterTemplateType: 'LetterTemplateTypeTenant'})
// export const LetterTemplateForProspectsSelect = (props) => LetterTemplateSelect({...props, letterTemplateType: 'LetterTemplateTypeProspect'})
// export const LetterTemplateForOwnersSelect = (props) => LetterTemplateSelect({...props, letterTemplateType: 'LetterTemplateTypeOwner'})

export const PropertyGroupSelect = ({onChange, onError, ...rest}) => {
  const params = new URLSearchParams({addAll: true}).toString();

  return (
    <BaseSelect
      serviceUrl={`propertygroup?${params}`}
      valueName="GroupID"
      labelName="Name"
      onChange={onChange}
      onError={onError}
      {...rest}
    />
  )
}

export const PropertySelect = ({onChange, onError, propertyGroup, ...rest}) => {
  const params = new URLSearchParams({propertyGroup, addAll: true}).toString();

  return (
      <BaseSelect
        serviceUrl={`property?${params}`}
        valueName="PropertyID"
        labelName="ShortName"
        onChange={onChange}
        onError={onError}
        useEffectDependencies={[propertyGroup]}
        {...rest}
      />
  )
}

export const RentManagerUdfFieldsSelect = ({options, onChange, onError, value, ...rest}) => {
  return (
      <BaseSelect2
        value={value}
        options={options}
        onChange={onChange}
        onError={onError}
        selectFirst={true}
        {...rest}
      />
  )
}

export const ProspectStatusSelect = ({onChange, onError, ...rest}) => {
  const params = new URLSearchParams({ addAll: true}).toString();

  return (
      <BaseSelect
        serviceUrl={`prospectstatus?${params}`}
        valueName="ProspectStatusID"
        labelName="ProspectStatusName"
        onChange={onChange}
        onError={onError}
        {...rest}
      />
  )
}

export const ContactTypeSelect = ({options, onChange, onError, value, ...rest}) => {
  return (
      <BaseSelect2
        options={options}
        value={value}
        onChange={onChange}
        onError={onError}
        selectFirst={true}
        {...rest}
      />
  )
}

export const HistoryCategorySelect = ({options, onChange, onError, value, ...rest}) => {
  return (
      <BaseSelect2
        options={options}
        value={value}
        onChange={onChange}
        onError={onError}
        selectFirst={false}
        {...rest}
      />
  )
}