import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import { useForm, useFormState } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import Setting from './Setting'
import useSettingsContext from './SettingsContext'
import { doRegisterInputCheckbox, doRegisterSelect } from './FormHookRegisters' 
import { Blank } from '../dropdowns/DefaultSelectOptions'
import { ConstantCodeMailFuzeChargeTenants,
  ConstantCodeMailingChargeTypeIDTenant,
} from '../../services/SettingsService'
import { MailingChargeTypeIDTenantSelect } from './TenantChargebackDowns'

export const TenantChargebackSettingsState = {
  mailFuzeChargeTenants: {
    constant: ConstantCodeMailFuzeChargeTenants,
    code: undefined,
    type: 'Boolean',
    value: false
  },
  mailingChargeTypeIDTenant: {
    constant: ConstantCodeMailingChargeTypeIDTenant,
    code: undefined,
    type: 'Integer',
    value: Blank.value
  }
}

export const TenantChargebackSettingsSchema = { //yup.object({
  mailFuzeChargeTenants: yup.object().shape({
    value: yup.boolean().required()
  }), 
  mailingChargeTypeIDTenant: yup.object()
    .when('mailFuzeChargeTenants', {
      is: (mailFuzeChargeTenants) => { 
        return mailFuzeChargeTenants.value === true
      },
      then: yup.object({
        value: yup.number().integer().moreThan(0, 'Please select a Charge Type.')
      }),
      otherwise: yup.object({
        value: yup.mixed().notRequired()
      })
    })
}//)//.required();

const InternalSchema = yup.object({
  ...TenantChargebackSettingsSchema
})

const TenantChargebackSettings = () => {

  const formRef = useRef(null)
  const {context: state, contextReducer: dispatchSecond} = useSettingsContext()

  console.log('Loading Chargeback Tenants Tab with context', state)

  const {
    //setFocus,
    register,
    setValue,
    //getFieldState,
    handleSubmit,
    trigger,
    reset,
    //control
    formState: { errors, isSubmitted }
  } = useForm({
    defaultValues: {
      ...state
    },
    // defaultValues: useMemo(() => {
    //   return {...state}
    // }, [state]),
    // defaultValues: {
    //   // mailFuzeChargeTenants: state.mailFuzeChargeTenants,
    //   // mailingGLAccountIDToBillPropertyManagementCompany: state.mailingGLAccountIDToBillPropertyManagementCompany,
    //   // mailFuzeMailingFeePropertyMethod: state.mailFuzeMailingFeePropertyMethod,
    //   // mailFuzeMailingFeeThisPropertyID: state.mailFuzeMailingFeeThisPropertyID
    //   ...state
    // },
    resolver: yupResolver(InternalSchema),
    mode: "onChange",
    context: { state }
  });

  const dispatch = (action) => {
    action.setValue = setValue
     
    dispatchSecond(action)
    if (state.isSubmitted || (action.name === 'isSubmitted' && action.value === true)) {
      trigger()
    }
  }
  
  useEffect(() => {
    reset({
      ...state
    });
    if (state.isSubmitted){
      trigger();
    }
      
    }, [state]);

  useEffect(() => {
    console.log('useEffect triggered by state.isSubmitted', state.isSubmitted, 'isSubmitted', isSubmitted)

    if (state.isSubmitted === false && isSubmitted) {
      dispatch({ type: 'SET', name: 'isSubmitted', value: isSubmitted })
    }
  }, [state.isSubmitted, isSubmitted])

  //trigger will change on the first load. It's only way to run the validation on the default values.
  useEffect(() => {
    if (state.isSubmitted) {
      trigger()
    }
  }, [trigger, state.isSubmitted, state.currentTabIndex])

  const save = async (e) => {
    e.target.blur();
    formRef.current.props.onSubmit(e)
    //settings.saveSettingsConstants(state, dispatch, 'Other settings saved!')
  }

  return (
    <div>
      <React.Fragment>
        <Form ref={formRef} 
          onSubmit={handleSubmit(state.onSubmit, state.onError)}
        >
          <Row>
            <Col md={11} lg={10} xl={9}>
              <Row className="mt-3">
                <Col xs={12}>
                  <FormGroup
                    check
                    inline
                  >
                    <Input
                      {...doRegisterInputCheckbox(register, dispatch, 'mailFuzeChargeTenants', state.isSubmitted, errors, state)}
                      style={{transform: 'scale(1.3)'}}
                    />
                    <Label for='mailFuzeChargeTenants'>I want to give users the option to charge tenants for each mailing</Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row className={`mt-2 ps-5 ${state.mailFuzeChargeTenants.value === false ? 'text-muted' : ''}`}>
                <Col xs={12}>
                  <Row className='ps-5'>
                    <Col xs={12}>
                      <Setting label='Charge Type to Use When Charging Tenant:' description=''>
                        <MailingChargeTypeIDTenantSelect
                          options={state.mailingChargeTypeIDTenant.options}
                          {...doRegisterSelect(register, dispatch, 'mailingChargeTypeIDTenant', state.isSubmitted, errors, state, '', true)}
                          isDisabled={state.mailFuzeChargeTenants.value === false}
                        />
                      </Setting>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row>
            <Col md={11} lg={8} xl={7} className="d-flex justify-content-end mb-3 mt-3">
              <Button color="dark" onClick={save}>Save</Button>
            </Col>
          </Row> */}
        </Form>
      </React.Fragment>
    </div>
  )
}

export default TenantChargebackSettings