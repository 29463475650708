import { useFetchWrapper } from '../services/fetch-wrapper'

export { useTenantAddressTypeService };

const useTenantAddressTypeService = () => {

  const fetchWrapper = useFetchWrapper()

  const GetTenantAddressTypes = async () => {
    const params = new URLSearchParams({ addDefault: true}).toString();
    return fetchWrapper.get(`tenant/tenantaddresstype?${params}`)
  }

  return { GetTenantAddressTypes }
}