import React, { useRef } from 'react'
import { Button, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap'
import { BinocularsFill, GripVertical, X, FileEarmarkPlusFill, InfoLg, SortNumericDown, CursorFill } from 'react-bootstrap-icons'


const SortablePartsHelp = () => {
  const addDocumentHelpRef = useRef(null)

  return (
    <>
      <Button
        innerRef={addDocumentHelpRef}
        color='primary'
        style={{ transform: 'scale(0.6)' }}
        className='btn-circle d-flex flex-column justify-content-center align-items-center'
      >
        <InfoLg size={22} style={{width: '25px', height: '25px' }} />
      </Button>
      <UncontrolledPopover
        placement="right"
        target={addDocumentHelpRef}
        trigger="focus"
      >
        <PopoverHeader>
          Adding Documents
        </PopoverHeader>
        <PopoverBody>
          <div className='text-muted'>
            <div><span className='text-grey-5 border rounded px-1 pb-1'><FileEarmarkPlusFill /></span> Add one or more documents to your mailing(s) using the options in the <span className="text-dark" style={{fontWeight: 500}}>'Add Document'</span> drop down menu.</div>
            <div className='pt-2'><span className='text-grey-5 border rounded px-1 pb-1'><SortNumericDown /></span> The mailing will be composed of all added documents in the order of appearance.</div>
            <div className='pt-2'><span className='text-grey-5 border rounded px-1 pb-1'><CursorFill /></span> Hover any document to bring up the actions menu.</div>
            <hr />
            <h6>Actions</h6>
            <div className='pt-2'><span className='text-grey-5 border rounded px-1 pb-1'><GripVertical /></span> Change the order any document using the drag icon.</div>
            <div className='pt-2'><span className='text-grey-5 border rounded px-1 pb-1'><BinocularsFill /></span> Preview any document by clicking the preview icon.</div>
            <div className='pt-2'><span className='text-grey-5 border rounded px-1 pb-1'><X /></span> Remove any document clicking the 'X' icon.</div>
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  )
}

export default SortablePartsHelp