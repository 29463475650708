import React, { useReducer, useEffect, useState } from 'react'
import { AlignBottom, AlignEnd, AlignStart, AlignTop, Icon123 } from 'react-bootstrap-icons'
import { Row, Col, ButtonToggle, ButtonGroup, Button } from 'reactstrap'
import OptionYesNo from '../widget/OptionYesNo'
import { customStyles } from '../dropdowns/Styles'

export const MailStatusAppendUspsTracking = ({onChange}) => {
  return (
    <OptionYesNo
      onChange={onChange}
      id="appendUspsTracking"
      text="Merge Letter & Tracking"
      initialValue={true}
    >
    </OptionYesNo>
  )
}

export const MailStatusAttachLetter = ({onChange}) => {
  return (
    <OptionYesNo
      onChange={onChange}
      id="attachLetter"
      text="Attach Results to History"
    >
    </OptionYesNo>
  )
}

export const MailStatusAttachErr = ({onChange}) => {
  return (
    <OptionYesNo
      onChange={onChange}
      id="attachElectronicReturnReceipt"
      text="Attach E.R.R. to History"
    >
    </OptionYesNo>
  )
}

export const MailStatusAttachCoM = ({onChange}) => {
  return (
    <OptionYesNo
      onChange={onChange}
      id="attachConfirmationOfMailing"
      text="Attach C.o.M. to History"
    >
    </OptionYesNo>
  )
}

export const EmbedTrackingNumber = ({onChange, onLoaded, onError, optionText = 'Embed Tracking Number', alignmentText = 'Tracking Number Alignment', ...rest}) => {
  const initialState = {
    displayOption: 'none',
    embedTrackingNumber: false,
    verticalAlignment: 'top',
    horizontalAlignment: 'right'
  }

  const actionReducer = (state, action) => {
    switch (action.type) {
      case 'SET': {
        if (action.name === 'embedTrackingNumber') {
          return {
            ...state,
            [action.name]: action.value,
            displayOption: action.value ? 'block' : 'none'
          }
        }
        else {
          return {
            ...state,
            [action.name]: action.value
          }
        }
      }
    }

    return state
  }

  const [state, dispatch] = useReducer(actionReducer, initialState)

  const [selected, setSelected] = useState({value: 0, label: ''})

  const verticalAlignmentRadios = [
    { name: (<React.Fragment><AlignTop /><br />Top</React.Fragment>), value: 'top' },
    { name: (<React.Fragment>Bottom<br /><AlignBottom /></React.Fragment>), value: 'bottom' }
  ]

  useEffect(() => {
    onChange({embedTrackingNumber: state.embedTrackingNumber, 
      verticalAlignment: state.verticalAlignment,
      horizontalAlignment: state.horizontalAlignment})
  }, [state.embedTrackingNumber, 
    state.verticalAlignment,
    state.horizontalAlignment])

  return (
    <React.Fragment>
      <Row>
        <Col>
        <OptionYesNo
          onChange={yn => dispatch({ type: 'SET', name: 'embedTrackingNumber', value: yn.embedTrackingNumber })}
          id="embedTrackingNumber"
          text={optionText}
        >
        </OptionYesNo>
        </Col>
      </Row>
      <div style={{display : state.displayOption}} className="m-2 mb-0">
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <span>{alignmentText}</span>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="d-flex justify-content-center">
            <ButtonGroup>
              <Button
                outline
                color="secondary"
                value="left"
                active={state.horizontalAlignment === 'left'}
                onClick={e => {
                  e.currentTarget.blur()
                  dispatch({ type: 'SET', name: 'horizontalAlignment', value: e.currentTarget.value })
                }}
              >
                <AlignStart /> Left
              </Button>
              <ButtonGroup vertical>
              {verticalAlignmentRadios.map((radio, idx) => (
                <Button
                  outline
                  className='rounded-0'
                  key={idx}
                  id={`radio-${idx}`}
                  value={radio.value}
                  active={state.verticalAlignment === radio.value}
                  onClick={e => {
                    e.currentTarget.blur()
                    dispatch({ type: 'SET', name: 'verticalAlignment', value: e.currentTarget.value })
                  }}
                >
                  {radio.name}
                </Button>
              ))}
            </ButtonGroup>
              <Button
                outline
                color="secondary"
                value="right"
                active={state.horizontalAlignment === 'right'}
                onClick={e => {
                  e.currentTarget.blur()
                  dispatch({ type: 'SET', name: 'horizontalAlignment', value: e.currentTarget.value })
                }}
              >
                Right <AlignEnd />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="d-flex justify-content-center">
                <div style={{width: '150px', height: '225px', paddingTop: (state.verticalAlignment === 'top' ? '0px' : '198px')}} className={`border border-secondary ${state.horizontalAlignment === "left" ? "" : "d-flex justify-content-end"}`}>
                  <div style={{width: '75px', height: '25px', fontSize: "10px" }} className="bg-primary d-flex flex-column text-center justify-content-center">xxxxxxxxxx</div>
                </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}