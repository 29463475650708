import { useFetchWrapper } from '../services/fetch-wrapper'

export { usePropertyService };

const usePropertyService = () => {

  const fetchWrapper = useFetchWrapper()

  const GetPropertyManagementCompanyProperty = async () => {
    return fetchWrapper.get(`property/managementcompany`)
  }

  const GetPropertyAddresses = async (property) => {
    return fetchWrapper.get(`property/${property}/addresses`)
  }

  return { GetPropertyAddresses, GetPropertyManagementCompanyProperty }
}