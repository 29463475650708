import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import { useForm, useFormState } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Col, Form, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import GeneralSettings, { GeneralSchema, GeneralSettingsState } from './GeneralSettings'
//import CorrespondenceSettings from './CorrespondenceSettings'
import AdminMenu from './Menu'
import { Roles } from '../../services/RoleService'
import authService from '../api-authorization/AuthorizeService'
import FlushSettings from './FlushSettings'
import SettingsLayout from './SettingsLayout'
import { useSettings } from './SettingsReducer'
import { usePropertyService } from '../../services/PropertyService'
import { useSettingsService } from '../../services/SettingsService'
import SubscriptionBillingSettings, { SubscriptionBillingSettingsSchema, SubscriptionBillingSettingsState } from './SubscriptionBillingSettings';
import { toast } from 'react-toastify'
import MailingBillingSettings, { MailingBillingSettingsState, MailingBillingSettingsSchema } from './MailingBillingSettings'
import { TabPanel } from './TabPanel'
import PropertyChargebackSettings, { PropertyChargebackSettingsSchema, PropertyChargebackSettingsState } from './PropertyChargebackSettings'
import { useVendorService } from '../../services/VendorService'
import TenantChargebackSettings, { TenantChargebackSettingsSchema, TenantChargebackSettingsState } from './TenantChargebackSettings'
import ReturnAddressSettings, { ReturnAddressSettingsSchema, ReturnAddressSettingsState } from './ReturnAddressSettings'
import CostCodeSettings, { CostCodeSettingsSchema, CostCodeSettingsState } from './CostCodeSettings'
import { useClientService } from '../../services/ClientService'
import { useAddressStateService } from '../../services/AddressStateService'
import { Blank } from '../dropdowns/DefaultSelectOptions'


const Settings = () => {

  const settingsService = useSettingsService()
  const clientService = useClientService()
  const addressStateService = useAddressStateService()

  // const [state, setState] = useState({
  //   isLoading: true,
  //   isClientAdmin : false,
  //   isSystemAdmin: false,
  // })

  // const schema = yup.object().shape({
  //   //provider: yup.mixed().transform((curr, orig) => orig === '' ? null : curr).required("Provider Name is required"),
  //   //patientFirstName: yup.string().trim().required("Patient first name is required"),
  //   //patientLastName: yup.string().trim().required("Patient last name is required"),
  //   //benefitNetwork: yup.mixed().transform((curr, orig) => orig === '' ? null : curr).required("Please select weather you want to be IN or OUT of network benefits."),
  //   //insuranceCompany: yup.mixed().transform((curr, orig) => orig === '' ? null : curr).required("Insurance Company is required"),
  //   //insuranceId: yup.string().trim().required("Insurance ID is required"),
  //   mailFuzeSubscriptionFeePropertyMethod: yup.string().oneOf(['0', '1']).required(),
  //   mailFuzeSubscriptionFeeThisPropertyID: yup.mixed().when("mailFuzeSubscriptionFeePropertyMethod", {
  //     is: '1',
  //     then: yup.mixed().transform((curr, orig) => orig.value === Blank.value ? null : curr).required("Please select a property.")
  //   })/*,
  //   secondaryInsuranceId: yup.string().when("hasSecondaryInsurance", {
  //     is: true,
  //     then: yup.string().trim().required("Insurance ID is required"),
  //   })*/
  // }).required();
  
  // const {
  //   //setFocus,
  //   register,
  //   setValue,
  //   //getFieldState,
  //   handleSubmit,
  //   trigger,
  //   //control
  //   formState: { errors, isSubmitted }
  // } = useForm({
  //   defaultValues: {
  //     mailingHistoryCategoryID: undefined,
  //     efformanceVendor: undefined,
  //     mailFuzeCreateBillSubscriptionFee: undefined,
  //     mailFuzeSubscriptionFeeGLAccountID: undefined,
  //     paymentFeeGLAccountID: Blank,
  //     mailFuzeSubscriptionFeePropertyMethod: '0',
  //     mailFuzeSubscriptionFeePropertyMethodPostfix: 0,
  //     mailFuzeSubscriptionFeeThisPropertyID: Blank
  //   },
  //   resolver: yupResolver(schema),
  // });

  const schema = useMemo(() => {
    return yup.object().shape({
    ...GeneralSchema,
    ...SubscriptionBillingSettingsSchema,
    ...MailingBillingSettingsSchema,
    ...PropertyChargebackSettingsSchema,
    ...TenantChargebackSettingsSchema,
    ...ReturnAddressSettingsSchema,
    ...CostCodeSettingsSchema
  }, [['mailFuzeChargeBackOwners', 'mailFuzeMailingFeePropertyMethod'],
  ['mailFuzeMailingFeePropertyMethod', 'mailFuzeChargeBackOwners'],
  ['mailFuzeCreateOwnerInvoice', 'mailFuzeCreateOwnerBill'],
  ['mailFuzeCreateOwnerBill','mailFuzeCreateOwnerInvoice'],
  ['mailFuzeChargeBackOwnersMarkupAmountEnabled', 'mailFuzeChargeBackOwnersMarkupPercentEnabled'],
  ['mailFuzeChargeBackOwnersMarkupPercentEnabled', 'mailFuzeChargeBackOwnersMarkupAmountEnabled']])

    
    
    /*SubscriptionBillingSettingsSchema
      .concat(MailingBillingSettingsSchema)
      .concat(PropertyChargebackSettingsSchema)
      .concat(TenantChargebackSettingsSchema)*/
    // return {
    //   ...SubscriptionBillingSettingsSchema,
    //   ...MailingBillingSettingsSchema
    // }
  }, [])

  const onSubmit = useCallback((data, e) => {
    console.log(data)
    console.log(e)

    //e.preventDefault();
    // dispatch({ type: 'SET', name: 'errors', value: [] })
    // dispatch({ type: 'SET', name: 'data', value: data })
    // dispatch({ type: 'SET', name: 'isSubmitted', value: true })

    // nextStep();
  }, [])

  const onError = useCallback((errors) => {
    //e.preventDefault()
    //e.stopPropagation();

    //console.log(stepErrors, e)
    console.log('submit errors', errors)

    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
  
    // if (firstError) {
    //   console.log('set focus call', firstError)
    //   //firstError.ref.focus()
    //   //setFocus(firstError.ref);
    // }

    toast.error('Please correct all errors.', {
      position: "bottom-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    Object.keys(errors).forEach((field) => {
      toast.warn(errors[field].message, {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false
      })

    });
  }, [])

  const initialSettingsContext = useMemo(() => {
    return {
      isSubmitted: false,
      isLoading: true,
      isClientAdmin : false,
      isSystemAdmin: false,
      currentTabIndex: 0,
      settleSettingsOpen: true,
      //schema,
      onSubmit,
      onError,
      ...GeneralSettingsState,
      ...SubscriptionBillingSettingsState,
      ...MailingBillingSettingsState,
      ...PropertyChargebackSettingsState,
      ...TenantChargebackSettingsState,
      ...ReturnAddressSettingsState,
      ...CostCodeSettingsState
    }
  }, [])

  const settings = useSettings()
  const propertyService = usePropertyService()
  const vendorService = useVendorService()

  const [settingsContext, setSettingsContext] = useReducer(settings.settingsReducer2, initialSettingsContext);

  useEffect(() => {
    let isActive = true

    console.log('HERE WE GO AGAIN!')

    load().then((dispatches) => {
      if (isActive) {
        dispatches.map(item => setSettingsContext(item))
        //setLoadingConstants(false)
      }
    })

    return () => {isActive = false}
  }, [])


  const load = async () => {
    const [mailingHistoryCategoryIDSelectOptions,efformanceVendorIDSelectOptions, glAccountIDSelectOptions,
      propertyIDSelectOptions, ownerInvoiceChargeTypeSelectOptions, propertyAddressTypeSelectOptions, client, statesOptions,
      constantsResults, loadAdditionalInfoResults, populateStateResults] = await Promise.all([

      settingsService.GetMailingHistoryCategoryIDSelectOptions(),
      settingsService.GetEfformanceVendorIDSelectOptions(),
      settingsService.GetGLAccountIDSelectOptions(),
      settingsService.GetPropertyIDSelectOptions(),
      settingsService.GetOwnerInvoiceChargeTypeSelectOptions(),
      settingsService.GetPropertyAddressTypeSelectOptions(),
      clientService.GetMyClient(),
      addressStateService.GetStateCodes(),
      loadConstants(),
      loadAdditionalInfo(),
      populateState()
    ])

    console.log('propertyIDSelectOptions', propertyIDSelectOptions)

    console.log('constantsResults', constantsResults);

    console.log('statesOptions', statesOptions)

    const dispatches = constantsResults.concat(loadAdditionalInfoResults).concat(populateStateResults);
    dispatches.push({ type: 'SET_OPTIONS', name: 'mailingHistoryCategoryID', value: mailingHistoryCategoryIDSelectOptions })
    dispatches.push({ type: 'SET_OPTIONS', name: 'efformanceVendorID', value: efformanceVendorIDSelectOptions })
    dispatches.push({ type: 'SET_OPTIONS', name: 'mailFuzeSubscriptionFeeGLAccountID', value: glAccountIDSelectOptions })
    dispatches.push({ type: 'SET_OPTIONS', name: 'paymentFeeGLAccountID', value: glAccountIDSelectOptions })
    dispatches.push({ type: 'SET_OPTIONS', name: 'mailFuzeSubscriptionFeeThisPropertyID', value: propertyIDSelectOptions })
    dispatches.push({ type: 'SET_OPTIONS', name: 'mailingGLAccountIDToBillPropertyManagementCompany', value: glAccountIDSelectOptions })
    dispatches.push({ type: 'SET_OPTIONS', name: 'mailFuzeMailingFeeThisPropertyID', value: propertyIDSelectOptions })
    dispatches.push({ type: 'SET_OPTIONS', name: 'mailFuzeOwnerInvoiceChargeTypeID', value: ownerInvoiceChargeTypeSelectOptions })
    dispatches.push({ type: 'SET_OPTIONS', name: 'mailingGLAccountID', value: glAccountIDSelectOptions })
    dispatches.push({ type: 'SET_OPTIONS', name: 'mailFuzeOwnerBillThisVendorID', value: efformanceVendorIDSelectOptions })
    dispatches.push({ type: 'SET_OPTIONS', name: 'mailingChargeTypeIDTenant', value: ownerInvoiceChargeTypeSelectOptions })
    dispatches.push({ type: 'SET_OPTIONS', name: 'mailFuzeReturnAddressThisProperty', value: propertyIDSelectOptions })
    
    dispatches.push({ type: 'SET_OPTIONS', name: 'mailFuzeReturnAddressRecipientAddressType', value: propertyAddressTypeSelectOptions })
    dispatches.push({ type: 'SET_ALL_OPTIONS', name: 'mailFuzeReturnAddressThisPropertyAddressType', value: propertyAddressTypeSelectOptions })

    dispatches.push({ type: 'SET_OPTIONS', name: 'mailFuzeReturnAddressCustomStateID', value: statesOptions })
    dispatches.push({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressCustomCompanyName', value: client.CompanyName })
    dispatches.push({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressCustomStreet1', value: client.Street1 })
    dispatches.push({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressCustomCity', value: client.City })
    dispatches.push({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressCustomStateID', value: client.StateID })
    dispatches.push({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressCustomZip', value: client.PostalCode })
    
    //because we need both values from 2 different calls, after we've gatherered all values to be dispatched, 
    //look through them to override the value of Method based on "postfix" and Method value.
    var mailFuzeSubscriptionFeePropertyMethod = dispatches.find(action => action.name === 'mailFuzeSubscriptionFeePropertyMethod')
    var mailFuzeSubscriptionFeePropertyMethodPostfix = dispatches.find(action => action.name === 'mailFuzeSubscriptionFeePropertyMethodPostfix')
    var mailFuzeMailingFeePropertyMethod = dispatches.find(action => action.name === 'mailFuzeMailingFeePropertyMethod')
    var mailFuzeMailingFeePropertyMethodPostfix = dispatches.find(action => action.name === 'mailFuzeMailingFeePropertyMethodPostfix')
    var mailFuzeOwnerBillVendorMethod = dispatches.find(action => action.name === 'mailFuzeOwnerBillVendorMethod')
    var mailFuzeOwnerBillVendorMethodPostfix = dispatches.find(action => action.name === 'mailFuzeOwnerBillVendorMethodPostfix')

    if (mailFuzeSubscriptionFeePropertyMethodPostfix === 'Not Found' && int.parse(mailFuzeSubscriptionFeePropertyMethod) < 1) {
      dispatches.push({ type: 'SET_VALUE', name: 'mailFuzeSubscriptionFeePropertyMethod', value: 1 })  
    }

    if (mailFuzeMailingFeePropertyMethodPostfix === 'Not Found' && int.parse(mailFuzeMailingFeePropertyMethod) < 1) {
      dispatches.push({ type: 'SET_VALUE', name: 'mailFuzeMailingFeePropertyMethod', value: 1 })  
    }

    if (mailFuzeOwnerBillVendorMethodPostfix === 'Not Found' && int.parse(mailFuzeOwnerBillVendorMethod) < 1) {
      dispatches.push({ type: 'SET_VALUE', name: 'mailFuzeOwnerBillVendorMethod', value: 1 })  
    }

    var mailFuzeReturnAddressThisProperty = dispatches.find(action => action.name === 'mailFuzeReturnAddressThisProperty' && action.type === 'SET_VALUE')
    var mailFuzeReturnAddressThisPropertyAddressType = dispatches.find(action => action.name === 'mailFuzeReturnAddressThisPropertyAddressType' && action.type === 'SET_VALUE')

    console.log('----mailFuzeReturnAddressThisProperty', mailFuzeReturnAddressThisProperty)
    console.log('----mailFuzeReturnAddressThisPropertyAddressType', mailFuzeReturnAddressThisPropertyAddressType)

    if (mailFuzeReturnAddressThisProperty.value !== Blank.value && mailFuzeReturnAddressThisPropertyAddressType.value !== Blank.value) {

      const addressesResult = await propertyService.GetPropertyAddresses(mailFuzeReturnAddressThisProperty.value)
      console.log('addressesResult', addressesResult)
      if (addressesResult && addressesResult.length === 1) {
        const filteredAddressTypes = propertyAddressTypeSelectOptions.filter(option => addressesResult[0].addresses.find(address => address.addressTypeID === option.value || option.value === Blank.value) !== undefined)
        console.log('filteredAddressTypes', filteredAddressTypes)

        const currentAddressType = filteredAddressTypes.find(option => option.value === mailFuzeReturnAddressThisPropertyAddressType.value)
        if (currentAddressType && currentAddressType.value !== Blank.value) {
          console.log('currentAddressType', currentAddressType)
          const selectedAddress = addressesResult[0].addresses.find(address => address.addressTypeID === currentAddressType.value)
          console.log('set address to ', selectedAddress)
          console.log('short name', addressesResult[0].shortName)

          dispatches.push({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressThisPropertyAddress', value: { propertyName: addressesResult[0].name, address: selectedAddress }})
        }

        dispatches.push({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressThisPropertyInfo', value: addressesResult[0]})
        dispatches.push({ type: 'SET_OPTIONS', name: 'mailFuzeReturnAddressThisPropertyAddressType', value: filteredAddressTypes })
      }
    }
    else {
      dispatches.push({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressThisPropertyAddress', value: undefined})
      dispatches.push({ type: 'SET_OPTIONS', name: 'mailFuzeReturnAddressThisPropertyAddressType', value: propertyAddressTypeSelectOptions })
    }


    dispatches.push({ type: 'SET', name: 'isLoading', value: false })

    //ConstantCodeMailFuzeMailingFeePropertyMethod,


    console.log('dispatches', dispatches)

    // //setLoadingConstants(false)
    return dispatches    
  }

  const loadConstants = async () => {
    return await settings.loadSettingsConstants(settingsContext)
  }

  const loadAdditionalInfo = async () => {
    const dispatchNotifications = []

    const [managementCompanyProperty, managementCompanyVendor] = await Promise.all([
        propertyService.GetPropertyManagementCompanyProperty(),
        vendorService.GetPropertyManagementCompanyVendor(),
    ])

    dispatchNotifications.push({ type: 'SET_VALUE', name: 'mailFuzeSubscriptionFeePropertyMethodPostfix', value: managementCompanyProperty.ShortName })
    dispatchNotifications.push({ type: 'SET_VALUE', name: 'mailFuzeMailingFeePropertyMethodPostfix', value: managementCompanyProperty.ShortName })
    dispatchNotifications.push({ type: 'SET_VALUE', name: 'mailFuzeOwnerBillVendorMethodPostfix', value: managementCompanyVendor.VendorName })

    return dispatchNotifications;
  }

  const onSettingsChange = (newSettings) => {
    console.log('Settings Changed: ', newSettings)
    //setMailingOptions(newMailingOptions)
  }

  const populateState = async () => {
    const dispatchNotifications = []

    const role = await authService.getRole()
    // setState({
    //   isLoading: false,
    //   isClientAdmin: (role && (role === Roles.Administrator || role.includes(Roles.Administrator))),
    //   isSystemAdmin: (role && (role === Roles.SystemAdministrator || role.includes(Roles.SystemAdministrator))),
    //   //activeTab: 1
    // })

    console.log(role)

    dispatchNotifications.push({ type: 'SET', name: 'isClientAdmin', value: (role && (role === Roles.Administrator || role.includes(Roles.Administrator))) })
    dispatchNotifications.push({ type: 'SET', name: 'isSystemAdmin', value: (role && (role === Roles.SystemAdministrator || role.includes(Roles.SystemAdministrator))) })
    
    return dispatchNotifications;
  }

  // useEffect(() => {
  //   settings.notifyChangeWhenReady(onUpdateContext, settingsContext)
  // }, [settingsContext])

  // const onUpdateContext = (newState) => {
  //   settingsContext.contextReducer(newState)
  // }

  // const onSubmit = (data, e) => {
  //   console.log(data)
  //   console.log(e)

  //   e.preventDefault();
  //   // dispatch({ type: 'SET', name: 'errors', value: [] })
  //   // dispatch({ type: 'SET', name: 'data', value: data })
  //   // dispatch({ type: 'SET', name: 'isSubmitted', value: true })

  //   // nextStep();
  // }

  // const onError = (stepErrors) => {
  //   //e.preventDefault()
  //   //e.stopPropagation();

  //   //console.log(stepErrors, e)
  //   console.log('stepErrors', stepErrors)

  //   const firstError = Object.keys(stepErrors).reduce((field, a) => {
  //     return !!stepErrors[field] ? field : a;
  //   }, null);
  
  //   // if (firstError) {
  //   //   console.log('set focus call', firstError)
  //   //   //firstError.ref.focus()
  //   //   //setFocus(firstError.ref);
  //   // }

  //   toast.error('Please correct all errors.', {
  //     position: "bottom-right",
  //     autoClose: 4000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: false
  //   })

  //   return false
  // }

  const submitAll = async (e) => {
    console.log('Doing Submit all!', e)
    //formRef.current.props.onSubmit(e)
      e.target.blur();
      let error = undefined
      const validationErrors = {}
      await schema.validate(settingsContext, { abortEarly: false }).catch(e => {
        
        console.log(e)
        e.inner.forEach((error) => {
          if (error.path !== undefined) {
            let fieldName = error.path.split('.')[0]
            validationErrors[fieldName] = error.errors[0];
          }
        });

        // error = {
        //   name: e.name,
        //   value: e.value,
        //   type: e.type,
        //  message: e.message,
        //  field: e.path,
        //  params: e.params
        // };
       });
  //console.log(error)
  console.log(validationErrors)

  if (Object.entries(validationErrors).length !== 0) {
    let index = 0
    let found = false
    //let fieldName = error.field.split('.')[0]

    toast.error('Please correct all errors.', {
      position: "bottom-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })

    Object.keys(validationErrors).forEach((error) => {
      console.log('error', error)
      toast.warn(validationErrors[error], {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false
      })
    });

    const fieldName = Object.keys(validationErrors)[0]
    console.log('looking for field:', fieldName)
    while (index < tabs.length && !found) {
      const matchingKey = Object.keys(tabs[index].fields).find(key => key === fieldName)
      if (matchingKey) {
        found = true
        console.log('Mathing key found at index!', index)
        console.log('Culprit from tabs', tabs[index].fields[fieldName]);
        console.log('Culprit from state', settingsContext[fieldName]);
      }

      if (!found) index++
    }
  
    if (!settingsContext.isSubmitted) {
      setSettingsContext({ type: 'SET', name: 'isSubmitted', value: true })
    }
    
    if (settingsContext.currentTabIndex !== index) {
      setSettingsContext({ type: 'SET', name: 'currentTabIndex', value: index })
    }
  } else {
    settings.saveSettingsConstants(settingsContext, 'Settings saved!')
  }

  //tabs.length

      //const validationResult = await state.schema.validate(state);
      //console.log(validationResult)
      //formRef.current.props.onSubmit(e)
      //settings.saveSettingsConstants(state, dispatch, 'Other settings saved!')
  }



  

  // const setActiveTab = (tabIndex) => {
  //   setActiveElement(activeTab, tabsRef[tabIndex])
  //   setActiveElement(activeTabPane, tabPanesRef[tabIndex])
  // }

  // const setActiveElement = (previousRef, activeRef) => {
  //   toggleActive(previousRef.current.current)
  //   toggleActive(activeRef.current)
  //   previousRef.current = activeRef
  // }

  // const toggleActive = (tabRef) => {
  //   tabRef.classList.toggle("active")
  //   if (tabRef.classList.contains('nav-item')) {
  //     tabRef.getElementsByTagName('a')[0].classList.toggle("active")
  //   }
  // }

  const tabs = useMemo(() => [
    {
      label: 'General',
      fields: GeneralSettingsState,
      child: <GeneralSettings />
    },
    {
      label: 'Subscription Billing',
      fields: SubscriptionBillingSettingsState,
      child: <SubscriptionBillingSettings />
    },
    {
      label: 'Mailing Billing',
      fields: MailingBillingSettingsState,
      child: <MailingBillingSettings />
    },
    {
      label: 'Property Chargebacks',
      fields: PropertyChargebackSettingsState,
      child: <PropertyChargebackSettings />
    },
    {
      label: 'Tenant Chargebacks',
      fields: TenantChargebackSettingsState,
      child: <TenantChargebackSettings />
      //child: <></>
    },
    {
      label: 'Return Address',
      fields: ReturnAddressSettingsState,
      child: <ReturnAddressSettings />
    },
    {
      label: 'Cost Codes',
      fields: CostCodeSettingsState,
      child: <CostCodeSettings />
    },
    {
      label: 'Refresh Settings',
      fields: {},
      child: <FlushSettings showResetAll={settingsContext.isSystemAdmin} />
    }
  ], [settingsContext.isSystemAdmin])

  // const tabsRef = tabs.map(x => useRef(null));
  // const tabPanesRef = tabs.map(x => useRef(null));
  // const activeTab = useRef(tabsRef[0])
  // const activeTabPane = useRef(tabPanesRef[0])

  //const [store, setStore] = React.useState({ test1: "", test2: "", test3: "" });
  //const [currentTabIndex, setCurrentTabIndex] = React.useState(0);

  const setCurrentTabIndex = (index) => {
    setSettingsContext({ type: 'SET', name: 'currentTabIndex', value: index })
  }

  // const onSubmit = (data) => {
  //   setStore({
  //     ...store,
  //     ...data
  //   });
  // };

  return (
    <div>
      <AdminMenu tab="settings" />
      <div className="m-3">
        <h2 className="text-primary">Settings</h2>
        <hr />
        <SettingsLayout context={settingsContext} contextReducer={setSettingsContext}>
          {/* <ul className="nav nav-tabs">
            {tabs.map((tab, index) => (
              <li className={`nav-item ${index === 0 ? 'active' : ''}`} ref={tabsRef[index]} key={index}>
                <a className={`${index === 0 ? 'active' : ''} nav-link`} onClick={() => setActiveTab(index)}>{tab}</a>
              </li>
            ))}
          </ul> */}
          <Nav tabs>
            {tabs.map((tab, index) => (
              <NavItem key={index}>
                <NavLink
                  className={`${index === settingsContext.currentTabIndex ? 'active' : ''}`}
                  onClick={() => { setCurrentTabIndex(index); }}
                >
                  {tab.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          {!settingsContext.isLoading && (
            <React.Fragment>
              {/* <div className="tab-content"> */}
              <TabContent activeTab={settingsContext.currentTabIndex}>
                {tabs.map((tab, index) => (
                  <TabPanel index={index} key={index}>
                    {tab.child}
                  </TabPanel>
                ))}

                {/* <TabPanel activeIndex={currentTabIndex} index="2">
                  <CorrespondenceSettings onChange={onSettingsChange} />
                </TabPanel>

                {(settingsContext.isSystemAdmin || settingsContext.isClientAdmin) && (
                  <TabPanel activeIndex={currentTabIndex} index="3">
                    <FlushSettings showResetAll={settingsContext.isSystemAdmin} onChange={onSettingsChange} />
                  </TabPanel>
                )} */}
              {/* </div> */}
              </TabContent>
              {/* <Modal size='xl' isOpen={settingsContext.settleSettingsOpen} toggle={() => setSettingsContext({ type: 'SET', name: 'settleSettingsOpen', value: false })}>
                <ModalHeader>
                  Fight!!
                </ModalHeader>
                <ModalBody>
                  <MailingBillingSettings />
                  <hr />
                  <PropertyChargebackSettings />
                </ModalBody>
              </Modal> */}
                <Row>
                  <Col md={12} lg={11} xl={10} className="d-flex justify-content-end mb-3 mt-3">
                    <Button color="primary" onClick={submitAll}>Save Settings</Button>
                  </Col>
                </Row>
            </React.Fragment>
          )}
          <hr />
        </SettingsLayout>
      </div>
    </div>
  )
}

export default Settings
