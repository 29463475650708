import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import { useForm, useFormState } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import Setting from './Setting'
import { useSettings } from './SettingsReducer'
import useSettingsContext from './SettingsContext'
import { doRegisterInput, doRegisterInputCheckbox, doRegisterInputRadio, doRegisterSelect } from './FormHookRegisters' 
import { Blank } from '../dropdowns/DefaultSelectOptions'
import { toast } from 'react-toastify'
import { ConstantCodeMailFuzeCreateBillMailingFee,
  ConstantCodeMailFuzeMailingFeePropertyMethod,
  ConstantCodeMailFuzeMailingFeeThisPropertyID,
  ConstantCodeMailingGLAccountIDToBillPropertyManagementCompany } from '../../services/SettingsService'
import { MailFuzeMailingFeeThisPropertyIDSelect, MailingGLAccountIDToBillPropertyManagementCompanySelect } from './MailingBillingDowns'

export const MailingBillingSettingsState = {
  mailFuzeCreateBillMailingFee: {
    constant: ConstantCodeMailFuzeCreateBillMailingFee,
    code: undefined,
    type: 'Boolean',
    value: false
  },
  mailingGLAccountIDToBillPropertyManagementCompany: {
    constant: ConstantCodeMailingGLAccountIDToBillPropertyManagementCompany,
    code: undefined,
    type: 'Integer',
    value: Blank.value
  },
  mailFuzeMailingFeePropertyMethod: {
    constant: ConstantCodeMailFuzeMailingFeePropertyMethod,
    code: undefined,
    type: 'Integer',
    value: Blank.value
  },
  mailFuzeMailingFeePropertyMethodPostfix: {
    type: 'String',
    value: undefined
  },
  mailFuzeMailingFeeThisPropertyID: {
    constant: ConstantCodeMailFuzeMailingFeeThisPropertyID,
    code: undefined,
    type: 'Integer',
    value: Blank.value
  },
}

export const MailingBillingSettingsSchema = { //yup.object().shape({
  mailFuzeCreateBillMailingFee: yup.object({
    value: yup.boolean().required()
  }),
  mailingGLAccountIDToBillPropertyManagementCompany: yup.object()
    .when('mailFuzeCreateBillMailingFee', {
      is: (mailFuzeCreateBillMailingFee) => { 
        return mailFuzeCreateBillMailingFee.value === true
      },
      then: yup.object({
        value: yup.number().integer().moreThan(0, 'Please select a GL Account.')
      }),
      otherwise: yup.object({
        value: yup.mixed().notRequired()
      })
    }),
  mailFuzeMailingFeePropertyMethod: yup.object()
    .when(['mailFuzeChargeBackOwners', 'mailFuzeCreateBillMailingFee'], {
      is: (mailFuzeChargeBackOwners, mailFuzeCreateBillMailingFee) => {
        return mailFuzeChargeBackOwners.value === true && mailFuzeCreateBillMailingFee.value === true
      },
      then: yup.object({
        value: yup.number().integer().oneOf([0, 1], 'You cannot select "Property/Unit of Each Recipient", because you have already opted to charge back owners/properties for each mailing in "Property Chargebacks".').required()
      }),
      otherwise: yup.object({
        value: yup.number().integer().oneOf([0, 1, 2]).required()
      })
    }),
  mailFuzeMailingFeeThisPropertyID: yup.object()
    .when(['mailFuzeCreateBillMailingFee', 'mailFuzeMailingFeePropertyMethod'], {
      is: (mailFuzeCreateBillMailingFee, mailFuzeMailingFeePropertyMethod) => { 
        return mailFuzeCreateBillMailingFee.value === true && mailFuzeMailingFeePropertyMethod.value === 1
      },
      then: yup.object({
        value: yup.number().integer().moreThan(0, 'Please select a property.')
      }),
      otherwise: yup.object({
        value: yup.mixed().notRequired()
      })
    })
  } //, ['mailFuzeMailingFeePropertyMethod', 'mailFuzeChargeBackOwners']) //.required();

  const InternalSchema = yup.object({
    mailFuzeChargeBackOwners: yup.object().shape({
      value: yup.boolean().required()
    }),
    ...MailingBillingSettingsSchema
  })

const MailingBillingSettings = () => {

  const formRef = useRef(null)
  const {context: state, contextReducer: dispatchSecond} = useSettingsContext()

  console.log('Loading Mailing Billing Tab with context', state)

  // const newObj=Object.create({});
  // Object.keys(MailingBillingSettingsState).forEach((key) => {
  //   newObj[key] = state[key]
  // })
  // const defVals = {
  //   ...newObj,
  //   mailFuzeChargeBackOwners: state.mailFuzeChargeBackOwners
  // }

  // const InternalSchema = useMemo(() => {
  //   return yup.object({
  //     mailFuzeChargeBackOwners: yup.object().shape({
  //       value: yup.boolean().required()
  //     }),
  //     ...MailingBillingSettingsSchema
  //   })
  // }, [])
  
  const {
    //setFocus,
    register,
    setValue,
    //getFieldState,
    handleSubmit,
    trigger,
    //watch,
    //control
    reset,
    formState: { errors, isSubmitted }
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      ...state
    },
    // defaultValues: useMemo(() => {
    //   return {...state}
    // }, [state]),
    //useMemo(() => {
      // return {
      //    mailFuzeChargeBackOwners: {
      //     constant: ConstantCodeMailFuzeChargeBackOwners,
      //     code: undefined,
      //     type: 'Boolean',
      //     value: false
      //   },
      //    mailFuzeCreateBillMailingFee: state.mailFuzeCreateBillMailingFee,
      //    mailingGLAccountIDToBillPropertyManagementCompany: state.mailingGLAccountIDToBillPropertyManagementCompany,
      //    mailFuzeMailingFeePropertyMethod: state.mailFuzeMailingFeePropertyMethod,
      //    mailFuzeMailingFeeThisPropertyID: state.mailFuzeMailingFeeThisPropertyID,
        //...state
        //...defVals,
    //  };
    //}, [state]),
    resolver: yupResolver(InternalSchema),
    mode: "onChange",
    context: { state }
  });

  const dispatch = (action) => {
    action.setValue = setValue
     
    dispatchSecond(action)

    if (state.isSubmitted || (action.name === 'isSubmitted' && action.value === true) ||
    (action.name === 'mailFuzeMailingFeePropertyMethod' && action.value === 2 && state.mailFuzeChargeBackOwners.value === true)) {
      trigger()
    }
  }

  useEffect(() => {
    reset({
      ...state
    });
    if (state.isSubmitted){
      trigger();
    }
      
    }, [state]);

  //   useEffect(() => {
  //     setValue('mailFuzeChargeBackOwners', state.mailFuzeChargeBackOwners)
  //     setValue('mailFuzeChargeBackOwners', state.mailFuzeCreateBillMailingFee)
  //     setValue('mailFuzeChargeBackOwners', state.mailingGLAccountIDToBillPropertyManagementCompany)
  //     setValue('mailFuzeChargeBackOwners', state.mailFuzeMailingFeePropertyMethod)
  //     setValue('mailFuzeChargeBackOwners', state.mailFuzeMailingFeeThisPropertyID)
  //   // reset({
  //   //   ...state
  //   //   //...defVals,
  //   // });
  // }, [state.mailFuzeChargeBackOwners.value,
  //      state.mailFuzeCreateBillMailingFee.value,
  //      state.mailingGLAccountIDToBillPropertyManagementCompany.value,
  //      state.mailFuzeMailingFeePropertyMethod.value,
  //      state.mailFuzeMailingFeeThisPropertyID.value
  //    ])

  // useEffect(() => {
  //   reset({
  //     mailFuzeChargeBackOwners: state.mailFuzeChargeBackOwners,
  //     mailFuzeCreateBillMailingFee: state.mailFuzeCreateBillMailingFee,
  //     mailingGLAccountIDToBillPropertyManagementCompany: state.mailingGLAccountIDToBillPropertyManagementCompany,
  //     mailFuzeMailingFeePropertyMethod: state.mailFuzeMailingFeePropertyMethod,
  //     mailFuzeMailingFeeThisPropertyID: state.mailFuzeMailingFeeThisPropertyID,
  //     //...state
  //     //...defVals,
  //   });
  // }, [state.mailFuzeChargeBackOwners,
  //   state.mailFuzeCreateBillMailingFee,
  //   state.mailingGLAccountIDToBillPropertyManagementCompany,
  //   state.mailFuzeMailingFeePropertyMethod,
  //   state.mailFuzeMailingFeeThisPropertyID
  // ]);

  useEffect(() => {
    console.log('useEffect triggered by state.isSubmitted', state.isSubmitted, 'isSubmitted', isSubmitted)

    if (state.isSubmitted === false && isSubmitted) {
      dispatch({ type: 'SET', name: 'isSubmitted', value: isSubmitted })
    }
  }, [state.isSubmitted, isSubmitted])

  //trigger will change on the first load. It's only way to run the validation on the default values.
  useEffect(() => {
    if (state.isSubmitted) {
      trigger()
    }
  }, [trigger, state.isSubmitted, state.currentTabIndex, state.mailFuzeChargeBackOwners.value])

  const save = async (e) => {
    e.target.blur();
    formRef.current.props.onSubmit(e)
    //settings.saveSettingsConstants(state, dispatch, 'Other settings saved!')
  }

  // const onSubmit = (data, e) => {
  //   console.log(data)
  //   console.log(e)

  //   //e.preventDefault();
  //   // dispatch({ type: 'SET', name: 'errors', value: [] })
  //   // dispatch({ type: 'SET', name: 'data', value: data })
  //   // dispatch({ type: 'SET', name: 'isSubmitted', value: true })

  //   // nextStep();
  // }

  // const onError = (stepErrors, e) => {
  //   //e.preventDefault()
  //   //e.stopPropagation();

  //   //console.log(stepErrors, e)
  //   console.log('stepErrors', stepErrors)

  //   const firstError = Object.keys(stepErrors).reduce((field, a) => {
  //     return !!stepErrors[field] ? field : a;
  //   }, null);
  
  //   // if (firstError) {
  //   //   console.log('set focus call', firstError)
  //   //   //firstError.ref.focus()
  //   //   //setFocus(firstError.ref);
  //   // }

  //   toast.error('Please correct all errors.', {
  //     position: "bottom-right",
  //     autoClose: 4000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: false
  //   })
  // }


  return (
    <div>
      <React.Fragment>
        <Form ref={formRef} 
          onSubmit={handleSubmit(state.onSubmit, state.onError)}
        >
          <Row>
            <Col md={11} lg={10} xl={9}>
              <Row className="mt-3">
                <Col xs={12}>
                  <FormGroup
                    check
                    inline
                  >
                    <Input
                      {...doRegisterInputCheckbox(register, dispatch, 'mailFuzeCreateBillMailingFee', state.isSubmitted, errors, state)}
                      style={{transform: 'scale(1.3)'}}
                    />
                    <Label for='mailFuzeCreateBillMailingFee'>I want MailFuze to automatically create bills in my Rent Manager database for mailings</Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row className={`mt-2 ps-5 ${state.mailFuzeCreateBillMailingFee.value === false ? 'text-muted' : ''}`}>
                <Col xs={12}>
                  <Row>
                    <Col xs={12}>
                      <Setting label='GL Account to Use on Bill for Mailings:' description=''>
                        <MailingGLAccountIDToBillPropertyManagementCompanySelect
                          options={state.mailingGLAccountIDToBillPropertyManagementCompany.options}
                          {...doRegisterSelect(register, dispatch, 'mailingGLAccountIDToBillPropertyManagementCompany', state.isSubmitted, errors, state, '', true)}
                          isDisabled={state.mailFuzeCreateBillMailingFee.value === false}
                        />
                      </Setting>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                        <FormGroup tag="fieldset" className="border p-2 mt-3">
                          <legend className="float-none w-auto ps-1 pe-1 fs-6 text-muted" style={{fontWeight: '500'}}>Property To Use On Bills</legend>

                          <div className='ps-3 pe-3 d-flex flex-column'>
                            <FormGroup check>
                              <Input
                                {...doRegisterInputRadio(0, register, dispatch, 'mailFuzeMailingFeePropertyMethod', state.isSubmitted, errors, state)}
                                disabled={state.mailFuzeCreateBillMailingFee.value === false || state.mailFuzeMailingFeePropertyMethodPostfix.value === "Not Found"}
                                style={{transform: 'scale(1.3)'}}
                              />
                              {' '}
                              <Label check for='mailFuzeMailingFeePropertyMethod0'>
                                Management Company Property in Rent Manager System Settings <span style={{color: (state.mailFuzeMailingFeePropertyMethodPostfix.value === "Not Found" ? 'var(--bs-danger)' : 'inherit')}}>{`(${state.mailFuzeMailingFeePropertyMethodPostfix.value})`}</span>
                              </Label>
                            </FormGroup>

                            <div className='d-flex flex-row align-items-baseline pb-1'>
                              <div>
                                <FormGroup check>
                                  <Input
                                    {...doRegisterInputRadio(1, register, dispatch, 'mailFuzeMailingFeePropertyMethod', state.isSubmitted, errors, state)}
                                    disabled={state.mailFuzeCreateBillMailingFee.value === false}
                                    style={{transform: 'scale(1.3)'}}
                                  />
                                  {' '}
                                  <Label check for='mailFuzeMailingFeePropertyMethod1'>
                                    This Property:
                                  </Label>
                                </FormGroup>
                              </div>
                              <div className='ms-2 d-flex flex-grow-1 flex-column'>
                                <MailFuzeMailingFeeThisPropertyIDSelect
                                  options={state.mailFuzeMailingFeeThisPropertyID.options}
                                  {...doRegisterSelect(register, dispatch, 'mailFuzeMailingFeeThisPropertyID', state.isSubmitted, errors, state, '', true)}
                                  isDisabled={state.mailFuzeCreateBillMailingFee.value === false || state.mailFuzeMailingFeePropertyMethod.value === 0 || state.mailFuzeMailingFeePropertyMethod.value === 2}
                                />
                              </div>
                            </div>

                            <FormGroup check>
                              <Input
                                {...doRegisterInputRadio("2", register, dispatch, 'mailFuzeMailingFeePropertyMethod', state.isSubmitted, errors, state)}
                                disabled={state.mailFuzeCreateBillMailingFee.value === false}
                                style={{transform: 'scale(1.3)'}}
                              />
                              {' '}
                              <Label check for='mailFuzeMailingFeePropertyMethod2'>
                                Property/Unit of Each Recipient
                              </Label>
                              {errors?.mailFuzeMailingFeePropertyMethod?.value && (
                                <FormFeedback className='ms-1'>{errors?.mailFuzeMailingFeePropertyMethod?.value?.message}</FormFeedback>
                              )}
                            </FormGroup>
                          </div>
                        </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row>
            <Col md={11} lg={8} xl={7} className="d-flex justify-content-end mb-3 mt-3">
              <Button color="dark" onClick={save}>Save</Button>
            </Col>
          </Row> */}

          <div style={{visibility: 'hidden', height: '0px'}}>
            <Input {...doRegisterInputCheckbox(register, dispatch, 'mailFuzeChargeBackOwners', state.isSubmitted, errors, state, '-bk')} />
          </div>
        </Form>
      </React.Fragment>
    </div>
  )
}

export default MailingBillingSettings