import { useFetchWrapper } from '../services/fetch-wrapper'

export { useProspectContactTypeService };

const useProspectContactTypeService = () => {

  const fetchWrapper = useFetchWrapper()

  const GetProspectContactTypes = async () => {
    const params = new URLSearchParams({ addAll: true}).toString();
    return fetchWrapper.get(`contacttype/Prospect?${params}`)
  }

  return { GetProspectContactTypes }
}