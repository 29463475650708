import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import React, { useEffect, useReducer, useRef } from 'react'
import { Alert, Button, Col, Input, Label, Offcanvas, OffcanvasBody, Row } from 'reactstrap';
import { DateTimeFormatter } from '../../services/DateFormatter';
import { useJobService } from '../../services/JobService';
//import { MailStatusDetailsCellMenuRenderer } from './MailStatusDetailsCellMenu';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Download, X } from 'react-bootstrap-icons';

const MailStatusDetails = ({mailingData, onOpen, onClose}) => {
  const initialState = {
    showDetails: false,
    trackingData: [],
    selectedRow: null
  }

  const stateReducer = (state, action) => {
    switch (action.type) {
      case 'SET': {
        return {
          ...state,
          [action.name]: action.value
        }
      }
    }

    return state
  }

  const [state, dispatch] = useReducer(stateReducer, initialState)
  const downLoadIconRef = useRef(React.createRef());
  const jobService = useJobService()
  const MySwal = withReactContent(Swal)

  const PlainSwal = MySwal.mixin({
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    }
  })

  const toggleDetails = () => {
    setShowDetails(!state.showDetails)
  }

  const setShowDetails = (show) => {
    dispatch({ type: 'SET', name: 'showDetails', value: show })
    if (show) {
      onOpen()
    } else {
      onClose()
    }
  }

  useEffect(() => {
    if (mailingData && mailingData.uniqueKey) {
      console.log('mailingData in MailStatusDetails', mailingData)
      setShowDetails(true)
      dispatch({ type: 'SET', name: 'trackingData', value: [] })
      loadJobTrackingData(mailingData.nodeData.id)
    } else {
      setShowDetails(false)
    }
  }, [mailingData])

  const loadJobTrackingData = async (jobId) => {
    console.log('jobId: ', jobId)
    dispatch({ type: 'SET', name: 'selectedRow', value: null })
    var data = await jobService.GetJobTrackingData(jobId)
    console.log('loadJobTrackingData', data)
    
    dispatch({ type: 'SET', name: 'trackingData', value: data })
  }

  const dateTimeFormatter = (params) => {
    try
    {
      return DateTimeFormatter.format(new Date(params.value))
    }
    catch
    {
      return ''
    }
  }

  const showDocumentProof = async () => {
    const jobId = mailingData.nodeData.id

    PlainSwal.fire({
      title: 'Preparing Document Proof',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        PlainSwal.showLoading()
      },
    })
  
    const result = await jobService.GetJobDocumentProof(jobId)

    PlainSwal.close()
  
    console.log(result)
  
    const dl = PlainSwal.fire({
      title: 'Your Document Proof is Ready',
      html: downLoadIconRef.current.outerHTML.replace('href="#"', `href="${result.statusUrl}" id="downloadBtn"`),
      showCloseButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      buttonsStyling: false,
      confirmButtonText: 'Close',
      customClass: {
        confirmButton: 'btn btn-dark'
      },
      didOpen: function(ele) {
        ele.querySelector('#downloadBtn').addEventListener('click', function(){ dl.close(); }, false);
      }
    })
  }

  const renderDetailItem = (label, value, isLast) => {
    return (
      <React.Fragment>
        <Row className={isLast ? "" : "mb-1"}>
          <Label lg={12} xl={4} className="col-form-label-sm" >{label}</Label>
          <Col lg={12} xl={8}>
            <Input type="text" className="form-control-sm" readOnly={true} value={value} />
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  return (
    <Offcanvas
      scrollable
      direction="bottom"
      backdrop={true}
      isOpen={state.showDetails}
      toggle={toggleDetails}
      returnFocusAfterClose={false}
      style={{minHeight: '30vh', display: 'flex', height: 'auto', width: '100%'}}
    >
      {/* <OffcanvasHeader toggle={toggleDetails}>
        
      </OffcanvasHeader> */}
      <OffcanvasBody style={{minHeight: '100%', display: 'flex', width: '100%' }}>
        <div className="d-none">
          <div ref={downLoadIconRef}>
            <Button color="primary" className="btn-lg" href="#"><Download /> Download Preview</Button>
          </div>
        </div>
        <Row className="flex-fill">
          {/* <Col xs={{ size: 12, order: 2}} lg={{ size: 4, order: 1 }}>
            {mailingData && (
              <div className="d-flex flex-column justify-content-between flex-grow-1" style={{height: '100%', maxHeight: '300px'}}>
                {renderDetailItem('Product', mailingData.product)}
                {renderDetailItem('Layout', mailingData.layout)}
                {renderDetailItem('Envelope', mailingData.envelope)}
                {renderDetailItem('Color', mailingData.color)}
                {renderDetailItem('Type', mailingData.paperType)}
                {renderDetailItem('Mail Class', mailingData.mailClass)}
                {renderDetailItem('Printing', mailingData.printOption)}
                {renderDetailItem('Production Time', mailingData.productionTime, true)}
              </div>
            )}
          </Col>
          <Col xs={{ size: 12, order: 1}} lg={{ size: 8, order: 2 }}> */}
          <Col xs={{ size: 12 }}>
            <Row>
              <Col>
                <div className="d-flex flex-row">
                  <Alert color="light" className='d-flex align-items-center flex-grow-1'>
                    <div className='flex-grow-1'>
                      <span className='fw-bold'>
                        USPS Tracking Information:
                      </span>
                      &nbsp;{state.selectedRow && (<span>{state.selectedRow.barCode}</span>)}
                    </div>
                    <div className='flex-grow-1 align-items-center'>
                      <span className='fw-bold'>
                        Address:
                      </span>
                      &nbsp;{state.selectedRow && (<span>{state.selectedRow.address}</span>)}
                    </div>
                    <a target='_blank' className={`btn btn-dark ${(state.selectedRow && state.selectedRow.barCode) ? '' : 'disabled'}`} rel="noopener noreferrer" href={`https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${state.selectedRow && state.selectedRow.barCode}`}>USPS Website</a>
                  </Alert>
                  <Button color="dark-outline" className="btn-close-off-canvas align-self-start" style={{float: 'right', marginRight: '-15px', marginTop: '-20px'}} onClick={toggleDetails}><X size={25} /></Button>
                </div>
              </Col>
            </Row>
            <Row>
              {state.trackingData && (
                <div className="ag-theme-alpine" style={{minHeight: '150px', width: '100%', maxHeight: '75vh' }}>
                  <AgGridReact
                    rowData={state.trackingData}
                    domLayout="autoHeight"
                    rowSelection="none"
                    pagination={false}
                    suppressCellSelection={true}
                    className="pb-1"
                    defaultColDef={{
                      sortable: false,
                    }}
                    // frameworkComponents={{
                    //   btnCellRenderer: MailStatusDetailsCellMenuRenderer
                    // }}
                    // getRowNodeId={(data) => {
                    //   return data.barCode;
                    // }}
                    onFirstDataRendered={(event) => {
                      const rowNode = event.api.getDisplayedRowAtIndex(0);
                      if (rowNode)
                      {
                        console.log(rowNode)
                        rowNode.setSelected(true)
                      }
                    }}
                    onRowSelected = {(event) => {
                      if (event.node.isSelected()) {
                        dispatch({ type: 'SET', name: 'selectedRow', value: event.node.data })
                      }
                      else {
                        dispatch({ type: 'SET', name: 'selectedRow', value: null })
                      }
                    }}
                    onGridReady={(event) => { 
                      event.api.sizeColumnsToFit()
                      event.api.deselectAll()
                    }}>
                      {/* <AgGridColumn field="barCode" headerName="Tracking #"></AgGridColumn> */}
                      {/* <AgGridColumn field="address" headerName="Recipient/Address"></AgGridColumn> */}
                      <AgGridColumn field="status" headerName="USPS Status" suppressSizeToFit={false} minWidth={300}></AgGridColumn>
                      <AgGridColumn
                        field="dateTime"
                        headerName="USPS Status Date/Time"
                        suppressSizeToFit={true}
                        maxWidth={200}
                        valueFormatter={dateTimeFormatter}>
                      </AgGridColumn>
                      <AgGridColumn field="statusLocation" headerName="Location"></AgGridColumn>
                      {/* <AgGridColumn
                        headerName=""
                        filter={false}
                        resizable={false}
                        suppressSizeToFit={true}
                        maxWidth={80}
                        cellRenderer="btnCellRenderer"
                        cellRendererParams={{
                          onShowDocumentProof(node) {
                            showDocumentProof()
                            //console.log(node)
                          },
                        }}
                      >
                      </AgGridColumn> */}
                  </AgGridReact>
                </div>
              )}
            </Row>
          </Col>
        </Row>
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default MailStatusDetails