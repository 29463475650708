import { useFetchWrapper } from '../services/fetch-wrapper'

export { useSendMailHistoryService };

const useSendMailHistoryService = () => {
  const fetchWrapper = useFetchWrapper()

  const GetHistory = async (params) => {
    return fetchWrapper.post(`sendmailhistory/history`, params)
  }

  return { GetHistory }
}