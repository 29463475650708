import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Col, Label, Row } from 'reactstrap'
import { ArrowsAngleExpand, Trash, Trash2, Trash2Fill } from 'react-bootstrap-icons'
import { dateTimeFormat } from '../../services/DateFormatter'
import { AddressWizardPageOption, useSendMailAddressWizardService } from '../../services/SendMailAddressWizardService'

const Thumbnail = ({thumbnail, templateId}) => {
  const [hovered, setHovered] = useState(false)
  const [highRes, setHighres] = useState(undefined)

  const addressWizardService = useSendMailAddressWizardService()

  useEffect(() => {
    if (!hovered)
      return;

    if (!highRes) {
      return getHighResTemplate()
    }
  }, [hovered, highRes])

  const getHighResTemplate = async () => {
    const blob = await addressWizardService.GetTemplateHighRes(templateId)
    const blobUrl = URL.createObjectURL(blob)
    setHighres(blobUrl)
  }

  return (
    <img
      src={`${(hovered && highRes) ? highRes : `data:image/png;base64, ${thumbnail}`}`}
      className='border border-light rounded zoom'
      onMouseOut={() => setHovered(false)}
      onMouseOver={() => setHovered(true)}
    />
  )
}

const TemplateDetailRowRenderer = ({ node, data, onDeleteTemplate, ...rest }) => {

  console.log('rest', rest)
  console.log('data', data)
  console.log('node', node)

  const styles = {
    fullWidthPanel: {
      whiteSpace: 'normal',
      height: '125px',
      width: '100%',
      display: 'flex',
      //border: 2px solid grey;
      //border-style: ridge;
      //box-sizing: border-box;
      //padding: 5px;
      //background-color: darkgray;
    }
  }

  const onDelete = useCallback(() => {
    onDeleteTemplate(node)
  }, [])

  const markerProps = useMemo(() => {
    return [ {
        marker: 'page',
        color: '#2d882d',
        background: 'rgba(45, 136, 45, 0.3)'
      },
      {
        marker: 'address',
        color: '#951d1d',
        background: 'rgba(149, 29, 29, 0.3)'
      }
    ]
  }, [])

  const renderMarkers = (id, markers) => {
    var markerMap = new Map(markers.map(m => [m.marker, m]))

    console.log(markerMap)
    console.log(markerMap.get('page'))

    return markerProps.map((m, i)=> {
      console.log(m.marker, markerMap.has(m.marker))
      console.log(id, i, m.marker)
      if (markerMap.has(m.marker)) {
        return (
          <div className='d-flex flex-column pb-2' key={`tpl_${id}_${m.marker}_${i}`}>
            <div className='fs-6 fw-bold' style={{textTransform: 'capitalize', color: m.color}}>{m.marker}</div>
            {(markerMap.get(m.marker).capturedText !== '') && (
              <div
                ref={(el) => el && el.style.setProperty('border-color', m.color, "important")}
                style={{backgroundColor: m.background, overflow: 'hidden' }}
                className='border rounded'>
                <div style={{height: '30px', overflowY: 'scroll'}} className='p-2 pt-0'>
                  <pre>
                    {markerMap.get(m.marker).capturedText}
                  </pre>
                </div>
              </div>
            )}
          </div>
        )
      }
      else {
        return <Fragment key={`tpl_${id}_${m.marker}_${i}`} />
      }
    })
  }

  return (
    <div style={styles.fullWidthPanel} className="p-2">
      <div className='d-flex' style={{ minWidth: '85ox', maxWidth: '85px'}}>
        <a className='thumbnail'>
          <Thumbnail thumbnail={data.thumbnail} templateId={data.id} />
          {/* <img style={{  }} src={`data:image/png;base64,${data.thumbnail}`} className='border border-light rounded zoom' /> */}
        </a>
      </div>
      <div className='d-flex flex-fill'>
        <div className='d-flex flex-column flex-fill'>
          <div className='fs-5 text-secondary fw-bold ps-2'>
            {data.name}
          </div>
          <div className='d-flex flex-row flex-fill ps-2'>
            <div style={{backgroundColor: 'var(--bs-light)', overflow: 'hidden', flex: '0 0 50%'}} className='border border-primary rounded d-flex'>
              <div className="p-2 pt-0" style={{ maxHeight: '80px', overflowY: 'auto'}}>
                {data.description}
              </div>
            </div>
            <div className='d-flex' style={{flex: '0 0 50%'}}>
              <div className='d-flex flex-column flex-fill ps-2 justify-content-between'>
                <Row className=''>
                  <Col xs={12} lg={7} style={{fontSize: '90%'}}>
                    <span style={{fontWeight: 500}} className='text-secondary'>Letter Split Option: </span>
                    <span className='text-muted fw-bold' style={{fontSize: '85%'}}>
                    {data.pageOption === AddressWizardPageOption.FixedPageNum && data.pageOptionNumPages === 1 && (
                      <>Every page</>
                    )}
                    {data.pageOption === AddressWizardPageOption.FixedPageNum && data.pageOptionNumPages > 1 && (
                      <>Every {data.pageOptionNumPages} pages</>
                    )}
                    {data.pageOption === AddressWizardPageOption.PageMarker && (
                      <>Page 1 Marker</>
                    )}
                    </span>
                  </Col>
                </Row>
                <Row className=''>
                  <Col xs={12} lg={7}>
                    <div className='text-secondary'>
                      {data.createdBy}
                    </div>
                    <div className='text-muted' style={{fontSize: '80%'}}>
                      {dateTimeFormat({value:  data.createdOn})}
                    </div>
                  </Col>
                  <Col xs={12} lg={5}>
                    <div className='d-flex flex-column align-items-end'>
                      <Button color='danger' size='sm' onClick={onDelete}><Trash /></Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateDetailRowRenderer