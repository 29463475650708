import Select, { components } from "react-select";

export const MultiValueContainer = ({ selectProps, children, data, ...props }) => {
  const label = data.label;
  //console.log(label)

  const allSelected = selectProps.value
  let val = ''

  //console.log(allSelected)
  if (Array.isArray(allSelected) && allSelected.length > 1) {
    const index = allSelected.findIndex(selected => selected.label === label);
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ', ';
    if (isLastSelected) {
      val = `${allSelected.length} Selected`
    }
  }
  else {
    val = `${label}`;
  }

  return val
  // return val; (
  //   <components.MultiValueContainer {...props}>
  //     {val}
  //   </components.MultiValueContainer>
  // )
}

export const MultiValueInitialsContainer = ({ selectProps, children, data, ...props }) => {
  const label = data.label;

  const allSelected = selectProps.value
  let val = ''

  //console.log(allSelected)
  if (Array.isArray(allSelected) && allSelected.length > 1) {
    const index = allSelected.findIndex(selected => selected.label === label);
    const isLastSelected = index === allSelected.length - 1;

    if (isLastSelected) {
      const initials = allSelected.map(selected => selected.label.substring(0, 1))
      val = initials.join(", ")
    }
  }
  else {
    if (label && label.length !== 0) {
      val = `${label}`;
    } else {
      val = ''
    }
  }

  return val
}


const ValueContainer = ({ selectProps, children, ...props }) => {
    let [values, input] = children;

    if (Array.isArray(values)) {
      values = selectProps.value.map((x) => x.label).join(', ');
    }

    return (
      <components.ValueContainer {...props}>
        <div style={{
          maxWidth: "80%",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}>
          {values}
         </div>
        {input}
      </components.ValueContainer>
    );
};

export default ValueContainer