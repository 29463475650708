import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Eye, EyeFill, ListCheck } from 'react-bootstrap-icons';
import { Button, Tooltip, UncontrolledTooltip } from 'reactstrap';
import { useTimeout } from 'usehooks-ts'

export const SendMailHistoryGridCellMenuRenderer = ({onShowDetails, onShowResults, node, eGridCell, ...rest}) => {

  console.log('node', node)
  console.log('eGridCell', eGridCell)
  console.log('eGridCell.attributes', eGridCell.attributes)
  console.log('eGridCell.attributes.comp-id', eGridCell.attributes['comp-id'])
  console.log('eGridCell.attributes.comp-id.value', eGridCell.attributes['comp-id'].value)
  //console.log('rest', rest)

  const [ready, setReady] = useState(false);

  // const detailsBtnId = useMemo(() => `tt-details${node.id}`, [node.id])
  // const resultsBtnId = useMemo(() => `tt-results-${node.id}`, [node.id])
  const detailsBtnId = useMemo(() => `tt-details-${node.data.SendMailID}`, [node.data.SendMailID])
  const resultsBtnId = useMemo(() => `tt-results-${node.data.SendMailID}`, [node.data.SendMailID])
  
  useTimeout(() => setReady(true), 500)

    //const [isDetailsTooltipOpen, setIsDetailsTooltipOpen] = useState(false)
  //const [isResultsTooltipOpen, setIsResultsTooltipOpen] = useState(false)
  // const [detailsReady, setDetailsReady] = useState(false);
  // const [resultsReady, setResultsReady] = useState(false);

  //const resultsButtonRef = useRef(null)

  return (
    <>
      <React.Fragment>
        <Button onClick={(e) => onShowDetails(e, node.data)} color="primary" className="btn-sm" id={detailsBtnId}><EyeFill style={{pointerEvents: 'none', marginTop: '-3px'}} /></Button>
        {ready && (<UncontrolledTooltip
          delay={400}
          placement="top"
          target={detailsBtnId}
          trigger="hover"
        >
          Recipient Details
        </UncontrolledTooltip>)}
        {node && node.data.Status === 'Complete' && (
          <>
            <Button onClick={(e) => onShowResults(e, node.data)} color="primary" className='btn-sm ms-1' id={resultsBtnId}><ListCheck style={{pointerEvents: 'none', marginTop: '-3px'}} /></Button>
            {ready && (<UncontrolledTooltip
              delay={400}
              placement="top"
              target={resultsBtnId}
              trigger="hover"
            >
              Results Details
            </UncontrolledTooltip>)}
          </>
        )}
      </React.Fragment>
    </>
  )
}