export {useSendMailGridCommon}

const useSendMailGridCommon = ({addressTypes, currentAddressType, onAddressTypeChanged}) => {
  const addressColumnDefConst = {
    headerName: 'Address',
    headerGroupComponent: "customHeaderGroup",
    headerClass: "ag-center-aligned-header",
    headerGroupComponentParams: {
      addressTypes: addressTypes,
      currentAddressType: currentAddressType,
      onAddressTypeChanged: onAddressTypeChanged
    },
    marryChildren: true,
    children: [
      { 
        headerName: 'Full Address',
        //headerClass: 'ag-header-cell-with-group',
        minWidth: 340,
        columnGroupShow: 'closed',
        valueGetter: (params) => {
          if (!params.node.rowPinned) {
              return params.data.street + ', ' + params.data.city + ', ' + params.data.state + ' ' + params.data.zip
          }
        }
      },
      { field: 'street', columnGroupShow: 'open' },
      { field: 'city', columnGroupShow: 'open' },
      { field: 'state', maxWidth: 100, columnGroupShow: 'open', suppressSizeToFit: true },
      { field: 'zip', maxWidth: 100, columnGroupShow: 'open', suppressSizeToFit: true }
    ],
  }

  const getAddressColumnDef = () => {
    return addressColumnDefConst
  }

  const notAddressColumnDefConst = {
    headerName: 'NotAddressGroup',
    headerGroupComponent: "customHeaderGroup",
    headerClass: "ag-center-aligned-header",
    headerGroupComponentParams: {
      //addressTypes: addressTypes,
      //currentAddressType: currentAddressType,
      //onAddressTypeChanged: onAddressTypeChanged
    },
    marryChildren: false,
    // children: [
    //   { 
    //     headerName: 'Full Address',
    //     headerClass: 'ag-header-cell-with-group',
    //     minWidth: 310,
    //     columnGroupShow: 'closed',
    //     valueGetter: (params) => {
    //       if (!params.node.rowPinned) {
    //           return params.data.street + ', ' + params.data.city + ', ' + params.data.state + ' ' + params.data.zip
    //       }
    //     }
    //   },
    //   { field: 'street', headerClass: 'ag-header-cell-with-group', columnGroupShow: 'open' },
    //   { field: 'city', headerClass: 'ag-header-cell-with-group', columnGroupShow: 'open' },
    //   { field: 'state', maxWidth: 100, headerClass: 'ag-header-cell-with-group', columnGroupShow: 'open', suppressSizeToFit: true },
    //   { field: 'zip', maxWidth: 100, headerClass: 'ag-header-cell-with-group', columnGroupShow: 'open', suppressSizeToFit: true }
    // ],
  }

  const getNotAddressColumnDef = () => {
    return notAddressColumnDefConst
  }

  return { getAddressColumnDef, getNotAddressColumnDef }
}