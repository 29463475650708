import React, { useState, useEffect } from 'react'
import { Gear, ListTask, Person } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { Roles } from '../../services/RoleService'
import AdminMenu from './Menu'
import authService from '../api-authorization/AuthorizeService';
import { useClientService } from '../../services/ClientService';

const Admin = () => {

  let _subscription = null

  const initialState = {
      isLoading: true,
      isAuthenticated: false,
      userName: null,
      role: null,
      isIntegrationPartner: false
  }

  const [state, setState] = useState(initialState)
  const clientService = useClientService()
  
  useEffect(() => {
    _subscription = authService.subscribe(() => populateState());
    populateState();

    // Specify how to clean up after this effect:
    return function cleanup() {
        authService.unsubscribe(_subscription);
    };
  }, []);

  const populateState = async () => {
    const [isAuthenticated, user, role] = await Promise.all([
        authService.isAuthenticated(),
        authService.getUser(),
        authService.getRole()
    ])

    let isIntegrationPartner = false;
    if (isAuthenticated) {
      const myClient = await clientService.GetMyClient()
      isIntegrationPartner = myClient && myClient.IsIntegrationPartner
    }

    setState({
        isLoading: false,
        isAuthenticated,
        userName: user && user.name,
        role,
        isIntegrationPartner
    });
  }

  return (
    <div>
      <AdminMenu />
      <div className="m-3">
        <h2 className="text-primary">Admin Menu</h2>
        <hr />
        <ListGroup>
          <ListGroupItem action tag={Link} to="/admin/settings"><Gear size={35} /> Settings</ListGroupItem>
          {/* { state.isIntegrationPartner && (
            <ListGroupItem action tag={Link} to="/admin/settings"><Gear size={35} /> Settings</ListGroupItem>
          )} */}
          <ListGroupItem action tag={Link} to="/admin/users"><Person size={35} />Users</ListGroupItem>
          { state.role && (state.role === Roles.SystemAdministrator || state.role.includes(Roles.SystemAdministrator)) && (
            <ListGroupItem action tag={Link} to="/admin/send-mail-logs"><ListTask size={35} />Send Mail Logs</ListGroupItem>  
          )}
        </ListGroup>
      </div>
    </div>
  )
}

export default Admin
