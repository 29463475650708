import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import { CustomLoadingOverlay } from '../widget/AGGridCustomOverlay';
import BasicGroupingHeader from '../widget/BasicGroupingHeader';
import AGGridBooleanFilter from '../widget/AGGridBooleanFilter'
import AGGridCheckmarkRenderer from '../widget/AGGridCheckmarkRenderer'
import { EnvelopeCheck, EnvelopeExclamation } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import CostCodeActionInput from '../widget/cost-codes/CostCodeActionInput';
import CostCodeHeader from '../widget/cost-codes/CostCodeGridHeader'
import { applyCostCode } from '../../services/CostCodeService';
import { centeredHeaderComponentParams } from '../widget/CenteredHeaderComponent'
import ReturnAddressActionInput from '../widget/return-address/ReturnAddressActionInput';
import { applyReturnAddress } from '../../services/ReturnAddressService';

const SendMailAddressWizardGrid = forwardRef(({listingData, isLoading, onSelectionChanged, costCodesEnabled }, ref) => {
  const gridRef = useRef()
  const [showCostCodeInput, setShowCostCodeInput] = useState(false)
  const [showReturnAddressInput, setShowReturnAddressInput] = useState(false)

  const msgCfg = {
    position: "bottom-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  }

  useEffect(() => {
    console.log('use effect triggered on isLoading changed to ' + isLoading)
    if (isLoading) {
      onShowLoading()
    }
    else {
      onHideOverlay()
    }
  }, [isLoading])

  const getRowNodeId = (data) => {
    return data.recipientID
  }

  useImperativeHandle(ref, () => {
    return {
      async unselectRows(pageNums) {
        //console.log('unselectRow called', pageNums)
        let removedCount = 0

        if (gridRef.current && pageNums && pageNums.length !== 0) {
          const gridApi = gridRef.current.api
          const selectedNodes = gridApi.getSelectedNodes()
          
          selectedNodes.forEach((rowNode) => {
            console.log(rowNode.data);
            if(pageNums.filter(pageNum => pageNum === rowNode.data.pageNum).length !== 0) {
              //console.log('unselecting row node:', rowNode.data.importRowNum)
              rowNode.setSelected(false)
              removedCount++
            }
          })
        }

        return removedCount
      }
    }
  }, [gridRef])

  const [defaultColumnDefs] = useState({
    sortable: false,
    filter: true,
    resizable: true,
    editable: true,
    headerComponentParams: centeredHeaderComponentParams()
  })

  const initialColumnDef = useCallback(() => [
    { 
      field: 'pageNum',
      maxWidth: 80,
      headerName: 'Page#',
      editable: false,
      filter: false,
      suppressSizeToFit: true,
      //checkboxSelection: true
    },
    {
      field: 'isValid',
      headerName: 'Valid',
      editable: false,
      minWidth: 110,
      maxWidth: 115,
      suppressSizeToFit: false,
      filter: 'AGGridBooleanFilter',
      filterParams: {
        title: 'Valid Address',
        allText: 'All',
        trueText: 'Valid',
        falseText: 'Invalid',
      },
      cellRenderer: "AGGridCheckmarkRenderer",
      cellRendererParams: {
        icon: (<EnvelopeCheck size={24} className="text-success" style={{opacity: 0.6}} />),
        altIcon: (<EnvelopeExclamation size={24} className="text-danger" />)
      }
    },
    { field: 'firstName', minWidth: 100 },
    { field: 'lastName', minWidth: 100 },
    //{ field: 'userDefined', maxWidth: 200, suppressSizeToFit: false},
    { field: 'street1', minWidth: 160 },
    { field: 'street2', minWidth: 100 },
    { field: 'city', minWidth: 140 },
    { field: 'state', minWidth: 90, maxWidth: 110, suppressSizeToFit: true  },
    { field: 'zip', maxWidth: 130, suppressSizeToFit: true },
    {
      field: 'returnName',
      maxWidth: 200,
      suppressSizeToFit: true,
      headerComponent: 'costCodeHeader',
      headerComponentParams: {
        onShowConfig: () => {
          setShowReturnAddressInput(true)
        }
      }
    },
    { field: 'returnStreet1', minWidth: 160 },
    { field: 'returnStreet2', minWidth: 100 },
    { field: 'returnCity', minWidth: 100 },
    { field: 'returnState', suppressSizeToFit: true, maxWidth: 100 },
    { field: 'returnZip', suppressSizeToFit: true, maxWidth: 100 }
  ], [])

  useEffect(() => {

    const defs = initialColumnDef()

    console.log('costCodesEnabled', costCodesEnabled)
    
    if (costCodesEnabled) {
      defs.splice(9, 0,
        {
          field: 'costCode',
          minWidth: 160,
          headerComponent: 'costCodeHeader',
          headerComponentParams: {
            onShowConfig: () => {
              setShowCostCodeInput(true)
            }
          }
        }
      )
    }

    setColumnDefs(defs)
  }, [costCodesEnabled, initialColumnDef])

  const [columnDefs, setColumnDefs] = useState();

  useEffect(() => {
    if (gridRef && gridRef.current) {
      gridRef.current.api.setColumnDefs(columnDefs)
    }
  }, [columnDefs])

  const setGridSelection = () => {
    if (gridRef.current) {
      const selectedNodes = gridRef.current.api.getSelectedNodes()
      const selectedData = selectedNodes.map(node => node.data)
      onSelectionChanged(selectedData)
      //setSelection(selectedData)
    }
    else {
      onSelectionChanged([])
      //setSelection([])
    }
  }

  const onShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, [])

  const onHideOverlay = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  const onCellValueChanged = (e) => {
    console.log('Data after change is', e.data);
    e.data.isEdited = true
    //e.data.isValid = true
    const gridApi = gridRef.current.api
    const modifiedRecipientArray = [e.data]
    const res = gridApi.applyTransaction({ update: modifiedRecipientArray });
  }

  const onCellEditingStopped = (e) => {
    console.log('Editting stopped on ', e);
    
    if (!e.data.isValid && e.data.isEdited) {
      const gridApi = gridRef.current.api
      const focusedCell = gridApi.getFocusedCell()
      const selectedNodes = gridApi.getSelectedNodes()

      const edittingCells = gridApi.getEditingCells()
      if (focusedCell.rowIndex !== selectedNodes[0].rowIndex || edittingCells.length === 0) {
        
        if (e.data.street1 && e.data.street1 !== ''
          && e.data.city && e.data.city !== ''
          && e.data.state && e.data.state !== ''
          && e.data.zip && e.data.zip !== '') {

          console.log('setting to valid', e.data)
          e.data.isValid = true

          if (!listingData.find(item => !item.isValid && item.pageNum !== e.data.pageNum)) {
            console.log('last bad address!!!')
            toast.info('All invalid addresses were modified.', msgCfg)
            gridApi.getFilterInstance('isValid', filterInstance => {
              if (filterInstance) {
                filterInstance.setModel(null)
              }
            })
          }
        }
        
        const modifiedRecipientArray = [e.data]
        const res = gridApi.applyTransaction({ update: modifiedRecipientArray });
        gridApi.redrawRows({ rowNodes: [e.node] });  
      }
    }
  }

  const onCloseCostCodeInput = () => {
    setShowCostCodeInput(false)
  }

  const onApplyCostCode = useCallback(({costCode, costCodeOption}) => {
    if (applyCostCode({gridRef, costCode, costCodeOption}))
    {
      setShowCostCodeInput(false)
    }
  }, [setShowCostCodeInput, applyCostCode])

  const onCloseReturnAddressInput = () => {
    setShowReturnAddressInput(false)
  }

  const onApplyReturnAddress = useCallback(({returnAddress, returnAddressOption, useMultilineAddress}) => {
    if (applyReturnAddress({gridRef, returnAddress, returnAddressOption, useMultilineAddress}))
    {
      setShowReturnAddressInput(false)
    }
  }, [setShowReturnAddressInput])

  return (
    <React.Fragment>
      <div className="ag-theme-alpine" style={{height: '95vh', minHeight: '400px', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          getRowNodeId={getRowNodeId}
          onCellValueChanged={onCellValueChanged}
          onCellEditingStopped={onCellEditingStopped}
          stopEditingWhenCellsLoseFocus={true}
          rowData={listingData}
          rowSelection="multiple"
          paginationPageSize={30}
          //columnDefs={columnDefs}
          loadingOverlayComponent="customLoadingOverLoad"
          loadingOverlayComponentParams={{
            loadingMessage: 'Loading...'
          }}
          defaultColDef={defaultColumnDefs}
          pagination={true}
          frameworkComponents={{
            customHeaderGroup: BasicGroupingHeader,
            customLoadingOverLoad: CustomLoadingOverlay,
            AGGridBooleanFilter: AGGridBooleanFilter,
            AGGridCheckmarkRenderer: AGGridCheckmarkRenderer,
            costCodeHeader: CostCodeHeader
          }}
          onRowDataChanged={(event) => {
            console.log('onRowDataChanged')
            //console.log(event)
            setGridSelection()
            
            if (listingData && listingData.length !== 0) {

              if (listingData.find(item => !item.isValid)) {
                const model = event.api.getFilterModel(); 
                console.log('model', model)


                // Get a reference to the 'name' filter instance
                //const filterInstance = event.api.getFilterInstance('isValid');
                event.api.getFilterInstance('isValid', filterInstance => {
                  if (filterInstance) {
                    filterInstance.setModel({
                      filter: 'false'
                    })
                  }
                })

                //console.log('filterInstance', filterInstance)

                // const newFilterModel = {
                //   ...model,
                //   isValid: {
                //     filter: 'true'
                //   }
                // }

                // Apply the model to ensure any changes in the UI or via API methods are recognised
                //filterInstance.applyModel();

                // Tell grid to run filter operation again
                //event.api.onFilterChanged();
                console.log('Set the filter to show errors only')
                const invalidCount = listingData.filter(item => !item.isValid).length
                toast.warning(`Please correct all invalid addresses (${invalidCount}/${listingData.length}).`, msgCfg)
              } else {
                console.log('Remove filter to show errors only')
                toast.success(`Successfully extracted ${listingData.length} valid addresses.`, msgCfg)
                //event.api.destroyFilter('isValid')
                event.api.getFilterInstance('isValid', filterInstance => {
                  if (filterInstance) {
                    filterInstance.setModel(null)
                  }
                });
              }
            }
          }}
          onGridReady={(event) => { 
            //console.log('onGridReady')
            event.api.sizeColumnsToFit()
            event.columnApi.applyColumnState({
              state: [
                {
                  colId: 'pageNum',
                  sort: 'asc'
                }
              ]
            })
          }}
          onSelectionChanged={(event) => {
            setGridSelection()
          }}
          getRowStyle={function (params) {
            if (!params.data.isValid) {
            //   return {
            //     'background-color': 'inherit'
            //   }
            // }
            // else {
              return {
                'background-color': 'var(--bs-yellow)'
              }
            }
          }}
        >
        </AgGridReact>
      </div>
      {showCostCodeInput && (
        <CostCodeActionInput onClose={onCloseCostCodeInput} showModal={showCostCodeInput} applyCostCode={onApplyCostCode}  />
      )}
      {showReturnAddressInput && (
        <ReturnAddressActionInput onClose={onCloseReturnAddressInput} showModal={showReturnAddressInput} applyReturnAddress={onApplyReturnAddress} useMultilineAddress={true}  />
      )}
    </React.Fragment>
  )
})

export default SendMailAddressWizardGrid