import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import React, { useState, useEffect, useRef } from 'react'
import { X } from 'react-bootstrap-icons';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { MultiLineCellRenderer } from '../../services/AGGridColumnFormatter'
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const SendMailResults = ({showModal, results, success, transactionLog, sendMailService, onClose}) => {
  const gridRef = useRef();
  const [modal, setModal] = useState(showModal)
  const [resultData, setResultData] = useState(results)
  const [gridApi, setGridApi] = useState()
  const canvasRef = useRef(document.getElementById('measurementCanvas') || document.createElement("canvas"))
  const MySwal = withReactContent(Swal)
  //const maxLines = 5
  console.log(transactionLog, results)

  useEffect(() => {
    setModal(showModal)
    setResultData(results)
  }, [showModal, results])

  const PlainSwal = MySwal.mixin({
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    }
  })

  const toggle = () => {
    if (modal) {
      onClose()
    }
    
    setModal(!modal)
  }

  const resizeRowCallaback = () => {
    gridRef.current.api.onRowHeightChanged()
  }

  const sendResultsEmail = () => {
    PlainSwal.fire({
      title: 'Emailing Support',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        PlainSwal.showLoading()
      },
    })

    sendMailService.CallSendMailResultSupport(transactionLog, resultData).then(() => {
      PlainSwal.close()
      toast.success('Results sent to support.', {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }).catch((error) => { console.log(error); PlainSwal.close() })
  }

  return (
    <div>
      <Modal
        backdrop="static"
        size="xl"
        isOpen={modal}
        toggle={toggle}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 700 }}
      >
        <ModalHeader toggle={toggle} close={<button className="close btn btn-white" onClick={toggle}><X size={25} /></button>}>
          <div>Results</div>
          <div style={{fontSize: '60%'}}>Transaction log: {transactionLog}</div>
        </ModalHeader>
        <ModalBody style={{ minHeight: '150px', maxHeight: '75vh', overflow: 'auto' }}>
          <div className="ag-theme-alpine" style={{minHeight: '150px', width: '100%' }}>
            <AgGridReact
              ref={gridRef}
              rowData={resultData}
              domLayout="autoHeight"
              //rowHeight={4+(maxLines*19)}
              // getRowHeight={(params) => {
              //   console.log(params)

              //   return 42;
              // }}
              defaultColDef={{
                sortable: false,
              }}
              frameworkComponents={{
                multiLineCellRenderer: MultiLineCellRenderer
              }}
              pagination={false}
              getRowStyle={function (params) {
                if (params.data.isSuccess) {
                  return {
                    'background-color': '#8CDD81'
                  }
                }
                else {
                  return {
                    'background-color': '#EE6363'
                  }
                }
              }}
              onGridReady={(event) => { 
                //setGridApi(event.api)
                // setGridColumnApi(event.columnApi)
                event.api.sizeColumnsToFit()
              }}>
                <AgGridColumn field="itemMessage" headerName="Item" cellRenderer="multiLineCellRenderer" cellRendererParams={{ canvasRef, maxLines: 2, resizeRowCallaback}}></AgGridColumn>
                <AgGridColumn field="apiResponse" headerName="Result" suppressSizeToFit={true} maxWidth={100}></AgGridColumn>
                <AgGridColumn
                  field="conclusionMessage"
                  headerName="Message"
                  cellEditor="agLargeTextCellEditor"
                  cellEditorPopup={true}
                  editable={true}
                  cellRenderer="multiLineCellRenderer"
                  cellRendererParams={{ canvasRef,  maxLines: 10, resizeRowCallaback }}></AgGridColumn>
            </AgGridReact>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="m-auto">
          {!success && (
              <Button color="warning" onClick={sendResultsEmail} className="me-3">
                Send to Support
              </Button>
            )}
            <Button color="primary" onClick={toggle}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default SendMailResults
