import { useFetchWrapper } from '../services/fetch-wrapper'

export { useUdfService };

const useUdfService = () => {

  const fetchWrapper = useFetchWrapper()

  const GetUdfs = async () => {
    const params = new URLSearchParams({ addAll: true}).toString();
    return fetchWrapper.get(`rentmanagerudf?${params}`)
  }

  return { GetUdfs }
}

