import React from 'react'
import { Button, ListGroup, ListGroupItem } from 'reactstrap'
import { Check2Square } from 'react-bootstrap-icons'

const WarningBadOwner = ({ownerValidationResult, onUnselect, hasUnselect = true}) => {

  if (ownerValidationResult && ownerValidationResult.badProperties) {

    if (ownerValidationResult.badProperties.length !== 0) {

      const badPropertyCount = ownerValidationResult.badProperties.length
      const badRecipientsCount = ownerValidationResult.badRecipients.length
      const ies = badPropertyCount === 1 ? 'y' : 'ies'
      const s = badPropertyCount === 1 ? '' : 's'
      const it = badPropertyCount === 1 ? 'it' : 'them'
      const it2 = badPropertyCount === 1 ? 'it' : 'they'
      const doNot = badPropertyCount === 1 ? 'doesn\'t' : 'do not'
      const these = badPropertyCount === 1 ? 'this' : 'these'

      const s2 = badRecipientsCount === 1 ? '' : 's'

      return (
        <div className='d-flex flex-fill flex-column justify-content-between'>
          <div>
            <div className='fw-bold d-flex justify-content-center'>Missing Owner{s}</div>
            <hr />
            {hasUnselect ? (
              <>
                <p>We cannot create a chargeback invoice for the following propert{ies} because {it2} {doNot} have a Primary Owner.</p>
                
                <span className='fs-5'>Please either:</span>
                <ol type='i'>
                  <li>unselect the associated recipient{s2} using the button below</li>
                  <li>set a primary owner for {these} propert{ies} at the source</li>
                  <li>or have a MailFuze Admin unselect the option to "Create an invoice for the management company's receivable" on the "Property Chargebacks" tab of the MailFuze Settings</li>
                </ol>
              </>
            ) : (
              <>
                <p>We cannot create a chargeback invoice for the following propert{ies} because {it2} {doNot} have a Primary Owner.</p>
                
                <span className='fs-5'>Please either:</span>
                <ol type='i'>
                  <li>set a primary owner for {these} propert{ies} at the source</li>
                  <li>or have a MailFuze Admin unselect the option to "Create an invoice for the management company's receivable" on the "Property Chargebacks" tab of the MailFuze Settings</li>
                </ol>
              </>
            )}
            <ListGroup style={{maxHeight: '10em', marginBottom: '1em', overflowY: 'auto'}}>
              {ownerValidationResult.badProperties.map(item => <ListGroupItem color='warning' key={item.propertyID}>{item.name}</ListGroupItem>)}
            </ListGroup>
            {hasUnselect && (
              <Button color='primary' className='w-100 mb-3' onClick={onUnselect}><Check2Square /> Unselect {it} for me</Button>
            )}
          </div>
        </div>
      )
    }
  }

  return <></>
}

export default WarningBadOwner