import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Button, ButtonGroup, Col, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label, PopoverBody, PopoverHeader, Row, UncontrolledPopover } from 'reactstrap';
import { Rnd } from "react-rnd";
import { ArrowDownLeftSquareFill, ArrowDownRightSquareFill, ArrowUpLeftSquareFill, ArrowUpRightSquareFill, InfoLg } from 'react-bootstrap-icons';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { AddressWizardPageOption } from '../../services/SendMailAddressWizardService';

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const Marker = ({rect: marker, markerName, styles, boundaryElm, onRectChange, onMarkerClick, innerRef}) => {
  
  const defaultStyles = {
    background: 'rgba(127, 127, 127, 0.5)',
    border: '1px solid #000',
    borderSizing: 'border-box',
    // borderWidth: `${border}px`,
    // borderStyle: 'solid',
    // borderColor: '#000',
    color: '#000'
    // marker: {
    //   background: 'rgba(127, 127, 127, 0.5)',
    //   border: '1px solid #000',
    //   color: '#000'
    // }
    // markerText: {
    //   background: 'rgba(127, 127, 127, 0.5)',
    //   border: '1px solid #000',
    //   color: '#000'
    // }
  }

  const innerStyles = {
    ...defaultStyles,
    ...styles,
    display: "flex",
    alignItems: "top",
    justifyContent: "center",

  }

  const markerTextStyle = {
    color: innerStyles.color,
    marginTop: '-1.5rem'
  }

  /*const [marker, setMarker] = useState(rect);

  useEffect(() => {
    onRectChange(marker)
  }, [marker, onRectChange])*/
  
  // {
  //   width: 200,
  //   height: 100,
  //   x: 0,
  //   y: 0
  // })

  const arrowSize = 12

  const CustomHandle = ({children}) => (
    // <div style={{ color: innerStyles.color, display: 'flex', justifyContent: 'center' }}><div style={{ backgroundColor: '#fff', display: 'flex', flexShrink: 1 }}>{children}</div></div>
    <div style={{ color: innerStyles.color, width: '100%', height: '100%', alignContent: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><div style={{ backgroundColor: '#fff', display: 'flex', flexShrink: 1 }}>{children}</div></div>
  )

  const TopRightHandle = () => (
    <CustomHandle>
      <ArrowUpRightSquareFill size={arrowSize} />
    </CustomHandle>
  );

  const TopLeftHandle = () => (
    <CustomHandle>
      <ArrowUpLeftSquareFill size={arrowSize} />
    </CustomHandle>
  );

  const BottomRightHandle = () => (
    <CustomHandle>
      <ArrowDownRightSquareFill size={arrowSize} />
    </CustomHandle>
  );

  const BottomLeftHandle = () => (
    <CustomHandle>
      <ArrowDownLeftSquareFill size={arrowSize} />
    </CustomHandle>
  );

  const getMarkerText = useMemo(() => {
    console.log('getMarkerText', markerName)
    switch (markerName) {
      case 'page':
        return 'Page 1 Marker'
      case 'address':
        return 'Address Marker'
      case 'costCode':
        return 'Cost Code'
      case 'returnAddress':
        return 'Return Address'
      default:
        return 'Unknown Marker'
    }
  }, [markerName])

  return (
    <Rnd
      //ref={innerRef}
      style={innerStyles}
      default={{
        ...marker
      }}
      size={marker}
      position={marker}
      //size={{ width: marker.width, height: marker.height }}
      //position={{ x: marker.x, y: marker.y }}
      onMouseDown={(e) => onMarkerClick(markerName)}
      onDragStop={(e, d) => {
        //setMarker({...marker, x: d.x, y: d.y, rndNode: d.node })
        onRectChange(markerName, {...marker, x: d.x, y: d.y })
        //setMarker({...marker, x: d.x, y: d.y })
      }}
      onResizeStart={(e, direction, ref, delta, position) => onMarkerClick(markerName)}
      onResizeStop={(e, direction, ref, delta, position) => {
        // setMarker({
        //   ...marker,
        //   width: ref.style.width,
        //   height: ref.style.height,
        //   //rndNode: direction.node,
        //   ...position,
        // })

        onRectChange(markerName, {
          ...marker,
          width: ref.style.width,
          height: ref.style.height,
          //rndNode: direction.node,
          ...position,
        })
      }}
      bounds={boundaryElm}
      resizeHandleComponent={{
        bottomLeft: <BottomLeftHandle />,
        bottomRight: <BottomRightHandle />,
        topLeft: <TopLeftHandle/>,
        topRight: <TopRightHandle/>
      }}
    >
      <span style={markerTextStyle}>{getMarkerText}</span>
      {/* <span style={{visibility: 'hidden'}}>{width}</span> */}
    </Rnd>
  )
}

const Markers = ({boundaryElm, markers, onMarkerChanged, onMarkerClick}) => {

  useEffect(() => {
    console.log('markers has changed', markers)
  }, [markers])

  const defaultStyles = {
    fontWeight: '900'
  }

  return markers.filter(m => m.isVisible).map((m, i) => {
    const innerStyles = {
      ...defaultStyles,
      ...m.styles
    }

    return (
        <Marker
          //innerRef={pageMarkerRef}
          //onRectChange={(rect) => markerChanged('page', rect)}
          key={m.marker}
          markerName={m.marker}
          onRectChange={(marker, rect) => onMarkerChanged(marker, rect)}
          onMarkerClick={(marker) => onMarkerClick(marker)}
          styles={innerStyles}
          boundaryElm={boundaryElm}
          rect={m.screenCoordinates}
        />
      )            
    }
  )
}

const SendMailAddressWizardManagerEditor = ({children, scale, onScaleChange, isFileUploading, documentBlobUrl,
    documentIsRendered, hasDocument, onDocumentIsRendered, onPageOptionChange, pageOption, onCostCodeOptionChange, costCodeOption,
    onReturnAddressOptionChange, returnAddressOption, markers, onMarkerChanged, onInitMarkers, onRunAddressTest, pageOptionNumPages,
    onPageOptionNumPagesChanged, pageOptionNumPagesError, onPageOptionNumPagesErrorChanged, templateName, onTemplateNameChanged,
    templateDescription, onTemplateDescriptionChanged, costCodesEnabled }) => {

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const dynamicScaleRef = useRef(scale)
  const canvasRef = useRef(null)
  const documentRef = useRef(null)
  const documentWidthRef = useRef(null)
  const pageMarkerHelpRef = useRef(null)
  const addressMarkerHelpRef = useRef(null)
  const costCodeMarkerHelpRef = useRef(null)
  const returnAddressMarkerHelpRef = useRef(null)

  //const [documentFile, setDocumentFile] = useState(null)
  //const [pageOption, setPageOption] = useState(1)
  //const [pageOptionNumPages, setPageOptionNum] = useState(1)
  //const [pageOptionNumPagesError, setPageOptionNumPagesError] = useState('')
  const [numPages, setNumPages] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  //const [rSelected, setRSelected] = useState(1);
  const [boundaryElm, setBoundaryElm] = useState()
  //const [markerMap, setMarkerMap] = useState({})
  //const [isRendered, setIsRendered] = useState(false);
  //const [showAddressMarker, setShowAddressMarker] = useState(false);
  
  //const [showPageMarker, setShowPageMarker]= useState(false)

  const documentOptions = useMemo(() => { return {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
  }}, [])

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const onRenderSuccess = (e) => {
    console.log('onRenderSuccess', e)
    console.log('onRenderSuccess._pageInfo', e._pageInfo)
    console.log('onRenderSuccess._pageInfo.view', e._pageInfo.view)

    const canvasRect = canvasRef.current?.getBoundingClientRect()
    const page1Base64 = canvasRef.current.toDataURL("image/png");

    const [x, y, w, h] = e._pageInfo.view
    console.log('w', w)
    console.log('h', h)

    console.log('width:', canvasRef.current.getAttribute('width'), 'height:', canvasRef.current.getAttribute('height'))

    const dynamicScale = canvasRect.width / w
    dynamicScaleRef.current = dynamicScale
    documentWidthRef.current = w

    console.log('dynamicScaleRef.current', dynamicScale)
    
    onScaleChange(dynamicScale)
    onInitMarkers(markers, dynamicScale)
    //onDocumentIsRendered(true, canvasRect.width, canvasRect.height, page1Base64)
    onDocumentIsRendered(true, w, h, page1Base64)
    //onDocumentIsRendered(true, canvasRef.current.getAttribute('width'), canvasRef.current.getAttribute('height'), page1Base64)
    
  }

  useEffect(() => {
    const handleResize =  
      debounce(() => {
        const canvasRect = canvasRef.current?.getBoundingClientRect()
        const dynamicScale = canvasRect.width / documentWidthRef.current
        dynamicScaleRef.current = dynamicScale
        onScaleChange(dynamicScale)
        onInitMarkers(markers, dynamicScale)
      }, 10)

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [canvasRef, onScaleChange, onInitMarkers])

  const getMarkerBootstrapClass = useCallback((markerName) => {
    console.log('getMarkerBootstrapClass', markerName)
    switch (markerName) {
      case 'page':
        return 'success'
      case 'address':
        return 'danger'
      case 'costCode':
        return 'info'
      case 'returnAddress':
        return 'warning'
      default:
        return 'secondary'
    }
  }, [markers])


  const getMarkerRect = (marker) => {
    const canvasRect = canvasRef.current?.getBoundingClientRect()
    const docRect = documentRef.current?.getBoundingClientRect()

    const wDiff = Math.floor((docRect.width - canvasRect.width) / 2)
    const hDiff = Math.floor((docRect.height - canvasRect.height) / 2)

    const width = Math.floor(parseInt(marker.width.replace('px', '')) / dynamicScaleRef.current)
    const height = Math.floor(parseInt(marker.height.replace('px', '')) / dynamicScaleRef.current)
    const x = Math.floor((marker.x - wDiff) / dynamicScaleRef.current)
    const y1 = Math.floor((marker.y - hDiff) / dynamicScaleRef.current)
    const y2 = Math.floor(canvasRect.height / dynamicScaleRef.current) - (y1 + height)

    return { width, height, x, y1, y2 }
  } 

  const getMarker = useCallback((marker) => {
    const markerFound = markers.find(m => m.marker === marker && m.isVisible)
    return markerFound;
  }, [markers])

  const onMarkerClick = useCallback((marker)  => {
    console.log('Focus on ' + marker)

    console.log(document.querySelectorAll("fieldset"))

    // document.querySelectorAll("fieldset").forEach(fieldset => {
    //   if (fieldset.classList.filter(cls => cls.startsWith('marker-form-group-')).length !== 0) {
    //     if (fieldset.classList.contains('marker-form-group-' + marker)) {
    //       if (!el.classList.contains('editing')) {
    //         el.classList.add('editing')
    //         document.querySelector('.marker-form-group-' + marker).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    //       }
    //     } else {
    //       if (el.classList.contains('editing')) {
    //         el.classList.remove('editing')
    //       }
    //     }
    //   }
    // })

    document.querySelectorAll("[class*='marker-form-group-']").forEach(el => {
      console.log(el)

      if (el.classList.contains('marker-form-group-' + marker)) {
        if (!el.classList.contains('editing')) {
          el.classList.add('editing')
          el.classList.add('border-2')
          el.classList.add('border-' + getMarkerBootstrapClass(marker))
          document.querySelector('.marker-form-group-' + marker).scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      } else {
        if (el.classList.contains('editing')) {
          el.classList.remove('editing')
          el.classList.remove('border-2')
          
          const clsList = el.classList.value.split(' ');
          const prevMarker = clsList.filter(cls => cls.startsWith('marker-form-group-'))[0].replace('marker-form-group-', '')
          el.classList.remove('border-' + getMarkerBootstrapClass(prevMarker))
        }
      }
    })
  }, [markers])

  const onTransformMarkerChanged = useMemo(() => async (markerName, screenRect) => {
    console.log('markerChanged', markerName, screenRect)
    const rect = getMarkerRect(screenRect)
    onMarkerChanged(markerName, screenRect, rect)
    // switch (markerName) {
    //   case 'page':
    //     setPageMarker(rect)
    //     break;
    //   case 'address':
    //     setAddressMarker(rect)
    //     break;
    //   default:
    //     break;
    // }

    //const rect2 = getMarkerRect(rect)
    //const result = await extractText(rect2.w, rect2.h, rect2.x, rect2.y2)

    /*switch (markerName) {
      case 'page':
        document.getElementById('pageText').value = result.result
        break;
      case 'address':
        document.getElementById('addressText').value = result.result
        break;
      default:
        break;
    }*/
  }, [getMarkerRect, onMarkerChanged])

  const onNumberOfPagesOptionChanged = (numberOfPagePerLetter) => {
    console.log('onNumberOfPagesOptionChanged hasDoc? ', hasDocument, numberOfPagePerLetter, numPages, pageOptionNumPagesError)
    if (hasDocument) {
      if (numPages % numberOfPagePerLetter !== 0) {
        onPageOptionNumPagesErrorChanged(`The number of pages in the document (${numPages}) is not equally divisible by the given number of pages per letter (${numberOfPagePerLetter})`)
      } else {
        onPageOptionNumPagesErrorChanged('')
      }
  
      //setPageOptionNum(numberOfPagePerLetter)
      onPageOptionNumPagesChanged(numberOfPagePerLetter)
    }
  }

  useEffect(() => {
    console.log('documentIsRendered', documentIsRendered)

    if (canvasRef && canvasRef.current && documentIsRendered) {
      console.log('setting boundry')
      setBoundaryElm(canvasRef.current)
    }
  }, [documentIsRendered])

  // useEffect(() => {
  //   console.log('SendMailAddressWizardManagerEditor markers has changed', markers)


  //   const newMarkers = markers.map((m) => {
  //     return {
  //       [m.marker]: {
  //         capturedText: m.capturedText
  //       }
  //     }
  //   })
    
  //   setMarkerMap(newMarkers)

  //   console.log('SendMailAddressWizardManagerEditor markers remapped', newMarkers)
  //   //.forEach((markerName, marker) => {
  //     //newRecipients.push(...recipientsFiltered)
  //     //recipientMap.set(fileId, recipients)

  //   //})


    
  // }, [markers, setMarkerMap])

  return (
    <>
      <div className='h-100 d-flex flex-fill' style={{minHeight: 0}}>
        <Col xs={12} md={12} lg={5} xl={4} className='d-flex flex-column pe-3' style={{overflowY: 'scroll'}}>
          <FormGroup tag="fieldset" className="border p-2 mt-3">
            <legend className="float-none w-auto ps-1 pe-1 fs-6 text-muted" style={{fontWeight: '500'}}>Sample File</legend>

            {children}
          </FormGroup>
          
          <FormGroup tag="fieldset" className="border p-2 mt-1">
            <legend className="float-none w-auto ps-1 pe-1 fs-6 text-muted" style={{fontWeight: '500'}}>Template Information</legend>

            <div className='ps-3 pe-3'>
              <Form>
                <FormGroup row>
                  <Label className='mb-0' hidden>Name</Label>
                  <Input type='text' placeholder='Name' defaultValue={templateName} onChange={onTemplateNameChanged} />
                </FormGroup>
                <FormGroup row>
                  <Label className='mb-0' hidden>Description</Label>
                  <Input type='textarea' placeholder='Description' defaultValue={templateDescription} onChange={onTemplateDescriptionChanged} />
                </FormGroup>
              </Form>
            </div>
          </FormGroup>

          {!isFileUploading && (
            <>
              <FormGroup tag="fieldset" className="border p-2 mt-1 marker-form-group-address">
                <legend className="float-none w-auto ps-1 pe-1 fs-6 text-muted" style={{fontWeight: '500'}}>Where to find the letter recipient's address</legend>

                <div className='ps-3 pe-0'>
                  <div className='d-flex flex-row justify-content-between' style={{marginTop: '-18px'}}>
                    <div className='d-flex flex-row flex-fill justify-content-between align-items-center' style={{fontWeight: 500}}>
                      Address Marker 
                      <Button
                        innerRef={addressMarkerHelpRef}
                        //onClick={onOpenHelp}
                        color='primary'
                        style={{ transform: 'scale(0.6)' }}
                        className='btn-circle d-flex flex-column justify-content-center align-items-center'
                      >
                          <InfoLg size={22} style={{width: '25px', height: '25px' }} />
                      </Button>
                    </div>
                    <UncontrolledPopover
                      placement="right"
                      target={addressMarkerHelpRef}
                      trigger="focus"
                    >
                      <PopoverHeader>
                        Address Marker Hint
                      </PopoverHeader>
                      <PopoverBody>
                        <p className='text-muted' style={{lineHeight: '95%'}}>Set the <span className="text-danger" style={{fontWeight: 500}}>'Address Marker'</span> in the "Page 1 Preview" to highlight the address of the recipient.</p>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </div>
                </div>
                <div className='ps-3 pe-3 pb-3 d-flex flex-row'>
                  {/* <Label className='mb-0 fw-bold text-muted text-nowrap me-2' style={{fontSize: '90%', lineHeight: '95%'}}>Marker Text</Label> */}
                  {/* <div className='ps-3 pe-3 d-flex flex-column flex-fill'> */}
                  <div className='d-flex flex-column flex-fill'>
                    <Input
                      type='textarea'
                      value={getMarker('address')?.capturedText}
                      style={{fontSize: '0.8rem'}}
                      rows={4}
                      readOnly={true}
                      placeholder='The text captured under the address marker will appear here'
                    />
                    <div className='mt-2' style={{fontSize: '70%', lineHeight: '125%'}}>
                      <Row><Col xs={5} md={4} xxl={3} className='fw-bold text-muted text-nowrap'>First Name:</Col><Col xs={7} md={8} xxl={9}>{getMarker('address')?.address?.firstName}</Col></Row>
                      <Row><Col xs={5} md={4} xxl={3} className='fw-bold text-muted text-nowrap'>Last Name:</Col><Col xs={7} md={8} xxl={9}>{getMarker('address')?.address?.lastName}</Col></Row>
                      <Row><Col xs={5} md={4} xxl={3} className='fw-bold text-muted text-nowrap'>Street 1:</Col><Col xs={7} md={8} xxl={9}>{getMarker('address')?.address?.street1}</Col></Row>
                      <Row><Col xs={5} md={4} xxl={3} className='fw-bold text-muted text-nowrap'>Street 2:</Col><Col xs={7} md={8} xxl={9}>{getMarker('address')?.address?.street2}</Col></Row>
                      <Row><Col xs={5} md={4} xxl={3} className='fw-bold text-muted text-nowrap'>City:</Col><Col xs={7} md={8} xxl={9}>{getMarker('address')?.address?.city}</Col></Row>
                      <Row><Col xs={5} md={4} xxl={3} className='fw-bold text-muted text-nowrap'>State:</Col><Col xs={7} md={8} xxl={9}>{getMarker('address')?.address?.state}</Col></Row>
                      <Row><Col xs={5} md={4} xxl={3} className='fw-bold text-muted text-nowrap'>Zip:</Col><Col xs={7} md={8} xxl={9}>{getMarker('address')?.address?.zip}</Col></Row>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <FormGroup tag="fieldset" className="border p-2 mt-1 marker-form-group-returnAddress">
                <legend className="float-none w-auto ps-1 pe-1 fs-6 text-muted" style={{fontWeight: '500'}}>Where to find the letter's return address</legend>

                <div style={{pointerEvents: `${hasDocument? 'inherit' : 'none'}`}}>
                  <BootstrapSwitchButton
                    id="returnAddressOpt"
                    name="returnAddressOpt"
                    onlabel='Return Address'
                    onstyle='primary'
                    offlabel='No Return Address'
                    offstyle='secondary'
                    style='w-100'
                    onChange={checked => onReturnAddressOptionChange(checked)}
                    checked={returnAddressOption.toString().toLowerCase() === 'true' ? true : false }
                  />
                </div>

                {returnAddressOption === true && (
                  <>
                    <div className='ps-3 pe-0'>
                      <div className='d-flex flex-row justify-content-between mt-2'>
                        <div className='d-flex flex-row flex-fill justify-content-between align-items-center' style={{fontWeight: 500}}>
                          Return Address Marker 
                          <Button
                            innerRef={returnAddressMarkerHelpRef}
                            color='primary'
                            style={{ transform: 'scale(0.6)' }}
                            className='btn-circle d-flex flex-column justify-content-center align-items-center'
                          >
                              <InfoLg size={22} style={{width: '25px', height: '25px' }} />
                          </Button>
                        </div>
                        <UncontrolledPopover
                          placement="right"
                          target={returnAddressMarkerHelpRef}
                          trigger="focus"
                        >
                          <PopoverHeader>
                            Return Address Marker Hint
                          </PopoverHeader>
                          <PopoverBody>
                            <p className='text-muted' style={{lineHeight: '95%'}}>Set the <span className="text-warning" style={{fontWeight: 500}}>'Return Address Marker'</span> in the "Page 1 Preview" to highlight the return address of the letter.</p>
                          </PopoverBody>
                        </UncontrolledPopover>
                      </div>
                    </div>

                    <div className='ps-3 pe-3 pb-3 d-flex flex-row'>
                      {/* <Label className='mb-0 fw-bold text-muted text-nowrap me-2' style={{fontSize: '90%', lineHeight: '95%'}}>Marker Text</Label> */}
                      {/* <div className='ps-3 pe-3 d-flex flex-column flex-fill'> */}
                      <div className='d-flex flex-column flex-fill'>
                        <Input
                          type='textarea'
                          value={getMarker('returnAddress')?.capturedText}
                          style={{fontSize: '0.8rem'}}
                          rows={4}
                          readOnly={true}
                          placeholder='The text captured under the return address marker will appear here'
                        />
                        <div className='mt-2' style={{fontSize: '70%', lineHeight: '125%'}}>
                          <Row><Col xs={5} md={4} xxl={3} className='fw-bold text-muted text-nowrap'>First Name:</Col><Col xs={7} md={8} xxl={9}>{getMarker('returnAddress')?.address?.firstName}</Col></Row>
                          <Row><Col xs={5} md={4} xxl={3} className='fw-bold text-muted text-nowrap'>Last Name:</Col><Col xs={7} md={8} xxl={9}>{getMarker('returnAddress')?.address?.lastName}</Col></Row>
                          <Row><Col xs={5} md={4} xxl={3} className='fw-bold text-muted text-nowrap'>Street 1:</Col><Col xs={7} md={8} xxl={9}>{getMarker('returnAddress')?.address?.street1}</Col></Row>
                          <Row><Col xs={5} md={4} xxl={3} className='fw-bold text-muted text-nowrap'>Street 2:</Col><Col xs={7} md={8} xxl={9}>{getMarker('returnAddress')?.address?.street2}</Col></Row>
                          <Row><Col xs={5} md={4} xxl={3} className='fw-bold text-muted text-nowrap'>City:</Col><Col xs={7} md={8} xxl={9}>{getMarker('returnAddress')?.address?.city}</Col></Row>
                          <Row><Col xs={5} md={4} xxl={3} className='fw-bold text-muted text-nowrap'>State:</Col><Col xs={7} md={8} xxl={9}>{getMarker('returnAddress')?.address?.state}</Col></Row>
                          <Row><Col xs={5} md={4} xxl={3} className='fw-bold text-muted text-nowrap'>Zip:</Col><Col xs={7} md={8} xxl={9}>{getMarker('returnAddress')?.address?.zip}</Col></Row>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </FormGroup>

              {costCodesEnabled && (
                <FormGroup tag="fieldset" className="border p-2 mt-1 marker-form-group-costCode">
                  <legend className="float-none w-auto ps-1 pe-1 fs-6 text-muted" style={{fontWeight: '500'}}>Where to find the letter's cost code</legend>

                  <div style={{pointerEvents: `${hasDocument? 'inherit' : 'none'}`}}>
                    <BootstrapSwitchButton
                      id="costCodeOpt"
                      name="costCodeOpt"
                      onlabel='Cost Code'
                      onstyle='primary'
                      offlabel='No Cost Code'
                      offstyle='secondary'
                      style='w-100'
                      onChange={checked => onCostCodeOptionChange(checked)}
                      checked={costCodeOption.toString().toLowerCase() === 'true' ? true : false }
                    />
                  </div>

                  {costCodeOption === true && (
                    <>
                      <div className='ps-3 pe-0'>
                        <div className='d-flex flex-row justify-content-between mt-2'>
                          <div className='d-flex flex-row flex-fill justify-content-between align-items-center' style={{fontWeight: 500}}>
                            Cost Code Marker
                            <Button
                              innerRef={costCodeMarkerHelpRef}
                              //onClick={onOpenHelp}
                              color='primary'
                              style={{ transform: 'scale(0.6)' }}
                              className='btn-circle d-flex flex-column justify-content-center align-items-center'
                            >
                                <InfoLg size={22} style={{width: '25px', height: '25px' }} />
                            </Button>
                          </div>
                          <UncontrolledPopover
                            placement="right"
                            target={costCodeMarkerHelpRef}
                            trigger="focus"
                          >
                            <PopoverHeader>
                              Cost Code Marker Hint
                            </PopoverHeader>
                            <PopoverBody>
                              {/* <p className='text-muted' style={{lineHeight: '95%'}}>Set the <span className="text-info" style={{fontWeight: 500}}>'Cost Code Marker'</span> in the "Page 1 Preview" to highlight a unique piece of text that the wizard will extract and use as the cost code when the letter is invoiced.</p> */}
                              <p className='text-muted' style={{lineHeight: '95%'}}>Set the <span className="text-info" style={{fontWeight: 500}}>'Cost Code Marker'</span> in the "Page 1 Preview" to highlight the cost code the wizard will use during invoicing.</p>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </div>
                      </div>

                      <div className='ps-3 pe-3 pb-3 d-flex flex-row'>
                        <div className='d-flex flex-column flex-fill'>
                          <Input
                            type='textarea'
                            value={getMarker('costCode')?.capturedText}
                            style={{fontSize: '0.8rem'}}
                            rows={2}
                            readOnly={true}
                            placeholder='The text captured under the cost code marker will appear here'
                          />
                        </div>
                      </div>
                    </>
                  )}
                </FormGroup>
              )}

              <FormGroup tag="fieldset" className="border p-2 mt-1 marker-form-group-page">
                <legend className="float-none w-auto ps-1 pe-1 fs-6 text-muted" style={{fontWeight: '500'}}>How to split each letter in the file</legend>

                {/* <div className='ps-3 pe-3 d-flex flex-column'>
                  <div className='d-flex flex-row flex-wrap align-items-baseline pb-1'>
                    <div>
                      <FormGroup check>
                        <Input
                          type="radio"
                          value={1}
                          id='pageOpt1'
                          name="pageOpt"
                          checked={pageOption == 1 ? 'checked' : ''}
                          onChange={onPageOptionChange}
                          style={{transform: 'scale(1.3)'}}
                        />
                        {' '}
                        <Label check for='pageOpt1' style={{fontWeight: 500}}>
                          Fixed pages:
                        </Label>
                      </FormGroup>
                    </div>
                    <div className='ms-2 d-flex flex-column flex-wrap'>
                      <InputGroup>
                        <InputGroupText className={pageOption == 1 ? 'bg-light' : ''}>
                          Each letter is
                        </InputGroupText>
                        <Input
                          type="number"
                          name="number"
                          id="exampleNumber"
                          placeholder=""
                          value={pageOptionNumPages}
                          onChange={(e) => {setPageOptionNum(e.target.value)}}
                          min={1}
                          max={numPages}
                          disabled={pageOption != 1}
                          size={1}
                          style={{minWidth: '30px'}}
                        />
                        <InputGroupText className={pageOption == 1 ? 'bg-light' : ''}>
                          pages exactly.
                        </InputGroupText>
                      </InputGroup>
                    </div>
                  </div>

                  <div className='d-flex flex-row align-items-baseline pb-1'>
                    <div>
                      <FormGroup check>
                        <Input
                          type="radio"
                          value={2}
                          id='pageOpt2'
                          name="pageOpt"
                          checked={pageOption == 2 ? 'checked' : ''}
                          onChange={onPageOptionChange}
                          style={{transform: 'scale(1.3)'}}
                        />
                        {' '}
                        <Label check for='pageOpt2'>
                          <span style={{fontWeight: 500}}>First page marker</span><p className='text-muted' style={{fontSize: '90%', lineHeight: '95%'}}>Set the <span className="text-success" style={{fontWeight: 500}}>'Page 1 Marker'</span> in the "Page 1 Preview" to highlight a unique piece of text that the wizard will use to split the document on every page where the text appears in that same postion.</p>
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                </div> */}
                <div style={{pointerEvents: `${hasDocument? 'inherit' : 'none'}`}}>
                  <BootstrapSwitchButton
                    id="pageOpt"
                    name="pageOpt"
                    onlabel='Page 1 Marker'
                    onstyle='primary'
                    offlabel='Fixed Page Size'
                    offstyle='secondary'
                    style='w-100'
                    onChange={checked => onPageOptionChange((checked ? AddressWizardPageOption.PageMarker : AddressWizardPageOption.FixedPageNum))}
                    checked={pageOption == AddressWizardPageOption.PageMarker ? true : false }
                  />
                </div>
                {pageOption == AddressWizardPageOption.PageMarker && (
                  <>
                    <div className='ps-3 pe-0'>
                      <div className='d-flex flex-row justify-content-between mt-2'>
                        <div className='d-flex flex-row flex-fill justify-content-between align-items-center' style={{fontWeight: 500}}>
                          Page 1 Marker
                          <Button
                            innerRef={pageMarkerHelpRef}
                            //onClick={onOpenHelp}
                            color='primary'
                            style={{ transform: 'scale(0.6)' }}
                            className='btn-circle d-flex flex-column justify-content-center align-items-center'
                          >
                              <InfoLg size={22} style={{width: '25px', height: '25px' }} />
                          </Button>
                        </div>
                        <UncontrolledPopover
                          placement="right"
                          target={pageMarkerHelpRef}
                          trigger="focus"
                        >
                          <PopoverHeader>
                            Page Marker Hint
                          </PopoverHeader>
                          <PopoverBody>
                            <p className='text-muted' style={{lineHeight: '95%'}}>Set the <span className="text-success" style={{fontWeight: 500}}>'Page 1 Marker'</span> in the "Page 1 Preview" to highlight a unique piece of text that the wizard will use to split the document on every page where the text appears in that same postion.</p>
                          </PopoverBody>
                        </UncontrolledPopover>
                      </div>
                    </div>

                    {/* <div className='p-3 pb-0'>
                      <div className='d-flex flex-row justify-content-between'>
                        <div style={{fontWeight: 500}}>
                          Page 1 Marker
                        </div>
                      </div>
                      <div>
                        <p className='text-muted' style={{fontSize: '90%', lineHeight: '95%'}}>Set the <span className="text-success" style={{fontWeight: 500}}>'Page 1 Marker'</span> in the "Page 1 Preview" to highlight a unique piece of text that the wizard will use to split the document on every page where the text appears in that same postion.</p>
                      </div>
                    </div> */}
                    <div className='ps-3 pe-3 pb-3 d-flex flex-row'>
                      {/* <Label className='mb-0 fw-bold text-muted text-nowrap me-2' style={{fontSize: '90%', lineHeight: '95%'}}>Marker Text</Label> */}
                      {/* <div className='ps-3 pe-3 d-flex flex-column flex-fill'> */}
                      <div className='d-flex flex-column flex-fill'>
                        <Input
                          type='textarea'
                          value={getMarker('page')?.capturedText}
                          style={{fontSize: '0.8rem'}}
                          rows={2}
                          readOnly={true}
                          placeholder='The text captured under the page 1 marker will appear here'
                        />
                      </div>
                    </div>
                  </>
                )}

                {pageOption == AddressWizardPageOption.FixedPageNum && (
                  <div className='ps-3 pe-3 pt-3'>
                    <div className='d-flex flex-row justify-content-between align-items-center'>
                      <div style={{fontWeight: 500}}>
                        Set the number of pages per letter
                      </div>
                      <div>
                        <div className="form-check form-switch d-flex justify-content-end ms-3">
                          <Input
                            type="number"
                            name="number"
                            id="fixedPageSize"
                            placeholder=""
                            value={pageOptionNumPages}
                            onChange={(e) => {onNumberOfPagesOptionChanged(e.target.value)}}
                            min={1}
                            max={numPages}
                            disabled={pageOption != AddressWizardPageOption.FixedPageNum}
                            size={1}
                            style={{minWidth: '30px'}}
                            valid={hasDocument && pageOptionNumPagesError === ''}
                            invalid={hasDocument && pageOptionNumPagesError !== ''}
                          />
                        </div>
                      </div>
                    </div>
                    <Input type='hidden' /> {/* this is a hack to get the form feedback to appear someplace other than under the immediate field in error. */}
                    {hasDocument && pageOptionNumPagesError !== '' && (
                      <FormFeedback className='mb-3 d-flex flex-fill w-100' style={{fontSize: '90%', lineHeight: '95%'}}>{pageOptionNumPagesError}</FormFeedback>
                    )}
                    <div>
                      <p className='text-muted' style={{fontSize: '90%', lineHeight: '95%'}}>Tell the wizard to split the document into one or more pages per mailing. The number of pages in the document must be equally divisible by the number of pages chosen.</p>
                    </div>
                  </div>
                )}
              </FormGroup>

              {/* <FormGroup tag="fieldset" className="border p-2 mt-3">
                <legend className="float-none w-auto ps-1 pe-1 fs-6 text-muted" style={{fontWeight: '500'}}>Run Address Test On Full Sample File</legend>

                <div className='ps-3 pe-3'>
                  <div>
                    <p className='text-muted' style={{fontSize: '90%', lineHeight: '95%'}}>Using the chosen settings, the wizard will find and display the corresponding addresses found throughout the samples file.</p>
                  </div>
                  <div className='d-flex flex-full justify-content-center align-items-center'>
                    <Button color='dark' onClick={onRunAddressTest}>Run Address Test</Button>
                  </div>
                </div>
              </FormGroup> */}
            </>
          )}
        </Col>
        <Col xs={12} md={12} lg={7} xl={8} className='d-flex flex-column pe-3 ps-3' style={{overflowY: 'auto', position: 'relative'}}>
          <FormGroup tag="fieldset" className="border p-2 mt-3">
            <legend className="float-none w-auto ps-1 pe-1 fs-6 text-muted" style={{fontWeight: '500'}}>Page 1 Preview</legend>

            <div className="Example__container">
              <div className="Example__container__document">
                {hasDocument && (
                <Document
                  file={documentBlobUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                  options={documentOptions}
                  inputRef={documentRef}
                >
                  <Page pageNumber={pageNumber} scale={scale} onRenderSuccess={onRenderSuccess} canvasRef={canvasRef}/>
                  <Markers boundaryElm={boundaryElm} markers={markers} onMarkerChanged={onTransformMarkerChanged} onMarkerClick={onMarkerClick} />

                  {/* {showPageMarker && (
                    <Marker
                      innerRef={pageMarkerRef}
                      onRectChange={(rect) => markerChanged('page', rect)}
                      styles={{ color: '#2d882d', background: 'rgba(45, 136, 45, 0.3)', border: '2px solid #2d882d', fontWeight: '900' }}
                      boundaryElm={boundaryElm}
                      markerText='Page 1 Marker'
                      rect={pageMarker}
                    />
                  )}
                  {showAddressMarker && (
                    <Marker
                      innerRef={addressMarkerRef}
                      onRectChange={(rect) => markerChanged('address', rect)}
                      styles={{ color: '#951d1d', background: 'rgba(149, 29, 29, 0.3)', border: '2px solid #951d1d', fontWeight: '900' }}
                      boundaryElm={boundaryElm}
                      markerText='Address Marker'
                      rect={addressMarker}
                    />
                  )} */}
                </Document>
                )}
              </div>
              {documentIsRendered && (
              <p className='pt-4'>
                Page {pageNumber} of {numPages}
              </p>
              )}
            </div>
          </FormGroup>
        </Col>
      </div>
    </>
  )
}

export default SendMailAddressWizardManagerEditor