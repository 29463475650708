import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import { CustomLoadingOverlay } from '../widget/AGGridCustomOverlay';
import BasicGroupingHeader from '../widget/BasicGroupingHeader';
import { Button, Col, Row } from 'reactstrap';
import { nanoid } from 'nanoid';
import { DashCircleFill, PlusCircleFill, XCircleFill } from 'react-bootstrap-icons';
import CostCodeHeader from '../widget/cost-codes/CostCodeGridHeader'
import { applyCostCode } from '../../services/CostCodeService';
import CostCodeActionInput from '../widget/cost-codes/CostCodeActionInput';
import { centeredHeaderComponentParams } from '../widget/CenteredHeaderComponent'
import ReturnAddressActionInput from '../widget/return-address/ReturnAddressActionInput';
import { applyReturnAddress } from '../../services/ReturnAddressService';

const SendMailCustomGrid = forwardRef(({listingData, isLoading, firstRowIsHeader, onFirstRowOptionChanged, onStartEdit, onStopEdit, onSelectionChanged, onRowAdded, onSelectionRemove, onClear, onCurrentEditingCell, onPreviousEditingCell, costCodesEnabled}, ref) => {
  const gridRef = useRef()
  const [showCostCodeInput, setShowCostCodeInput] = useState(false)
  const [showReturnAddressInput, setShowReturnAddressInput] = useState(false)

  useEffect(() => {
    console.log('use effect triggered on isLoading changed to ' + isLoading)
    if (isLoading) {
      onShowLoading()
    }
    else {
      onHideOverlay()
    }
  }, [isLoading])

  useEffect(() => {
    console.log('firstRowIsHeader changed to ' + firstRowIsHeader)
  }, [firstRowIsHeader])

  // const getRowId = useMemo(() => {
  //   return (params) => {
  //     return params.data.recipientId;
  //   };
  // }, []);  


  const getRowId = useMemo(() => { //doesn't work, but follows documentation to a t.
    return ({ data }) => data.recipientId;
  }, []);

  const getRowNodeId = (data) => {
    return data.recipientId
  }

  const [defaultColumnDefs] = useState({
      sortable: false,
      filter: true,
      resizable: true,
      editable: true,
      headerComponentParams: centeredHeaderComponentParams()
  })

  const initialColumnDef = useCallback(() => [
    { 
      field: 'importRowNum',
      maxWidth: 150,
      headerName: 'Row#',
      editable: false,
      suppressSizeToFit: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      filter: 'agNumberColumnFilter'
    },
    { field: 'firstName', minWidth: 150, suppressSizeToFit: false, },
    { field: 'lastName', minWidth: 150, suppressSizeToFit: false },
    //{ field: 'userDefined', maxWidth: 200, suppressSizeToFit: false},
    { field: 'street', minWidth: 150 },
    { field: 'city', minWidth: 140 },
    { field: 'state', maxWidth: 120, suppressSizeToFit: true  },
    { field: 'zip', maxWidth: 130, suppressSizeToFit: true },
    {
      field: 'returnName',
      maxWidth: 200,
      suppressSizeToFit: true,
      headerComponent: 'costCodeHeader',
      headerComponentParams: {
        onShowConfig: () => {
          setShowReturnAddressInput(true)
        }
      }
    },
    { field: 'returnStreet', minWidth: 150 },
    { field: 'returnCity', minWidth: 140 },
    { field: 'returnState', maxWidth: 120, suppressSizeToFit: true  },
    { field: 'returnZip', maxWidth: 130, suppressSizeToFit: true }
  ], [])

  useEffect(() => {

    const defs = initialColumnDef()//[...initialColumnDef()]

    console.log('costCodesEnabled', costCodesEnabled)
    
    if (costCodesEnabled) {
      //defs.push(
      defs.splice(7, 0,
        {
          field: 'costCode',
          minWidth: 140,
          suppressSizeToFit: true,
          headerComponent: 'costCodeHeader',
          headerComponentParams: {
            onShowConfig: () => {
              setShowCostCodeInput(true)
    
              /*const costCode = prompt("Use the following Cost Code")
    
              if (costCode) {
                const assignToAll = confirm("Assign '" + costCode + "' to all records")
                console.log('calling', costCode, assignToAll)
      
                const itemsToUpdate = [];
                gridRef.current.api.forEachNodeAfterFilterAndSort(function (rowNode, index) {
                  const data = rowNode.data
                  if (assignToAll || data.costCode === '') {
                    data.costCode = costCode
                  }
                  
                  itemsToUpdate.push(data)
                })
                const res = gridRef.current.api.applyTransaction({ update: itemsToUpdate })
                console.log(res)
              }*/
            }
          }
        }
      )
    }

    setColumnDefs(defs)
  }, [costCodesEnabled, initialColumnDef])

  const [columnDefs, setColumnDefs] = useState();

  useEffect(() => {
    //console.log('columnDefs changed')
    if (gridRef && gridRef.current) {
      //console.log('columnDefs changed --> sizeColumnsToFit')
      gridRef.current.api.setColumnDefs(columnDefs)
      //gridRef.current.columnApi.autoSizeColumns()
      //gridRef.current.api.sizeColumnsToFit()
    }
  }, [columnDefs])

  const setGridSelection = () => {
    if (gridRef.current) {
      //const selectedNodes = gridRef.current.api.getSelectedNodes()
      //console.log(selectedNodes)
      //const selectedData = selectedNodes.map(node => node.data)
      const selectedData = gridRef.current.api.getSelectedRows()
      var sortedSelectedData = selectedData.sort((a, b) => a.importRowNum - b.importRowNum);

      console.log(sortedSelectedData)
      onSelectionChanged(sortedSelectedData)
      //setSelection(selectedData)
    }
    else {
      onSelectionChanged([])
      //setSelection([])
    }
  }

  useImperativeHandle(ref, () => {
    return {
      async unselectRows(importRowNums) {
        //console.log('unselectRow called', importRowNums)
        let removedCount = 0;

        if (gridRef.current && importRowNums && importRowNums.length !== 0) {
          const gridApi = gridRef.current.api
          const selectedNodes = gridApi.getSelectedNodes()
          
          selectedNodes.forEach((rowNode) => {
            if(importRowNums.filter(rowNum => rowNum === rowNode.data.importRowNum).length !== 0) {
              //console.log('unselecting row node:', rowNode.data.importRowNum)
              rowNode.setSelected(false);
              removedCount++
            }
          })
        }

        return removedCount
      }
    }
  }, [gridRef])

  const setGridEdittingCell = () => {

    if (gridRef.current) {
      const currentEdit = onCurrentEditingCell()
      const previousEdit = onPreviousEditingCell()
      console.log('currentEdit', currentEdit)
      if (currentEdit) {

        console.log('previousEdit', previousEdit)
        if (previousEdit && currentEdit.recipientId === previousEdit.recipientId) {
          console.log('previousEdit and currentEdit are the same, return')
          return;
        }

        const gridApi = gridRef.current.api
        gridApi.paginationGoToLastPage()
  
        const newRowNode = gridApi.getRowNode(currentEdit.recipientId)
        console.log('newRowNode', newRowNode)
        const newRowIndex = newRowNode.rowIndex
        //let newRowIndex = -1 // newRowNode.rowIndex

        // gridApi.forEachNode((rowNode, index) => {
        //   console.log('node ' + rowNode.data.recipientId + ' is in the grid');

        //   if (rowNode.data.recipientId === currentEdit.recipientId) {
        //     newRowIndex = index
        //   }
        // });
  
        gridApi.ensureIndexVisible(newRowIndex, 'bottom');
    
        gridApi.startEditingCell({
          rowIndex: newRowIndex,
          colKey: currentEdit.column
        });
      }
    }
    else {
      //onSelectionChanged([])
      //setSelection([])
    }
  }

  const onShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, [])

  const onHideOverlay = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  const addNewRow = useCallback(async () => {
    const id = nanoid()
    const gridApi = gridRef.current.api
    console.log(gridApi.paginationGetRowCount)
    let largestRowNum = 0
    gridApi.forEachNode((rowNode, index) => {
      if (rowNode.data.importRowNum > largestRowNum) {
        largestRowNum = rowNode.data.importRowNum
      }
    })
    const newRowNum = largestRowNum + 1
    console.log('largestRowNum', largestRowNum)
    console.log('newRowNum', newRowNum)
    const newRecipientArray = [{ importRowNum: newRowNum, importId: id, importSource: 'grid-entry', recipientId: id, isValid: true, isEdited: true }]
    //const transactionResult = await gridApi.applyTransaction({add: newRecipientArray})

    
    //console.log(transactionResult)

    //const newRowNode = transactionResult.add[0] //gridOptions.api.getRowNode('55'); 
    //const newRowIndex = transactionResult.add[0].rowIndex

    // do something with the row, e.g. select it
    //newRowNode.setSelected(true);
    await onRowAdded(id, newRecipientArray);

    onStartEdit('firstName', id)
  }, [])
  
  const onCellValueChanged = (e) => {
    console.log('Data after change is', e.data);
    e.data.isEdited = true
    //e.data.isValid = true
    const gridApi = gridRef.current.api
    const modifiedRecipientArray = [e.data]
    const res = gridApi.applyTransaction({ update: modifiedRecipientArray });
  }

  const onCellEditingStarted = (e) => {
    console.log('Editting started on ', e.node);
    onStartEdit(e.column.colId, e.data.recipientId)
  }

  const onCellEditingStopped = (e) => {
    console.log('Editting stopped on ', e);
    
    onStopEdit()
    
    if (!e.data.isValid && e.data.isEdited) {
      const gridApi = gridRef.current.api
      const focusedCell = gridApi.getFocusedCell();
      const selectedNodes = gridApi.getSelectedNodes()
      //const selectedData = selectedNodes.map(node => node.data)

      const edittingCells = gridApi.getEditingCells()
      if (focusedCell.rowIndex !== selectedNodes[0].rowIndex || edittingCells.length === 0) {
        
        e.data.isValid = true
        const modifiedRecipientArray = [e.data]
        const res = gridApi.applyTransaction({ update: modifiedRecipientArray });
        gridApi.redrawRows({ rowNodes: [e.node] });  
      }
    }
  }

  const onCloseCostCodeInput = () => {
    setShowCostCodeInput(false)
  }

  const onApplyCostCode = useCallback(({costCode, costCodeOption}) => {
    if (applyCostCode({gridRef, costCode, costCodeOption}))
    {
      setShowCostCodeInput(false)
    }
  }, [setShowCostCodeInput, applyCostCode])

  const onCloseReturnAddressInput = () => {
    setShowReturnAddressInput(false)
  }

  const onApplyReturnAddress = useCallback(({returnAddress, returnAddressOption}) => {
    if (applyReturnAddress({gridRef, returnAddress, returnAddressOption}))
    {
      setShowReturnAddressInput(false)
    }
  }, [setShowReturnAddressInput])

  return (
    <React.Fragment>
      <div className='grid-outer-buttons mt-3'>
        <Row className='d-flex align-items-center flex-grow-1'>
          <Col xs={12} xl={7}>
            <Button color='dark' className='me-2 me-md-1' onClick={addNewRow}><PlusCircleFill /> Add Recipient</Button>
            <Button color='dark' className='me-2 me-md-1' onClick={onSelectionRemove}><DashCircleFill /> Remove Selected</Button>
            <Button color='dark' onClick={onClear}><XCircleFill /> Clear</Button>
          </Col>
          <Col xs={12} xl={5}>
            <div className='d-flex flex-row justify-content-between mt-2 mt-xl-0'>
              <div className='d-flex flex-grow-1'>The first row contains the column headers</div>
              <div className='d-flex flex-grow-1'>
                <div className="form-check form-switch d-flex align-items-end flex-column pe-1 justify-content-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="firstRowIsHeader"
                    name="firstRowIsHeader"
                    onChange={(e) => onFirstRowOptionChanged(e.target.checked)}
                    checked={firstRowIsHeader ? 'checked' : ''}
                    style={{transform: 'scale(1.6)'}}/>
                </div>
                {/* <FormGroup
                  check
                  inline
                >
                  <Input
                    type='checkbox'
                    checked={firstRowIsHeader ? 'checked' : ''}
                    name='firstRowIsHeader'
                    id='firstRowIsHeader'
                    onChange={(e) => onFirstRowOptionChanged(e.target.checked)}
                    style={{transform: 'scale(1.3)'}}
                  />
                  <Label for='firstRowIsHeader'>The first row contains the column headers</Label>
                </FormGroup> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="ag-theme-alpine" style={{height: '95vh', minHeight: '400px', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          //editType='fullRow'
          getRowId={getRowId}
          getRowNodeId={getRowNodeId}
          onCellValueChanged={onCellValueChanged}
          onCellEditingStarted={onCellEditingStarted}
          onCellEditingStopped={onCellEditingStopped}
          stopEditingWhenCellsLoseFocus={true}
          rowData={listingData}
          rowSelection="multiple"
          //domLayout="autoHeight"
          paginationPageSize={30}
          //columnDefs={columnDefs}
          //isExternalFilterPresent={isExternalFilterPresent}
          //doesExternalFilterPass={doesExternalFilterPass}
          loadingOverlayComponent="customLoadingOverLoad"
          loadingOverlayComponentParams={{
            loadingMessage: 'Loading...'
          }}
          //animateRows={true}
          defaultColDef={defaultColumnDefs}
          pagination={true}
          frameworkComponents={{
            //customHeaderGroup: BasicGroupingHeader,
            customLoadingOverLoad: CustomLoadingOverlay,
            costCodeHeader: CostCodeHeader,
            returnAddressHeader: CostCodeHeader
          }}
          onRowDataChanged={(event) => {
            console.log('onRowDataChanged')
            console.log(event)
            setGridSelection()
            setGridEdittingCell()
          }}
          onGridReady={(event) => { 
            //console.log('onGridReady')
            //setGridApi(event.api)
            //setGridColumnApi(event.columnApi)
            //console.log(event);
            event.api.sizeColumnsToFit()
            event.columnApi.applyColumnState({
              state: [
                {
                  colId: 'importRowNum',
                  sort: 'asc'
                }
              ]
            })
          }}
          // onFirstDataRendered={(event) => {
          //   event.api.sizeColumnsToFit()
          // }}
          onSelectionChanged={(event) => {
            setGridSelection()
          }}
          getRowStyle={function (params) {
            if (!params.data.isValid) {
            //   return {
            //     'background-color': 'inherit'
            //   }
            // }
            // else {
              return {
                'background-color': 'var(--bs-yellow)'
              }
            }
          }}
        >
        </AgGridReact>
      </div>
      {showCostCodeInput && (
        <CostCodeActionInput onClose={onCloseCostCodeInput} showModal={showCostCodeInput} applyCostCode={onApplyCostCode}  />
      )}
      {showReturnAddressInput && (
        <ReturnAddressActionInput onClose={onCloseReturnAddressInput} showModal={showReturnAddressInput} applyReturnAddress={onApplyReturnAddress}  />
      )}
    </React.Fragment>
  )
})

export default SendMailCustomGrid