import BaseSelect2 from "../dropdowns/BaseSelect2"

export const AddressWizardTemplatesSelect = ({options, onChange, onError, value, ...rest}) => {
  return (
      <BaseSelect2
        options={options}
        value={value}
        onChange={onChange}
        onError={onError}
        selectFirst={true}
        {...rest}
      />
  )
}