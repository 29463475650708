import React, { useEffect, useState } from 'react'
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { Roles } from '../../services/RoleService'

export const UserCellMenuRenderer = ({enableUserClicked, disableUserClicked, modifyRolesClicked, resetPasswordClicked, isSystemAdmin, userID, node, ...rest}) => {

  let canEdit = true
  //A Client Administrator cannot edit Systems Administrator.
  //A user cannot edit their own role or disable self.
  if ((node.data.roles.includes(Roles.SystemAdministrator) && !isSystemAdmin) || node.data.userID === userID) {
    canEdit = false;
  }

  const [showEnable, setShowEnable] = useState(node.data.disabled)
  //const [showButton, setShowButton] = useState(canEdit)

  //console.log(rest)
  //console.log(node)
  //console.log(node.data)
  console.log(node.data.disabled)
  

  useEffect(() => {
    setShowEnable(node.data.disabled)
  }, [node.data.disabled])

  const onEnableUser = (e) => {
    enableUserClicked(node)
  }

  const onDisableUser = (e) => {
    disableUserClicked(node)
  }

  const onModifyRoles = (e) => {
    modifyRolesClicked(node)
  }

  const onResetPassword = (e) => {
    resetPasswordClicked(node)
  }

  // if (node.data.roles.includes(Roles.SystemAdministrator) && !isSystemAdmin) {
  //   setShowButton(false)
  // }

  return (
    <React.Fragment>
      {canEdit && (
        <UncontrolledButtonDropdown>
          <DropdownToggle color="white" className="btn-sm btn-grid-action dropdown-toggle">
            <ThreeDotsVertical />
          </DropdownToggle>
          <DropdownMenu container="body">
            {!showEnable && (
              <DropdownItem onClick={onDisableUser}>
                Disable User
              </DropdownItem>
            )}
            {showEnable && (
              <DropdownItem onClick={onEnableUser}>
                Enable User
              </DropdownItem>
            )}
            <DropdownItem onClick={onModifyRoles}>
              Modify Roles
            </DropdownItem>
            <DropdownItem onClick={onResetPassword}>
              Reset Password
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      )}
    </React.Fragment>
  )
}