import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Nav, NavItem, NavLink } from 'reactstrap'
import authService from '../api-authorization/AuthorizeService';
import { useClientService } from '../../services/ClientService';
import { Roles } from '../../services/RoleService'

const Menu = (props) => {

  let _subscription = null

  const initialState = {
      isLoading: true,
      isAuthenticated: false,
      userName: null,
      role: null,
      isIntegrationPartner: false
  }

  const [state, setState] = useState(initialState)
  const clientService = useClientService()
  
  useEffect(() => {
    _subscription = authService.subscribe(() => populateState());
    populateState();

    // Specify how to clean up after this effect:
    return function cleanup() {
        authService.unsubscribe(_subscription);
    };
  }, []);

  const populateState = async () => {
    const [isAuthenticated, user, role] = await Promise.all([
        authService.isAuthenticated(),
        authService.getUser(),
        authService.getRole()
    ])

    let isIntegrationPartner = false;
    if (isAuthenticated) {
      const myClient = await clientService.GetMyClient()
      isIntegrationPartner = myClient && myClient.IsIntegrationPartner
    }

    setState({
        isLoading: false,
        isAuthenticated,
        userName: user && user.name,
        role,
        isIntegrationPartner
    });
  }

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink active={props && !props.tab} tag={Link} to="/admin">Admin</NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={props && props.tab && props.tab === 'settings'} tag={Link} to="/admin/settings">Settings</NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={props && props.tab && props.tab === 'users'} tag={Link} to="/admin/users">Manage Users</NavLink>
        </NavItem>
        { state.role && (state.role === Roles.SystemAdministrator || state.role.includes(Roles.SystemAdministrator)) && (
          <NavItem>
            <NavLink active={props && props.tab && props.tab === 'send-mail-logs'} tag={Link} to="/admin/send-mail-logs">Send Mail Logs</NavLink>
          </NavItem>
        )}
      </Nav>
    </div>
  )
}

export default Menu
