import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { Container, Form, FormGroup, Label, Button, Row, Col, Badge, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { ArrowClockwise, BinocularsFill, Boxes, Calculator, CashCoin, Download, EnvelopeExclamation, GearWideConnected, Paperclip, Send } from 'react-bootstrap-icons'
import {
  ConstantCodeMailFuzeChargeBackOwners,
  ConstantCodeMailFuzeChargeBackOwnersMethod,
  ConstantCodeMailFuzeChargeBackOwnersMarkup,
  ConstantCodeMailFuzeChargeBackOwnersMarkupAmount,
  ConstantCodeMailFuzeChargeBackOwnersMarkupAmountEnabled,
  ConstantCodeMailFuzeChargeBackOwnersMarkupPercent,
  ConstantCodeMailFuzeChargeBackOwnersMarkupPercentEnabled,
  ConstantCodeMailingHistoryCategoryID,
  ConstantCodeMailingHistoryCategoryEnabled,
  useSettingsService
} from '../../services/SettingsService'
import { ChargeOwnerMethods, LetterOptions, LetterPartTypes, useSendMailService } from '../../services/SendMailService'
import { useProspectAddressTypeService } from '../../services/ProspectAddressTypeService'
import { useProspectContactTypeService } from '../../services/ProspectContactTypeService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import SendMailResults from './SendMailResults';
import SendMailProspectsGrid from './SendMailProspectsGrid'
import { ContactTypeSelect, HistoryCategorySelect, PropertyGroupSelect, PropertySelect, ProspectStatusSelect } from './SendMailDropDowns'
import MultiSelectOption from '../dropdowns/MultiSelectOption'
import {MultiValueContainer} from '../dropdowns/MultiSelectValueContainer'
import ChargeOwner from './ChargeOwner'
//import ChargeAttachAffidavit from './ChargeAttachAffidavit'
import ChargeAttachLetter from './ChargeAttachLetter'
import MailingFields from './MailingFields'
import { CurrencyFormatter } from '../../services/CurrencyFormatter'
import authService from '../api-authorization/AuthorizeService';
import useInterval from '@use-it/interval';
import { toast } from 'react-toastify';
import { SendMailLetterOptions } from './SendMailLetterOptions'
import { useHotkeys } from 'react-hotkeys-hook'
import { useDocumentClassService } from '../../services/DocumentClassService'
import { All, Blank } from '../dropdowns/DefaultSelectOptions'
import SortablePartsHelp from './SortablePartsHelp'
import SendMailValidationResult from '../send-mail-validation/SendMailValidationResult'
import WarningBadAddress from '../send-mail-validation/WarningBadAddress'
import WarningBadOwner from '../send-mail-validation/WarningBadOwner'
import WarningInvalidDocumentDimensions from '../send-mail-validation/WarningInvalidDocumentDimensions'
import WarningHistoryCategory from '../send-mail-validation/WarningHistoryCategory'
import WarningHistoryCategoryRequired from '../send-mail-validation/WarningHistoryCategoryRequired'
import WarningLetterNumberPageOfPages from '../send-mail-validation/WarningLetterNumberPageOfPages'
import WarningInvalidClientAddress from '../send-mail-validation/WarningInvalidClientAddress'
import WarningInvalidThisPropertyAddress from '../send-mail-validation/WarningInvalidThisPropertyAddress'
import PreviewModal from '../widget/preview/PreviewModal'

//import SendMailWatermark from './SendMailWatermark'

const SendMailProspects = () => {
  
  const sendMailService = useSendMailService()
  const prospectAddressTypeService = useProspectAddressTypeService()
  const prospectContactTypeService = useProspectContactTypeService()
  const settingService = useSettingsService()
  const documentClassService = useDocumentClassService()

  const downLoadIconRef = useRef(React.createRef());
  const sendMailProspectsGridRef = useRef(null)
  const attachmentsOptionsRef = useRef(null)
  const attachmentsOptionsAttachLetterRef = useRef(null)
  const refreshButtonWrapperRef = useRef(null)

  const initialState = {
    isLoading: false,
    displayOptions: 'hidden',
    isValidationErrorsOpen: false,
    property: -1,
    propertyGroup: -1,
    prospectStatuses: [],
    useContacts: false,
    contactEnvelopOption: 'separate',
    useContactAddress: false,
    contactType: { ContactTypeID: All.value, Name: All.label },
    contactTypes: [],
    addressType: {AddressTypeID: -1, Name: '', Description: '', SortOrder: -1},
    addressTypes: [],
    documentClasses: [],
    documentClassOptions: [],
    listingSelection: [],
    listingLoadedOnce: false,
    pollingInterval: null,
    sendMailId: null,
    recipientsWithoutAddress: [],
    ownerValidationResult: {
      badRecipients: [],
      badProperties: []
    },
    documentDimensionResult: {
      validDocumentDimensions: true,
      targetDocumentDimensions: {
        documentWidth: 8.5,
        documentHeight: 11
      }
    },
    envelopeNumberOfPagesResult: {
      isDuplex: false,
      duplexSupported: false,
      simplexSupported: false,
      maxNumberOfSheets: 0,
      maxNumberOfSheetsDuplex: 0,
    },
    clientAddressValidationResult: {
      isValid: false
    },
    thisPropertyAddressValidationResult: {
      isValid: false
    },
    historyCategoryValidationResult: {
      errorMessage: null,
      exists: false,
      isActive: false,
      isDefault: false,
      isValid: false,
      name: Blank.label
    },
    historyCategoryRequiredValidationResult: {
      isBlank: false,
      isRequired: false,
      isValid: false
    }
  }

  const stateReducer = (state, action) => {
    console.log(action)
    switch (action.type) {
      case 'SET': {

        if (action.name === 'isLoading' && action.value)
        {
          //console.log('updating gridkey!!')
          return {
            ...state,
            [action.name]: action.value,
            //gridKey: nanoid()
          }
        }
        else {
          return {
            ...state,
            [action.name]: action.value
          }
        }
      }
      case 'MULTISET': {
        let values = []
        if (Array.isArray(action.value)) {
          values = action.value.map(item => item.value)
        } else {
          values.push(action.value.value)
        }

        return {
          ...state,
          [action.name]: values
        }
      }
    }

    return state
  }
  
  const [state, dispatch] = useReducer(stateReducer, initialState)
 
  const initialChargesFieldsState = {
    chargeOwner: false,
    chargeOwnerMethod: ChargeOwnerMethods.FixedAmount,
    chargeOwnerAmount: 0.0,
    chargeOwnerCostAmountMarkupDefault: null,
    chargeOwnerCostAmountMarkup: 0.0,
    chargeOwnerCostPercentMarkupDefault: null,
    chargeOwnerCostPercentMarkup: 0.0,
    //attachAffidavit: false,
    attachLetter: true,
    historyCategoryID: Blank.value,
    paymentTypeName: '',
  }

  const actionChargesFieldsReducer = (state, action) => {
    //console.log(action)
    switch (action.type) {
      case 'SET': {
        return {
          ...state,
          [action.name]: action.value,
        }
      }
      case 'MERGE': {
        return {
          ...state,
          ...action.value
        }
      }
    }

    return state
  }

  const [chargesFields, dispatchChargesFields] = useReducer(actionChargesFieldsReducer, initialChargesFieldsState)
  
  const initialLetterTemplateFieldsState = {
    letterOption: LetterOptions.Multi,
    //letterTemplate: -1,
    //letterTemplateText: '',
    //fixedFiles: [],
    letterUploading: false,

    letterParts: []
  }

  const actionLetterTemplateFieldsReducer = (state, action) => {
    switch (action.type) {
      case 'MERGE': {
        return {
          ...state,
          ...action.value
        }
      }
    }

    return state
  }

  const [letterTemplateFields, dispatchLetterTemplateFields] = useReducer(actionLetterTemplateFieldsReducer, initialLetterTemplateFieldsState)

  // const [letterTemplateFields, setLettertemplate] = useState({
  //   letterTemplate: -1,
  //   letterTemplateText: ''
  // });
  
  //const loadedCount = useRef(0)
  //const downLoadIconRef = useRef(React.createRef());
  //const [displayOptions, setDisplayOptions] = useState('hidden')
  const [sendMailResults, setSendMailResults] = useState({ showModal: false, results: []})
  const [preview, setPreview] = useState({ showModal: false, request: {}})
  const [propertyData, setPropertyData] = useState([])
  //const [loadingPropertyData, setLoadingPropertyData] = useState(false)
  const [mailingOptions, setMailingOptions] = useState(null)
  //const [gridApi, setGridApi] = useState()
  //const gridRef = useRef();
  //const [gridColumnApi, setGridColumnApi] = useState()
  const MySwal = withReactContent(Swal)
  
  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    if (state.addressType.AddressTypeID !== -1 && state.listingLoadedOnce) {
      onRefresh()
    }
  }, [state.addressType.AddressTypeID])

  useEffect(() => {
    if (propertyData && propertyData.length !== 0 && !state.listingLoadedOnce) {
      dispatch({ type: 'SET', name: 'listingLoadedOnce', value: true })
    }
  }, [propertyData])

  useHotkeys('ctrl+m', () => {
    if (attachmentsOptionsRef && attachmentsOptionsRef.current && !chargesFields.mailingHistoryCategoryEnabled) {
      attachmentsOptionsRef.current.classList.toggle('d-none');
    }

    if (attachmentsOptionsAttachLetterRef && attachmentsOptionsAttachLetterRef.current) {
      attachmentsOptionsAttachLetterRef.current.classList.toggle('d-none');
    }
  })

  // useInterval(() => {
  //   sendMailService.PollSendMailToRentManagerProspects(state.sendMailId).then(sendMailResults => {

  //     var sendMailStatusEl = document.getElementById('processingText')
  //     if (sendMailStatusEl && sendMailStatusEl.innerText !== sendMailResults.currentStep) {
  //       sendMailStatusEl.innerText = sendMailResults.currentStep
  //     }

  //     if (sendMailResults.isComplete)
  //     {
  //       setSendMailPollingData(null, null);
        
  //       PlainSwal.close()

  //       console.log(sendMailResults)
  
  //       setSendMailResults({
  //           showModal: true,
  //           success: sendMailResults.results.isSuccess,
  //           transactionLog: sendMailResults.sendMailId,
  //           results: sendMailResults.results,
  //           sendMailService
  //       })
  //     }

  //     console.log('send mail results: ', sendMailResults)
  //   }).catch(error => { console.log(error); PlainSwal.close(); setSendMailPollingData(null, null); });
  // }, state.pollingInterval);

  // const setSendMailPollingData = (pollingInterval, sendMailId) => {
  //   dispatch({ type: 'SET', name: 'pollingInterval', value: pollingInterval })
  //   dispatch({ type: 'SET', name: 'sendMailId', value: sendMailId })
  // }

  const load = async () => {
    if (state.addressTypes.length == 0 || chargesFields.paymentTypeName === '')
    {
      const [defaultPaymentTypeName, constantsResults, documentClasses, documentClassOptions, prospectAddressTypeResult, prospectContactTypeResult, historyCategoriesResult] = await Promise.all([
        sendMailService.GetDetaultPaymentTypeName(),
        settingService.GetConstants([ConstantCodeMailFuzeChargeBackOwners,
          ConstantCodeMailFuzeChargeBackOwnersMethod,
          ConstantCodeMailFuzeChargeBackOwnersMarkup,
          ConstantCodeMailFuzeChargeBackOwnersMarkupAmount,
          ConstantCodeMailFuzeChargeBackOwnersMarkupAmountEnabled,
          ConstantCodeMailFuzeChargeBackOwnersMarkupPercent,
          ConstantCodeMailFuzeChargeBackOwnersMarkupPercentEnabled,
          ConstantCodeMailingHistoryCategoryID,
          ConstantCodeMailingHistoryCategoryEnabled]),
        documentClassService.GetDocumentClasses(),
        documentClassService.GetDocumentClassOptions(),
        prospectAddressTypeService.GetProspectAddressTypes(),
        prospectContactTypeService.GetProspectContactTypes(),
        settingService.GetMailingHistoryCategoryIDSelectOptions()
      ])

      const constantCodes = constantsResults.map(constant => constant.value)
      const clientSettings = await settingService.GetClientSettings(constantCodes)
      //console.log('clientSettings', clientSettings)

      const mailFuzeChargeBackOwners = getConstant(constantsResults, clientSettings, ConstantCodeMailFuzeChargeBackOwners, (valStr) => {
        return valStr.toLowerCase() === 'true'
      })
      const mailFuzeChargeBackOwnersMethod = getConstant(constantsResults, clientSettings, ConstantCodeMailFuzeChargeBackOwnersMethod, (valStr) => {
        return !isNaN(valStr) ? parseInt(valStr) : -1
      })
      const mailFuzeChargeBackOwnersMarkup = getConstant(constantsResults, clientSettings, ConstantCodeMailFuzeChargeBackOwnersMarkup, (valStr) => {
        return valStr.toLowerCase() === 'true'
      })
      const mailFuzeChargeBackOwnersMarkupAmount = getConstant(constantsResults, clientSettings, ConstantCodeMailFuzeChargeBackOwnersMarkupAmount, (valStr) => {
        return !isNaN(valStr) ? parseFloat(valStr) : ''
      })
      const mailFuzeChargeBackOwnersMarkupAmountEnabled = getConstant(constantsResults, clientSettings, ConstantCodeMailFuzeChargeBackOwnersMarkupAmountEnabled, (valStr) => {
        return valStr.toLowerCase() === 'true'
      })
      const mailFuzeChargeBackOwnersMarkupPercent = getConstant(constantsResults, clientSettings, ConstantCodeMailFuzeChargeBackOwnersMarkupPercent, (valStr) => {
        return !isNaN(valStr) ? parseFloat(valStr) : ''
      })
      const mailFuzeChargeBackOwnersMarkupPercentEnabled = getConstant(constantsResults, clientSettings, ConstantCodeMailFuzeChargeBackOwnersMarkupPercentEnabled, (valStr) => {
        return valStr.toLowerCase() === 'true'
      })
      const mailingHistoryCategoryID = getConstant(constantsResults, clientSettings, ConstantCodeMailingHistoryCategoryID, (valStr) => {
        return !isNaN(valStr) ? parseInt(valStr) : Blank.value
      })
      const mailingHistoryCategoryEnabled = getConstant(constantsResults, clientSettings, ConstantCodeMailingHistoryCategoryEnabled, (valStr) => {
        return valStr.toLowerCase() === 'true'
      })

      const sortedAddressTypes = prospectAddressTypeResult.sort((a, b) => a.AddressTypeID - b.AddressTypeID);
      const firstAddressType = sortedAddressTypes[0]

      //copy sorted types to blank array
      state.addressTypes.push.apply(state.addressTypes, sortedAddressTypes)

      //copy document classes to blank array
      state.documentClasses.push.apply(state.documentClasses, documentClasses)
      state.documentClassOptions.push.apply(state.documentClassOptions, documentClassOptions)

      //console.log(sortedAddressTypes)
      if (prospectContactTypeResult) {
        state.contactTypes.push.apply(state.contactTypes, prospectContactTypeResult)
      } else {
        state.contactTypes.push.apply(state.contactTypes, [ state.contactType ])
      }

      //dispatch({ type: 'SET', name: 'addressTypes', value: sortedAddressTypes })
      dispatch({ type: 'SET', name: 'addressType', value: firstAddressType })
      dispatch({ type: 'SET', name: 'displayOptions', value: 'visible' })
      dispatchChargesFields({ type: 'SET', name: 'paymentTypeName', value: defaultPaymentTypeName.value })
      dispatchChargesFields({ type: 'SET', name: 'mailFuzeChargeBackOwners', value: mailFuzeChargeBackOwners })
      dispatchChargesFields({ type: 'SET', name: 'mailFuzeChargeBackOwnersMethod', value: mailFuzeChargeBackOwnersMethod })
      dispatchChargesFields({ type: 'SET', name: 'mailFuzeChargeBackOwnersMarkup', value: isNaN(mailFuzeChargeBackOwnersMarkup) ? 0 : mailFuzeChargeBackOwnersMarkup })
      dispatchChargesFields({ type: 'SET', name: 'chargeOwnerCostAmountMarkupDefault', value: isNaN(mailFuzeChargeBackOwnersMarkupAmount) ? 0 : mailFuzeChargeBackOwnersMarkupAmount })
      dispatchChargesFields({ type: 'SET', name: 'chargeOwnerCostAmountMarkup', value: isNaN(mailFuzeChargeBackOwnersMarkupAmount) ? 0 : mailFuzeChargeBackOwnersMarkupAmount }) //assign default
      dispatchChargesFields({ type: 'SET', name: 'mailFuzeChargeBackOwnersMarkupAmountEnabled', value: mailFuzeChargeBackOwnersMarkupAmountEnabled })
      dispatchChargesFields({ type: 'SET', name: 'chargeOwnerCostPercentMarkupDefault', value: isNaN(mailFuzeChargeBackOwnersMarkupPercent) ? 0 : mailFuzeChargeBackOwnersMarkupPercent })
      dispatchChargesFields({ type: 'SET', name: 'chargeOwnerCostPercentMarkup', value: isNaN(mailFuzeChargeBackOwnersMarkupPercent) ? 0 : mailFuzeChargeBackOwnersMarkupPercent }) //assign default
      dispatchChargesFields({ type: 'SET', name: 'mailFuzeChargeBackOwnersMarkupPercentEnabled', value: mailFuzeChargeBackOwnersMarkupPercentEnabled })
      dispatchChargesFields({ type: 'SET', name: 'historyCategoryID', value: mailingHistoryCategoryID })
      dispatchChargesFields({ type: 'SET', name: 'historyCategoryOptions', value: historyCategoriesResult })
      dispatchChargesFields({ type: 'SET', name: 'mailingHistoryCategoryEnabled', value: mailingHistoryCategoryEnabled })
    }
  }

  const getConstant = (constantsResults, clientSettings, constantName, conversionFunc) => {
    let constant = constantsResults.find(constant => constant.name === constantName)
    let setting = clientSettings.find(setting => setting.constantCode === constant.value)
    const constantValueStr = setting.constantValue

    return conversionFunc(constantValueStr)
  }

  const PlainSwal = MySwal.mixin({
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    }
  })

  const onShowLoading = () => {
    dispatch({ type: 'SET', name: 'isLoading', value: true })
  }

  const onHideOverlay = () => {
    dispatch({ type: 'SET', name: 'isLoading', value: false })
  }

  const onRefresh = (/*e*/) => {
    //e.preventDefault()
    //setRefreshHitOnce(true)
    onShowLoading()
    //setLoadingPropertyData(true)
    populatePropertyData()
  }

  const onCloseResults = () => {
    setSendMailResults({showModal: false, results: []})
  }

  const onClosePreview = () => {
    setPreview({showModal: false, results: {}})
  }

  const onDataLoadError = (response) => {
    console.log('----------------------')
    console.log(response)
  }

  //onAddressTypeChanged, onUseContactsChanged, onUseContactAddressChanged, onContactTypeChanged, onContactEnvelopOptionChanged
  const refreshReminder = useCallback(() => {
    if (refreshButtonWrapperRef && refreshButtonWrapperRef.current) {
      const children = refreshButtonWrapperRef.current.children
      const filtered = [].filter.call(children, function (el) {
        var style = window.getComputedStyle(el);
        return (style.display !== 'none')
      });
      const target = filtered[0].children[0]
      target.classList.add('reminder-pulse-big')
      setTimeout(() => {
        target.classList.remove('reminder-pulse-big')
      }, 1100)
    }
  }, [refreshButtonWrapperRef])

  const onAddressTypeChanged = useCallback((selectedAddressType) => {
    console.log('Got selected address type in SendMail', selectedAddressType)
    dispatch({ type: 'SET', name: 'addressType', value: selectedAddressType })
    refreshReminder()
  }, [refreshReminder])

  const onContactTypeChanged = useCallback((selectedContactType) => {
    console.log('Got selected contact type in SendMail', selectedContactType)
    dispatch({ type: 'SET', name: 'contactType', value: selectedContactType })
    refreshReminder()
  }, [refreshReminder])

  const onUseContactAddressChanged = useCallback((e) => {
    console.log('Change option value useContactAddress', e.target.checked)
    dispatch({ type: 'SET', name: 'useContactAddress', value: e.target.checked })
    refreshReminder()
  }, [refreshReminder])

  const onContactEnvelopOptionChanged = useCallback((value) => {
    console.log('Change option value contactEnvelopOption', value)
    dispatch({ type: 'SET', name: 'contactEnvelopOption', value: value })
    refreshReminder()
  }, [refreshReminder])

  const onUseContactsChanged = useCallback((e) => {
    console.log('Change option value useContacts', e.target.checked)
    dispatch({ type: 'SET', name: 'useContacts', value: e.target.checked })
    refreshReminder()
  }, [refreshReminder])

  const onMailingFieldsChange = useCallback((newMailingOptions) => {
    console.log('Mailing Fields Changed: ', newMailingOptions)
    setMailingOptions(newMailingOptions)
    refreshReminder()
  }, [refreshReminder])

  const onSelectionChanged = useCallback((selectedData) => {
    //console.log(selectedData)
    dispatch({ type: 'SET', name: 'listingSelection', value: selectedData })
  }, [])

  const populatePropertyData = async () => {
    const request = { 
      propertyGroup: state.propertyGroup,
      property: state.property,
      prospectStatuses: state.prospectStatuses,
      addressType: state.addressType.AddressTypeID,
      useContacts: state.useContacts,
      useContactAddress: state.useContactAddress,
      contactType: state.contactType.ContactTypeID,
    };

    const data = await sendMailService.GetPropertyProspectsData(request);
    console.log(data)
    setPropertyData(data)
    //setLoadingPropertyData(false)
    onHideOverlay()
  }

  const getGridSelection = () => {
    return state.listingSelection;
  }

  const inputWarning = (title, text) => {
    PlainSwal.fire({
      title,
      icon: 'warning',
      text,
      showCloseButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary btn-lg'
      }
    })
  }

  const showError = (title, text) => {
    PlainSwal.fire({
      title,
      icon: 'error',
      text,
      showCloseButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary btn-lg'
      }
    })
  }

  const validateInputs = (sanitizedLetterTemplateFields = undefined, needsRecipients = true, skipSendMailChecks = false) => {
    const selection = getGridSelection()

    console.log('validateInputs', sanitizedLetterTemplateFields, needsRecipients, skipSendMailChecks)

    if (!sanitizedLetterTemplateFields) {
      sanitizedLetterTemplateFields = getSanitizedLetterTemplateFields()
    }

    console.log(selection)
    if (needsRecipients)
    {
      if (!selection || selection.length === 0) {
        inputWarning('No Recipients Selected', 'You must select at least one mail recipient.')
        return -1
      }
    }
    
    if (!skipSendMailChecks)
    {
      // ' All selects must have a valid selection
      if (!mailingOptions) {
        inputWarning(`Invalid Mailing Type`, `You must choose a Mailing Type from the list.`)
        return -2      
      }

      //mailingOptions.envelopeHasOptions = true

      let selectName = null
      let selectLabel = null
      let hasError = false
      Object.entries(mailingOptions).forEach(([key, value]) => {
        if (!hasError) {
          selectName = key.replace('HasOptions', '')
          if (key.endsWith('HasOptions') && value === true && (mailingOptions[selectName] === null || mailingOptions[selectName] === 0)) {
            selectLabel = mailingOptions[selectName + 'Label']
            inputWarning(`Invalid ${selectLabel}`, `You must choose a ${selectLabel} from the list.`)
            hasError = true
          }
        }
      })

      if (hasError)
        return -2

      if (chargesFields.chargeOwner) {
        if (chargesFields.chargeOwnerMethod === ChargeOwnerMethods.FixedAmount && (isNaN(chargesFields.chargeOwnerAmount) || chargesFields.chargeOwnerAmount <= 0)) {
          inputWarning('Invalid Property Charge', 'You must enter a valid amount to charge the property.')
          return -3
        }
        else if (chargesFields.chargeOwnerMethod === ChargeOwnerMethods.CostAmountMarkup && (isNaN(chargesFields.chargeOwnerCostAmountMarkup) || chargesFields.chargeOwnerCostAmountMarkup <= 0)) {
          inputWarning('Invalid Property Charge Markup', 'You must enter a valid cost + $ markup to charge the property.')
          return -4
        }
        else if (chargesFields.chargeOwnerMethod === ChargeOwnerMethods.CostPercentMarkup && (isNaN(chargesFields.chargeOwnerCostPercentMarkup) || chargesFields.chargeOwnerCostPercentMarkup <= 0)) {
          inputWarning('Invalid Property Charge Markup', 'You must enter a valid cost + % markup to charge the property.')
          return -5
        }
      }
    }
    //console.log('letter option', letterTemplateFields.letterOption)

    //console.log('sanitizedLetterTemplateFields.letterParts', sanitizedLetterTemplateFields.letterParts)

    //const sanitizedLetterTemplateFields = getSanitizedLetterTemplateFields()
    if (!sanitizedLetterTemplateFields.letterParts || sanitizedLetterTemplateFields.letterParts.length === 0) {
      inputWarning('Invalid Document', 'You must add at least one document using an option from the "Add document" drop down.')
      return -7
    }

    let partError = 0;
    let index = 0;
    do {
      const part = sanitizedLetterTemplateFields.letterParts[index]

      console.log('part', part)
      if (part.type === LetterPartTypes.RentManager && part.letterTemplate === -1) {
        inputWarning('Invalid Rent Manager Template', 'You must select a template from the Rent Manager template list.')
        partError = -8
      }
  
      if (part.type === LetterPartTypes.Fixed && !part.hasDocument) {
        inputWarning('Invalid Static PDF', 'You must upload a Static PDF.')
        partError = -9
      }

      index++
      
    } while (index < sanitizedLetterTemplateFields.letterParts.length && partError === 0)

    if (partError !== 0) {
      return partError;
    }


    return 0
  }

  const getSanitizedLetterTemplateFields = () => {
    return sendMailService.GetSanitizedLetterTemplateFields(letterTemplateFields)
  }

  const onCostEstimate = async (e) => {
    if (validateInputs() === 0) {
      const selectedData = getGridSelection()
      console.log(selectedData)
      const selectedProspectIds = selectedData.map(node => node.prospectID)

      PlainSwal.fire({
        title: 'Calculating Cost Estimate',
        allowEscapeKey: false,
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          PlainSwal.showLoading()
        },
      })
      
      const body = {
        recipients: selectedProspectIds,
        ...getSanitizedLetterTemplateFields(),
        ...mailingOptions,
        paymentTypeName: chargesFields.paymentTypeName
      }

      sendMailService.GetMailingEstimates(body).then(estimateResult => {
        PlainSwal.close()

        if (!showEstimateError(estimateResult)) {
          console.log('estimateResult', estimateResult)
          const estimate = estimateResult.estimate
          const letterInfo = estimateResult.letterInfo
          const batchInfo = estimateResult.batchInfo
          const sPageSuffix = letterInfo.documentPageCount === 1 ? '' : 's'
          const pageCountWarning = letterTemplateFields.letterParts.find(part => part.type === LetterPartTypes.RentManager) ? ' or any indivdiual documents are more than ' + letterInfo.documentPageCount + ' page' + sPageSuffix : ''
          const splitBatchWarning = batchInfo && !batchInfo.useBatching ? '' : 'Due to the number of recipients and/or the size of your file, the ' + batchInfo.recipientCount + ' mailings will be split into ' + batchInfo.batchCount + ' smaller batches.'

          PlainSwal.fire({
              title: 'Mailing Cost Estimate',
              html: '<div style="text-align: left"><span class="fw-bold">Pages in document: </span>' + letterInfo.documentPageCount + '<br />' +
                  '<span class="fw-bold">Cost for each recipient: </span>' + CurrencyFormatter.format(estimate.perRecipientCost) + '<br />' +
                  '<span class="fw-bold">Number of recipients: </span>' + selectedProspectIds.length + '<br />' +
                  '<span class="fw-bold">Total cost of mailing: </span>' + CurrencyFormatter.format(estimate.totalCost) + '</div>' +
                  '<div style="color: red; margin-top: 1em">NOTE: This is an ESTIMATE only and cost could be higher if any addresses are non-standard' +
                  pageCountWarning + '.</span>' +
                  (splitBatchWarning === '' ? '' : '<div style="color: red; margin-top: 1em">NOTE: ' + splitBatchWarning + '</div>'),
              showCloseButton: true,
              buttonsStyling: false,
              customClass: {
                  confirmButton: 'btn btn-primary btn-lg'
              },
          })
        }
      }).catch(error => { PlainSwal.close(); showEstimateError(error); })
    }
  }

  const onPreviewOne = async (e) => {
    return onPreview(e, getSanitizedLetterTemplateFields(), 1)
  }

  // const onPreviewTen = async (e) => {
  //   return onPreview(e, 10)
  // }

  const onPreviewAll = async (e) => {
    return onPreview(e, getSanitizedLetterTemplateFields())
  }

  const onPreviewN = async (e) => {
    return onPreview(e, getSanitizedLetterTemplateFields(), 30)
  }

  const handlePreviewMailingPart = async (id, previewCount) => {
    const sanitizedLetterTemplateFields = getSanitizedLetterTemplateFields()
    console.log('handlePreviewMailingPart', id)
    console.log('handlePreviewMailingPart', sanitizedLetterTemplateFields)

    const index = letterTemplateFields.letterParts.findIndex(part => part.id === id)
    console.log('handlePreviewMailingPart index', index)
    const remainder = sanitizedLetterTemplateFields.letterParts.filter((item, i) => i === index)

    sanitizedLetterTemplateFields.letterParts = remainder

    let needsRecipients = true
    let skipSendMailChecks = true
    if (remainder[0].type === LetterPartTypes.Fixed) {
      needsRecipients = false
    }

    return onPreview(null, sanitizedLetterTemplateFields, previewCount, needsRecipients, skipSendMailChecks)
  }

  const onPreview = async (e, sanitizedLetterTemplateFields, previewCount = 0, needsRecipients = true, skipSendMailChecks = false) => {
    if (validateInputs(sanitizedLetterTemplateFields, needsRecipients, skipSendMailChecks) === 0) {
      const selectedData = getGridSelection()
      const selectedProspectIds = selectedData.reduce((accumulator, node, index) => {
        if (previewCount === 0 || index <= previewCount - 1)
        {
          accumulator.push(node.prospectID);
        }
        return accumulator
      }, [])

      let documentClass = -1
      if (mailingOptions && mailingOptions.documentClass !== 0) {
        documentClass = mailingOptions.documentClass
      }

      const body = {
        recipients: selectedProspectIds,
        documentClass: documentClass,
        previewCount: previewCount,
        ...sanitizedLetterTemplateFields
      }

      console.log('preview body', body)

      // PlainSwal.fire({
      //   title: 'Generating Preview',
      //   allowEscapeKey: false,
      //   allowOutsideClick: false,
      //   timerProgressBar: true,
      //   didOpen: () => {
      //     PlainSwal.showLoading()
      //   },
      // })

      //const result = await sendMailService.GetPreview(body)
      //const authToken = await authService.getAccessToken()

      setPreview({ showModal: true, request: body})

      //PlainSwal.close()

      //console.log(result)

      // const dl = PlainSwal.fire({
      //   title: 'Your Preview is Ready',
      //   html: downLoadIconRef.current.outerHTML.replace('href="#"', `href="${result.returnDataAsString}" authtoken="${authToken}" onClick="openPdf(event)" id="downloadBtn"`),
      //   showCloseButton: true,
      //   allowEscapeKey: false,
      //   allowOutsideClick: false,
      //   buttonsStyling: false,
      //   confirmButtonText: 'Close',
      //   customClass: {
      //     confirmButton: 'btn btn-dark'
      //   },
      //   didOpen: function(ele) {
      //     ele.querySelector('#downloadBtn').addEventListener('click', function(){ dl.close(); }, false);
      //   }
      // })
    }
  }

  const onSend = async (e) => {
    if (validateInputs() === 0) {
      const selectedData = getGridSelection()
      console.log(selectedData)
      
      const selectedRequestData = selectedData.map(data => { return { 
        ProspectID: data.prospectID,
        OwnerID: data.ownerID,
        Owner: data.owner,
        PropertyID: data.propertyID,
        Property: data.property,
        ContactID: state.useContacts && state.contactEnvelopOption !== 'same' ? data.contactID: -1,
        ContactTypeID: state.useContacts ? data.contactTypeID : -1,
        ContactType: state.useContacts ? data.contactType : null,
        UnitID: data.unitID,
        Unit: data.unit,
        FirstName: data.firstName,
        LastName: data.lastName,
        Street: data.street,
        City: data.city,
        State: data.state,
        Zip: data.zip //data['ZIP']
      }})

      const selectedRecipientIds = selectedRequestData.map(data => data.ProspectID)

      //const estimateResult = await onSendEstimate(selectedProspectIds)
      const validationResult = await onValidation(selectedRecipientIds, selectedRequestData)
      if (!validationResult.hasEstimateError) {
        if (!showValidationError(validationResult)){
          console.log('Validation completed successufully!', validationResult);
  
          if (validationResult.hasError) {
            console.log('Validation yielded errors!');
  
            console.log('sendMailResults:', validationResult)
            dispatch({ type: 'SET', name: 'isValidationErrorsOpen', value: true })
            dispatch({ type: 'SET', name: 'historyCategoryValidationResult', value: validationResult.historyCategoryValidationResult })
            dispatch({ type: 'SET', name: 'historyCategoryRequiredValidationResult', value: validationResult.historyCategoryRequiredValidationResult })
            dispatch({ type: 'SET', name: 'recipientsWithoutAddress', value: validationResult.badAddresses })
            dispatch({ type: 'SET', name: 'ownerValidationResult', value: validationResult.ownerValidationResult })
            dispatch({ type: 'SET', name: 'documentDimensionResult', value: validationResult.documentDimensionResult })
            dispatch({ type: 'SET', name: 'envelopeNumberOfPagesResult', value: validationResult.envelopeNumberOfPagesResult })
            dispatch({ type: 'SET', name: 'clientAddressValidationResult', value: validationResult.clientAddressValidationResult })
            dispatch({ type: 'SET', name: 'thisPropertyAddressValidationResult', value: validationResult.thisPropertyAddressValidationResult })
          } else {
            const estimate = validationResult.estimateResult.estimate
            const batchInfo = validationResult.estimateResult.batchInfo
            const splitBatchWarning = batchInfo && !batchInfo.useBatching ? '' : 'Due to the number of recipients and/or the size of your file, the ' + batchInfo.recipientCount + ' mailings will be split into ' + batchInfo.batchCount + ' smaller batches.'
            
            PlainSwal.fire({
              title: 'Confirm Mailing Cost Estimate',
              html:  'The total ESTIMATED cost of this mailing for ' + selectedRecipientIds.length + 
                      ' prospect' + (selectedRecipientIds.length === 1 ? '' : 's') + ' is ' + CurrencyFormatter.format(estimate.totalCost) + '.' +
                      (splitBatchWarning === '' ? '' : '<div style="color: red; margin-bottom: 1em; margin-top: 1em">NOTE: ' + splitBatchWarning + '</div>') +
                      '<p class="fw-bold">Are you sure you want to do this?</p>',
              showCloseButton: true,
              showCancelButton: true,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-primary btn-lg me-1',
                cancelButton: 'btn btn-secondary btn-lg ms-1'
              },
              confirmButtonText: 'Confirm',
            }).then(async (result) => {
              if (result.isConfirmed) {
                onSendEstimateConfirmed(selectedRequestData, validationResult.estimateResult)
              }
            })       
          }
        }
      }
    }
  }

  const onValidation = async (selectedRecipientIds, selectedRequestData) => {
    PlainSwal.fire({
      title: 'Validating Submission',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        PlainSwal.showLoading()
      },
    })
    
    const estimateBody = {
      recipients: selectedRecipientIds,
      ...getSanitizedLetterTemplateFields(),
      ...mailingOptions,
      paymentTypeName: chargesFields.paymentTypeName,
    }

    const estimateResult = await sendMailService.GetMailingEstimates(estimateBody).catch(error => { PlainSwal.close(); showEstimateError(error); });
    
    if (!showEstimateError(estimateResult)) {

      const sendMailBody = {
        recipients: selectedRequestData,
        ...getSanitizedLetterTemplateFields(),
        ...mailingOptions,
        ...chargesFields,
        estimate: estimateResult,
        useContacts: state.useContacts,
        useContactAddress: state.useContactAddress,
        contactType: state.contactType.ContactTypeID,
        contactTypeText: state.contactType.Name,
      }

      const validationResult = await sendMailService.ValidateSendMailToRentManagerProspects(sendMailBody).catch(error => { PlainSwal.close(); showValidationError(error); });

      PlainSwal.close()

      return {
        ...validationResult,
        estimateResult
      }
    }

    return {
      hasEstimateError: true
    }
  }

  // const onSendEstimate = async (selectedProspectIds) => {
  //   PlainSwal.fire({
  //     title: 'Calculating Cost Estimate',
  //     allowEscapeKey: false,
  //     allowOutsideClick: false,
  //     timerProgressBar: true,
  //     didOpen: () => {
  //       PlainSwal.showLoading()
  //     },
  //   })

  //   // console.log(selectedProspectIds)
  //   // console.log(letterTemplateFields)
  //   // console.log(mailingOptions)

  //   const estimateBody = {
  //     recipients: selectedProspectIds,
  //     ...getSanitizedLetterTemplateFields(),
  //     ...mailingOptions,
  //     paymentTypeName: chargesFields.paymentTypeName
  //   }

  //   console.log('estimateBody', estimateBody)

  //   const result = await sendMailService.GetMailingEstimates(estimateBody).catch(error => { PlainSwal.close(); showEstimateError(error); });

  //   PlainSwal.close()

  //   return result;
  // }

  const showEstimateError = (estimateResult) => {
    if (!estimateResult || estimateResult.returnCode !== 0) {
      showError("Error Getting Cost Estimate", estimateResult && estimateResult.errorMessage)
      console.log(estimateResult)
      return true
    }
    
    return false
  }

  const showValidationError = (validationResult) => {
    if (!validationResult || !validationResult.isSuccess) {
      if (validationResult && validationResult.hasEstimateError) {
        return false
      }

      showError("Error Occurred During Validation", validationResult && validationResult.errorMessage)
      console.log(validationResult)
      return true
    }
    
    return false
  }

  const onSendEstimateConfirmed = async (selectedRequestData, estimateResults) => {
    console.log('Confirmed')

    // PlainSwal.fire({
    //   title: 'Prospect Coming Soon',
    //   html: '<div id="processingText"><img src="https://c.tenor.com/hYVsWvkpdrMAAAAC/you-didnt-say-the-magic-word-ah-ah.gif" /></div>',
    //   showCloseButton: true,
    //   allowEscapeKey: true,
    //   allowOutsideClick: true,
    //   timerProgressBar: false
    // })

    // return;

    const sendMailBody = {
      recipients: selectedRequestData,
      ...getSanitizedLetterTemplateFields(),
      ...mailingOptions,
      ...chargesFields,
      estimate: estimateResults,
      useContacts: state.useContacts,
      useContactAddress: state.useContactAddress,
      contactType: state.contactType.ContactTypeID,
      contactTypeText: state.contactType.Name
    }

    PlainSwal.fire({
      title: 'Processing',
      html: '<div id="processingText">Submitting</div>',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        PlainSwal.showLoading()
      },
    })

    // toast.info('Submitting', {
    //   toastId: 'submitting-toast-id',
    //   position: "bottom-right",
    //   hideProgressBar: false,
    //   closeOnClick: false,
    //   pauseOnHover: true,
    //   draggable: false,
    //   progress: undefined,
    // })

    sendMailService.CallSendMailToRentManagerProspects(sendMailBody).then(sendMailResults => {

      // if (sendMailResults.isSuccess) {
      //   setSendMailPollingData(5000, sendMailResults.sendMailId);
      // }
      // else {
      //   PlainSwal.close()
      // }

      PlainSwal.close()
      if (sendMailResults.isSuccess) {
        // toast.update('submitting-toast-id', {
        //   closeOnClick: true,
        //   //render: 'Send Mail Submitted',
        //   //type: toast.TYPE.SUCCESS,
        //   autoClose: 2000
        // });

        toast.success('Send Mail Submitted', {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        })
      }
      else {
        // toast.update('submitting-toast-id', {
        //   closeOnClick: true,
        //   //render: 'Send Mail Failed',
        //   //type: toast.TYPE.ERROR,
        //   autoClose: 2000
        // });

        console.log('sendMailResults:', sendMailResults)
        dispatch({ type: 'SET', name: 'isValidationErrorsOpen', value: true })
        dispatch({ type: 'SET', name: 'historyCategoryValidationResult', value: sendMailResults.historyCategoryValidationResult })
        dispatch({ type: 'SET', name: 'historyCategoryRequiredValidationResult', value: sendMailResults.historyCategoryRequiredValidationResult })
        dispatch({ type: 'SET', name: 'recipientsWithoutAddress', value: sendMailResults.badAddresses })
        dispatch({ type: 'SET', name: 'ownerValidationResult', value: sendMailResults.ownerValidationResult })
        dispatch({ type: 'SET', name: 'documentDimensionResult', value: sendMailResults.documentDimensionResult })
        dispatch({ type: 'SET', name: 'envelopeNumberOfPagesResult', value: sendMailResults.envelopeNumberOfPagesResult })
        dispatch({ type: 'SET', name: 'clientAddressValidationResult', value: sendMailResults.clientAddressValidationResult })
        dispatch({ type: 'SET', name: 'thisPropertyAddressValidationResult', value: sendMailResults.thisPropertyAddressValidationResult })

        // toast.error('Send Mail Failed', {
        //   position: "bottom-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: false,
        //   progress: undefined,
        // })
      }
    }).catch(error => {
      console.log(error);
      PlainSwal.close()
      // toast.update('submitting-toast-id', {
      //   closeOnClick: true,
      //   //render: 'Send Mail Failed',
      //   //type: toast.TYPE.ERROR,
      //   autoClose: 5000
      // });

      toast.error('Send Mail Failed', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    });

    // sendMailService.CallSendMail(sendMailBody).then(sendMailResults => {
    //   PlainSwal.close()

    //   console.log(sendMailResults)

    //   setSendMailResults({
    //       showModal: true,
    //       success: sendMailResults.success,
    //       transactionLog: sendMailResults.transactionLog,
    //       results: sendMailResults.results,
    //       sendMailService
    //   })
    // }).catch(error => { console.log(error); PlainSwal.close() });
  }

  const onUnselectBadAddress = async () => {
    return await onUnselect(async () => {
      return await sendMailProspectsGridRef.current.unselectRows(state.recipientsWithoutAddress.map(r => parseInt(r.externalRecipientID)))
    })
  }

  const onUnselectBadOwner = async () => {
    return await onUnselect(async () => {
      return await sendMailProspectsGridRef.current.unselectRows(state.ownerValidationResult.badRecipients.map(r => parseInt(r.externalRecipientID)))
    })
  }

  const onUnselect = async (unselectFunc) => {

    //console.log('onUnselect called')

    if (sendMailProspectsGridRef && sendMailProspectsGridRef.current) {
      console.log('call imperative unselectRows on current')
      console.log(state.recipientsWithoutAddress)
      console.log(state.recipientsWithoutAddress.map(r => parseInt(r.externalRecipientID)))
      //const removedCount = await sendMailProspectsGridRef.current.unselectRows(state.recipientsWithoutAddress.map(r => parseInt(r.externalRecipientID)))
      const removedCount = await unselectFunc()
      if (removedCount !== 0)
      {
        let s = removedCount == 1 ? '' : 's'
        toast.success(`${removedCount} Prospect${s} Removed From Selection`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        })
      } else {
        toast.warning(`No Prospects Removed`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        })
      }
    }
  }

  const onTestShowResults = () => {
    setSendMailResults({
      sendMailService,
      showModal: true,
      success: false,
      transactionLog: '7e25daf5-d03d-409e-a3b3-69f477e8e8d9',
      results: [
        {
            "isSuccess": false,
            "apiResponse": "Failure",
            "itemMessage": "Create invoice for ",
            "conclusionMessage": "HTTP Status: InternalServerError\r\nError Code: -2147024809\r\nDeveloper Message: A valid account is required.\r\nException: LcsArgumentException\r\nMoreInfoUri: https://efformance.api.rentmanager.com/Help/Resource/Invoices\r\nUser Message: Unspecified Error\r\nMessage: ",
            "mailResponse": null,
            "recipient": null
        }
      ]
    })
  }

  return (
    <div>
      <div className="d-none">
        <div ref={downLoadIconRef}>
          <Button color="primary" className="btn-lg" href="#"><Download /> Download Preview</Button>
        </div>
      </div>
      {/* <Container style={{maxWidth: '2000px', width: '1600px'}}> */}
      <Container>
        <Row style={{visibility: state.displayOptions}}>
          <Col md={12} lg={9}>
            <Form>
              <Row>
                <Row>
                  {/* <Col md={12} lg={1}>
                    <SendMailWatermark watermark="Prospects">
                      <BookmarkPlus className="prospects" size={150} />
                    </SendMailWatermark>
                  </Col> */}
                  <Col md={12} lg={7}>
                    <FormGroup row>
                      <Label htmlFor="propertyGroup" sm={12} md={2}>Group:</Label>
                      <Col sm={12} md={10}>
                        <PropertyGroupSelect
                          id="propertyGroup" 
                          name="propertyGroup"
                          onError={onDataLoadError}
                          onLoaded={value => dispatch({ type: 'LOADED', name: 'propertyGroup', value })}
                          onChange={selected => dispatch({ type: 'SET', name: 'propertyGroup', value: selected.value })}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label htmlFor="property" sm={12} md={2}>Property:</Label>
                      <Col sm={12} md={10}>
                        <PropertySelect
                          id="property"
                          name="property"
                          propertyGroup={state.propertyGroup}
                          onLoaded={value => dispatch({ type: 'LOADED', name: 'property', value })}
                          onChange={selected => dispatch({ type: 'SET', name: 'property', value: selected.value })}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={12} lg={5}>
                    <FormGroup row>
                      <Label sm={12} md={2}>Status:</Label>
                      <Col sm={12} md={10}>
                        <ProspectStatusSelect
                            id="prospectStatuses"
                            name="prospectStatuses"
                            isMulti={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            allowSelectAll={false}
                            allSelected={true}
                            customStyles={{
                              valueContainer: (provided, state) => ({
                                ...provided,
                                textOverflow: "ellipsis",
                                maxWidth: "80%",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                display: "initial"
                              }),
                              input: (provided, state) => ({
                                  ...provided,
                                  minWidth: '20%'
                              })
                            }}
                            components={{
                              Option: MultiSelectOption,
                              //valueContainer: MultiSelectValueContainer, 
                              MultiValueContainer: MultiValueContainer
                            }}
                            onLoaded={value => dispatch({ type: 'LOADED', name: 'prospectStatuses', value })}
                            onChange={(selected, action) => {
                              console.log(selected)
                              console.log(action)
                              dispatch({ type: 'MULTISET', name: 'prospectStatuses', value: selected })
                            }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col xs={12} className="text-end mt-sm-0">
                        <div ref={refreshButtonWrapperRef}>
                          <div>
                            <Button color="dark" onClick={onRefresh}><ArrowClockwise /> Refresh</Button>
                          </div>
                        </div>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <FormGroup row>

                  </FormGroup>
                </Row>
              </Row>
            </Form>
          
            <Row>
              <Col>
                <SendMailProspectsGrid
                  ref={sendMailProspectsGridRef}
                  isLoading={state.isLoading}
                  listingData={propertyData}
                  addressTypes={state.addressTypes}
                  currentAddressType={state.addressType}
                  useContacts={state.useContacts}
                  onUseContactsChanged={onUseContactsChanged}
                  contactEnvelopOption={state.contactEnvelopOption}
                  onContactEnvelopOptionChanged={onContactEnvelopOptionChanged}
                  useContactAddress={state.useContactAddress}
                  onUseContactAddressChanged={onUseContactAddressChanged}
                  contactTypes={state.contactTypes}
                  currentContactType={state.contactType}
                  onContactTypeChanged={onContactTypeChanged}
                  //addressTypes={filterFields.addressTypes}
                  //onAddressTypeChanged={(selectedAddressType) => dispatchFilterFields({ type: 'SET', name: 'addressType', value: selectedAddressType })}
                  onAddressTypeChanged={onAddressTypeChanged}
                  onSelectionChanged={onSelectionChanged}
                />
              </Col>
            </Row>
          </Col>
          <Col md={12} lg={3}>
            <div className="border ml-3 p-3" style={{flex: '0 0 auto', maxWidth: '100%', height: '100%'}}>
              <Row>
                <Col>
                  <fieldset>
                    <legend><div className='d-flex flex-row justify-content-between'><div><Badge><EnvelopeExclamation /></Badge> Documents</div><SortablePartsHelp /></div></legend>
                    <hr />
                    <SendMailLetterOptions
                        letterTemplateType='LetterTemplateTypeProspect'
                        initialValues={letterTemplateFields}
                        onChange={value => dispatchLetterTemplateFields({ type: 'MERGE', value })}
                        onPreviewMailingPart={handlePreviewMailingPart}
                    >
                    </SendMailLetterOptions>
                  </fieldset>
                </Col>
              </Row>
              <Row>
                <Col>
                  <hr style={{ zIndex: -1, position: 'relative' }} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <fieldset className="mt-3">
                    <legend><Badge><GearWideConnected /></Badge> Mail Options</legend>
                    <hr />
                    <MailingFields
                      onChange={onMailingFieldsChange}
                      documentClasses={state.documentClasses}
                      documentClassOptions={state.documentClassOptions}
                    />
                  </fieldset>
                </Col>
              </Row>
              <Row>
                <Col className="w-100 text-center">
                  <Button color="dark" onClick={onCostEstimate} disabled={letterTemplateFields.letterUploading}><Calculator /> Cost Estimate</Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <hr />
                </Col>
              </Row>
              {(chargesFields.mailFuzeChargeBackOwners && chargesFields.mailFuzeChargeBackOwnersMethod === 0) && (
                <>
                  <Row>
                    <Col>
                      <fieldset className="mt-3">
                        <legend><Badge><CashCoin /></Badge> Charges</legend>
                        <hr />
                        {chargesFields.mailFuzeChargeBackOwners && chargesFields.mailFuzeChargeBackOwnersMethod === 0 && (
                          <Row className="mb-3">
                            <Col>
                              <ChargeOwner
                                defaultAmount={chargesFields.chargeOwnerAmount}
                                allowMarkup={chargesFields.mailFuzeChargeBackOwnersMarkup}
                                allowMarkupAmount={chargesFields.mailFuzeChargeBackOwnersMarkupAmountEnabled}
                                defaultMarkupAmount={chargesFields.chargeOwnerCostAmountMarkupDefault}
                                allowMarkupPercent={chargesFields.mailFuzeChargeBackOwnersMarkupPercentEnabled}
                                defaultMarkupPercent={chargesFields.chargeOwnerCostPercentMarkupDefault}
                                chargeMethod={chargesFields.chargeOwnerMethod}
                                onChange={value => dispatchChargesFields({ type: 'MERGE', value })}
                              />
                            </Col>
                          </Row>
                        )}
                      </fieldset>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <hr />
                    </Col>
                  </Row>
                </>
              )}
              <div className={chargesFields.mailingHistoryCategoryEnabled ? '' : 'd-none'} ref={attachmentsOptionsRef}>
                <Row>
                  <Col>
                    <fieldset className="mt-3">
                      <legend><Badge><Paperclip /></Badge> Attachments</legend>
                      <hr />
                      {/* <Row className="mt-3">
                        <Col>
                          <ChargeAttachAffidavit
                            onChange={value => dispatchChargesFields({ type: 'MERGE', value })}
                          />
                        </Col>
                      </Row> */}
                      <Row className="mt-3 mailing-type-options">
                        <Col>
                          <FormGroup row>
                            <Label><Boxes />History Category</Label>
                            <Col className='me-1'>
                              <HistoryCategorySelect
                                value={chargesFields.historyCategoryID}
                                options={chargesFields.historyCategoryOptions}
                                onChange={option => dispatchChargesFields({ type: 'SET', name: 'historyCategoryID', value: option.value })}
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className='d-none' ref={attachmentsOptionsAttachLetterRef}>
                        <Row className="">
                          <Col>
                            <ChargeAttachLetter
                              initialValue={chargesFields.attachLetter}
                              onChange={value => dispatchChargesFields({ type: 'MERGE', value })}
                            />
                          </Col>
                        </Row>
                      </div>
                    </fieldset>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <hr />
                  </Col>
                </Row>
              </div>
              <Row>
                <React.Fragment>
                  <Col sm={6} md={12} lg={12} xxl={6}>
                    {!letterTemplateFields || !letterTemplateFields.letterParts || letterTemplateFields.letterParts.length === 0 || (letterTemplateFields.letterParts.filter(part => part.type === LetterPartTypes.Fixed).length === letterTemplateFields.letterParts.length) ? (
                      letterTemplateFields.letterParts.filter(part => part.type === LetterPartTypes.Fixed).length === letterTemplateFields.letterParts.length ? (
                        <Button color="dark" className="w-100" onClick={ onPreviewOne } disabled={letterTemplateFields.letterUploading}><BinocularsFill /> Preview</Button>
                      ) : (
                        <Button color="dark" className="w-100" onClick={ onPreviewAll } disabled={letterTemplateFields.letterUploading}><BinocularsFill /> Preview</Button>
                      )
                    ) : (
                      <Button color="dark" className="w-100" onClick={ onPreviewAll } disabled={letterTemplateFields.letterUploading}><BinocularsFill /> Preview</Button>
                      // <UncontrolledButtonDropdown className="w-100">
                      //   <Button id="caret" color="dark" className="w-100" onClick={ onPreviewOne } disabled={letterTemplateFields.letterUploading}><span className='text-nowrap'><BinocularsFill /> Preview</span></Button>
                      //   <DropdownToggle caret color="dark" className={`dropdown-toggle-border${letterTemplateFields.letterUploading ? ' disabled' : '' }`} />
                      //   <DropdownMenu>
                      //     <DropdownItem header>Preview How Many?</DropdownItem>
                      //     <DropdownItem onClick={ onPreviewOne }>Only 1 (Default)</DropdownItem>
                      //     {/* <DropdownItem onClick={ onPreviewN }>Up to 30</DropdownItem> */}
                      //     <DropdownItem onClick={ onPreviewAll }>All Selected</DropdownItem>
                      //     {/* {letterTemplateFields.letterParts.length < 2 ? (
                      //       <DropdownItem onClick={ onPreviewAll }>All Selected</DropdownItem>
                      //     ) : (
                      //       <DropdownItem onClick={ onPreviewFive }>Up to 5</DropdownItem>
                      //     )} */}
                      //   </DropdownMenu>
                      // </UncontrolledButtonDropdown>
                    )}
                  </Col>
                  <Col sm={6} md={12} xxl={6} className="mt-3 mt-sm-0 mt-md-3 mt-xxl-0 d-flex">
                    <Button color="dark" className="w-100" onClick={ onSend } disabled={letterTemplateFields.letterUploading}><Send /> Send</Button>
                  </Col>
                </React.Fragment>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      
      {/* <Button color="success" onClick={onTestShowResults}>TEst Results</Button> */}

      <SendMailValidationResult
        showCanvas={state.isValidationErrorsOpen}
        //recipientsWithoutAddress={state.recipientsWithoutAddress}
        onClose={() => dispatch({ type: 'SET', name: 'isValidationErrorsOpen', value: false })}
      >
        {state.envelopeNumberOfPagesResult && !state.envelopeNumberOfPagesResult.isValid && (
          <WarningLetterNumberPageOfPages
            result={state.envelopeNumberOfPagesResult}
          />
        )}

        {state.historyCategoryValidationResult && !state.historyCategoryValidationResult.isValid && (
          <WarningHistoryCategory
            result={state.historyCategoryValidationResult}
          />
        )}

        {state.historyCategoryRequiredValidationResult && !state.historyCategoryRequiredValidationResult.isValid && (
          <WarningHistoryCategoryRequired
            result={state.historyCategoryRequiredValidationResult}
          />
        )}

        {state.documentDimensionResult && !state.documentDimensionResult.validDocumentDimensions && (
          <WarningInvalidDocumentDimensions
            validDocumentDimensions={state.documentDimensionResult.validDocumentDimensions}
            targetDocumentWidth={state.documentDimensionResult.targetDocumentDimensions.documentWidth}
            targetDocumentHeight={state.documentDimensionResult.targetDocumentDimensions.documentHeight}
          />
        )}

        {state.clientAddressValidationResult && !state.clientAddressValidationResult.isValid && (
          <WarningInvalidClientAddress
            result={state.clientAddressValidationResult}
          />
        )}
        
        {state.thisPropertyAddressValidationResult && !state.thisPropertyAddressValidationResult.isValid && (
          <WarningInvalidThisPropertyAddress
            result={state.thisPropertyAddressValidationResult}
          />
        )}

        {state.recipientsWithoutAddress && state.recipientsWithoutAddress.length !== 0 && (
          <WarningBadAddress
            recipientsWithoutAddress={state.recipientsWithoutAddress}
            onUnselect={onUnselectBadAddress}
          />
        )}

        {state.ownerValidationResult && state.ownerValidationResult.badProperties.length !== 0 && (
          <WarningBadOwner
            ownerValidationResult={state.ownerValidationResult}
            onUnselect={onUnselectBadOwner}
          />
        )}

      </SendMailValidationResult>

      {sendMailResults.showModal && (
        <SendMailResults onClose={onCloseResults} {...sendMailResults} />
      )}

      {preview.showModal && (
        <PreviewModal
          {...preview}
          onClose={onClosePreview}
        />
      )}

    </div>
  )
}

export default SendMailProspects
