export const ReturnAddressActions = {
  BlankOnly: 0,
  SelectedOnly: 1,
  All: 2
};

export const applyReturnAddress = ({ gridRef, returnAddress, returnAddressOption, useMultilineAddress }) => {
  //if (costCode) {
    //showCostCodeInput(false)

    console.log('calling', returnAddress, returnAddressOption, useMultilineAddress)
    const gridApi = gridRef.current.api
    
    const itemsToUpdate = [];
    gridApi.forEachNodeAfterFilterAndSort((rowNode) => {
      if ((returnAddressOption === ReturnAddressActions.All)
        || (returnAddressOption === ReturnAddressActions.SelectedOnly && rowNode.isSelected())
        || (returnAddressOption === ReturnAddressActions.BlankOnly
          && (
            (!rowNode.data.returnName || rowNode.data.returnName.trim() === '')
            && (
              useMultilineAddress
              && (!rowNode.data.returnStreet1 || rowNode.data.returnStreet1.trim() === '')
              && (!rowNode.data.returnStreet2 || rowNode.data.returnStreet2.trim() === '')
            ) || (
              !useMultilineAddress
              && (!rowNode.data.returnStreet || rowNode.data.returnStreet.trim() === '')
            )
            && (!rowNode.data.returnCity || rowNode.data.returnCity.trim() === '')
            && (!rowNode.data.returnState || rowNode.data.returnState.trim() === '')
            && (!rowNode.data.returnZip || rowNode.data.returnZip.trim() === '')
          )
        )) {
          const data = rowNode.data
          data.returnName = returnAddress.name
          if (useMultilineAddress) {
            data.returnStreet1 = returnAddress.street1
            data.returnStreet2 = returnAddress.street2
          } else {
            data.returnStreet = returnAddress.street
          }
          data.returnCity = returnAddress.city
          data.returnState = returnAddress.state
          data.returnZip = returnAddress.zip
          itemsToUpdate.push(data)
      }
    });

    const res = gridApi.applyTransaction({ update: itemsToUpdate })
    console.log(res)

    return true
  //} else {
    //alert('Cost code blank?') 
    //return false
  //}
}
