import BaseSelect2 from "../dropdowns/BaseSelect2";

export const MailingChargeTypeIDTenantSelect = ({ options, onChange, value, ...rest }) => {
  return (
      <BaseSelect2
          options={options}
          onChange={onChange}
          value={value}
          selectFirst={false}
          {...rest}
      />
  )
}