import React from 'react'
import { Col, Label, Row } from 'reactstrap'

const Setting = ({label, description = '', children}) => {
  return (
    <React.Fragment>
      <Row className="mt-3">
        <Col xs={12} md={7}>
          <Label>{label}</Label>
        </Col>
        <Col xs={12} md={5}>
            {children}
        </Col>
      </Row>
      {/* {description !== '' && (
        <Row className="mt-0">
          <Col xs={12} md={{offset: 5, size: 7}} className="text-muted">
            <small>{description}</small>
          </Col>
        </Row>
      )} */}
    </React.Fragment>
  )
}

export default Setting
