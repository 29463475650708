import { createContext, useContext } from 'react';

export const SettingsContext = createContext()

const useSettingsContext = () => {
  const context = useContext(SettingsContext)
  if (!context) {
    throw new Error('The composite component cannot be rendered outside of the parent Settings.')
  }

  return context
}

export default useSettingsContext