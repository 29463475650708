import React, { useCallback, useState } from 'react'
import { FilterLeft, ListCheck, ListStars, X } from 'react-bootstrap-icons'
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { CostCodeActions } from '../../../services/CostCodeService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const CostCodeActionInput = ({showModal, onClose, applyCostCode}) => {

  const [modal, setModal] = useState(showModal)
  const [costCode, setCostCode] = useState('')
  const [costCodeOption, setCostCodeOption] = useState(CostCodeActions.BlankOnly)
  const MySwal = withReactContent(Swal)

  const PlainSwal = MySwal.mixin({
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    }
  })

  const toggle = () => {
    if (modal) {
      onClose()
    }
    
    setModal(!modal)
  }

  const onCostCodeChanged = useCallback((e) => {
    setCostCode(e.target.value)
  }, [setCostCode])

  const onApplyCostCode = useCallback(() => {

    if (!costCode || costCode.trim() === '') {
      PlainSwal.fire({
        title: 'Blank Cost Code',
        html:  '<p>Are you sure you want to set the cost code to blank?</p>',
        showCloseButton: true,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          cancelButton: 'btn btn-dark btn-lg me-1',
          confirmButton: 'btn btn-primary btn-lg me-1 swal-order-2',
        },
        confirmButtonText: 'Set to Blank'
      }).then((results) => {
        if (results.isConfirmed) {
          applyCostCode({ costCode, costCodeOption })
        }
      })
    } else {
      applyCostCode({ costCode, costCodeOption })
    }
  }, [costCode, costCodeOption, applyCostCode])

  const CostCodeOption = ({value, label, children}) => {
    return (
      <div className='cost-code-option mb-0 d-flex flex-fill flex-row align-items-center'>
        <Input
          type='radio'
          name='costCodeOption'
          id={`costCodeOption${value}`}
          checked={costCodeOption === value}
          onChange={() => setCostCodeOption(value)}
        />
        <div className={`flex-fill ms-2 p-1 border border-1 border-primary rounded ${costCodeOption === value ? 'border-primary selected' : 'border-white'}`}>
          <Label className='mb-0 d-flex flex-row align-items-center' for={`costCodeOption${value}`}>
            <span className='icon' style={{opacity: costCodeOption === value ? 1 : 0.8}}>{children}</span>
            <span>{label}</span>
          </Label>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Modal
        backdrop="static"
        size='lg'
        isOpen={modal}
        toggle={toggle}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 700 }}
      >
        <ModalHeader toggle={toggle} close={<button className="close btn btn-white" onClick={toggle}><X size={25} /></button>}>
          <div>Set Cost Code</div>
        </ModalHeader>
        <ModalBody style={{ minHeight: '150px', maxHeight: '75vh', overflow: 'auto' }}>
          <div className='d-flex justify-content-around align-items-center'>
            <div className='d-flex flex-grow-0' style={{ maxWidth: '33%' }}>
              <div style={{position: 'relative'}}>
                <div className='p-2 bg-light rounded-start pointy-box'>
                  <div className='d-flex flex-row align-items-center justify-content-space flex-grow-1' style={{height: '100%'}}>
                    <Label className='text-nowrap me-3 mb-0'>Cost Code</Label>
                    <Input type='text' className='text-center' onChange={onCostCodeChanged}></Input>
                  </div>
                </div>
              </div>
            </div>
            <div className='ps-5 d-flex flex-grow-1'>
              <div className='p-3 flex-fill'>
                <CostCodeOption value={CostCodeActions.BlankOnly} label='Only rows with blank cost codes'>
                  <FilterLeft size={32} />
                </CostCodeOption>
                <CostCodeOption value={CostCodeActions.SelectedOnly} label='Only selected rows'>
                  <ListCheck size={32} />
                </CostCodeOption>
                <CostCodeOption value={CostCodeActions.All} label='All rows'>
                  <ListStars size={32} style={{transform: 'rotate(180deg)'}} />
                </CostCodeOption>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-end">
            <Button color="secondary" onClick={toggle} className="me-1">
              Cancel
            </Button>
            <Button color="primary" onClick={onApplyCostCode} className="ms-1">
              Apply
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default CostCodeActionInput