export const CostCodeActions = {
  BlankOnly: 0,
  SelectedOnly: 1,
  All: 2
};

export const applyCostCode = ({ gridRef, costCode, costCodeOption }) => {
  //if (costCode) {
    //showCostCodeInput(false)

    console.log('calling', costCode, costCodeOption)
    const gridApi = gridRef.current.api
    
    const itemsToUpdate = [];
    gridApi.forEachNodeAfterFilterAndSort((rowNode) => {
      if ((costCodeOption === CostCodeActions.All)
        || (costCodeOption === CostCodeActions.SelectedOnly && rowNode.isSelected())
        || (costCodeOption === CostCodeActions.BlankOnly && (!rowNode.data.costCode || rowNode.data.costCode === ''))) {
          const data = rowNode.data
          data.costCode = costCode
          itemsToUpdate.push(data)
      }
    });

    const res = gridApi.applyTransaction({ update: itemsToUpdate })
    console.log(res)

    return true
  //} else {
    //alert('Cost code blank?') 
    //return false
  //}
}
