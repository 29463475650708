import React from 'react'
import { Button, ListGroup, ListGroupItem } from 'reactstrap'
import { Check2Square } from 'react-bootstrap-icons'
//import HelpHeaderSM from '../widget/HelpHeaderSM'

const WarningBadAddress = ({recipientsWithoutAddress, onUnselect, hasUnselect = true}) => {
  

  // const showHelp = () => {
  //   console.log('show help!')
  // }

  if (recipientsWithoutAddress) {

    if (recipientsWithoutAddress.length !== 0) {

      const es = recipientsWithoutAddress.length === 1 ? '' : 'es'
      const s = recipientsWithoutAddress.length === 1 ? '' : 's'
      //const is = recipientsWithoutAddress.length === 1 ? 'is' : 'are'
      const has = recipientsWithoutAddress.length === 1 ? 'has' : 'have'
      const an = recipientsWithoutAddress.length === 1 ? ' an' : ''
      const it = recipientsWithoutAddress.length === 1 ? 'it' : 'them'
      //const it2 = recipientsWithoutAddress.length === 1 ? 'it' : 'they'

      return (
        <div className='d-flex flex-fill flex-column justify-content-between'>
          <div>
            <div className='fw-bold d-flex justify-content-center'>Missing Address{es}</div>
            <hr />
            {hasUnselect ? (
              <p>The following recipient{s} {has}{an} unmailable address{es}.  Please either unselect {it} using the button below or fix the address{es} at the source.</p>
            ) : (
              <p>The following recipient{s} {has}{an} unmailable address{es}.  Please fix the address{es} at the source.</p>
            )}
            <ListGroup style={{maxHeight: '10em', marginBottom: '1em', overflowY: 'auto'}}>
              {recipientsWithoutAddress.map(item => <ListGroupItem color='warning' key={item.recipientID}>{item.externalRecipientID} - {item.fullNameWithComma}</ListGroupItem>)}
            </ListGroup>
            {hasUnselect && (
              <Button color='primary' className='w-100 mb-3' onClick={onUnselect}><Check2Square /> Unselect {it} for me</Button>
            )}
            {/* <div className='w-100 text-end'><a className='clear-action me-2 mb-1' onClick={showHelp}>help</a></div> */}
          </div>
          {/* <div className='mb-3'>
            <hr />
            <div className='d-flex flex-row justify-content-between mt-1 mx-2 text-dark fw-bold'><span>Options</span><span>Help</span></div>
            <div className='w-100 text-center text-dark fw-bold'></div>
            <HelpHeaderSM title="Unselect">
              <p className='text-muted' style={{lineHeight: '95%'}}>You can manually unselect by using the grid on the left or by clicking the "Unselect {it} for me" button above to automatically unselect the recipient{s} with a bad address{es}.</p>
              <p className='text-muted fw-bold' style={{lineHeight: '95%'}}>Once {it2} {is} unselected, you can submit again, but the unselected recipient{s} will not receive the mailing.</p>
            </HelpHeaderSM>
            <HelpHeaderSM title="Fix in the Grid">
              <p className='text-muted' style={{lineHeight: '95%'}}>You can manually edit the address fields by using the grid on the left. You can edit inline by double-clicking on the row and column of the recipient{s} with a bad address{es} and filling the address field.</p>
              <p className='text-muted fw-bold' style={{lineHeight: '95%'}}>Once {it2} {is} fixed, you can submit again. At this point, the fixed recipient{s} will also receive the mailing.</p>
            </HelpHeaderSM>
            <HelpHeaderSM title="Fix in the File">
              <p className='text-muted' style={{lineHeight: '95%'}}>You can manually edit the file.</p>
            </HelpHeaderSM>
            <HelpHeaderSM title="Fix in your Application">
              <p className='text-muted' style={{lineHeight: '95%'}}>You can go to your management application, locate the recipient{s}, fix the address{es} and save.</p>
              <p className='text-muted' style={{lineHeight: '95%'}}>Once fixed, you must re-export the recipients to a new file.</p>
              <p className='text-muted' style={{lineHeight: '95%'}}>Finally, using the "Letter Recipients" panel on the top-left of this screen, remove the old file containing the incomplete address{es} and replace it with the new file you created above.</p>
              <p className='text-muted fw-bold' style={{lineHeight: '95%'}}>When ready, you can submit again. At this point, the fixed recipient{s} will also receive the mailing.</p>
            </HelpHeaderSM>
          </div> */}
        </div>
      )
    }
  }

  return <></>
}

export default WarningBadAddress