import React from "react"
import { Spinner } from "reactstrap"

export const CustomLoadingOverlay = (props) => {
  return (
    <div
      className="ag-overlay-loading-center bg-light text-dark d-flex flex-row align-items-center p-4"
      style={{ height: '9%' }}
    >
      <Spinner /><span className="ps-4 fs-5">{props.loadingMessage}</span>
    </div>
  )
}