import { useFetchWrapper } from '../services/fetch-wrapper'

export { useDocumentClassService };

const useDocumentClassService = () => {

  const fetchWrapper = useFetchWrapper()

  const HasConfirmationOfMailing = async (documentClass) => {
    return fetchWrapper.get(`documentclass/${documentClass}/hasConfirmationOfMailing`)
  }

  const GetDocumentClasses = async () => {
    return fetchWrapper.get('documentclass')
  }
  
  const GetDocumentClassOptions = async () => {
    return fetchWrapper.get('documentclass/options')
  }

  return { GetDocumentClasses, GetDocumentClassOptions, HasConfirmationOfMailing }
}

