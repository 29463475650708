import React, { useReducer, useEffect, useRef, createRef, useState } from 'react'
import { Row, Col, Input, InputGroup, InputGroupText, Label } from 'reactstrap'
import { ChargeOwnerMethods } from '../../services/SendMailService';
import OptionYesNo from '../widget/OptionYesNo';

const ChargeOwner = ({ defaultAmount, allowMarkup, allowMarkupAmount, defaultMarkupAmount, allowMarkupPercent, defaultMarkupPercent, chargeMethod, onChange}) => {

  // const methodRefs = useRef(Array(4)
  // .fill()
  // .map(() => createRef()));

  const methodRefs = useRef({
    [ChargeOwnerMethods.FixedAmount]: createRef(),
    [ChargeOwnerMethods.Cost]: createRef(),
    [ChargeOwnerMethods.CostAmountMarkup]: createRef(),
    [ChargeOwnerMethods.CostPercentMarkup]: createRef(),
  })

  const [chargeOwner, setChargeOwner] = useState(false)

  // console.log('defaultMarkupAmount', defaultMarkupAmount)
  // console.log('defaultMarkupPercent', defaultMarkupPercent)

  // const initialState = {
  //    chargeOwner: false,
  //    chargeOwnerAmount: null,
  //    chargeOwnerMethod: chargeMethod,
  //    chargeOwnerCostAmountMarkup: null,
  //    chargeOwnerCostPercentMarkup: null
  // }

  // console.log('initial state', initialState)

  // const actionReducer = (state, action) => {
  //   switch (action.type) {
  //     case 'SET': {
  //       return {
  //         ...state,
  //         [action.name]: action.value
  //       }
  //     }
  //   }

  //   return state
  // }

  // const [state, dispatch] = useReducer(actionReducer, initialState)

  // useEffect(() => {
  //   console.log('stage changed', state)
  //   onChange(state)
  // }, [state])

  // useEffect(() => {
  //   methodRefs.current = Array(4)
  //     .fill()
  //     .map((_, i) => methodRefs.current[i] || createRef());
  // }, [])

  const dispatch = (action) => {
    onChange({[action.name]: action.value})
  }

  useEffect(() => {
    console.log('chargeMethod', chargeMethod)
    
    if (methodRefs && methodRefs.current && methodRefs.current.length !== 0 && methodRefs.current[chargeMethod] && methodRefs.current[chargeMethod].current) {
      methodRefs.current[chargeMethod].current.focus()
    }
  }, [chargeMethod])

  return (
    <React.Fragment>
      <OptionYesNo
        onChange={({chargeOwner: chargeOwnerVal}) => {
          setChargeOwner(chargeOwnerVal)
          dispatch({ type: 'SET', name: 'chargeOwner', value: chargeOwnerVal }
        )}}
        id="chargeOwner"
        text="Charge Property"
        initialValue={chargeOwner}
      />
      {
        chargeOwner && (
          <React.Fragment>
            <Row className="mt-2">
              <Col>
                <InputGroup
                  onClick={e => dispatch({ type: 'SET', name: 'chargeOwnerMethod', value: ChargeOwnerMethods.FixedAmount })}
                >
                  <InputGroupText>
                    <Input
                      addon
                      className='form-check-input mt-0'
                      name='chargeOwnerMethod'
                      value={ChargeOwnerMethods.FixedAmount}
                      aria-label="Option to charge fixed amount"
                      type="radio"
                      checked={chargeMethod === ChargeOwnerMethods.FixedAmount ? 'checked' : ''}
                      onChange={e => dispatch({ type: 'SET', name: e.target.name, value: e.target.value })}
                    />
                  </InputGroupText>
                  <InputGroupText><span style={{minWidth: '2.33rem'}}>Fixed</span></InputGroupText>
                  <Input
                    className="text-end"
                    name="chargeOwnerAmount"
                    type="number"
                    step="0.01"
                    min="0"
                    max="50"
                    value={defaultAmount}
                    onChange={e => dispatch({ type: 'SET', name: e.target.name, value: e.target.value })}
                    disabled={chargeMethod !== ChargeOwnerMethods.FixedAmount }
                    style={{pointerEvents: chargeMethod !== ChargeOwnerMethods.FixedAmount ? 'none' : 'inherit'}}
                    innerRef={methodRefs.current[ChargeOwnerMethods.FixedAmount]}
                  />
                  <InputGroupText><span style={{minWidth: '1rem'}}>$</span></InputGroupText>
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <InputGroup
                  onClick={e => dispatch({ type: 'SET', name: 'chargeOwnerMethod', value: ChargeOwnerMethods.Cost })}
                >
                  <InputGroupText>
                    <Input
                      addon
                      className='form-check-input mt-0'
                      name='chargeOwnerMethod'
                      value={ChargeOwnerMethods.Cost}
                      aria-label="Option to charge cost"
                      type="radio"
                      checked={chargeMethod === ChargeOwnerMethods.Cost ? 'checked' : ''}
                      onChange={e => dispatch({ type: 'SET', name: e.target.name, value: e.target.value })}
                    />
                  </InputGroupText>
                  <InputGroupText><span style={{minWidth: '2.33rem'}}>Cost</span></InputGroupText>
                  <Input
                    name="chargeOwnerCost"
                    disabled={true}
                    style={{pointerEvents: 'none'}}
                  />
                </InputGroup>
              </Col>
            </Row>
            {allowMarkup && allowMarkupAmount && (
              <Row className="mt-2">
                <Col>
                  <InputGroup
                    onClick={e => dispatch({ type: 'SET', name: 'chargeOwnerMethod', value: ChargeOwnerMethods.CostAmountMarkup })}
                  >
                    <InputGroupText>
                      <Input
                        addon
                        className='form-check-input mt-0'
                        name='chargeOwnerMethod'
                        value={ChargeOwnerMethods.CostAmountMarkup}
                        aria-label="Option to charge amount"
                        type="radio"
                        checked={chargeMethod === ChargeOwnerMethods.CostAmountMarkup ? 'checked' : ''}
                        onChange={e => dispatch({ type: 'SET', name: e.target.name, value: e.target.value })}
                      />
                    </InputGroupText>
                    <div className='mb-0 form-floating'>
                      <Input
                        className="text-end"
                        name="chargeOwnerCostAmountMarkup"
                        type="number"
                        step="0.01"
                        min="0"
                        max="50"
                        defaultValue={defaultMarkupAmount}
                        //value={defaultAmount}
                        onChange={e => dispatch({ type: 'SET', name: e.target.name, value: e.target.value })}
                        placeholder='Cost + $ Markup'
                        disabled={chargeMethod !== ChargeOwnerMethods.CostAmountMarkup}
                        style={{pointerEvents: chargeMethod !== ChargeOwnerMethods.CostAmountMarkup ? 'none' : 'inherit'}}
                        innerRef={methodRefs.current[ChargeOwnerMethods.CostAmountMarkup]}
                      />
                      <Label for="exampleEmail">
                        Cost + $ Markup
                      </Label>
                    </div>
                    <InputGroupText className='d-flex flex-column justify-content-end pb-2'><span style={{minWidth: '1rem'}}>$</span></InputGroupText>
                  </InputGroup>
                </Col>
              </Row>
            )}
            {allowMarkup && allowMarkupPercent && (
              <Row className="mt-2">
                <Col>
                  <InputGroup
                    onClick={e => dispatch({ type: 'SET', name: 'chargeOwnerMethod', value: ChargeOwnerMethods.CostPercentMarkup })}
                  >
                    <InputGroupText>
                      <Input
                        addon
                        className='form-check-input mt-0'
                        name='chargeOwnerMethod'
                        value={ChargeOwnerMethods.CostPercentMarkup}
                        aria-label="Option to charge percentage"
                        type="radio"
                        checked={chargeMethod === ChargeOwnerMethods.CostPercentMarkup ? 'checked' : ''}
                        onChange={e => dispatch({ type: 'SET', name: e.target.name, value: e.target.value })}
                      />
                    </InputGroupText>
                    <div className='mb-0 form-floating'>
                      <Input
                        className="text-end"
                        name="chargeOwnerCostPercentMarkup"
                        type="number"
                        step="0.01"
                        min="0"
                        max="500"
                        //value={defaultPercent}
                        defaultValue={defaultMarkupPercent}
                        onChange={e => dispatch({ type: 'SET', name: e.target.name, value: e.target.value })}
                        placeholder='Cost + % Markup'
                        disabled={chargeMethod !== ChargeOwnerMethods.CostPercentMarkup}
                        style={{pointerEvents: chargeMethod !== ChargeOwnerMethods.CostPercentMarkup ? 'none' : 'inherit'}}
                        innerRef={methodRefs.current[ChargeOwnerMethods.CostPercentMarkup]}
                      />
                      <Label for="exampleEmail">
                        Cost + % Markup
                      </Label>
                    </div>
                    <InputGroupText className='d-flex flex-column justify-content-end pb-2'><span style={{minWidth: '1rem'}}>%</span></InputGroupText>
                  </InputGroup>
                </Col>
              </Row>
            )}
          </React.Fragment>
        )
      }
    </React.Fragment>
  )
}

export default ChargeOwner