import React, { useEffect, useState } from 'react'
import { ArrowRight } from 'react-bootstrap-icons';

export default (props) => {
  const [expandState, setExpandState] = useState('collapsed');

  const expandOrCollapse = (e) => {
    console.log(e.target)
    e.stopPropagation()
    e.target.parentElement.blur()
    let currentState = props.columnGroup.getOriginalColumnGroup().isExpanded();
    props.setExpanded(!currentState);
  };

  const syncExpandButtons = () => {
    setExpandState(
      props.columnGroup.getOriginalColumnGroup().isExpanded()
        ? 'expanded'
        : 'collapsed'
    );
  };

  useEffect(() => {
    //console.log(props)
    //console.log(props.columnGroup)
    //console.log(props.columnGroup.getOriginalColumnGroup())
    props.columnGroup
      .getOriginalColumnGroup()
      .addEventListener('expandedChanged', syncExpandButtons);
    syncExpandButtons();

    return () => {
      props.columnGroup
        .getOriginalColumnGroup()
        .removeEventListener('expandedChanged', syncExpandButtons);
    };
  }, []);

  return (
    <div className="ag-header-group-cell-label" style={{overflow: 'visible'}}>
      <div className="d-flex">
        <div className="flex-grow-0 text-start">
          <div className="d-flex">
            <div className="flex-grow-0 customHeaderLabel">{props.displayName}</div>
            <div
              className={`flex-grow-0 customExpandButton ${expandState}`}
              onClick={expandOrCollapse}
            >
              <ArrowRight size={15} style={{pointerEvents: 'none'}} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}