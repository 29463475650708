import React, { useCallback, useEffect, useReducer, useRef } from 'react'
import SendMailAddressWizardManagerGrid from './SendMailAddressWizardManagerGrid'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Tooltip, UncontrolledTooltip } from 'reactstrap'
import SendMailAddressWizardManagerEditor from './SendMailAddressWizardManagerEditor'
import { PdfUploader } from '../widget/PdfUploader';
import { AddressWizardPageOption, useSendMailAddressWizardService } from '../../services/SendMailAddressWizardService'
import authService from '../api-authorization/AuthorizeService';
import AddressWizardResultGrid from './AddressWizardResultGrid'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { toast } from 'react-toastify'
import { Magic, PlusCircleFill } from 'react-bootstrap-icons'
import { 
  ConstantCodeMailFuzeCostCodesEnabled,
  useSettingsService
} from '../../services/SettingsService'

const SendMailAddressWizardManager = () => {

  let _subscription = null

  const addressTestButtonRef = useRef(null)
  const addressWizardService = useSendMailAddressWizardService()
  const settingService = useSettingsService()

  const initialState = {
    showEditor: false,
    isLoading: true,
    templates: [],
    templateSelection: [],
    scale: 1.5,
    dynamicScale: 1.5, //start same as above
    pageOption: AddressWizardPageOption.PageMarker,
    pageOptionNumPages: 1,
    pageOptionNumPagesError: '',
    costCodeOption: false,
    costCodesEnabled: false,
    returnAddressOption: false,
    templateName: '',
    templateDescription: '',
    files: [],
    fileUploading: false,
    documentBlobUrl: undefined,
    documentIsRendered: false,
    hasDocument: false,
    documentWidth: 0,
    documentHeight: 0,
    documentPage1Base64: '',
    markers: [],
    showAddressResults: false,
    addressResults: []
  }

  const stateReducer = (state, action) => {
    //console.log(action)
    switch (action.type) {
      case 'SET': {
        return {
          ...state,
          [action.name]: action.value
        }
      }
      case 'RESET_EDITOR': {
        
        //don't update these
        const {authToken, templates, isLoading, costCodesEnabled, ...editorInitState} = initialState

        return {
          ...state,
          ...editorInitState
        }
      }
    }

    return state
  }
  
  const [state, dispatch] = useReducer(stateReducer, initialState)

  useEffect(() => {
    _subscription = authService.subscribe(() => populateState());
    populateState();

    // Specify how to clean up after this effect:
    return function cleanup() {
        authService.unsubscribe(_subscription);
    };
  }, []);

  const populateState = async () => {
    const [authToken, templates, constantsResults] = await Promise.all([
      authService.getAccessToken(),
      addressWizardService.GetTemplates(),
      settingService.GetConstants([
        ConstantCodeMailFuzeCostCodesEnabled
      ])
    ])

    const constantCodes = constantsResults.map(constant => constant.value)
    const clientSettings = await settingService.GetClientSettings(constantCodes)

    const mailFuzeCostCodesEnabled = settingService.GetConstantValueFromResults(constantsResults, clientSettings, ConstantCodeMailFuzeCostCodesEnabled, (valStr) => {
      return valStr.toLowerCase() === 'true'
    })

    console.log('mailFuzeCostCodesEnabled', mailFuzeCostCodesEnabled)

    dispatch({ type: 'SET', name: 'authToken', value: authToken })
    dispatch({ type: 'SET', name: 'templates', value: templates.result })
    dispatch({ type: 'SET', name: 'costCodesEnabled', value: mailFuzeCostCodesEnabled })
    dispatch({ type: 'SET', name: 'isLoading', value: false })
  }

  const MySwal = withReactContent(Swal)

  const PlainSwal = MySwal.mixin({
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    }
  })

  const onSelectionChanged = (selectedData) => {
    console.log('selectedData', selectedData)
    dispatch({ type: 'SET', name: 'templateSelection', value: selectedData })
  }

  // const onPdfRemove = (fileId) => {
  //   console.log('onPdfRemove')
  //   //dispatch({ type: 'SET', name: 'documentBlobUrl', value: null })
  // }

  const onPdfRemoved = (fileId) => {
    setTimeout(() => {
      console.log('onPdfRemoved')

      const markers = state.markers
      //showOrInitMarker('page', false, markers)
      //showOrInitMarker('address', false, markers)

      if (markers.filter(m => m.isVisible)) {
        const newMarkers = []
        markers.forEach(m => {
          newMarkers.push({...m, isVisible: false})
        });

        dispatch({ type: 'SET', name: 'markers', value: newMarkers })
      }

      // const newMarkers = [
      //   ...markers.slice(0, currentMarkerIndex),
      //   newMarker,
      //   ...markers.slice(currentMarkerIndex + 1),
      // ]
      
      //dispatch({ type: 'SET', name: 'markers', value: [] })
      dispatch({ type: 'SET', name: 'files', value: [] })
      dispatch({ type: 'SET', name: 'documentIsRendered', value: false })
      dispatch({ type: 'SET', name: 'hasDocument', value: false })
      dispatch({ type: 'SET', name: 'pageOptionNumPages', value: AddressWizardPageOption.PageMarker })
      dispatch({ type: 'SET', name: 'pageOptionNumPagesError', value: '' })
      
      //dispatch({ type: 'SET', name: 'documentBlobUrl', value: null })
      //dispatch({ type: 'SET', name: 'pageOption', value: 1 })
    }, 100);
  }

  const onDocumentIsRendered = (isRendered, width, height, documentPage1Base64) => {
    dispatch({ type: 'SET', name: 'documentIsRendered', value: isRendered })

    if (width && height && documentPage1Base64) {
      dispatch({ type: 'SET', name: 'documentWidth', value: width })
      dispatch({ type: 'SET', name: 'documentHeight', value: height })
      dispatch({ type: 'SET', name: 'documentPage1Base64', value: documentPage1Base64 })
    }
  }

  const onScaleChange = useCallback((newScale) => {
    //dispatch({ type: 'SET', name: 'scale', value: newScale })
    dispatch({ type: 'SET', name: 'dynamicScale', value: newScale })
  }, [])

  const onPageOptionChanged = (selectedOption) => {

    console.log('onPageOptionChanged', selectedOption)

    //const newPageOption = parseInt(selectedOption)
    const newPageOption = selectedOption

    if (newPageOption === AddressWizardPageOption.PageMarker) {
      console.log('onPageOptionChanged option ' + AddressWizardPageOption.PageMarker + ' match')

      console.log('state.markers', state.markers)

      showOrInitMarker('page', true, state.markers)
    } else {
      console.log('onPageOptionChanged NOT option ' + AddressWizardPageOption.PageMarker)
      showOrInitMarker('page', false, state.markers)
    }

    dispatch({ type: 'SET', name: 'pageOption', value: newPageOption })
  }

  const onCostCodeOptionChanged = (toggleValue) => {
    showOrInitMarker('costCode', toggleValue, state.markers)
    dispatch({ type: 'SET', name: 'costCodeOption', value: toggleValue })
  }

  const onReturnAddressOptionChange = (toggleValue) => {
    showOrInitMarker('returnAddress', toggleValue, state.markers)
    dispatch({ type: 'SET', name: 'returnAddressOption', value: toggleValue })
  }

  const onMarkerChanged = (marker, screenRect, rect) => {
    console.log('marker', marker, screenRect, rect)

    const currentMarkerIndex = state.markers.findIndex(m => m.marker === marker)
    console.log('currentMarkerIndex', currentMarkerIndex)

    //state.markers[currentMarkerIndex].coordinates = rect;
    //state.markers[currentMarkerIndex].screenCoordinates = screenRect;

    const newMarker = {...state.markers[currentMarkerIndex],
      coordinates: rect,
      screenCoordinates: screenRect
    }

    console.log('onMarkerChanged newMarker', newMarker)

    // const newMarkers = [
    //   ...state.markers.slice(0, currentMarkerIndex),
    //   newMarker,
    //   ...state.markers.slice(currentMarkerIndex === 0 ? 1 : currentMarkerIndex)
    // ]
    // const newMarkers = [
    //   ...state.markers.slice(0, currentMarkerIndex),
    //   newMarker,
    //   ...state.markers.slice(currentMarkerIndex + 1),
    // ]

    // console.log('onMarkerChanged newMarkers', newMarkers)

    // dispatch({ type: 'SET', name: 'markers', value: newMarkers })

    // setTimeout(() => {
    //   extractAndUpdateMarker(marker, rect)  
    // }, 1000);

    const newMarkers = [
      ...state.markers.slice(0, currentMarkerIndex),
      newMarker,
      ...state.markers.slice(currentMarkerIndex + 1),
    ]

    console.log('onMarkerChanged newMarkers', newMarkers)

    dispatch({ type: 'SET', name: 'markers', value: newMarkers })

    extractAndUpdateMarker(currentMarkerIndex, newMarker, rect)
  }

  const extractAndUpdateMarker = async (currentMarkerIndex, newMarker, rect) => {
    console.log('extractAndUpdateMarker', currentMarkerIndex, newMarker, rect)

    //const currentMarkerIndex = state.markers.findIndex(m => m.marker === marker)
    //console.log('currentMarkerIndex', currentMarkerIndex)

    //state.markers[currentMarkerIndex].coordinates = rect;
    //state.markers[currentMarkerIndex].screenCoordinates = screenRect;

    if (newMarker.marker === 'page' || newMarker.marker === 'costCode' || newMarker.marker === 'returnAddress') {
      const result = await extractText(rect.x, rect.y2, rect.width, rect.height)
      newMarker.capturedText = result.result
    } else {
      const result = await extractAddress(rect.x, rect.y2, rect.width, rect.height)
      newMarker.capturedText = result.result
      newMarker.address =  result.address
    }
    //const rect2 = getMarkerRect(rect)
    
    

    const newMarkers = [
      ...state.markers.slice(0, currentMarkerIndex),
      newMarker,
      ...state.markers.slice(currentMarkerIndex + 1),
    ]

    console.log('onMarkerChanged newMarkers', newMarkers)

    dispatch({ type: 'SET', name: 'markers', value: newMarkers })
  }

  const onRunAddressTest = async (e) => {
    e.target.blur();

    const fileId = getFileId()
    //const addressMarker = state.markers.find(m => m.marker === 'address')
    //const { x: ax, y2: ay2, width: aw, height: ah } = addressMarker.coordinates

    // const markers = {
    //   address : {
    //     x: addressMarker.coordinates.x,
    //     y: addressMarker.coordinates.y2,
    //     w: addressMarker.coordinates.width,
    //     h: addressMarker.coordinates.height,
    //   }
    // }

    if (state.costCodesEnabled && state.costCodeOption) {
      //const costCodeMarker = state.markers.find(m => m.marker === 'costCode')

      // markers.costCode = {
      //   x: costCodeMarker.coordinates.x,
      //   y: costCodeMarker.coordinates.y2,
      //   w: costCodeMarker.coordinates.width,
      //   h: costCodeMarker.coordinates.height,
      // }
    }

    const visibleMarkers = state.markers.filter(m => m.isVisible)
    let addressResults = []
    if (state.pageOption == AddressWizardPageOption.PageMarker) {
      //const pageMarker = state.markers.find(m => m.marker === 'page')
      //const { x: px, y2: py2, width: pw, height: ph } = pageMarker.coordinates

      // markers.page = {
      //   x: pageMarker.coordinates.x,
      //   y: pageMarker.coordinates.y2,
      //   w: pageMarker.coordinates.width,
      //   h: pageMarker.coordinates.height,
      // }

      //addressResults = await addressWizardService.GetAddressesByPageMarker(fileId, ax, ay2, aw, ah, px, py2, pw, ph)
      
      addressResults = await addressWizardService.GetDataByPageMarkers(fileId, visibleMarkers)
    } else {
      //addressResults = await addressWizardService.GetAddresses(fileId, ax, ay2, aw, ah, state.pageOptionNumPages)
      addressResults = await addressWizardService.GetData(fileId, visibleMarkers, state.pageOptionNumPages)
    }

    console.log(addressResults)
    const reshapedAddressResults = addressResults.result.map((item) => {
      const {returnAddress, ...reshapeAddress} = item

      if (returnAddress) {
        reshapeAddress.returnName = returnAddress.name
        reshapeAddress.returnStreet1 = returnAddress.street1
        reshapeAddress.returnStreet2 = returnAddress.street2
        reshapeAddress.returnCity = returnAddress.city
        reshapeAddress.returnState = returnAddress.state
        reshapeAddress.returnZip = returnAddress.zip
      }

      return reshapeAddress
    })

    dispatch({ type: 'SET', name: 'addressResults', value: reshapedAddressResults })

    toggleAddressResults()

  }

  const extractText = async (x, y2, w, h) => {

    console.log('x, y2', x, y2)
    console.log('w, h', w, h)
    
    const fileId = getFileId()
    const response = await addressWizardService.GetTextData(fileId, x, y2, w, h)
    console.log(response)

    return response
  }

  const extractAddress = async (x, y2, w, h) => {
    console.log('x, y2', x, y2)
    console.log('w, h', w, h)
    
    const fileId = getFileId()
    const response = await addressWizardService.GetAddressData(fileId, x, y2, w, h)
    console.log(response)

    return response
  }

  // const extractAddresses = async (x, y2, w, h) => {

  //   console.log('x, y2', x, y2)
  //   console.log('w, h', w, h)
    
  //   const response = await addressWizardService.GetAddressesData(fileId, x, y2, w, h)
  //   console.log(response)

  //   return response
  // }

  const getFileId = () => {
    if (state.files && state.files.length === 1) {
      const file = state.files[0]
      console.log("file.serverId", file.serverId)

      let str = file.serverId
      if(str.startsWith("\"") && str.endsWith("\""))
      {
          str = str.substring(1, str.length-1);
      }
      
      return str
    }

    return ''
  }

  const onInitMarkers = (markers, scale) => {

    console.log('onInitMarkers', markers)

    //markers = state.markers

    if (markers && markers.length !== 0) {
      console.log('change visibility of existing markers')

      const newMarkers = []
      markers.forEach(m => {
        let isVisible = false
        if (m.marker === 'page') {
          if (state.pageOption == AddressWizardPageOption.PageMarker) {
            isVisible = true
          }
        }
        else if (m.marker === 'costCode') {
          if (state.costCodeOption === true) {
            isVisible = true
          }
        }
        else if (m.marker === 'returnAddress') {
          if (state.returnAddressOption === true) {
            isVisible = true
          }
        }
        else {
          isVisible = true
        }

        newMarkers.push({...m, isVisible: isVisible, screenCoordinates: {
          x: m.coordinates.x * scale,
          y: m.coordinates.y1 * scale,
          width: `${m.coordinates.width * scale}px`,
          height: `${m.coordinates.height * scale}px`,
        }, })
      });

      console.log('setting visibility of new markers')

      dispatch({ type: 'SET', name: 'markers', value: newMarkers })
    }
    else {
      const newMarkers = showOrInitMarker(
        'address',
        true,
        markers
      )

      if (state.pageOption == AddressWizardPageOption.PageMarker) {
        showOrInitMarker(
          'page',
          true,
          newMarkers
        )
      }

      if (state.costCodeOption === true) {
        showOrInitMarker(
          'costCode',
          true,
          newMarkers
        )
      }

      if (state.returnAddressOption === true) {
        showOrInitMarker(
          'returnAddress',
          true,
          newMarkers
        )
      }
    }
  }

  const showOrInitMarker = (marker, show, markers) => {
    let currentMarkerIndex = markers.findIndex(m => m.marker === marker)
    console.log('currentMarkerIndex', currentMarkerIndex)
    let newMarker = {}

    if (currentMarkerIndex !== -1) {
      const currentmarker = markers[currentMarkerIndex]
      newMarker = { ...currentmarker, isVisible: show,         screenCoordinates: {
        x: currentmarker.coordinates.x * state.dynamicScale,
        y: currentmarker.coordinates.y1 * state.dynamicScale,
        width: `${currentmarker.coordinates.width * state.dynamicScale}px`,
        height: `${currentmarker.coordinates.height * state.dynamicScale}px`,
      }, }
    } else {
      let offset = 0
      let styles = {}
      switch (marker) {
        case 'page':
          offset = 0
          styles = { color: '#2d882d', background: 'rgba(45, 136, 45, 0.3)', border: '2px solid #2d882d' }
          break;
        case 'address':
          offset = 20
          styles = { color: '#951d1d', background: 'rgba(149, 29, 29, 0.3)', border: '2px solid #951d1d' }
          break;
        case 'costCode':
          offset = -10
          styles = { color: '#3049bb', background: 'rgba(48, 73, 187, 0.3)', border: '2px solid #3049bb' }
          break;
        case 'returnAddress':
          offset = 30
          styles = { color: '#ffcb05', background: 'rgba(255, 203, 5, 0.3)', border: '2px solid #ffcb05' }
          break;
        default:
          break;
      }

      const coords = {
        x: 20 + offset,
        y1: 100 + offset,
        y2: 600 - offset,
        width: 160 + offset,
        height: 55 + offset
      }

      newMarker = {
        marker: marker,
        capturedText: '',
        isVisible: show,
        coordinates: coords,
        screenCoordinates: {
          x: coords.x * state.dynamicScale,
          y: coords.y1 * state.dynamicScale,
          width: `${coords.width * state.dynamicScale}px`,
          height: `${coords.height * state.dynamicScale}px`,
        },
        styles: styles
      }
    }

    // const newMarkers = [
    //   ...markers.slice(0, currentMarkerIndex),
    //   newMarker,
    //   ...markers.slice(currentMarkerIndex === 0 ? 1 : currentMarkerIndex)
    // ]

    //console.log('current markers', markers)
    //console.log('adding new marker', newMarker)

    let newMarkers = []
    if (currentMarkerIndex === -1) {
      newMarkers = [
        ...markers,
        newMarker
      ]
    } else {
      newMarkers = [
        ...markers.slice(0, currentMarkerIndex),
        newMarker,
        ...markers.slice(currentMarkerIndex + 1),
      ]
    }

    //state.markers.push.apply(state.documentClasses, documentClasses)
    //state.markers.push.apply(state.markers, [newMarker])
    console.log('newMarkers', newMarkers)

    dispatch({ type: 'SET', name: 'markers', value: newMarkers })

    return newMarkers
  }

  // useEffect(() => {
  //   const handleResize =  
  //     debounce(() => {

  //       const newMarkers = showOrInitMarker(
  //         'address',
  //         false,
  //         state.markers
  //       )

  //       showOrInitMarker(
  //         'page',
  //         state.pageOption == AddressWizardPageOption.PageMarker,
  //         newMarkers
  //       )
  //       // console.log('handleResize called')

  //       // const visibleIndexes = new Array()
  //       // let currentMarkers = state.markers
  //       // console.log('currentMarkers', currentMarkers)

  //       // currentMarkers.forEach((m, i) => {
  //       //   if (m.isVisible) {
  //       //     visibleIndexes.push(i)
  //       //   }
  //       // })

  //       // console.log('visibleIndexes', visibleIndexes)

  //       // for (let i = 0; i < visibleIndexes.length; i++) {
  //       //   currentMarkers = [
  //       //     ...currentMarkers.slice(0, i),
  //       //     {...currentMarkers[i], isVisible: false},
  //       //     ...currentMarkers.slice(i + 1),
  //       //   ]          
  //       // }
  //       // dispatch({ type: 'SET', name: 'markers', value: currentMarkers })

  //       setTimeout(() => {
  //         const newerMarkers = showOrInitMarker(
  //           'address',
  //           true,
  //           newMarkers
  //         )

  //         showOrInitMarker(
  //           'page',
  //           state.pageOption == AddressWizardPageOption.PageMarker,
  //           newerMarkers
  //         )
  //       }, 10)

  //       // let visibleMarkerIndex = currentMarkers.findIndex(m => m.isVisible)
  //       // while (visibleMarkerIndex !== -1)
  //       // {
  //       //   console.log('visibleMarkerIndex', visibleMarkerIndex, currentMarkers)
  //       //   visibleIndexes.push(visibleMarkerIndex)

  //       //   currentMarkers = [
  //       //     ...currentMarkers.slice(0, visibleMarkerIndex),
  //       //     {...currentMarkers[visibleMarkerIndex], isVisible: false},
  //       //     ...currentMarkers.slice(visibleMarkerIndex + 1),
  //       //   ]

  //       //   visibleMarkerIndex = currentMarkers.findIndex(m => m.isVisible)
  //       // }

  //       // dispatch({ type: 'SET', name: 'markers', value: currentMarkers })
        
  //       // setTimeout(() => {
  //       //   visibleIndexes.forEach((i) => {
  //       //     console.log('revert ', i, currentMarkers[i])
  //       //     currentMarkers = [
  //       //       ...currentMarkers.slice(0, i),
  //       //       {...currentMarkers[i], isVisible: true},
  //       //       ...currentMarkers.slice(i + 1),
  //       //     ]
  //       //   })

  //       //   dispatch({ type: 'SET', name: 'markers', value: currentMarkers })

  //       // }, 10)
  //     }, 10)

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   }
  // }, [state.markers, showOrInitMarker, state.dynamicScale])

  // useEffect(() => {
  //   loadData()
  // }, [loadData])

  // useEffect(() => {
  //   console.log('state.pageOption changed', state.pageOption)
  // }, [state.pageOption, state.markers, dispatch])

  const toggleEditor = useCallback(() => {
    //dispatch({ type: 'SET', name: 'showEditor', value: !state.showEditor })
    dispatch({ type: 'RESET_EDITOR' })
    dispatch({ type: 'SET', name: 'showEditor', value: !state.showEditor })
  })

  const toggleAddressResults = useCallback(() => {
    dispatch({ type: 'SET', name: 'showAddressResults', value: !state.showAddressResults })
  })

  const onIsUploadingChanged = useCallback((isUploadingState) => {
    dispatch({ type: 'SET', name: 'isFileUploading', value: isUploadingState })
  }, [])

  const inputWarning = (title, text) => {
    PlainSwal.fire({
      title,
      icon: 'warning',
      text,
      showCloseButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary btn-lg'
      }
    })
  }

  const validateInputs = useCallback(() => {
    console.log('fileid', getFileId())
    console.log('validateInputs', state.files)

    if (state.files && state.files.length !== 1) {
      inputWarning('Invalid Document', 'You must upload a sample PDF for the template.')
      return -1
    }

    console.log('state.templateName:', state.templateName)
    if (!state.templateName || state.templateName.trim() === '') {
      inputWarning('Invalid Template Name', 'You must enter a valid template name.')
      return -2
    }

    if (state.pageOption == AddressWizardPageOption.FixedPageNum && state.pageOptionNumPagesError && state.pageOptionNumPagesError.trim() !== '') {
      inputWarning('Invalid Page per Letter', state.pageOptionNumPagesError)
      return -3
    }

    let hasError = false
    const invalidMarker = state.markers.find(m => m.isVisible && m.capturedText.trim() === '')
    if (invalidMarker) {
      inputWarning('Invalid Marker', `The wizard could not find readable text below the '${invalidMarker.marker}' marker.`)
      hasError = true
    }

    if (hasError)
      return -4

    return 0
  }, [state])

  const getImages = (imagesReadyCallback) => {
      // //create a new canvas
      // var newCanvas = document.createElement('canvas');

      // //set dimensions
      // newCanvas.width = state.documentWidth;
      // newCanvas.height = state.documentHeight;

      // const callback = (canvas) => {
      //   console.log('callback is called')
      //   var w = window.open("")
      //   const img3 = canvas.toDataURL("image/png");
      //   w.document.write('<img src="'+img3+'"/>');
      //   //w.document.write(img2.outerHTML)
      //   w.document.close()
  
      //   console.log(img3)

      //   setTimeout(() => {
      //     var w = window.open("")
      //     const img3 = canvas.toDataURL("image/png");
      //     w.document.write('<img src="'+img3+'"/>');
      //     w.document.close()
      //   }, 2000)
      // }

      // var img = new Image();
      // var ctx = newCanvas.getContext('2d');
      // img.onload = function () {
      //   console.log('drawing onload')
      //     ctx.drawImage(img,0,0);
      //     callback(newCanvas)
      // }
      // img.src = state.documentPage1Base64;

      //console.log(state.documentBlobUrl)

      var newCanvas = document.createElement('canvas');
      newCanvas.width = state.documentWidth * state.scale;
      newCanvas.height = state.documentHeight * state.scale;
      var newCanvasCtx = newCanvas.getContext("2d");

      const borderWidth = 2

      let svg = `<svg width="${newCanvas.width}" height="${newCanvas.height}" xmlns="http://www.w3.org/2000/svg">
        ${
          state.markers.filter(m => m.isVisible).map((m) => {
            let {x, y1: y, width : w, height : h} = m.coordinates
            let color = ''
            switch (m.marker) {
              case 'page':
                color = 'rgb(45, 136, 45)'  
                break;
              case 'address':
                color = 'rgb(149, 29, 29)'
                break;
              case 'costCode':
                color = 'rgb(48, 73, 187)'
                break;
              case 'returnAddress':
                color = 'rgb(255, 203, 5)'
                break;
              default:
                color = 'rgb(127, 127, 127)'
                break;
            }

            x = x * state.scale
            y = y * state.scale
            w = w * state.scale
            h = h * state.scale

            // x: m.coordinates.x * scale,
            // y: m.coordinates.y1 * scale,
            // width: `${m.coordinates.width * scale}px`,
            // height: `${m.coordinates.height * scale}px`,


            return `<rect x="${x}" y="${y}" width="${w}" height="${h}" stroke="${color}" stroke-width="${borderWidth}px" fill="${color}" fill-opacity="0.3" />`
          }).join(' ')
        }
      </svg>`
      
      console.log('svg', svg)

      const callback = (newCanvas, image) => {
        //setTimeout(() => {
          var thumbnailSize = {
            width: 360 * (state.documentWidth / state.documentHeight),
            height: 360,
          }

          var markedImage = new Image();
          markedImage.onload = function() {
            // var w1 = window.open("")
            // w1.document.write('<img src="'+markedImage.src+'"/>');
            // w1.document.close()

            // Using Canvas - linear interpolation, 3-steps
            // Huge difference in clarity
            //https://codepen.io/mttaked/pen/ZLdEKm
            var c1 = document.createElement('canvas');
            c1.width = thumbnailSize.width * 4;
            c1.height = thumbnailSize.height * 4;
            c1.getContext('2d').drawImage(markedImage, 0, 0, c1.width, c1.height);

            var c2 = document.createElement('canvas');
            c2.width = thumbnailSize.width * 2;
            c2.height = thumbnailSize.height * 2;
            c2.getContext('2d').drawImage(c1, 0, 0, c2.width, c2.height);
          
            var r = document.createElement('canvas');
            r.width = thumbnailSize.width;
            r.height = thumbnailSize.height;
            r.getContext('2d').drawImage(c2, 0, 0, r.width, r.height);

            // var w = window.open("")
            // //const img3 = newCanvas.toDataURL("image/png");
            // const img3 = r.toDataURL("image/png");
            // w.document.write('<img src="'+img3+'"/>');
            // w.document.close()

            const thumbnail = r.toDataURL("image/png");
            imagesReadyCallback(markedImage.src, thumbnail)
          }
          markedImage.src = newCanvas.toDataURL("image/png");



          // setTimeout(() => {
          //   var c1 = document.createElement('canvas');
          //   c1.width = thumbnailSize.width * 4;
          //   c1.height = thumbnailSize.height * 4;
          //   c1.getContext('2d').drawImage(markedImage, 0, 0, c1.width, c1.height);

          //   var c2 = document.createElement('canvas');
          //   c2.width = thumbnailSize.width * 2;
          //   c2.height = thumbnailSize.height * 2;
          //   c2.getContext('2d').drawImage(c1, 0, 0, c2.width, c2.height);
          
          //   var r = document.createElement('canvas');
          //   r.width = thumbnailSize.width;
          //   r.height = thumbnailSize.height;
          //   r.getContext('2d').drawImage(c2, 0, 0, r.width, r.height);

          //   // var w = window.open("")
          //   // //const img3 = newCanvas.toDataURL("image/png");
          //   // const img3 = r.toDataURL("image/png");
          //   // w.document.write('<img src="'+img3+'"/>');
          //   // w.document.close()


          // }, 2000)

        //}, 2000)
      }

      let blob = new Blob([svg], {type: 'image/svg+xml'});
      let svgUrl = URL.createObjectURL(blob);
      
      var image = new Image();
      image.onload = function() {
        
        newCanvasCtx.drawImage(image, 0, 0, newCanvas.width, newCanvas.height);
        var image2 = new Image(); 
        image2.onload = function() {
          newCanvasCtx.drawImage(image2, 0, 0); 
          callback(newCanvas, image)
        }
        image2.src = svgUrl;

        //newCanvasCtx.drawImage(image, 0, 0, thumbnailSize.width, thumbnailSize.height);
        // Using Canvas - linear interpolation, 3-steps
        // Huge difference in clarity
        // var c1 = document.createElement('canvas');
        // c1.width = thumbnailSize.width * 4;
        // c1.height = thumbnailSize.height * 4;
        // c1.getContext('2d').drawImage(image, 0, 0, c1.width, c1.height);

        // var c2 = document.createElement('canvas');
        // c2.width = thumbnailSize.width * 2;
        // c2.height = thumbnailSize.height * 2;
        // c2.getContext('2d').drawImage(c1, 0, 0, c2.width, c2.height);
      
        // var r = document.createElement('canvas');
        // r.width = thumbnailSize.width;
        // r.height = thumbnailSize.height;
        // r.getContext('2d').drawImage(c2, 0, 0, r.width, r.height);

        // var w = window.open("")
        // //const img3 = newCanvas.toDataURL("image/png");
        // const img3 = r.toDataURL("image/png");
        // w.document.write('<img src="'+img3+'"/>');
        // w.document.close()
      };
      image.src = state.documentPage1Base64

      // var w = window.open("")
      // //const img3 = canvas.toDataURL("image/png");
      // w.document.write('<img src="'+state.documentPage1Base64+'"/>');
      // w.document.close()

      //grab the context from your destination canvas
      // var destCtx = destinationCanvas.getContext('2d');
      
      // //call its drawImage() function passing it the source canvas directly
      // destCtx.drawImage(sourceCanvas, 0, 0);

  }

  const saveTemplate = () => {

    if (validateInputs() === 0) {
      PlainSwal.fire({
        title: 'Saving Template',
        allowEscapeKey: false,
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          PlainSwal.showLoading()
        },
      })

      let fileId = state.files[0].serverId
      if(fileId.startsWith("\"") && fileId.endsWith("\""))
      {
        fileId = fileId.substring(1, fileId.length-1);
      }
      console.log("file serverId", fileId)

      const visibleMarkers = state.markers.filter(m => m.isVisible)

      getImages(async (imgFull, imgThumbnail) => {
        const payload = {
          name: state.templateName,
          description: state.templateDescription,
          pageOption: state.pageOption,
          pageOptionNumPages: state.pageOptionNumPages,
          fileId: fileId,
          //documentBlobUrl: undefined,
          //documentIsRendered: false,
          markers: visibleMarkers,
          thumbnail: imgThumbnail.split(',')[1],
          highRes: imgFull.split(',')[1]
        }
  
        console.log('payload', payload)

        await addressWizardService.SaveTemplate(payload).catch(error => PlainSwal.close())

        setTimeout(() => {
          PlainSwal.close()
        }, 200);

        dispatch({ type: 'SET', name: 'isLoading', value: true })
        await populateState()
        dispatch({ type: 'RESET_EDITOR' })
      })
    }
  }

  const deleteTemplate = (id, name) => {
    PlainSwal.fire({
      title: 'Delete Template',
      html: `Are you sure you want to delete the "${name}" template?`,
      showCloseButton: true,
      showCancelButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary btn-lg me-1',
        cancelButton: 'btn btn-secondary btn-lg ms-1'
      },
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.isConfirmed) {
        onDeleteTemplateConfirmed(id)
      }
    })     
  }

  const onDeleteTemplateConfirmed = async (id) => {
    const result = await addressWizardService.DeleteTemplate(id)
    if (result && result.isSuccess) {

      toast.success('Template Deleted', {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })

      const remainder = state.templates.filter(template => template.id !== id)
      dispatch({ type: 'SET', name: 'templates', value: remainder })
    }
  }

  return (
    <>
      {!state.isLoading && (
        <>
          <div className='grid-outer-buttons mt-3 justify-content-end'>
                <Button color='dark' onClick={toggleEditor}><div className='d-flex flex-row align-items-center'><div className='d-flex me-2 pt-1'><PlusCircleFill /></div><div className='d-flex'>New Template</div></div></Button>
          </div>
          <SendMailAddressWizardManagerGrid
            isLoading={state.isLoading}
            listingData={state.templates}
            onSelectionChanged={onSelectionChanged}
            onDeleteTemplate={deleteTemplate}
          />

          <Modal isOpen={state.showEditor} keyboard={false} toggle={toggleEditor} fullscreen>
            <ModalHeader toggle={toggleEditor} className='text-primary bg-light'><Magic size={28} /> <span className='text-white'>Create Address Wizard Template</span></ModalHeader>
            <ModalBody className='pt-0 pb-0 pe-0 overflow-hidden'>
              <SendMailAddressWizardManagerEditor
                scale={state.scale}
                isFileUploading={state.isFileUploading}
                onScaleChange={onScaleChange}
                documentBlobUrl={state.documentBlobUrl}
                documentIsRendered={state.documentIsRendered}
                onDocumentIsRendered={onDocumentIsRendered}
                hasDocument={(state.hasDocument)}
                pageOption={state.pageOption}
                onPageOptionChange={onPageOptionChanged}
                costCodeOption={state.costCodeOption}
                onCostCodeOptionChange={onCostCodeOptionChanged}
                returnAddressOption={state.returnAddressOption}
                onReturnAddressOptionChange={onReturnAddressOptionChange}
                templateName={state.templateName}
                onTemplateNameChanged={(e) => dispatch({ type: 'SET', name: 'templateName', value: e.target.value })}
                templateDescription={state.templateDescription}
                onTemplateDescriptionChanged={(e) => dispatch({ type: 'SET', name: 'templateDescription', value: e.target.value })}
                markers={state.markers}
                onMarkerChanged={onMarkerChanged}
                onInitMarkers={onInitMarkers}
                //onRunAddressTest={onRunAddressTest}
                pageOptionNumPages={state.pageOptionNumPages}
                onPageOptionNumPagesChanged={(newNumPages) => dispatch({ type: 'SET', name: 'pageOptionNumPages', value: newNumPages })}
                pageOptionNumPagesError={state.pageOptionNumPagesError}
                onPageOptionNumPagesErrorChanged={(error) => dispatch({ type: 'SET', name: 'pageOptionNumPagesError', value: error })}
                costCodesEnabled={state.costCodesEnabled}
              >
                <PdfUploader
                  labelIdle='Drag & Drop your sample letter file or <span class="filepond--label-action">Browse</span>'
                  labelFileProcessing='Loading Sample PDF'
                  labelFileProcessingComplete='PDF Sample Loaded'
                  authToken={state.authToken}
                  //files={state.files}
                  //onChange={() => {}}
                  // onChange={(files) => {
                  //   if (files && files.length === 1 /*&& files[0].file && files[0].file.size !== 0*/) {
                  //     console.log('files', files)
                  //     console.log('files', files[0])
                  //     console.log('files status', files[0].status)
                  //     console.log('files', files[0].source)
                  //     const file = files[0].file
                  //     console.log('file', file)
                  //     //const url = URL.createObjectURL(file)
                  //     //console.log(url)
                  //     //dispatch({ type: 'SET', name: 'documentBlobUrl', value: url})
  
                  //     // setTimeout(() => {
                  //     //   onBlobUrlReady(url, filePondRef.current.getFiles())  
                  //     // }, 1000);
  
                  //     // const newFiles = files.map((f) => { return {
                  //     //   file: f.file,
                  //     //   fileExtension: f.fileExtension,
                  //     //   fileSize: f.fileSize,
                  //     //   fileType: f.fileType,
                  //     //   filename: f.filename,
                  //     //   filenameWithoutExtension: f.filenameWithoutExtension,
                  //     //   id: f.id,
                  //     //   origin: f.origin,
                  //     //   relativePath: f.relativePath,
                  //     //   serverId: f.serverId,
                  //     //   source: url,
                  //     //   status: f.status,
                  //     //   transferId: f.transferId,
                  //     //   options: {...f.options, type: "local"}
                  //     // }})

                  //     // const newFiles = files.map((f) => { return {
                  //     //   // the server file reference
                  //     //   source: f.serverId,
            
                  //     //   // set type to local to indicate an already uploaded file
                  //     //   options: {
                  //     //       type: 'local',
            
                  //     //       // mock file information
                  //     //       file: {
                  //     //           name: f.filename,
                  //     //           size: f.fileSize,
                  //     //           type: f.fileType
                  //     //       },
                  //     //   }
                  //     // }})
                  //       console.log('newFiles', files)

                  //     dispatch({ type: 'SET', name: 'files', value: { files: files }})
                  //   }
                  // }}
                  //onRemove={onPdfRemove}
                  onRemoved={onPdfRemoved}
                  onBlobUrlReady={(url, files) => { 
                    dispatch({ type: 'SET', name: 'documentBlobUrl', value: url}) 
                    dispatch({ type: 'SET', name: 'files', value: files})
                    dispatch({ type: 'SET', name: 'hasDocument', value: (files && files.length === 1)})
                  }}
                  onChange={(files) => dispatch({ type: 'SET', name: 'files', value: { files: files }})}
                  onIsUploadingChanged={onIsUploadingChanged}
                />
              </SendMailAddressWizardManagerEditor>
              <Modal
                isOpen={state.showAddressResults}
                toggle={toggleAddressResults}
                size="xl"
                //onClosed={closeAll ? toggle : undefined}
              >
                <ModalHeader toggle={toggleAddressResults}>Extracted Addresses</ModalHeader>
                <ModalBody>
                  <AddressWizardResultGrid addresses={state.addressResults} costCodesEnabled={state.costCodesEnabled} />
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={toggleAddressResults}>
                    Close
                  </Button>{' '}
                </ModalFooter>
              </Modal>
            </ModalBody>
            <ModalFooter>
              <div className=''>
                <div className='d-flex flex-full justify-content-center align-items-center'>
                  <Button innerRef={addressTestButtonRef} color='success' onClick={onRunAddressTest} disabled={state.isFileUploading || !state.documentIsRendered}>Run Address Test</Button>
                  
                  <UncontrolledTooltip delay={400} placement='top' target={addressTestButtonRef}>
                    Using the chosen settings, the wizard will find and display the corresponding addresses found throughout the samples file.
                  </UncontrolledTooltip>
                </div>
              </div>

              <Button color="primary" onClick={saveTemplate} disabled={state.isFileUploading || !state.documentIsRendered}>
                Save Template
              </Button>{' '}
              <Button color="secondary" onClick={toggleEditor}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  )
}

export default SendMailAddressWizardManager