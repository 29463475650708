import React, { useEffect } from 'react'
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { ConstantCodeMailFuzeFixDocumentDimensions } from '../../services/SettingsService'
import useSettingsContext from './SettingsContext'
import { doRegisterInputCheckbox } from './FormHookRegisters'
import { useRef } from 'react'

export const GeneralStandardSettingsState = {
  mailFuzeFixDocumentDimensions: {
    constant: ConstantCodeMailFuzeFixDocumentDimensions,
    code: undefined,
    type: 'Boolean',
    value: false
  }
}

export const GeneralStandardSettingsSchema = {
  mailFuzeFixDocumentDimensions: yup.object().shape({
    value: yup.boolean().required()
  }),
}

const InternalSchema = yup.object({
  ...GeneralStandardSettingsSchema
})

const GeneralStandardSettings = ({onChange}) => {

  const formRef = useRef(null)
  const {context: state, contextReducer: dispatchSecond} = useSettingsContext()
  console.log('Loading General Standard Tab with context', state)

  const {
    //setFocus,
    register,
    setValue,
    //getFieldState,
    handleSubmit,
    trigger,
    reset,
    //control
    formState: { errors, isSubmitted }
  } = useForm({
    defaultValues: {
      ...state
    },
    resolver: yupResolver(InternalSchema),
    mode: "onChange", //not sure this is necessary
    context: { state } //not sure this is necessary try to remove after stability achieved.
  });

  const dispatch = (action) => {
    action.setValue = setValue
    dispatchSecond(action)
    if (state.isSubmitted || (action.name === 'isSubmitted' && action.value === true)) {
      trigger()
    }
  }

  useEffect(() => {
    reset({
      ...state
    });
    if (state.isSubmitted){
      trigger();
    }
      
    }, [state]);

  useEffect(() => {
    if (state.isSubmitted === false && isSubmitted) {
      dispatch({ type: 'SET', name: 'isSubmitted', value: isSubmitted })
    }

  }, [state.isSubmitted, isSubmitted])

  //trigger will change on the first load. It's only way to run the validation on the default values.
  useEffect(() => {
    if (state.isSubmitted) {
      trigger()
    }
  }, [trigger, state.isSubmitted, state.currentTabIndex])


  return (
    <div>
      <React.Fragment>
        <Form ref={formRef}
          onSubmit={handleSubmit(state.onSubmit, state.onError)}
        >
          <Row>
            <Col xs={12} md={12} lg={5} xl={4}>
              <Label>Letter Page Dimensions:</Label>
            </Col>
            <Col xs={12} md={12} lg={7} xl={8}>
              <FormGroup check inline>
                <div style={{paddingLeft: '2px'}}>
                  <Input
                    {...doRegisterInputCheckbox(register, dispatch, 'mailFuzeFixDocumentDimensions', state.isSubmitted, errors, state)}
                    style={{transform: 'scale(1.3)'}}
                  />
                  {' '}
                  <Label check for='mailFuzeFixDocumentDimensions'>
                    I want MailFuze to automatically resize any pages that do not match the letter type dimensions
                  </Label>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={{ size: 12 }} lg={{ size: 7, offset: 5 }} xl={{ size: 8, offset: 4 }}>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    </div>
  )
}

export default GeneralStandardSettings