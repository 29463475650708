import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import { useForm, useFormState } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import Setting from './Setting'
import { useSettings } from './SettingsReducer'
import useSettingsContext from './SettingsContext'
import { doRegisterInput, doRegisterInputCheckbox, doRegisterInputRadio, doRegisterSelect } from './FormHookRegisters' 
import { Blank } from '../dropdowns/DefaultSelectOptions'
import { toast } from 'react-toastify'
import { ConstantCodeMailFuzeCreateBillSubscriptionFee,
  ConstantCodeMailFuzeSubscriptionFeeGLAccountID,
  ConstantCodeMailFuzeSubscriptionFeePropertyMethod,
  ConstantCodeMailFuzeSubscriptionFeeThisPropertyID,
  ConstantCodePaymentFeeGLAccountID,
} from '../../services/SettingsService'
import { MailFuzeSubscriptionFeeGLAccountIDSelect,
  MailFuzeSubscriptionFeeThisPropertyIDSelect,
  PaymentFeeGLAccountIDSelect } from './SubscriptionBillingDowns'

export const SubscriptionBillingSettingsState = {

  mailFuzeCreateBillSubscriptionFee: {
    constant: ConstantCodeMailFuzeCreateBillSubscriptionFee,
    code: undefined,
    type: 'Boolean',
    value: false
  },
  mailFuzeSubscriptionFeeGLAccountID: {
    constant: ConstantCodeMailFuzeSubscriptionFeeGLAccountID,
    code: undefined,
    type: 'Integer',
    value: undefined
  },
  paymentFeeGLAccountID: {
    constant: ConstantCodePaymentFeeGLAccountID,
    code: undefined,
    type: 'Integer',
    value: undefined
  },
  mailFuzeSubscriptionFeePropertyMethod: {
    constant: ConstantCodeMailFuzeSubscriptionFeePropertyMethod,
    code: undefined,
    type: 'Integer',
    value: 0
  },
  mailFuzeSubscriptionFeePropertyMethodPostfix: {
    type: 'String',
    value: undefined
  },
  mailFuzeSubscriptionFeeThisPropertyID: {
    constant: ConstantCodeMailFuzeSubscriptionFeeThisPropertyID,
    code: undefined,
    type: 'Integer',
    value: Blank.value
  }
}

export const SubscriptionBillingSettingsSchema = { //yup.object({
  mailFuzeCreateBillSubscriptionFee: yup.object().shape({
    value: yup.boolean().required()
  }),
  mailFuzeSubscriptionFeeGLAccountID: yup.object()
    .when('mailFuzeCreateBillSubscriptionFee', {
      is: (mailFuzeCreateBillSubscriptionFee) => {
        return mailFuzeCreateBillSubscriptionFee.value === true
      },
      then: yup.object({
        value: yup.number().integer().moreThan(0, 'Please select a GL Account.').required()
      }),
      otherwise: yup.object({
        value: yup.number().notRequired()
      })
    }),
  paymentFeeGLAccountID: yup.object()
    .when('mailFuzeCreateBillSubscriptionFee', {
      is: (mailFuzeCreateBillSubscriptionFee) => {
        return mailFuzeCreateBillSubscriptionFee.value === true
      },
      then: yup.object({
        value: yup.number().integer().moreThan(0, 'Please select a GL Account.').required()
      }),
      otherwise: yup.object({
        value: yup.number().notRequired()
      })
    }),
  mailFuzeSubscriptionFeePropertyMethod: yup.object({
    value: yup.number().integer().oneOf([0, 1]).required()
  }),
  mailFuzeSubscriptionFeeThisPropertyID: yup.object()
    // .when(['mailFuzeCreateBillSubscriptionFee', 'mailFuzeSubscriptionFeePropertyMethod'], {
    //   is: (mailFuzeCreateBillSubscriptionFee, mailFuzeSubscriptionFeePropertyMethod) => { 
    //     return mailFuzeCreateBillSubscriptionFee.value === false
    //       || mailFuzeSubscriptionFeePropertyMethod.value === 0
    //   },
    //   then: yup.object({
    //     value: yup.mixed().nullable().notRequired()
    //   }),
    //   otherwise: yup.mixed().notRequired()
    // })
    .when(['mailFuzeCreateBillSubscriptionFee', 'mailFuzeSubscriptionFeePropertyMethod'], {
      is: (mailFuzeCreateBillSubscriptionFee, mailFuzeSubscriptionFeePropertyMethod) => { 
        return mailFuzeCreateBillSubscriptionFee.value === true && mailFuzeSubscriptionFeePropertyMethod.value === 1
      },
      then: yup.object({
        value: yup.number().integer().moreThan(0, 'Please select a property.')
      }),
      otherwise: yup.object({
        value: yup.mixed().notRequired()
      })
    })
    // .when('mailFuzeSubscriptionFeePropertyMethod', {
    //   is: (mailFuzeSubscriptionFeePropertyMethod) => mailFuzeSubscriptionFeePropertyMethod.value === 0,
    //   then: yup.object({
    //     value: yup.number().integer().notRequired()
    //   })
    // })
  // mailFuzeSubscriptionFeeThisPropertyID: yup.object().shape({
  //   value: yup.number().integer().when(['mailFuzeCreateBillSubscriptionFee', 'mailFuzeSubscriptionFeePropertyMethod'], {
  //     is: (mailFuzeCreateBillSubscriptionFee, mailFuzeSubscriptionFeePropertyMethod) => {
  //       console.log('mailFuzeCreateBillSubscriptionFee', mailFuzeCreateBillSubscriptionFee)
  //       console.log('mailFuzeSubscriptionFeePropertyMethod', mailFuzeSubscriptionFeePropertyMethod)
  //       if (mailFuzeCreateBillSubscriptionFee === true && mailFuzeSubscriptionFeePropertyMethod === 1) {
  //           return true // is required
  //       } else return false // for all other cases mailFuzeSubscriptionFeeThisPropertyID is not required        
  //     },
  //     then: yup.number().integer().transform((curr, orig) => orig.value.toString() === Blank.value.toString() ? null : curr).required("Please select a property.")
  //   }
  //   )
  // })
  // mailFuzeSubscriptionFeeThisPropertyID: yup.mixed().when(['mailFuzeCreateBillSubscriptionFee', 'mailFuzeSubscriptionFeePropertyMethod'], {
  //   is: (mailFuzeCreateBillSubscriptionFee, mailFuzeSubscriptionFeePropertyMethod) => {
  //     if (mailFuzeCreateBillSubscriptionFee === true && mailFuzeSubscriptionFeePropertyMethod === 1) {
  //         return true // is required
  //     } else return false // for all other cases mailFuzeSubscriptionFeeThisPropertyID is not required
  //   },
  //   then: yup.mixed().transform((curr, orig) => orig.toString() === Blank.value.toString() ? null : curr).required("Please select a property.")
  // })
}//)//.required();

const InternalSchema = yup.object({
  ...SubscriptionBillingSettingsSchema
})

const SubscriptionBillingSettings = () => {

  const formRef = useRef(null)
  const {context: state, contextReducer: dispatchSecond} = useSettingsContext()

  // const dispatch = (action) => {
  //   //setValue(action.name, action.value, { shouldValidate: isSubmitted })
  //   setValue(action.name, action.value, { shouldValidate: state.isSubmitted })
  //   dispatchSecond(action)
  // }

  //const [state, dispatch] = useReducer(settings.settingsReducer2, settingsContext.context)
  console.log('Loading Subcriptions Billing Tab with context', state)

  const {
    //setFocus,
    register,
    setValue,
    //getFieldState,
    handleSubmit,
    trigger,
    reset,
    //control
    formState: { errors, isSubmitted }
  } = useForm({
    defaultValues: {
      ...state
    },
    // defaultValues: useMemo(() => {
    //   return {...state}
    // }, [state]),
    // defaultValues: {
    //   // mailFuzeCreateBillSubscriptionFee: state.mailFuzeCreateBillSubscriptionFee,
    //   // mailFuzeSubscriptionFeeGLAccountID: state.mailFuzeSubscriptionFeeGLAccountID,
    //   // paymentFeeGLAccountID: state.paymentFeeGLAccountID,
    //   // mailFuzeSubscriptionFeePropertyMethod: state.mailFuzeSubscriptionFeePropertyMethod,
    //   // mailFuzeSubscriptionFeeThisPropertyID: state.mailFuzeSubscriptionFeeThisPropertyID
    //   ...state
    // },
    resolver: yupResolver(InternalSchema),
    mode: "onChange", //not sure this is necessary
    context: { state } //not sure this is necessary try to remove after stability achieved.
  });

  const dispatch = (action) => {
    action.setValue = setValue

    dispatchSecond(action)
    if (state.isSubmitted || (action.name === 'isSubmitted' && action.value === true)) {
      trigger()
    }
  }

  useEffect(() => {
    reset({
      ...state
    });
    if (state.isSubmitted){
      trigger();
    }
      
    }, [state]);

  // useEffect(() => {
  //   settings.notifyChangeWhenReady(onUpdateContext, state)
  // }, [state])

  useEffect(() => {
    if (state.isSubmitted === false && isSubmitted) {
      dispatch({ type: 'SET', name: 'isSubmitted', value: isSubmitted })
    }

  }, [state.isSubmitted, isSubmitted])

  //trigger will change on the first load. It's only way to run the validation on the default values.
  useEffect(() => {
    if (state.isSubmitted) {
      trigger()
    }
  }, [trigger, state.isSubmitted, state.currentTabIndex])

  // const save = async (e) => {
  //   e.target.blur();
  //   settings.saveSettingsConstants(state, dispatch, 'Other settings saved!')
  // }

  const save = async (e) => {
    e.target.blur();
    formRef.current.props.onSubmit(e)
    //settings.saveSettingsConstants(state, dispatch, 'Other settings saved!')
  }

  // const onSubmit = (data, e) => {
  //   console.log(data)
  //   console.log(e)

  //   //onSave()

  //   //e.preventDefault();
  //   // dispatch({ type: 'SET', name: 'errors', value: [] })
  //   // dispatch({ type: 'SET', name: 'data', value: data })
  //   // dispatch({ type: 'SET', name: 'isSubmitted', value: true })

  //   // nextStep();
  // }

  // const onError = (stepErrors, e) => {
  //   //e.preventDefault()
  //   //e.stopPropagation();

  //   //console.log(stepErrors, e)
  //   console.log('stepErrors', stepErrors)

  //   const firstError = Object.keys(stepErrors).reduce((field, a) => {
  //     return !!stepErrors[field] ? field : a;
  //   }, null);
  
  //   // if (firstError) {
  //   //   console.log('set focus call', firstError)
  //   //   //firstError.ref.focus()
  //   //   //setFocus(firstError.ref);
  //   // }

  //   toast.error('Please correct all errors.', {
  //     position: "bottom-right",
  //     autoClose: 4000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: false
  //   })

  //   allErrors.forEach(element => {
      
  //   });
  //   toast.error()
  // }


  return (
    <div>
      <React.Fragment>
        <Form ref={formRef} 
          onSubmit={handleSubmit(state.onSubmit, state.onError)}
        >
          <Row>
            <Col md={11} lg={10} xl={9}>
              <Row className="mt-3">
                <Col xs={12}>
                  <FormGroup
                    check
                    inline
                  >
                    <Input
                      {...doRegisterInputCheckbox(register, dispatch, 'mailFuzeCreateBillSubscriptionFee', state.isSubmitted, errors, state)}
                      style={{transform: 'scale(1.3)'}}
                    />
                    <Label for='mailFuzeCreateBillSubscriptionFee'>I want MailFuze to automatically create my bills in my Rent Manager database for the MailFuze subscription fee</Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row className={`mt-2 ps-5 ${state.mailFuzeCreateBillSubscriptionFee.value === false ? 'text-muted' : ''}`}>
                <Col xs={12}>
                  <Row>
                    <Col xs={12}>
                      <Setting label='GL Account to Use on Bill for Monthly Subscription:' description=''>
                        <MailFuzeSubscriptionFeeGLAccountIDSelect
                          options={state.mailFuzeSubscriptionFeeGLAccountID.options}
                          {...doRegisterSelect(register, dispatch, 'mailFuzeSubscriptionFeeGLAccountID', state.isSubmitted, errors, state, '', true)}
                          isDisabled={state.mailFuzeCreateBillSubscriptionFee.value === false}
                        />
                      </Setting>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Setting label='GL Account to Use on Bill for Payment Processing Fee:' description=''>
                        <PaymentFeeGLAccountIDSelect
                          options={state.paymentFeeGLAccountID.options}
                          {...doRegisterSelect(register, dispatch, 'paymentFeeGLAccountID', state.isSubmitted, errors, state, '', true)}
                          isDisabled={state.mailFuzeCreateBillSubscriptionFee.value === false}
                        />
                      </Setting>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                        <FormGroup tag="fieldset" className="border p-2 mt-3">
                          <legend className="float-none w-auto ps-1 pe-1 fs-6 text-muted" style={{fontWeight: '500'}}>Property To Use On Bills</legend>

                          <div className='ps-3 pe-3 d-flex flex-column'>
                            <FormGroup check>
                              <Input
                                {...doRegisterInputRadio(0, register, dispatch, 'mailFuzeSubscriptionFeePropertyMethod', state.isSubmitted, errors, state)}
                                //{...doRegisterInput(register, dispatch, 'mailFuzeSubscriptionFeePropertyMethod', state.isSubmitted, errors, state)}
                                disabled={state.mailFuzeCreateBillSubscriptionFee.value === false || state.mailFuzeSubscriptionFeePropertyMethodPostfix.value === "Not Found"}
                                //type="radio"
                                //name='mailFuzeSubscriptionFeePropertyMethod'
                                //id='mailFuzeSubscriptionFeePropertyMethod0'
                                //value="0"
                                //checked={state.mailFuzeSubscriptionFeePropertyMethod === "0" ? 'checked' : ''}
                                //onChange={e => dispatch({ type: 'SET', name: e.target.name, selected: { label: state.mailFuzeSubscriptionFeePropertyMethod, value: e.target.value }})}
                                style={{transform: 'scale(1.3)'}}
                              />
                              {' '}
                              <Label check for='mailFuzeSubscriptionFeePropertyMethod0'>
                                Management Company Property in Rent Manager System Settings <span style={{color: (state.mailFuzeSubscriptionFeePropertyMethodPostfix.value === "Not Found" ? 'var(--bs-danger)' : 'inherit')}}>{`(${state.mailFuzeSubscriptionFeePropertyMethodPostfix.value})`}</span>
                              </Label>
                            </FormGroup>

                            <div className='d-flex flex-row align-items-baseline'>
                              <div>
                                <FormGroup check>
                                  <Input
                                    {...doRegisterInputRadio(1, register, dispatch, 'mailFuzeSubscriptionFeePropertyMethod', state.isSubmitted, errors, state)}
                                    disabled={state.mailFuzeCreateBillSubscriptionFee.value === false}
                                    //type="radio"
                                    //name='mailFuzeSubscriptionFeePropertyMethod'
                                    //id='mailFuzeSubscriptionFeePropertyMethod1'
                                    //value="1"
                                    //checked={state.mailFuzeSubscriptionFeePropertyMethod === "1" ? 'checked' : ''}
                                    style={{transform: 'scale(1.3)'}}
                                  />
                                  {' '}
                                  <Label check for='mailFuzeSubscriptionFeePropertyMethod1'>
                                    This Property:
                                  </Label>
                                </FormGroup>
                              </div>
                              <div className='ms-2 d-flex flex-grow-1 flex-column'>
                                <MailFuzeSubscriptionFeeThisPropertyIDSelect
                                  options={state.mailFuzeSubscriptionFeeThisPropertyID.options}
                                  {...doRegisterSelect(register, dispatch, 'mailFuzeSubscriptionFeeThisPropertyID', state.isSubmitted, errors, state, '', true)}
                                  isDisabled={state.mailFuzeCreateBillSubscriptionFee.value === false || state.mailFuzeSubscriptionFeePropertyMethod.value === 0}
                                />
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row>
            <Col md={11} lg={8} xl={7} className="d-flex justify-content-end mb-3 mt-3">
              <Button color="dark" onClick={save}>Save</Button>
            </Col>
          </Row> */}
        </Form>
      </React.Fragment>
    </div>
  )
}

export default SubscriptionBillingSettings
