import BaseSelect2 from "../dropdowns/BaseSelect2";

export const MailFuzeReturnAddressThisPropertySelect = ({ options, onChange, value, ...rest }) => {
  return (
      <BaseSelect2
          options={options}
          onChange={onChange}
          value={value}
          selectFirst={false}
          {...rest}
      />
  )
}

export const MailFuzeReturnAddressThisPropertyAddressTypeSelect = ({ options, onChange, value, ...rest }) => {
  return (
      <BaseSelect2
          options={options}
          onChange={onChange}
          value={value}
          selectFirst={false}
          {...rest}
      />
  )
}

export const MailFuzeReturnAddressStateIDSelect = ({options, onChange, onError, value, registerMe = () => {}, valid, invalid, message, ...rest}) => {
  return (
      <BaseSelect2
        options={options}
        value={value}
        onChange={onChange}
        onError={onError}
        selectFirst={false}
        invalid={invalid}
        valid={valid}
        registerMe={registerMe}
        message={message}
        {...rest}
      />
  )
}

export const MailFuzeReturnAddressRecipientAddressTypeSelect = ({ options, onChange, value, ...rest }) => {
  return (
      <BaseSelect2
          options={options}
          onChange={onChange}
          value={value}
          selectFirst={false}
          {...rest}
      />
  )
}
