import BaseSelect2 from "../dropdowns/BaseSelect2";

export const MailFuzeOwnerInvoiceChargeTypeIDSelect = ({ options, onChange, value, ...rest }) => {
  return (
      <BaseSelect2
          options={options}
          onChange={onChange}
          value={value}
          selectFirst={false}
          {...rest}
      />
  )
}

export const MailingGLAccountIDSelect = ({ options, onChange, value, ...rest }) => {
  return (
      <BaseSelect2
        options={options}
        onChange={onChange}
        value={value}
        selectFirst={false}
        {...rest}
      />
  )
}

export const MailFuzeOwnerBillThisVendorIDSelect = ({ options, onChange, value, ...rest }) => {
  return (
      <BaseSelect2
        options={options}
        onChange={onChange}
        value={value}
        selectFirst={false}
        {...rest}
      />
  )
}

export const MailFuzeChargeBackOwnersMethodSelect = ({ options, onChange, value, ...rest }) => {
  return (
      <BaseSelect2
        options={options}
        onChange={onChange}
        value={value}
        selectFirst={false}
        {...rest}
      />
  )
}