import React, { useEffect, useLayoutEffect, useReducer, useRef, useState } from 'react'
import { Alert, Badge, Button, CarouselControl, CarouselIndicators, CarouselItem, Col, Input, Label, ListGroup, ListGroupItem, Offcanvas, OffcanvasBody, Row, UncontrolledCarousel } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Check2Square, EnvelopeCheck, ExclamationOctagon, SendPlus, SendX, Stop, StopCircle, Stoplights, X, XCircle, XCircleFill } from 'react-bootstrap-icons';

const SendMailValidationResult = ({ showCanvas, onClose, children }) => {

  const initialState = {
    showCanvas: false,
    errorPageIndex: 0
  }

  const stateReducer = (state, action) => {
    switch (action.type) {
      case 'SET': {
        return {
          ...state,
          [action.name]: action.value
        }
      }
    }

    return state
  }

  const [state, dispatch] = useReducer(stateReducer, initialState)

  const toggleCanvas = () => {
    setShowCanvas(!state.showCanvas)
  }

  const setShowCanvas = (show) => {
    dispatch({ type: 'SET', name: 'showCanvas', value: show })
    if (!show) {
      setIndex(0)
      onClose()
    }
  }

  const setIndex = (index) => {
    dispatch({type: 'SET', name: 'errorPageIndex', value: index})
  }

  useEffect(() => {
    if ((showCanvas || showCanvas !== state.showCanvas)) {
      setShowCanvas(true)
    } else {
      setShowCanvas(false)
    }
  }, [/*selectedMailingData, */showCanvas])

  const filteredChildren = React.Children.toArray(children).filter(child => child != null && child != undefined)
  
  const handleNext = () => {
    const errorCount = filteredChildren && filteredChildren.length || 0
    const nextIndex = state.errorPageIndex < errorCount - 1 ? state.errorPageIndex + 1 : 0;
    if (errorCount > 0) {
      setIndex(nextIndex)
    }
  };

  const handlePrev = () => {
    const errorCount = filteredChildren && filteredChildren.length || 0
    const nextIndex = state.errorPageIndex > 0 ? state.errorPageIndex - 1 : errorCount - 1;
    if (errorCount > 0) {
      setIndex(nextIndex)
    }
  };

  const handleGo = (goIndex) => {
    setIndex(goIndex);
  }

  return (
    <Offcanvas
      scrollable
      direction="end"
      backdrop={false}
      isOpen={state.showCanvas}
      // offcanvasTransition={ {   
      
      // timeout: 900,
      // appear: true,
      // enter: true,
      // exit: true,
      // in: true } }
      toggle={toggleCanvas}
      className='offcanvas-end send-mail-validation-fade'
      returnFocusAfterClose={false}
    >
      <Button color="dark-outline" className="btn-close-off-canvas align-self-start" style={{float: 'right', marginRight: '-25px', marginBottom: '-30px', zIndex: 1001}} onClick={toggleCanvas}><X size={25} /></Button>
      <OffcanvasBody style={{minHeight: '100%', display: 'flex', width: '100%' }} className='bg-danger'>
        <Row className="d-flex flex-fill mt-3">
          <Col className='d-flex flex-column flex-fill'>
            <fieldset className="bg-white border rounded d-flex flex-column flex-fill">
              {/* <legend className='pt-4 px-4'><Badge color='danger'><SendX size={22} /></Badge> Validation Errors</legend> */}
              <legend className='pt-4 px-4 text-danger d-flex flex-column align-items-center'>Validation Error{filteredChildren && filteredChildren.length > 1 ? <>s</> : <></>} {/*<ExclamationOctagon />*/}</legend>
                {filteredChildren && filteredChildren.length > 1 ? (
                  <React.Fragment>
                  <div className='my-1 d-flex flex-row justify-content-center send-mail-validation-paging'>
                    {filteredChildren.map((item, index) => (
                        <Button key={`error-button-${index}`} color={`${state.errorPageIndex === index ? 'dark' : 'primary'}`} onClick={() => handleGo(index)}>{index + 1}</Button>
                    ))}
                  </div>
                  </React.Fragment>
                ) : (<></>)}

              {/* <div className='my-1 d-flex flex-row justify-content-center send-mail-validation-paging'>
                <Button color={`${state.errorPageIndex === 0 ? 'dark' : 'primary'}`} onClick={() => handleGo(0)}>1</Button>
                <Button color={`${state.errorPageIndex === 1 ? 'dark' : 'primary'}`} onClick={() => handleGo(1)}>2</Button>
                <Button color={`${state.errorPageIndex === 2 ? 'dark' : 'primary'}`} onClick={() => handleGo(2)}>3</Button>
              </div> */}
              <div className='px-4'><hr /></div>
              <div className="carousel carousel-dark slide d-flex flex-column flex-fill" data-bs-ride="carousel">
      
                <div className="carousel-inner pt-1 d-flex flex-column flex-fill" role="listbox">
                  {filteredChildren && filteredChildren.length !== 0 ? (filteredChildren.map((item, index) => (
                    <div key={`error-${index}`} className={`carousel-item px-4${state.errorPageIndex === index ? ' active' : ''}`}>
                      { item }
                    </div>
                  ))) : (<></>)}
                  {/* <div className={`carousel-item px-4 ${state.errorPageIndex === 1 ? 'active' : ''}`}>
                    { GenerateWarning2() }
                  </div>
                  <div className={`carousel-item px-4 ${state.errorPageIndex === 2 ? 'active' : ''}`}>
                    { GenerateWarning3() }
                  </div> */}
                </div>
                {filteredChildren && filteredChildren.length > 1 && (
                  <React.Fragment>
                    <button
                      className="carousel-control-prev align-items-start justify-content-start"
                      style={{bottom: 'auto'}}
                      type="button"
                      onClick={handlePrev}
                    >
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    </button>
                    <button
                      className="carousel-control-next align-items-start justify-content-end"
                      style={{bottom: 'auto'}}
                      type="button"
                      onClick={handleNext}
                    >
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    </button>
                  </React.Fragment>
                )}
              </div>
            </fieldset>
          </Col>
        </Row>
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default SendMailValidationResult