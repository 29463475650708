import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import { useForm, useFormState } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import Setting from './Setting'
import { useSettings } from './SettingsReducer'
import useSettingsContext from './SettingsContext'
import { doRegisterInput, doRegisterInputCheckbox, doRegisterInputRadio, doRegisterSelect } from './FormHookRegisters'
import { Blank } from '../dropdowns/DefaultSelectOptions'
import { toast } from 'react-toastify'
import { ConstantCodeMailFuzeChargeBackOwners,
  ConstantCodeMailFuzeChargeBackOwnersMethod,
  ConstantCodeMailFuzeChargeBackOwnersMarkup,
  ConstantCodeMailFuzeChargeBackOwnersMarkupMethod,
  ConstantCodeMailFuzeChargeBackOwnersMarkupAmount,
  ConstantCodeMailFuzeChargeBackOwnersMarkupAmountEnabled,
  ConstantCodeMailFuzeChargeBackOwnersMarkupPercent,
  ConstantCodeMailFuzeChargeBackOwnersMarkupPercentEnabled,
  ConstantCodeMailFuzeCreateOwnerBill,
  ConstantCodeMailFuzeCreateOwnerInvoice,
  ConstantCodeMailFuzeOwnerBillThisVendorID,
  ConstantCodeMailFuzeOwnerBillVendorMethod,
  ConstantCodeMailFuzeOwnerInvoiceChargeTypeID,
  ConstantCodeMailingGLAccountID} from '../../services/SettingsService'
import { MailFuzeOwnerBillThisVendorIDSelect, MailFuzeChargeBackOwnersMethodSelect, MailFuzeOwnerInvoiceChargeTypeIDSelect, MailingGLAccountIDSelect } from './PropertyChargebackDowns'

export const PropertyChargebackSettingsState = {
  mailFuzeChargeBackOwners: {
    constant: ConstantCodeMailFuzeChargeBackOwners,
    code: undefined,
    type: 'Boolean',
    value: false
  },
  mailFuzeChargeBackOwnersMethod: {
    constant: ConstantCodeMailFuzeChargeBackOwnersMethod,
    options: [
      { value: 0, label: 'I want to give users the option to charge back the property for each mailing' },
      { value: 1, label: 'Automatically charge the property the cost (+ markup) for each mailing' }
    ],
    code: undefined,
    type: 'Integer',
    value: 0
  },
  mailFuzeChargeBackOwnersMarkup: {
    constant: ConstantCodeMailFuzeChargeBackOwnersMarkup,
    code: undefined,
    type: 'Boolean',
    value: false
  },
  mailFuzeChargeBackOwnersMarkupMethod: {
    constant: ConstantCodeMailFuzeChargeBackOwnersMarkupMethod,
    code: undefined,
    type: 'Integer',
    value: 0
  },

  mailFuzeChargeBackOwnersMarkupMethodPrevValue: {
    code: undefined,
    type: 'Integer',
    value: 0
  },
  mailFuzeChargeBackOwnersMarkupAmount: {
    constant: ConstantCodeMailFuzeChargeBackOwnersMarkupAmount,
    code: undefined,
    type: 'Float',
    value: 0.00
  },
  mailFuzeChargeBackOwnersMarkupAmountEnabled: {
    constant: ConstantCodeMailFuzeChargeBackOwnersMarkupAmountEnabled,
    code: undefined,
    type: 'Boolean',
    value: true
  },
  mailFuzeChargeBackOwnersMarkupPercent: {
    constant: ConstantCodeMailFuzeChargeBackOwnersMarkupPercent,
    code: undefined,
    type: 'Float',
    value: 0.00
  },
  mailFuzeChargeBackOwnersMarkupPercentEnabled: {
    constant: ConstantCodeMailFuzeChargeBackOwnersMarkupPercentEnabled,
    code: undefined,
    type: 'Boolean',
    value: true
  },
  mailFuzeCreateOwnerInvoice: {
    constant: ConstantCodeMailFuzeCreateOwnerInvoice,
    code: undefined,
    type: 'Boolean',
    value: false
  },
  mailFuzeCreateOwnerBill: {
    constant: ConstantCodeMailFuzeCreateOwnerBill,
    code: undefined,
    type: 'Boolean',
    value: false
  },
  mailFuzeOwnerInvoiceChargeTypeID: {
    constant: ConstantCodeMailFuzeOwnerInvoiceChargeTypeID,
    code: undefined,
    type: 'Integer',
    value: Blank.value
  },
  mailingGLAccountID: {
    constant: ConstantCodeMailingGLAccountID,
    code: undefined,
    type: 'Integer',
    value: Blank.value
  },
  mailFuzeOwnerBillVendorMethod: {
    constant: ConstantCodeMailFuzeOwnerBillVendorMethod,
    code: undefined,
    type: 'Integer',
    value: 0
  },
  mailFuzeOwnerBillVendorMethodPostfix: {
    type: 'String',
    value: undefined
  },
  mailFuzeOwnerBillThisVendorID: {
    constant: ConstantCodeMailFuzeOwnerBillThisVendorID,
    code: undefined,
    type: 'Integer',
    value: Blank.value
  },
}

export const PropertyChargebackSettingsSchema = { //yup.object().shape({
  // mailFuzeChargeBackOwners: yup.object().shape({
  //   value: yup.boolean().required()
  // }), 

  mailFuzeChargeBackOwners: yup.object()
    .when(['mailFuzeMailingFeePropertyMethod', 'mailFuzeCreateBillMailingFee'], {
      is: (mailFuzeMailingFeePropertyMethod, mailFuzeCreateBillMailingFee) => {
        //console.log('-----------------------------------------', mailFuzeMailingFeePropertyMethod)
        return mailFuzeMailingFeePropertyMethod && mailFuzeCreateBillMailingFee.value === true && mailFuzeMailingFeePropertyMethod.value === 2
      },
      then: yup.object({
        value: yup.boolean().oneOf([false], 'You cannot charge back properties for each mailing, because you have already opted to automatically create bills for "Property/Unit of Each Recipient" in "Mailing Billing".').required()
      }),
      otherwise: yup.object({
        value: yup.boolean().oneOf([true, false])
      })
    })
  ,
  mailFuzeChargeBackOwnersMethod: yup.object()
    .when('mailFuzeChargeBackOwners', {
      is: (mailFuzeChargeBackOwners) => { 
        return mailFuzeChargeBackOwners.value === true
      },
      then: yup.object({
        value: yup.number().typeError('Charge Back Method Required').integer().oneOf([0, 1], 'Charge Back Method Required')
      }),
      otherwise: yup.object({
        value: yup.mixed().notRequired()
      })
    })
  ,
  mailFuzeChargeBackOwnersMarkup: yup.object().shape({
    value: yup.boolean().required()
  }), 
  mailFuzeCreateOwnerInvoice: yup.object()
    .when(['mailFuzeChargeBackOwners','mailFuzeCreateOwnerBill'], {
      is: (mailFuzeChargeBackOwners, mailFuzeCreateOwnerBill) => {
        return mailFuzeChargeBackOwners.value === true && mailFuzeCreateOwnerBill.value === false
      },
      then: yup.object({
        value: yup.boolean().oneOf([true], 'Please check at least one option.')
      }),
      otherwise: yup.object({
        value: yup.mixed().notRequired()
      })
    })
  ,
  mailFuzeCreateOwnerBill: yup.object()
    .when(['mailFuzeChargeBackOwners','mailFuzeCreateOwnerInvoice'], {
      is: (mailFuzeChargeBackOwners, mailFuzeCreateOwnerInvoice) => {
        return mailFuzeCreateOwnerInvoice && mailFuzeChargeBackOwners.value === true && mailFuzeCreateOwnerInvoice.value === false
      },
      then: yup.object({
        value: yup.boolean().oneOf([true], 'Please check at least one option.')
      }),
      otherwise: yup.object({
        value: yup.mixed().notRequired()
      })
    })
  ,
  mailFuzeOwnerBillVendorMethod: yup.object({
    value: yup.number().integer().oneOf([0, 1]).required()
  })
  ,
  mailFuzeOwnerBillThisVendorID: yup.object()
    .when(['mailFuzeChargeBackOwners', 'mailFuzeOwnerBillVendorMethod'], {
      is: (mailFuzeChargeBackOwners, mailFuzeOwnerBillVendorMethod) => { 
        //console.log('+++++++', mailFuzeChargeBackOwners, mailFuzeOwnerBillVendorMethod)
        return mailFuzeChargeBackOwners.value === true && mailFuzeOwnerBillVendorMethod.value === 1
      },
      then: yup.object({
        value: yup.number().integer().moreThan(0, 'Please select a vendor.')
      }),
      otherwise: yup.object({
        value: yup.mixed().notRequired()
      })
    })
  ,
  mailFuzeOwnerInvoiceChargeTypeID: yup.object()
    .when(['mailFuzeChargeBackOwners', 'mailFuzeCreateOwnerInvoice'], {
    is: (mailFuzeChargeBackOwners, mailFuzeCreateOwnerInvoice) => {
      return mailFuzeChargeBackOwners.value === true && mailFuzeCreateOwnerInvoice.value === true
    },
    then: yup.object({
      value: yup.number().integer().moreThan(0, 'Please select a charge type.')
    }),
    otherwise: yup.object({
      value: yup.mixed().notRequired()
    })
    })
  ,
    mailingGLAccountID: yup.object()
    .when(['mailFuzeChargeBackOwners', 'mailFuzeCreateOwnerBill'], {
      is: (mailFuzeChargeBackOwners, mailFuzeCreateOwnerBill) => {
        return mailFuzeChargeBackOwners.value === true && mailFuzeCreateOwnerBill.value === true
      },
      then: yup.object({
        value: yup.number().integer().moreThan(0, 'Please select a GL account.')
      }),
      otherwise: yup.object({
        value: yup.mixed().notRequired()
      })
    })
  ,
  mailFuzeChargeBackOwnersMarkupAmountEnabled: yup.object()
    .when(['mailFuzeChargeBackOwners', 'mailFuzeChargeBackOwnersMethod', 'mailFuzeChargeBackOwnersMarkup', 'mailFuzeChargeBackOwnersMarkupPercentEnabled'], {
      is: (mailFuzeChargeBackOwners, mailFuzeChargeBackOwnersMethod, mailFuzeChargeBackOwnersMarkup, mailFuzeChargeBackOwnersMarkupPercentEnabled) => {
        return mailFuzeChargeBackOwners.value === true && mailFuzeChargeBackOwnersMarkup.value === true && mailFuzeChargeBackOwnersMethod && mailFuzeChargeBackOwnersMethod.value === 0 && mailFuzeChargeBackOwnersMarkupPercentEnabled && mailFuzeChargeBackOwnersMarkupPercentEnabled.value === false
      },
      then: yup.object({
      value: yup.boolean().oneOf([true], 'Please check at least one option.')
      }),
      otherwise: yup.object({
        value: yup.mixed().notRequired()
      })
  })
  ,
  mailFuzeChargeBackOwnersMarkupPercentEnabled: yup.object()
    .when(['mailFuzeChargeBackOwners', 'mailFuzeChargeBackOwnersMethod', 'mailFuzeChargeBackOwnersMarkup', 'mailFuzeChargeBackOwnersMarkupAmountEnabled'], {
      is: (mailFuzeChargeBackOwners, mailFuzeChargeBackOwnersMethod, mailFuzeChargeBackOwnersMarkup, mailFuzeChargeBackOwnersMarkupAmountEnabled) => {
        return mailFuzeChargeBackOwners.value === true && mailFuzeChargeBackOwnersMarkup.value === true && mailFuzeChargeBackOwnersMethod && mailFuzeChargeBackOwnersMethod.value === 0 && mailFuzeChargeBackOwnersMarkupAmountEnabled && mailFuzeChargeBackOwnersMarkupAmountEnabled.value === false
      },
      then: yup.object({
        value: yup.boolean().oneOf([true], 'Please check at least one option.')
      }),
      otherwise: yup.object({
        value: yup.mixed().notRequired()
      })
  })
  ,
  mailFuzeChargeBackOwnersMarkupPercent: yup.object()
    .when(['mailFuzeChargeBackOwners','mailFuzeChargeBackOwnersMarkup'], {
      is: (mailFuzeChargeBackOwners, mailFuzeChargeBackOwnersMarkup) => {
        return mailFuzeChargeBackOwners.value === true && mailFuzeChargeBackOwnersMarkup && mailFuzeChargeBackOwnersMarkup.value === true
      },
      then: yup.object()
        .when('mailFuzeChargeBackOwnersMethod', {
          is: (mailFuzeChargeBackOwnersMethod) => {
            return mailFuzeChargeBackOwnersMethod && mailFuzeChargeBackOwnersMethod.value === 0
          },
          then: yup.object()
            .when('mailFuzeChargeBackOwnersMarkupPercentEnabled', {
              is: (mailFuzeChargeBackOwnersMarkupPercentEnabled) => {
                return mailFuzeChargeBackOwnersMarkupPercentEnabled && mailFuzeChargeBackOwnersMarkupPercentEnabled.value === true
              },
              then: yup.object({
                //value: yup.number().positive().nullable(true).transform((_, val) => val === Number(val) ? val : null) 
                value: yup.mixed()
                  .transform((_, val) => val ? val.toString() : '')
                  .test('default-markup-decimal-required', 'Amount Required', (value) => /^\d*[\.{1}\d*]\d*$/.test(value) || value.length === 0)
              }),
              otherwise: yup.object({
                value: yup.mixed().notRequired()
              })
            }),
          otherwise: yup.object()
            .when('mailFuzeChargeBackOwnersMethod', {
              is: (mailFuzeChargeBackOwnersMethod) => {
                return mailFuzeChargeBackOwnersMethod && mailFuzeChargeBackOwnersMethod.value === 1
              },
              then: yup.object()
                .when('mailFuzeChargeBackOwnersMarkupMethod', {
                  is: (mailFuzeChargeBackOwnersMarkupMethod) => {
                    return mailFuzeChargeBackOwnersMarkupMethod && mailFuzeChargeBackOwnersMarkupMethod.value === 1
                  },
                  then: yup.object({
                    //value: yup.number().positive().nullable().required('Percentage Required').transform((_, val) => val === Number(val) ? val : null)
                    value: yup.mixed()
                      .transform((_, val) => val ? val.toString() : '')
                      .test('markup-decimal-required', 'Amount Required', (value) => /^((0?0?\.([1-9]\d*|0[1-9]\d*))|(([1-9]|0[1-9])\d*(\.\d+)?))$/.test(value) && value.length !== 0)
                  })
              })
            })
          })
        }),
      otherwise: yup.object({
        value: yup.mixed().notRequired()
      })
    ,
    mailFuzeChargeBackOwnersMarkupAmount: yup.object()
      .when(['mailFuzeChargeBackOwners','mailFuzeChargeBackOwnersMarkup'], {
        is: (mailFuzeChargeBackOwners, mailFuzeChargeBackOwnersMarkup) => {
          return mailFuzeChargeBackOwners.value === true && mailFuzeChargeBackOwnersMarkup && mailFuzeChargeBackOwnersMarkup.value === true
        },
        then: yup.object()
          .when('mailFuzeChargeBackOwnersMethod', {
            is: (mailFuzeChargeBackOwnersMethod) => {
              return mailFuzeChargeBackOwnersMethod && mailFuzeChargeBackOwnersMethod.value === 0
            },
            then: yup.object()
              .when('mailFuzeChargeBackOwnersMarkupAmountEnabled', {
                is: (mailFuzeChargeBackOwnersMarkupAmountEnabled) => {
                  return mailFuzeChargeBackOwnersMarkupAmountEnabled && mailFuzeChargeBackOwnersMarkupAmountEnabled.value === true
                },
                then: yup.object({
                  //value: yup.number().positive().nullable(true).transform((_, val) => val === Number(val) ? val : null) 
                  value: yup.mixed()
                    .transform((_, val) => val ? val.toString() : '')
                    .test('default-markup-decimal-required', 'Amount Required', (value) => /^\d*[\.{1}\d*]\d*$/.test(value) || value.length === 0)
                }),
                otherwise: yup.object({
                  value: yup.mixed().notRequired()
                })
              }),
            otherwise: yup.object()
              .when('mailFuzeChargeBackOwnersMethod', {
                is: (mailFuzeChargeBackOwnersMethod) => {
                  return mailFuzeChargeBackOwnersMethod && mailFuzeChargeBackOwnersMethod.value === 1
                },
                then: yup.object()
                  .when('mailFuzeChargeBackOwnersMarkupMethod', {
                    is: (mailFuzeChargeBackOwnersMarkupMethod) => {
                      return mailFuzeChargeBackOwnersMarkupMethod && mailFuzeChargeBackOwnersMarkupMethod.value === 0
                    },
                    then: yup.object({
                      value: yup.mixed()
                        .transform((_, val) => val ? val.toString() : '')
                        .test('markup-decimal-required', 'Amount Required', (value) => /^((0?0?\.([1-9]\d*|0[1-9]\d*))|(([1-9]|0[1-9])\d*(\.\d+)?))$/.test(value) && value.length !== 0)
                      //value: yup.string()
                      //value: yup.string().test('markup-decimal-required', 'Amount Required', (value) => /^\d*[\.{1}\d*]\d*$/.test(value) || value.length === 0)
                      //value: yup.number().typeError('Must be numeric.').positive().required('Amount Required').transform((_, val) => val === Number(val) ? parseFloat(val) : null)
                      //value: yup.mixed().transform((_, val) => val === Number(val) ? parseFloat(val) : null).notRequired()
                      //value: yup.number().transform((curr, orig) => orig !== Number(orig) ? null : curr).required("Please select a property.")
                    }),
                    otherwise: yup.object({
                      value: yup.mixed().notRequired()
                    })
                })
              })
            })
          }),
        otherwise: yup.object({
          value: yup.mixed().notRequired()
        })
          
}//, ['mailFuzeMailingFeePropertyMethod', 'mailFuzeChargeBackOwners']) //.required();

const InternalSchema = yup.object().shape({
  mailFuzeMailingFeePropertyMethod: yup.object().shape({
    value: yup.number().integer().oneOf([0,1,2])
  }),
  mailFuzeCreateBillMailingFee: yup.object().shape({
    value: yup.boolean().required()
  }),
  ...PropertyChargebackSettingsSchema
}, [['mailFuzeCreateOwnerInvoice', 'mailFuzeCreateOwnerBill'],
['mailFuzeCreateOwnerBill','mailFuzeCreateOwnerInvoice'],
['mailFuzeChargeBackOwnersMarkupAmountEnabled', 'mailFuzeChargeBackOwnersMarkupPercentEnabled'],
['mailFuzeChargeBackOwnersMarkupPercentEnabled', 'mailFuzeChargeBackOwnersMarkupAmountEnabled']])

const PropertyChargebackSettings = () => {

  const formRef = useRef(null)
  const {context: state, contextReducer: dispatchSecond} = useSettingsContext()

  console.log('Loading Chargeback Owners Tab with context', state)

  // const newObj=Object.create({});
  // Object.keys(PropertyChargebackSettingsState).forEach((key) => {
  //   newObj[key] = state[key]
  // })
  // const defVals = {
  //   ...newObj,
  //   mailFuzeMailingFeePropertyMethod: state.mailFuzeMailingFeePropertyMethod
  // }

  const {
    //setFocus,
    register,
    setValue,
    //getFieldState,
    handleSubmit,
    trigger,
    reset,
    //control
    formState: { errors, isSubmitted }
  } = useForm({
    defaultValues: {
      ...state
    },
    // defaultValues: useMemo(() => {
    //   // mailFuzeChargeBackOwners: state.mailFuzeChargeBackOwners,
    //   // mailingGLAccountIDToBillPropertyManagementCompany: state.mailingGLAccountIDToBillPropertyManagementCompany,
    //   // mailFuzeMailingFeePropertyMethod: state.mailFuzeMailingFeePropertyMethod,
    //   // mailFuzeMailingFeeThisPropertyID: state.mailFuzeMailingFeeThisPropertyID
    //   return {...state}
    //   //...defVals
    // }, [state]),
    resolver: yupResolver(InternalSchema),
    mode: "onChange",
    context: { state }
  });

  const dispatch = (action) => {
    action.setValue = setValue
     
    dispatchSecond(action)

    if (state.isSubmitted || (action.name === 'isSubmitted' && action.value === true) ||
    (action.name === 'mailFuzeChargeBackOwners' && action.value === true && state.mailFuzeMailingFeePropertyMethod.value === 2)) {
      trigger()
    }
  }

  useEffect(() => {
    reset({
      ...state
    });
    if (state.isSubmitted){
      trigger();
    }
      
    }, [state]);

  useEffect(() => {
    console.log('useEffect triggered by state.isSubmitted', state.isSubmitted, 'isSubmitted', isSubmitted)

    if (state.isSubmitted === false && isSubmitted) {
      dispatch({ type: 'SET', name: 'isSubmitted', value: isSubmitted })
    }
  }, [state.isSubmitted, isSubmitted])

  //trigger will change on the first load. It's only way to run the validation on the default values.
  useEffect(() => {
    if (state.isSubmitted) {
      trigger()
    }
  }, [trigger, state.isSubmitted, state.currentTabIndex, state.mailFuzeMailingFeePropertyMethod.value])

  useEffect(() => {
    console.log('mailFuzeChargeBackOwnersMethod:', state.mailFuzeChargeBackOwnersMethod.value)

    //dispatch({ type: 'SET_VALUE', name: 'mailFuzeChargeBackOwnersMarkupMethodPrevValue', value: state.mailFuzeChargeBackOwnersMarkupMethod.value })

    if (state.mailFuzeChargeBackOwnersMethod.value === 0) {
      dispatch({ type: 'SET_VALUE', name: 'mailFuzeChargeBackOwnersMarkupMethod', value: 2 })
    } else {
      if (state.mailFuzeChargeBackOwnersMarkupMethod.value === 2) {
        dispatch({ type: 'SET_VALUE', name: 'mailFuzeChargeBackOwnersMarkupMethod', value: 0 })
      }
      //console.log('state.mailFuzeChargeBackOwnersMarkupMethodPrevValue?.value:', state.mailFuzeChargeBackOwnersMarkupMethodPrevValue?.value)
      //dispatch({ type: 'SET_VALUE', name: 'mailFuzeChargeBackOwnersMarkupMethod', value: state.mailFuzeChargeBackOwnersMarkupMethodPrevValue?.value ?? 0 })
    }
  }, [state.mailFuzeChargeBackOwnersMethod.value])

  const save = async (e) => {
    e.target.blur();
    formRef.current.props.onSubmit(e)
    //settings.saveSettingsConstants(state, dispatch, 'Other settings saved!')
  }

  return (
    <div>
      <React.Fragment>
        <Form ref={formRef} 
          onSubmit={handleSubmit(state.onSubmit, state.onError)}
        >
          <Row>
            <Col md={11} lg={10} xl={9}>
              <Row className="mt-3">
                <Col xs={12}>
                  <FormGroup
                    check
                    inline
                  >
                    <Input
                      {...doRegisterInputCheckbox(register, dispatch, 'mailFuzeChargeBackOwners', state.isSubmitted, errors, state)}
                      style={{transform: 'scale(1.3)'}}
                    />
                    <Label for='mailFuzeChargeBackOwners'>Allow the management company to charge back properties</Label>
                    {errors?.mailFuzeChargeBackOwners?.value && (
                      <FormFeedback className='ms-1'>{errors?.mailFuzeChargeBackOwners?.value?.message}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className={`mt-2 ps-5 ${state.mailFuzeChargeBackOwners.value === false ? 'text-muted' : ''}`}>
                <Col xs={12}>
                  {/* <FormGroup
                    tag="fieldset"
                    className="border p-2 mt-3"
                    disabled={state.mailFuzeCreateOwnerBill.value === false}
                  >
                    <legend className="float-none w-auto ps-1 pe-1 fs-6 text-muted" style={{fontWeight: '500'}}>Charge Back Method</legend>

                    <div className='ps-3 pe-3 d-flex flex-column'>
                      <FormGroup check className='pb-2'>
                        <Input
                          {...doRegisterInputRadio(0, register, dispatch, 'mailFuzeChargeBackOwnersMethod', state.isSubmitted, errors, state)}
                          disabled={state.mailFuzeChargeBackOwners.value === false}
                          style={{transform: 'scale(1.3)'}}
                        />
                        {' '}
                        <Label check for='mailFuzeChargeBackOwnersMethod0'>
                          I want to give users the option to charge back for each mailing
                        </Label>
                      </FormGroup>

                      <FormGroup check className='pb-2'>
                        <Input
                          {...doRegisterInputRadio(1, register, dispatch, 'mailFuzeChargeBackOwnersMethod', state.isSubmitted, errors, state)}
                          disabled={state.mailFuzeChargeBackOwners.value === false}
                          style={{transform: 'scale(1.3)'}}
                        />
                        {' '}
                        <Label check for='mailFuzeChargeBackOwnersMethod1'>
                          Automatically charge the owner/property the exact amount for each mailing
                        </Label>
                      </FormGroup>
                    </div>
                  </FormGroup> */}
                  <MailFuzeChargeBackOwnersMethodSelect
                    options={state.mailFuzeChargeBackOwnersMethod.options}
                    {...doRegisterSelect(register, dispatch, 'mailFuzeChargeBackOwnersMethod', state.isSubmitted, errors, state, '', true)}
                    isDisabled={state.mailFuzeChargeBackOwners.value === false}
                  />
                </Col>
              </Row>

              <Row className={`mt-2 ps-5 ${state.mailFuzeChargeBackOwners.value === false ? 'text-muted' : ''}`}>
                <Col xs={12}>
                  <Row className="mt-3">
                    <Col xs={12}>
                      <FormGroup
                        check
                        inline
                      >
                        <Input
                          {...doRegisterInputCheckbox(register, dispatch, 'mailFuzeChargeBackOwnersMarkup', state.isSubmitted, errors, state)}
                          disabled={state.mailFuzeChargeBackOwners.value === false}
                          style={{transform: 'scale(1.3)'}}
                        />
                        <Label for='mailFuzeChargeBackOwnersMarkup'>Enable charge back markup</Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className='fw-bold text-muted' style={{fontSize: '80%', display: state.mailFuzeChargeBackOwnersMarkup.value === false && state.mailFuzeChargeBackOwnersMethod.value === 1 ? 'block' : 'none'}}>
                    The exact cost will be charged without a markup.
                  </div>

                  <div className='fw-bold text-muted' style={{fontSize: '80%', display: state.mailFuzeChargeBackOwnersMarkup.value === true && state.mailFuzeChargeBackOwnersMethod.value === 1 ? 'block' : 'none'}}>
                    The cost + markup will be charged as configured below.
                  </div>

                  <Row className={state.mailFuzeChargeBackOwners.value === false || state.mailFuzeChargeBackOwnersMarkup.value === false ? 'text-muted' : ''}>
                    <Col xs={12}>
                      <FormGroup
                        tag="fieldset"
                        className="border p-2 mt-3"
                      >
                        <legend className="float-none w-auto ps-1 pe-1 fs-6 text-muted" style={{fontWeight: '500'}}>Markup Settings</legend>

                        <div className='ps-3 pe-3 d-flex flex-column'>

                          <div className='pb-1' style={{display: state.mailFuzeChargeBackOwnersMethod.value === 0 ? 'block' : 'none'}}>
                              <div className='d-none'>
                                <Input
                                  {...doRegisterInputRadio(2, register, dispatch, 'mailFuzeChargeBackOwnersMarkupMethod', state.isSubmitted, errors, state)}
                                  disabled={state.mailFuzeChargeBackOwners.value === false || state.mailFuzeChargeBackOwnersMarkup.value === false }
                                  style={{transform: 'scale(1.3)'}}
                                />
                                {' '}
                              </div>
                              <Label for='mailFuzeChargeBackOwnersMarkupMethod2'>
                                Check the options shown to the user (*optionally enter default values)
                              </Label>
                          </div>

                          <div>
                            <div className='d-flex flex-row align-items-baseline pb-1'>
                              <div>
                                <FormGroup check>
                                  <div style={{display: state.mailFuzeChargeBackOwnersMethod.value === 0 ? 'block' : 'none'}}>
                                    <Input
                                      {...doRegisterInputCheckbox(register, dispatch, 'mailFuzeChargeBackOwnersMarkupAmountEnabled', state.isSubmitted, errors, state)}
                                      disabled={state.mailFuzeChargeBackOwners.value === false || state.mailFuzeChargeBackOwnersMarkup.value === false }
                                      style={{transform: 'scale(1.3)'}}
                                    />
                                    {' '}
                                    <Label check for='mailFuzeChargeBackOwnersMarkupAmountEnabled' style={{width: '12.50rem'}}>
                                      A fixed amount markup of $
                                    </Label>
                                    {errors?.mailFuzeChargeBackOwnersMarkupAmountEnabled?.value && (
                                      <FormFeedback className='ms-1'>{errors?.mailFuzeChargeBackOwnersMarkupAmountEnabled?.value?.message}</FormFeedback>
                                    )}
                                  </div>
                                  <div style={{display: state.mailFuzeChargeBackOwnersMethod.value === 1 ? 'block' : 'none'}}>
                                    <Input
                                      {...doRegisterInputRadio(0, register, dispatch, 'mailFuzeChargeBackOwnersMarkupMethod', state.isSubmitted, errors, state)}
                                      disabled={state.mailFuzeChargeBackOwners.value === false || state.mailFuzeChargeBackOwnersMarkup.value === false }
                                      style={{transform: 'scale(1.3)'}}
                                    />
                                    {' '}
                                    <Label check for='mailFuzeChargeBackOwnersMarkupMethod0' style={{width: '12.50rem'}}>
                                      A fixed amount markup of $
                                    </Label>
                                  </div>
                                </FormGroup>
                              </div>
                              <div className='ms-2 d-flex flex-grow-1 flex-column'>
                                <FormGroup>
                                  <Input
                                    {...doRegisterInput(register, dispatch, 'mailFuzeChargeBackOwnersMarkupAmount', state.isSubmitted, errors, state)}
                                    disabled={state.mailFuzeChargeBackOwners.value === false || state.mailFuzeChargeBackOwnersMarkup.value === false || (state.mailFuzeChargeBackOwnersMethod.value === 0 && state.mailFuzeChargeBackOwnersMarkupAmountEnabled.value === false) || (state.mailFuzeChargeBackOwnersMethod.value === 1 && state.mailFuzeChargeBackOwnersMarkupMethod.value !== 0)}
                                  />
                                  {errors?.mailFuzeChargeBackOwnersMarkupAmount?.value && (
                                    <FormFeedback className='ms-1'>{errors?.mailFuzeChargeBackOwnersMarkupAmount?.value?.message}</FormFeedback>
                                  )}
                                </FormGroup>
                              </div>
                            </div>
                            <div className='d-flex flex-row align-items-baseline pb-1'>
                              <div>
                                <FormGroup check>
                                  <div style={{display: state.mailFuzeChargeBackOwnersMethod.value === 0 ? 'block' : 'none'}}>
                                    <Input
                                      {...doRegisterInputCheckbox(register, dispatch, 'mailFuzeChargeBackOwnersMarkupPercentEnabled', state.isSubmitted, errors, state)}
                                      disabled={state.mailFuzeChargeBackOwners.value === false || state.mailFuzeChargeBackOwnersMarkup.value === false}
                                      style={{transform: 'scale(1.3)'}}
                                    />
                                    {' '}
                                    <Label check for='mailFuzeChargeBackOwnersMarkupPercentEnabled' style={{width: '12.50rem'}}>
                                      A percentage markup of %
                                    </Label>
                                    {errors?.mailFuzeChargeBackOwnersMarkupPercentEnabled?.value && (
                                      <FormFeedback className='ms-1'>{errors?.mailFuzeChargeBackOwnersMarkupPercentEnabled?.value?.message}</FormFeedback>
                                    )}
                                  </div>
                                  <div style={{display: state.mailFuzeChargeBackOwnersMethod.value === 1 ? 'block' : 'none'}}>
                                    <Input
                                      {...doRegisterInputRadio(1, register, dispatch, 'mailFuzeChargeBackOwnersMarkupMethod', state.isSubmitted, errors, state)}
                                      disabled={state.mailFuzeChargeBackOwners.value === false || state.mailFuzeChargeBackOwnersMarkup.value === false}
                                      style={{transform: 'scale(1.3)'}}
                                    />
                                    {' '}
                                    <Label check for='mailFuzeChargeBackOwnersMarkupMethod1' style={{width: '12.50rem'}}>
                                      A percentage markup of %
                                    </Label>
                                  </div>
                                </FormGroup>
                              </div>

                              <div className='ms-2 d-flex flex-grow-1 flex-column'>
                                <FormGroup>
                                  <Input
                                    {...doRegisterInput(register, dispatch, 'mailFuzeChargeBackOwnersMarkupPercent', state.isSubmitted, errors, state)}
                                    disabled={state.mailFuzeChargeBackOwners.value === false || state.mailFuzeChargeBackOwnersMarkup.value === false || (state.mailFuzeChargeBackOwnersMethod.value === 0 && state.mailFuzeChargeBackOwnersMarkupPercentEnabled.value === false) || (state.mailFuzeChargeBackOwnersMethod.value === 1 && state.mailFuzeChargeBackOwnersMarkupMethod.value !== 1)}
                                  />
                                  {errors?.mailFuzeChargeBackOwnersMarkupPercent?.value && (
                                    <FormFeedback className='ms-1'>{errors?.mailFuzeChargeBackOwnersMarkupPercent?.value?.message}</FormFeedback>
                                  )}
                                </FormGroup>

                                <div className='pb-1' style={{display: state.mailFuzeChargeBackOwnersMethod.value === 0 ? 'block' : 'none'}}>
                                  <span style={{fontSize: '0.9em'}}>*The default values can be changed by the user during mailings</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col xs={12}>
                    {errors?.mailFuzeCreateOwnerInvoice?.value && (
                          <>
                            <span className='is-invalid'></span>
                            <FormFeedback className='ms-1'>{errors?.mailFuzeCreateOwnerInvoice?.value?.message}</FormFeedback>
                          </>
                        )}
                      <FormGroup
                        check
                        inline
                      >
                        <Input
                          {...doRegisterInputCheckbox(register, dispatch, 'mailFuzeCreateOwnerInvoice', state.isSubmitted, errors, state)}
                          disabled={state.mailFuzeChargeBackOwners.value === false}
                          style={{transform: 'scale(1.3)'}}
                        />
                        <Label for='mailFuzeCreateOwnerInvoice'>Create an invoice for the management company's receivable</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className='ps-5'>
                    <Col xs={12}>
                      <Setting label='Charge Type to Use on Invoice:' description=''>
                        <MailFuzeOwnerInvoiceChargeTypeIDSelect
                          options={state.mailFuzeOwnerInvoiceChargeTypeID.options}
                          {...doRegisterSelect(register, dispatch, 'mailFuzeOwnerInvoiceChargeTypeID', state.isSubmitted, errors, state, '', true)}
                          isDisabled={state.mailFuzeChargeBackOwners.value === false || state.mailFuzeCreateOwnerInvoice.value === false}
                        />
                      </Setting>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={12}>
                      <FormGroup
                        check
                        inline
                      >
                        <Input
                          {...doRegisterInputCheckbox(register, dispatch, 'mailFuzeCreateOwnerBill', state.isSubmitted, errors, state)}
                          disabled={state.mailFuzeChargeBackOwners.value === false}
                          style={{transform: 'scale(1.3)'}}
                        />
                        <Label for='mailFuzeCreateOwnerBill'>Create a bill for the property's payable</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className='ps-5'>
                    <Col xs={12}>
                      <Setting label='GL Account to Use on Bill:' description=''>
                        <MailingGLAccountIDSelect
                          options={state.mailingGLAccountID.options}
                          {...doRegisterSelect(register, dispatch, 'mailingGLAccountID', state.isSubmitted, errors, state, '', true)}
                          isDisabled={state.mailFuzeChargeBackOwners.value === false || state.mailFuzeCreateOwnerBill.value === false}
                        />
                      </Setting>
                    </Col>
                  </Row>
                  <Row className={state.mailFuzeChargeBackOwners.value === true && state.mailFuzeCreateOwnerBill.value === false ? 'text-muted' : ''}>
                    <Col xs={12}>
                      <FormGroup
                        tag="fieldset"
                        className="border p-2 mt-3"
                        disabled={state.mailFuzeCreateOwnerBill.value === false}
                      >
                        <legend className="float-none w-auto ps-1 pe-1 fs-6 text-muted" style={{fontWeight: '500'}}>Vendor To Use On Bills</legend>

                        <div className='ps-3 pe-3 d-flex flex-column'>
                          <FormGroup check>
                            <Input
                              {...doRegisterInputRadio(0, register, dispatch, 'mailFuzeOwnerBillVendorMethod', state.isSubmitted, errors, state)}
                              disabled={state.mailFuzeChargeBackOwners.value === false || state.mailFuzeOwnerBillVendorMethodPostfix.value === "Not Found"}
                              style={{transform: 'scale(1.3)'}}
                            />
                            {' '}
                            <Label check for='mailFuzeOwnerBillVendorMethod0'>
                              Management Company Vendor in Rent Manager System Settings <span style={{color: (state.mailFuzeOwnerBillVendorMethodPostfix.value === "Not Found" ? 'var(--bs-danger)' : 'inherit')}}>{`(${state.mailFuzeOwnerBillVendorMethodPostfix.value})`}</span>
                            </Label>
                          </FormGroup>

                          <div className='d-flex flex-row align-items-baseline pb-1'>
                            <div>
                              <FormGroup check>
                                <Input
                                  {...doRegisterInputRadio(1, register, dispatch, 'mailFuzeOwnerBillVendorMethod', state.isSubmitted, errors, state)}
                                  disabled={state.mailFuzeChargeBackOwners.value === false}
                                  style={{transform: 'scale(1.3)'}}
                                />
                                {' '}
                                <Label check for='mailFuzeOwnerBillVendorMethod1'>
                                  This Vendor:
                                </Label>
                              </FormGroup>
                            </div>
                            <div className='ms-2 d-flex flex-grow-1 flex-column'>
                              <MailFuzeOwnerBillThisVendorIDSelect
                                options={state.mailFuzeOwnerBillThisVendorID.options}
                                {...doRegisterSelect(register, dispatch, 'mailFuzeOwnerBillThisVendorID', state.isSubmitted, errors, state, '', true)}
                                isDisabled={state.mailFuzeChargeBackOwners.value === false || state.mailFuzeOwnerBillVendorMethod.value === 0}
                              />
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row>
            <Col md={11} lg={8} xl={7} className="d-flex justify-content-end mb-3 mt-3">
              <Button color="dark" onClick={save}>Save</Button>
            </Col>
          </Row> */}
          <div style={{visibility: 'hidden', height: '0px'}}>
            <Input {...doRegisterInputCheckbox(register, dispatch, 'mailFuzeCreateBillMailingFee', state.isSubmitted, errors, state, '-bk')} />
            <Input {...doRegisterInput(register, dispatch, 'mailFuzeMailingFeePropertyMethod', state.isSubmitted, errors, state)} />
          </div>
        </Form>
      </React.Fragment>
    </div>
  )
}

export default PropertyChargebackSettings