import BaseSelect2 from "../dropdowns/BaseSelect2";

export const MailFuzeSubscriptionFeeGLAccountIDSelect = ({ options, onChange, value, ...rest }) => {
  return (
      <BaseSelect2
          options={options}
          onChange={onChange}
          value={value}
          selectFirst={false}
          {...rest}
      />
  )
}

export const PaymentFeeGLAccountIDSelect = ({ options, onChange, value, ...rest }) => {
  return (
      <BaseSelect2
          options={options}
          onChange={onChange}
          value={value}
          selectFirst={false}
          {...rest}
      />
  )
}

export const MailFuzeSubscriptionFeeThisPropertyIDSelect = ({options, onChange, value, registerMe = () => {}, valid, invalid, message, ...rest}) => {
  return (
      <BaseSelect2
        options={options}
        value={value}
        onChange={onChange}
        selectFirst={true}
        invalid={invalid}
        valid={valid}
        registerMe={registerMe}
        message={message}
        {...rest}
      />
  )
}