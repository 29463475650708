import { useFetchWrapper } from '../services/fetch-wrapper'

export { useLetterTemplateService };

const useLetterTemplateService = () => {

  const fetchWrapper = useFetchWrapper()

  const GetLetterTemplates = async (letterTemplateType) => {
    const params = new URLSearchParams({letterTemplateType, addBlank: true}).toString();

    return await fetchWrapper.fetchDropDown(`lettertemplate?${params}`, "LetterTemplateID", "Name")
  }

  return { GetLetterTemplates }
}