import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import { centeredHeaderComponentParams } from '../widget/CenteredHeaderComponent';

const AddressWizardResultGrid = ({addresses, costCodesEnabled}) => {
  const gridRef = useRef()

  const initialColumnDef = useCallback(() => [
    { field: 'pageNum', headerName: "Page", suppressSizeToFit: true, maxWidth: 75 },
    { field: 'firstName', minWidth: 100 },
    { field: 'lastName', minWidth: 100 },
    { field: 'street1', minWidth: 160 },
    { field: 'street2', minWidth: 100 },
    { field: 'city', minWidth: 140 },
    { field: 'state', suppressSizeToFit: true, maxWidth: 90 },
    { field: 'zip', suppressSizeToFit: true, maxWidth: 80 },
    { field: 'returnName', minWidth: 170 },
    { field: 'returnStreet1', minWidth: 160 },
    { field: 'returnStreet2', minWidth: 100 },
    { field: 'returnCity', minWidth: 100 },
    { field: 'returnState', suppressSizeToFit: true, maxWidth: 90 },
    { field: 'returnZip', suppressSizeToFit: true, maxWidth: 80 }

  ], [])

  useEffect(() => {

    const defs = initialColumnDef()

    if (costCodesEnabled) {
      defs.splice(8, 0,
        {
          field: 'costCode',
          minWidth: 140
        }
      )
      // defs.push(
      //   {
      //     field: 'costCode',
      //     minWidth: 140,
      //   }
      // )
    }

    setColumnDefs(defs)
  }, [costCodesEnabled, initialColumnDef])

  const [columnDefs, setColumnDefs] = useState()

  useEffect(() => {
    if (gridRef && gridRef.current) {
      gridRef.current.api.setColumnDefs(columnDefs)
    }
  }, [columnDefs])

  const [defaultColumnDefs] = useState({
    sortable: false,
    filter: false,
    resizable: true,
    headerComponentParams: centeredHeaderComponentParams()
  })

  return (
    <>
      <div className="ag-theme-alpine" style={{height: '75vh', minHeight: '400px', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={addresses}
          rowSelection="none"
          paginationPageSize={30}
          //columnDefs={columnDefs}
          defaultColDef={defaultColumnDefs}
          pagination={true}
          onGridReady={(event) => { 
            event.api.sizeColumnsToFit()
            event.columnApi.applyColumnState({
              state: [
                {
                  colId: 'pageNum',
                  sort: 'asc'
                }
              ]
            })
          }}
        >
        </AgGridReact>
      </div>
    </>
  )
}

export default AddressWizardResultGrid