import { useFetchWrapper } from '../services/fetch-wrapper'

export const FiveDayThresholdAmount = 'FiveDayThresholdAmount'
export const DefaultServiceIssuePriorityID = 'DefaultServiceIssuePriorityID'

export const ConstantCodeAdministratorRoleID = "ConstantCodeAdministratorRoleID"
export const ConstantCodeBookkeeperRoleID = "ConstantCodeBookkeeperRoleID"
export const ConstantCodePropertyCoordinatorRoleID = "ConstantCodePropertyCoordinatorRoleID"
export const ConstantCodeServiceManagerRoleID = "ConstantCodeServiceManagerRoleID"
export const ConstantCodeServiceTechRoleID = "ConstantCodeServiceTechRoleID"
export const ConstantCodeRentChargeTypeID = "ConstantCodeRentChargeTypeID"
export const ConstantCodeRentAssistanceChargeTypeID = "ConstantCodeRentAssistanceChargeTypeID"
export const ConstantCodeRentMTMChargeTypeID = "ConstantCodeRentMTMChargeTypeID"
export const ConstantCodeEfformanceVendorID = "ConstantCodeEfformanceVendorID"

export const ConstantCodeMailingChargeTypeIDTenant = 'ConstantCodeMailingChargeTypeIDTenant'
export const ConstantCodeMailingGLAccountID = 'ConstantCodeMailingGLAccountID'
export const ConstantCodeMailingInventoryItemID = 'ConstantCodeMailingInventoryItemID'
export const ConstantCodeMailingGLAccountIDToBillPropertyManagementCompany = 'ConstantCodeMailingGLAccountIDToBillPropertyManagementCompany'
export const ConstantCodeMailingHistoryCategoryID = 'ConstantCodeMailingHistoryCategoryID'
export const ConstantCodeMailingHistoryCategoryEnabled = 'ConstantCodeMailingHistoryCategoryEnabled'
export const ConstantCodeMailFuzeSubscriptionFeeGLAccountID = 'ConstantCodeMailFuzeSubscriptionFeeGLAccountID'
export const ConstantCodePaymentFeeGLAccountID = 'ConstantCodePaymentFeeGLAccountID'

export const ConstantCodeMailFuzeCreateBillSubscriptionFee = 'ConstantCodeMailFuzeCreateBillSubscriptionFee'
export const ConstantCodeMailFuzeSubscriptionFeePropertyMethod = 'ConstantCodeMailFuzeSubscriptionFeePropertyMethod'
export const ConstantCodeMailFuzeSubscriptionFeeThisPropertyID = 'ConstantCodeMailFuzeSubscriptionFeeThisPropertyID'
export const ConstantCodeMailFuzeCreateBillMailingFee = 'ConstantCodeMailFuzeCreateBillMailingFee'
export const ConstantCodeMailFuzeMailingFeePropertyMethod = 'ConstantCodeMailFuzeMailingFeePropertyMethod'
export const ConstantCodeMailFuzeMailingFeeThisPropertyID = 'ConstantCodeMailFuzeMailingFeeThisPropertyID'
export const ConstantCodeMailFuzeChargeBackOwners = 'ConstantCodeMailFuzeChargeBackOwners'
export const ConstantCodeMailFuzeChargeBackOwnersMethod = 'ConstantCodeMailFuzeChargeBackOwnersMethod'
export const ConstantCodeMailFuzeChargeBackOwnersMarkup = 'ConstantCodeMailFuzeChargeBackOwnersMarkup'
export const ConstantCodeMailFuzeChargeBackOwnersMarkupMethod = 'ConstantCodeMailFuzeChargeBackOwnersMarkupMethod'

export const ConstantCodeMailFuzeChargeBackOwnersMarkupAmount = 'ConstantCodeMailFuzeChargeBackOwnersMarkupAmount'
export const ConstantCodeMailFuzeChargeBackOwnersMarkupAmountEnabled = 'ConstantCodeMailFuzeChargeBackOwnersMarkupAmountEnabled'
export const ConstantCodeMailFuzeChargeBackOwnersMarkupPercent = 'ConstantCodeMailFuzeChargeBackOwnersMarkupPercent'
export const ConstantCodeMailFuzeChargeBackOwnersMarkupPercentEnabled = 'ConstantCodeMailFuzeChargeBackOwnersMarkupPercentEnabled'

export const ConstantCodeMailFuzeCreateOwnerInvoice = 'ConstantCodeMailFuzeCreateOwnerInvoice'
export const ConstantCodeMailFuzeOwnerInvoiceChargeTypeID = 'ConstantCodeMailFuzeOwnerInvoiceChargeTypeID'
export const ConstantCodeMailFuzeCreateOwnerBill = 'ConstantCodeMailFuzeCreateOwnerBill'
export const ConstantCodeMailFuzeOwnerBillVendorMethod = 'ConstantCodeMailFuzeOwnerBillVendorMethod'
export const ConstantCodeMailFuzeOwnerBillThisVendorID = 'ConstantCodeMailFuzeOwnerBillThisVendorID'
export const ConstantCodeMailFuzeChargeTenants = 'ConstantCodeMailFuzeChargeTenants'

export const ConstantCodeMailFuzeReturnAddressMethod = 'ConstantCodeMailFuzeReturnAddressMethod';
export const ConstantCodeMailFuzeReturnAddressThisProperty = 'ConstantCodeMailFuzeReturnAddressThisProperty';
export const ConstantCodeMailFuzeReturnAddressThisPropertyAddressType = 'ConstantCodeMailFuzeReturnAddressThisPropertyAddressType';
export const ConstantCodeMailFuzeReturnAddressRecipientAddressType = 'ConstantCodeMailFuzeReturnAddressRecipientAddressType';

export const ConstantCodeMailFuzeCostCodesEnabled = 'ConstantCodeMailFuzeCostCodesEnabled';

export const ConstantCodeMailFuzeFixDocumentDimensions = 'ConstantCodeMailFuzeFixDocumentDimensions';

export { useSettingsService };

const useSettingsService = () => {

  const fetchWrapper = useFetchWrapper()

  const CreateEfformanceVendor = async () => {
    const response = await fetchWrapper.put(`settings/createefformancevendor`)

    console.log(response)

    return response
  }

  const GetConstant = async (constantName) => {
    console.log(constantName)

    return fetchWrapper.get(`settings/constant/${constantName}`)

    // const token = await authService.getAccessToken()
    // const response = await fetch(`settings/constant/${constantName}`, {
    //     headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    // }).catch((error) => {
    //   console.log('The following error occured fetching settings')
    //   console.log(error)
    // })

    // if (response.ok) {
    //   try {
    //     var data = await response.json();  
    //     console.log(data)
    //     return data;
    //   } catch (error) {
    //     console.log('The following error occured trying to parse json')
    //     console.log(error)
    //   }
    // }

    // return undefined;
  }

  const GetConstants = async (constantNames) => {
    return fetchWrapper.post(`settings/constants`, constantNames)

    // const token = await authService.getAccessToken()
    // const authHeader = !token ? {} : { 'Authorization': `Bearer ${token}` }

    // const requestOptions = {
    //   method: 'POST',
    //   credentials: 'same-origin',
    //   mode: 'same-origin',
    //   headers: {...{
    //     'Accept':       'application/json',
    //     'Content-Type': 'application/json',
    //   }, ...authHeader},
    //   //headers: { ...{ 'Content-Type': 'application/json' }, ...authHeader },
    //   body: JSON.stringify(constantNames)
    // }

    // console.log(requestOptions)

    // const response = await fetch(`settings/constants`, requestOptions)
    //   .catch((error) => { console.log(error) })

    // if (response.ok) {
    //   var data = await response.json();
    //   return data;
    // }

    // return undefined;
  }

  const GetConstantValueFromResults = (constantsResults, clientSettings, constantName, conversionFunc) => {
    let constant = constantsResults.find(constant => constant.name === constantName)
    let setting = clientSettings.find(setting => setting.constantCode === constant.value)
    const constantValueStr = setting.constantValue

    return conversionFunc(constantValueStr)
  }

  const GetClientSettings = async (constantCodes) => {
    return fetchWrapper.post(`settings/clientsettings`, constantCodes)

    // const token = await authService.getAccessToken()
    // const authHeader = !token ? {} : { 'Authorization': `Bearer ${token}` }

    // const requestOptions = {
    //   method: 'POST',
    //   credentials: 'same-origin',
    //   mode: 'same-origin',
    //   headers: {...{
    //     'Accept':       'application/json',
    //     'Content-Type': 'application/json',
    //   }, ...authHeader},
    //   //headers: { ...{ 'Content-Type': 'application/json' }, ...authHeader },
    //   body: JSON.stringify(constantCodes)
    // }

    // console.log(requestOptions)

    // const response = await fetch(`settings/clientsettings`, requestOptions)
    //   .catch((error) => { console.log(error); return error; })

    // if (response.ok) {
    //   var data = await response.json();
    //   return data;
    // }

    // return undefined;
  }

  const UpdateClientSettings = async (constants) => {
    const response = await fetchWrapper.put(`settings/clientsettings`, constants)

    console.log(response)

    return response.isSuccess

    // const token = await authService.getAccessToken()
    // const authHeader = !token ? {} : { 'Authorization': `Bearer ${token}` }

    // const requestOptions = {
    //   method: 'PUT',
    //   credentials: 'same-origin',
    //   mode: 'same-origin',
    //   headers: {...{
    //     'Accept':       'application/json',
    //     'Content-Type': 'application/json',
    //   }, ...authHeader},
    //   body: JSON.stringify(constants)
    // }

    // console.log(requestOptions)

    // const response = await fetch(`settings/clientsettings`, requestOptions)
    //   .catch((error) => { console.log(error); return error; })

    // if (response.ok) {
    //   return true;
    // }

    // return false;
  }

  const GetMailingHistoryCategoryIDSelectOptions = async () => {
    return await fetchWrapper.fetchDropDown(`historycategory?addBlank=true`, "HistoryCategoryID", "Category")
  }

  const GetEfformanceVendorIDSelectOptions = async () => {
    return await fetchWrapper.fetchDropDown(`vendor?addBlank=true`, "VendorID", "VendorName")
  }

  const GetGLAccountIDSelectOptions = async () => {
    return await fetchWrapper.fetchDropDown(`glaccount?addBlank=true`, "GLAccountID", "FullName")
  }

  const GetPropertyIDSelectOptions = async () => {
    const params = new URLSearchParams({propertyGroup: -1, addAll: false, addBlank: true}).toString();
    return await fetchWrapper.fetchDropDown(`property?${params}`, "PropertyID", "ShortName")
  }

  const GetOwnerInvoiceChargeTypeSelectOptions = async () => {
    return await fetchWrapper.fetchDropDown(`chargetype?addBlank=true`, "ChargeTypeID", "Name")
  }
  
  const GetPropertyAddressTypeSelectOptions = async () => {
    return await fetchWrapper.fetchDropDown(`addresstype?addBlank=true`, "AddressTypeID", "Name")
  }

  const FlushMySettings = () => {
    return fetchWrapper.put(`settings/flush/my`)   
  }

  const FlushAllSettings = () => {
    return fetchWrapper.put(`settings/flush/all`)   
  }

  return { CreateEfformanceVendor, GetConstant, GetConstants, GetConstantValueFromResults, GetClientSettings,
    GetEfformanceVendorIDSelectOptions,
    GetGLAccountIDSelectOptions,
    GetMailingHistoryCategoryIDSelectOptions,
    GetPropertyIDSelectOptions,
    GetOwnerInvoiceChargeTypeSelectOptions,
    GetPropertyAddressTypeSelectOptions,
    UpdateClientSettings, FlushMySettings, FlushAllSettings }
}