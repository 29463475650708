import { SettingsContext } from './SettingsContext';

const SettingsLayout = ({children, context, contextReducer}) => {
  return (
    <SettingsContext.Provider value={{context, contextReducer}}>
      {children}
    </SettingsContext.Provider>
  )
}

export default SettingsLayout