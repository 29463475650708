import React from 'react';
import { TabPane } from 'reactstrap';

export const TabPanel = ({children, index, hidden = false}) => {
  return (
    <React.Fragment>
      <TabPane tabId={index} hidden={hidden}>
      {/* <div className={`tab-pane ${tabIndex == 0 ? 'active' : ''}`} ref={tabPanesRef[tabIndex]}> */}
        <div className='p-3'>
          {children}
        </div>
        <hr />
      {/* </div> */}
      </TabPane>
    </React.Fragment>
  )
}