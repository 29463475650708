import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { customStyles } from './Styles'
import { All, Blank } from './DefaultSelectOptions'
import { FormFeedback } from 'reactstrap'

const BaseSelect2 = ({options, /*useEffectDependencies,*/ onChange, disabled, registerMe = () => {}, selectFirst = true, value = undefined, invalid, valid, message, ...rest}) => {
    //const [options, setOptions] = useState([])
    //const [loading, setLoading] = useState(true)
    //const [error, setError] = useState(false)
    const combinedCustomStyles = {
      ...customStyles,
      ...rest.customStyles
    };


    const onSelectedValueChange = (selectedOption, action) => {
      if (isFunction(onChange)) {
        if (rest.isMulti && action) {
          //action.action === 'clear'
          console.log(selectedOption)
          console.log(action)
          if (action && action.option)
            console.log(action.option.value)
          console.log(All.value)

          let isGrouped = false
          if (options[0] && options[0].options && options[0].options.length !== 0) {
            isGrouped = true;
          }

          let allValue = All.value.toString()
          let blankValue = Blank.value.toString()
          if (isGrouped) {
            allValue = '_' + allValue
            blankValue = '_' + blankValue
          }

          const hasOptions = options && options.length > 0
          const allBeingSelected = action.option && action.option.value && (action.option.value.toString() === allValue)
          const blankBeingSelected = action.option && action.option.value && (action.option.value.toString() === blankValue)
          const allCurrentlySelected = selectedOption && selectedOption.length > 0 && (selectedOption.some(option => option.value.toString() === allValue))
          const blankCurrentlySelected = selectedOption && selectedOption.length > 0 && (selectedOption.some(option => option.value.toString() === blankValue))
          const allOrBlankBeingSelected = allBeingSelected || blankBeingSelected
          const allOrBlankCurrentlySelected = allCurrentlySelected || blankCurrentlySelected

          //console.log('hasOptions: ', hasOptions)
          //console.log('allOrBlankBeingSelected: ', allOrBlankBeingSelected)
          //All was selected, so we deselect all other options
          if (action && allOrBlankBeingSelected) {
            if (allBeingSelected) {
              //setSelected(All)
              onChange({ label: All.label, value: allValue }) //don't send action.
            }
            else if (blankBeingSelected){
              //setSelected(Blank)
              onChange({ label: Blank.label, value: blankValue }) //don't send action.
            }
          }
          else if (action && hasOptions && selectedOption && selectedOption.length > 1 && allOrBlankCurrentlySelected)
          {
            if (allCurrentlySelected) {
              console.log('Remove "All" from Selection', allValue)
              var everySelectedOptionExceptAll = selectedOption.filter(option => option.value.toString() !== allValue)
              console.log('select', everySelectedOptionExceptAll)
              //setSelected(everySelectedOptionExceptAll)
              onChange(everySelectedOptionExceptAll, action)
            }
            else if (blankCurrentlySelected) {
              console.log('Remove "Blank" from Selection', blankValue)
              console.log('select', everySelectedOptionExceptBlank)
              var everySelectedOptionExceptBlank = selectedOption.filter(option => option.value.toString() !== blankValue)
              //setSelected(everySelectedOptionExceptBlank)
              onChange(everySelectedOptionExceptBlank, action)
            }
          }
          else if (selectedOption.length == 0 && selectFirst && hasOptions)
          {
            //setSelected(options[0])
            console.log('Nothing selected, select first')
            if (isGrouped) {
              console.log('isGrouped', options[0].options[0])
              onChange(options[0].options[0]) //don't send action.
            }
            else {
              onChange(options[0]) //don't send action.
            }
          }
          // else if (selectedOption.length == 0 && initialValue && hasOptions) {
          //   const initialOption = findInitialValue()
          //   if (initialOption) {
          //     //setSelected(initialOption)
          //     onChange(initialOption) //don't send action.
          //   }
          // }
          else {
            //setSelected(selectedOption)
            console.log('selectedOption', selectedOption, action)
            onChange(selectedOption, action)            
          }
        }
        else {  //not multi
          //setSelected(selectedOption)
          onChange(selectedOption, action)
        }
      }
      else { //no onChange, just set the selected value
        //setSelected(selectedOption)
      }
    }


    const getValue = () => {
      const results = []

      if (options && options.length !== 0 && value) {

        let isGrouped = false
        if (options[0] && options[0].options && options[0].options.length !== 0) {
          isGrouped = true;
        }

        if (rest.isMulti) {
          if (isGrouped) {
            options.forEach(({options: opts}) => {
              results.push(...opts.filter(({value: val}) => value.find(selectedOption => val.toString() === selectedOption.toString())))
            })
          } else {
            results.push(...options.filter(({value: val}) => value.find(selectedOption => val.toString() === selectedOption.toString())))
          }
        } else {
          return options.filter(({value: val}) => val.toString() === value.toString())
        }
      }

      return results
      //options.filter(({value: val}) => value != null && (((rest.isMulti && value.find(selectedOption => val.toString() === selectedOption.toString())) || (!rest.isMulti && val.toString() === value.toString()))))
    }

    return (
        <React.Fragment>
          {options && (
            <Select
                styles={combinedCustomStyles}
                options={options}
                //value={selected}
                value={getValue()}
                onChange={ onSelectedValueChange }
                placeholder={rest.placeholder ? rest.placeholder : ''}
                invalid={invalid}
                valid={valid}
                {...registerMe}
                {...rest}
                className={`${invalid? 'is-invalid': ''}`}
            /> 
          )}
          {invalid && (
            <FormFeedback className='ms-1'>{message}</FormFeedback>
          )}
          
        </React.Fragment >
    )
}

export default BaseSelect2