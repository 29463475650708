import React, { useCallback, useEffect, useState } from 'react'
import { Eye, EyeFill } from 'react-bootstrap-icons';
import { Button } from 'reactstrap';

export const MailStatusGridCellMenuRenderer = ({onShowDetails, node, ...rest}) => {

  // const [nodeData, setNodeData] = useState(node.data)

  // useEffect(() => {
  //   if (node.data.id !== nodeData.id)
  //     setNodeData(node.data)
  // }, [node.data])

  // const showDetails = useCallback((e) => {
  //   onShowDetails(e, jobId)
  // }, []);

  return (
    <React.Fragment>
      <Button onClick={(e) => onShowDetails(e, node.data)} color="primary" className='btn-sm'><EyeFill style={{pointerEvents: 'none', marginTop: '-3px'}} /></Button>
    </React.Fragment>
  )
}