import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Container, Form, FormGroup, Badge, Button, Row, Col, Input, InputGroup, InputGroupText, ListGroup, ListGroupItem } from 'reactstrap'
import { ArrowClockwise, BinocularsFill, BoxArrowUpRight, Calculator, Check2Square, Download, EnvelopeExclamation, GearWideConnected, InfoCircleFill, InfoLg, People, PersonLinesFill, Send } from 'react-bootstrap-icons'
import { LetterOptions, useSendMailService } from '../../services/SendMailService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
//import SendMailCustomGrid from './SendMailCustomGrid'
import MailingFields from '../send-mail/MailingFields'
import { CurrencyFormatter } from '../../services/CurrencyFormatter'
import authService from '../api-authorization/AuthorizeService';
import { toast } from 'react-toastify';
import { SendMailLetterOptions } from '../send-mail/SendMailLetterOptions'
import { useDocumentClassService } from '../../services/DocumentClassService'
import SendMailCustomUpload from './SendMailCustomUpload'
import SendMailCustomGrid from './SendMailCustomGrid'
import SendMailCustomHelp from './SendMailCustomHelp'
import { 
  ConstantCodeMailFuzeCostCodesEnabled,
  useSettingsService
} from '../../services/SettingsService'
import SendMailValidationResult from '../send-mail-validation/SendMailValidationResult'
import WarningBadAddress from '../send-mail-validation/WarningBadAddress'
import WarningInvalidDocumentDimensions from '../send-mail-validation/WarningInvalidDocumentDimensions'
import WarningLetterNumberPageOfPages from '../send-mail-validation/WarningLetterNumberPageOfPages'
import WarningInvalidClientAddress from '../send-mail-validation/WarningInvalidClientAddress'


const SendMailCustom = () => {
  
  const sendMailService = useSendMailService()
  const documentClassService = useDocumentClassService()
  const settingService = useSettingsService()

  const downLoadIconRef = useRef(React.createRef())
  const sendMailCustomGridRef = useRef(null)
  
  const initialState = {
    isInitialized: false,
    isLoading: false,
    isHelpOpen: false,
    isValidationErrorsOpen: false,
    displayOptions: 'hidden',
    documentClasses: [],
    documentClassOptions: [],
    listingSelection: [],
    listingLoadedOnce: false,
    mailingOptions: {},
    paymentTypeName: '',
    costCodesEnabled: false,
    recipientsWithoutAddress: [],
    documentDimensionResult: {
      validDocumentDimensions: true,
      targetDocumentDimensions: {
        documentWidth: 8.5,
        documentHeight: 11
      }
    },
    envelopeNumberOfPagesResult: {
      isDuplex: false,
      duplexSupported: false,
      simplexSupported: false,
      maxNumberOfSheets: 0,
      maxNumberOfSheetsDuplex: 0,
    },
    clientAddressValidationResult: {
      isValid: false
    },
    letterTemplateFields: {
      letterOption: LetterOptions.Custom,
      isMergeFile: false,
      fixedFiles: [],
      letterUploading: false
    },
    recipientSourceFields2: {
      files: [],
      fileUploading: false
    },
    recipientSourceFields: {
      recipients: [],
      recipientMap: new Map(),
      firstRowMap: new Map(),
      firstRowIsHeader: false
    }
  }

  const stateReducer = (state, action) => {
    //console.log(action)
    switch (action.type) {
      case 'SET': {

        if (action.name === 'isLoading' && action.value)
        {
          //console.log('updating gridkey!!')
          return {
            ...state,
            [action.name]: action.value,
            //gridKey: nanoid()
          }
        }
        else {
          return {
            ...state,
            [action.name]: action.value
          }
        }
      }
      case 'MERGE_LTF': {
        return {
          ...state,
          letterTemplateFields: {
            ...state.letterTemplateFields,
            ...action.value  
          }
        }
      }
      case 'MERGE_RSF': {
        console.log(action.value)
        return {
          ...state,
          recipientSourceFields: {
            ...state.recipientSourceFields,
            ...action.value  
          }
        }
      }
      case 'MERGE_RSF2': {
        console.log(action.value)
        return {
          ...state,
          recipientSourceFields2: {
            ...state.recipientSourceFields2,
            ...action.value  
          }
        }
      }
    }

    return state
  }
  
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const [propertyData, setPropertyData] = useState([])
  //const [loadingPropertyData, setLoadingPropertyData] = useState(false)
  //const [mailingOptions, setMailingOptions] = useState(null)
    //const [gridApi, setGridApi] = useState()
  //const gridRef = useRef();
  //const [gridColumnApi, setGridColumnApi] = useState()
  const MySwal = withReactContent(Swal)
  
  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    if (propertyData && propertyData.length !== 0 && !state.listingLoadedOnce) {
      dispatch({ type: 'SET', name: 'listingLoadedOnce', value: true })
    }
  }, [propertyData])

  const load = async () => {
    if (state.documentClassOptions.length == 0 || state.paymentTypeName === '')
    {
      const [defaultPaymentTypeName, documentClasses, documentClassOptions, constantsResults] = await Promise.all([
        sendMailService.GetDetaultPaymentTypeName(),
        documentClassService.GetDocumentClasses(),
        documentClassService.GetDocumentClassOptions(),
        settingService.GetConstants([
          ConstantCodeMailFuzeCostCodesEnabled
        ])
      ])

      const constantCodes = constantsResults.map(constant => constant.value)
      const clientSettings = await settingService.GetClientSettings(constantCodes)

      const mailFuzeCostCodesEnabled = settingService.GetConstantValueFromResults(constantsResults, clientSettings, ConstantCodeMailFuzeCostCodesEnabled, (valStr) => {
        return valStr.toLowerCase() === 'true'
      })

      //copy document classes to blank array
      state.documentClasses.push.apply(state.documentClasses, documentClasses)
      state.documentClassOptions.push.apply(state.documentClassOptions, documentClassOptions)

      dispatch({ type: 'SET', name: 'paymentTypeName', value: defaultPaymentTypeName.value })
      dispatch({ type: 'SET', name: 'displayOptions', value: 'visible' })
      dispatch({ type: 'SET', name: 'costCodesEnabled', value: mailFuzeCostCodesEnabled })
      dispatch({ type: 'SET', name: 'isInitialized', value: true })
    }
  }

  const PlainSwal = MySwal.mixin({
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    }
  })

  const onShowLoading = () => {
    dispatch({ type: 'SET', name: 'isLoading', value: true })
  }

  const onHideOverlay = () => {
    dispatch({ type: 'SET', name: 'isLoading', value: false })
  }

  const onRefresh = (/*e*/) => {
    //e.preventDefault()
    //setRefreshHitOnce(true)
    onShowLoading()

    populatePropertyData()
  }

  const onMailingFieldsChange = (newMailingOptions) => {
    console.log('Mailing Fields Changed: ', newMailingOptions)
    dispatch({ type: 'SET', name: 'mailingOptions', value: newMailingOptions })
    
    //setMailingOptions(newMailingOptions)
  }

  const onSelectionChanged = (selectedData) => {
    console.log('selectedData',selectedData)
    dispatch({ type: 'SET', name: 'listingSelection', value: selectedData })
  }

  const onFirstRowOptionChange = async (firstRowIsHeader, recipients, fileId) => {

    console.log('onFirstRowOptionChange')
    console.log(firstRowIsHeader, recipients, fileId)
    const isOnFilterFirstRow = !fileId
    let isGridEntry = false
    let newRecipients = []
    if ((recipients && recipients.length !== 0) || isOnFilterFirstRow) {
      const recipientMap = state.recipientSourceFields.recipientMap
      const firstRowMap = state.recipientSourceFields.firstRowMap
      if (!isOnFilterFirstRow) {
        console.log('Adding new recipient to maps')
        recipientMap.set(fileId, recipients)
        firstRowMap.set(fileId, recipients[0])
        //isGridEntry = recipients[0].importSource === 'grid-entry'
      }
  
      let rowNum = 1
      recipientMap.forEach((fileRecipients, fileId) => {
        const firstRowRecipientId = firstRowMap.get(fileId)?.recipientId
        const recipientsFiltered = fileRecipients.filter(r => !firstRowIsHeader || r.importSource === 'grid-entry' || r.recipientId !== firstRowRecipientId)
        console.log('recipientsFiltered', recipientsFiltered)
        recipientsFiltered.forEach(r => {
          r.importRowNum = rowNum
          rowNum++
        });
        newRecipients.push(...recipientsFiltered)
      })

      if (true) {
        dispatch({ type: 'MERGE_RSF', value: {
          previousEditCell: undefined,
          //currentEditCell: undefined,
          firstRowIsHeader: firstRowIsHeader,
          recipients: newRecipients,
        }})
      }
    }
  }

  const onCustomAppend = async (fileId, newRecipients) => {
    return onFirstRowOptionChange(state.recipientSourceFields.firstRowIsHeader, newRecipients, fileId)
  }

  const onCustomRemoved = (fileId) => {
    console.log('onCustomRemoved')

    // PlainSwal.fire({
    //   title: 'Removing Related Items',
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   timer: 2000,
    //   timerProgressBar: true,
    //   didOpen: () => {
    //     PlainSwal.showLoading()
    //   },
    // }).then((results) => {
    //   onFirstRowOptionChange(state.recipientSourceFields.firstRowIsHeader, state.recipientSourceFields.recipients, undefined)
    // })
  }

  const onCustomRemove = async (fileId) => {
    console.log('fileId', fileId)
    //console.log('recipients', state.recipientSourceFields.recipients)
    const recipientMap = state.recipientSourceFields.recipientMap
    //const recipientCount = state.recipientSourceFields.recipients.filter(r => r.importId === fileId).length
    const recipients = recipientMap.get(fileId)
    if (recipients && recipients.length !== 0) {
      const recipientCount = recipients.length
      // const result = await PlainSwal.fire({
      //   title: 'Remove Recipients',
      //   html:  '<p>Do you want <span class="fw-bold">Remove</span> or <span class="fw-bold">Keep</span> the ' + recipientCount + ' recipients loaded from this file?</p>',
      //   showCloseButton: true,
      //   showCancelButton: true,
      //   showDenyButton: true,
      //   buttonsStyling: false,
      //   customClass: {
      //     actions: 'my-swal-actions',
      //     cancelButton: 'btn btn-dark btn-lg swal-order-1 swal-right-gap',
      //     confirmButton: 'btn btn-primary btn-lg me-1 swal-order-2',
      //     denyButton: 'btn btn-secondary btn-lg swal-order-3'
      //   },
      //   confirmButtonText: 'Remove',
      //   denyButtonText: 'Keep',
      // })
      const result = await PlainSwal.fire({
        title: 'Remove Recipients',
        html:  '<p>Do you want <span class="fw-bold">Remove</span> the ' + recipientCount + ' recipients loaded from this file?</p>',
        showCloseButton: true,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          cancelButton: 'btn btn-dark btn-lg me-1',
          confirmButton: 'btn btn-primary btn-lg me-1',
        },
        confirmButtonText: 'Remove',
      })

      
      if (result.isConfirmed) {
        console.log('Remove file and remove listing')
        const firstRowMap = state.recipientSourceFields.firstRowMap
  
        recipientMap.delete(fileId)
        firstRowMap.delete(fileId)

        PlainSwal.fire({
          title: 'Removing File Recipients',
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            PlainSwal.showLoading()
          },
        }).then((results) => {
          onFirstRowOptionChange(state.recipientSourceFields.firstRowIsHeader, state.recipientSourceFields.recipients, undefined)
        })

        return true
      }
      else if (result.isDenied) {
        console.log('Remove file, but keep listing')
        return true
      }
      else {
        console.log('Cancel removing the file, and keep listing')
        return false
      }
  
    }
    
    return true;
  }

  const onSelectionRemove = () => {
    console.log('onSelectionRemove!')

    const selectedRecipients = getGridSelection()


    if (selectedRecipients && selectedRecipients.length > 0) {
      console.log('selectedRecipients!', selectedRecipients)

      const recipientMap = state.recipientSourceFields.recipientMap
      const firstRowMap = state.recipientSourceFields.firstRowMap

      selectedRecipients.forEach((selectedRecipient) => {
        console.log('current selected recipient', selectedRecipient)
        const recipients = recipientMap.get(selectedRecipient.importId)
        const firstRowRecipient = firstRowMap.get(selectedRecipient.importId)

        console.log('recipients', recipients)
        console.log('current first row recipient', firstRowRecipient)

        if (firstRowRecipient && firstRowRecipient.recipientId === selectedRecipient.recipientId) {
          console.log('remove this recipient\'s first row.', selectedRecipient)
          firstRowMap.delete(selectedRecipient.importId)
        }

        if (recipients) {
          const recipientIndex = recipients.findIndex(r => r.recipientId === selectedRecipient.recipientId) 
          if (recipientIndex !== -1) {
            console.log('remove this recipient.', selectedRecipient, recipientIndex)
            recipients.splice(recipientIndex, 1)

            if (recipients.length === 0) {
              recipientMap.delete(selectedRecipient.importId)
            }
          }
        }
      })

      onFirstRowOptionChange(state.recipientSourceFields.firstRowIsHeader, state.recipientSourceFields.recipients, undefined)

    } else {
      toast.warning('No recipients selected', {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      })
    }
  }

  const onCustomClear = async () => {
    const recipientMap = state.recipientSourceFields.recipientMap

    if (recipientMap && recipientMap.length !== 0) {
      const result = await PlainSwal.fire({
        title: 'Clear Recipients',
        html:  '<p>Are you sure you want to remove all recipients?</p>',
        showCloseButton: true,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          cancelButton: 'btn btn-dark btn-lg me-1',
          confirmButton: 'btn btn-primary btn-lg me-1 swal-order-2',
        },
        confirmButtonText: 'Clear',
      })
  
      if (result.isConfirmed) {
        const firstRowMap = state.recipientSourceFields.firstRowMap
        
        console.log('Remove file and remove listing')
        recipientMap.clear()
        firstRowMap.clear()
     
        dispatch({ type: 'MERGE_RSF2', value: {
          files: []
        }});
        
        dispatch({ type: 'MERGE_RSF', value: {
          previousEditCell: undefined,
          currentEditCell: undefined,
          recipients: [],
        }})
      }
    }
  }

  const onEditStart = (column, recipientId) => {
    console.log('column', column, 'recipientId', recipientId)
    dispatch({ type: 'MERGE_RSF', value: {
      currentEditCell: { column, recipientId },
    }})
  }

  const onStopEdit =() => {
    dispatch({ type: 'MERGE_RSF', value: {
      currentEditCell: undefined,
      previousEditCell: onCurrentEditingCell()
    }})
  }

  const onCurrentEditingCell = () => {
    return state.recipientSourceFields.currentEditCell
  }

  const onPreviousEditingCell = () => {
    return state.recipientSourceFields.previousEditCell
  }

  const populatePropertyData = async () => {

    const data = await sendMailService.GetPropertyTenantsData(request);
    console.log(data)
    setPropertyData(data.data)


    onHideOverlay()
  }

  const getGridSelection = () => {
    return state.listingSelection;
  }

  const inputWarning = (title, text) => {
    PlainSwal.fire({
      title,
      icon: 'warning',
      text,
      showCloseButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary btn-lg'
      }
    })
  }

  const showError = (title, text) => {
    PlainSwal.fire({
      title,
      icon: 'error',
      text,
      showCloseButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary btn-lg'
      }
    })
  }

  const validateInputs = () => {
    const selection = getGridSelection()
    console.log(selection)
    if (!selection || selection.length === 0) {
      inputWarning('No Recipients Selected', 'You must select at least one mail recipient.')
      return -1
    }

    // ' All selects must have a valid selection
    if (!state.mailingOptions) {
      inputWarning(`Invalid Mailing Type`, `You must choose a Mailing Type from the list.`)
      return -2      
    }

    //mailingOptions.envelopeHasOptions = true

    let selectName = null
    let selectLabel = null
    let hasError = false
    Object.entries(state.mailingOptions).forEach(([key, value]) => {
      if (!hasError) {
        selectName = key.replace('HasOptions', '')
        if (key.endsWith('HasOptions') && value === true && (state.mailingOptions[selectName] === null || state.mailingOptions[selectName] === 0)) {
          selectLabel = state.mailingOptions[selectName + 'Label']
          inputWarning(`Invalid ${selectLabel}`, `You must choose a ${selectLabel} from the list.`)
          hasError = true
        }
      }
    })

    if (hasError)
      return -2

    if (state.letterTemplateFields.letterOption === LetterOptions.Custom && (!state.letterTemplateFields.fixedFiles || state.letterTemplateFields.fixedFiles.length === 0)) {
      inputWarning('Invalid Custom Letter', 'You must upload a Static PDF.')
      return -6
    }

    return 0
  }

  const getSanitizedLetterTemplateFields = () => {
    const sanitizedLetterTemplateFields = {
      letterOption: state.letterTemplateFields.letterOption,
      isMergeFile: state.letterTemplateFields.isMergeFile,
      //fixedFiles: []
    }

    //const files = filePondRef.current.getFiles()
    //const files = letterTemplateFields.fixedFiles
    if (state.letterTemplateFields.letterOption === LetterOptions.Custom)
    {
      // state.letterTemplateFields.fixedFiles.forEach(file => {
      //   console.log("file.serverId", file.serverId)
      //   let str = file.serverId
      //   if(str.startsWith("\"") && str.endsWith("\""))
      //   {
      //       str = str.substring(1, str.length-1);
      //   }
      //   sanitizedLetterTemplateFields.fixedFiles.push(str)
      // });

      const fixedFiles = state.letterTemplateFields.fixedFiles
      sanitizedLetterTemplateFields.fixedFileId = ''
      if (fixedFiles && fixedFiles.length > 0) {
        const file = fixedFiles[0]
        console.log("file.serverId", file.serverId)
        let str = file.serverId
        if(str.startsWith("\"") && str.endsWith("\""))
        {
            str = str.substring(1, str.length-1)
        }
        sanitizedLetterTemplateFields.fixedFileId = str
      }
    }

    return sanitizedLetterTemplateFields
  }

  const onCostEstimate = async (e) => {
    if (validateInputs() === 0) {
      const selectedData = getGridSelection()
      console.log(selectedData)
      const selectedRecipientIds = selectedData.map(node => node.importRowNum)

      PlainSwal.fire({
        title: 'Calculating Cost Estimate',
        allowEscapeKey: false,
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          PlainSwal.showLoading()
        },
      })
      
      const body = {
        recipients: selectedRecipientIds,
        ...getSanitizedLetterTemplateFields(),
        ...state.mailingOptions,
        paymentTypeName: state.paymentTypeName
      }

      //console.log('body', body)

      sendMailService.GetMailingEstimates(body).then(estimateResult => {
        PlainSwal.close()

        if (!showEstimateError(estimateResult)) {
          const estimate = estimateResult.estimate
          const letterInfo = estimateResult.letterInfo
          const batchInfo = estimateResult.batchInfo
          const sPageSuffix = letterInfo.documentPageCount === 1 ? '' : 's'
          const pageCountWarning = state.letterTemplateFields.letterOption === LetterOptions.Custom ? '' : 'or any indivdiual documents are more than ' + letterInfo.documentPageCount + ' page' + sPageSuffix + '.'
          const splitBatchWarning = batchInfo && !batchInfo.useBatching ? '' : 'Due to the number of recipients and/or the size of your file, the ' + batchInfo.recipientCount + ' mailings will be split into ' + batchInfo.batchCount + ' smaller batches.'

          PlainSwal.fire({
              title: 'Mailing Cost Estimate',
              html: '<div style="text-align: left"><span class="fw-bold">Pages in document: </span>' + letterInfo.documentPageCount + '<br />' +
                  '<span class="fw-bold">Cost for each recipient: </span>' + CurrencyFormatter.format(estimate.perRecipientCost) + '<br />' +
                  '<span class="fw-bold">Number of recipients: </span>' + selectedRecipientIds.length + '<br />' +
                  '<span class="fw-bold">Total cost of mailing: </span>' + CurrencyFormatter.format(estimate.totalCost) + '</div>' +
                  '<div style="color: red; margin-top: 1em">NOTE: This is an ESTIMATE only and cost could be higher if any addresses are non-standard ' +
                  pageCountWarning + '.</span>' +
                  (splitBatchWarning === '' ? '' : '<div style="color: red; margin-top: 1em">NOTE: ' + splitBatchWarning + '</div>'),
              showCloseButton: true,
              buttonsStyling: false,
              customClass: {
                  confirmButton: 'btn btn-primary btn-lg'
              },
          })
        }
      }).catch(error => { PlainSwal.close(); showEstimateError(error); })
    }
  }

  const onPreview = async (e, previewCount = 0) => {
    if (validateInputs() === 0) {
      const selectedData = getGridSelection()
      const selectedRecipientIds = selectedData.map(node => node.importRowNum)

      let documentClass = -1
      if (state.documentClass !== 0) {
        documentClass = state.documentClass
      }

      const body = {
        recipients: selectedRecipientIds,
        documentClass: documentClass,
        previewCount: previewCount,
        ...getSanitizedLetterTemplateFields()
      }

      console.log(body)

      PlainSwal.fire({
        title: 'Generating Preview',
        allowEscapeKey: false,
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          PlainSwal.showLoading()
        },
      })

      const result = await sendMailService.GetPreview(body)
        .catch(error => { PlainSwal.close(); showPreviewError(error); })

      if (!showPreviewError(result)) {
        const authToken = await authService.getAccessToken()

        PlainSwal.close()

        console.log(result)

        const dl = PlainSwal.fire({
          title: 'Your Preview is Ready',
          html: downLoadIconRef.current.outerHTML.replace('href="#"', `href="${result.returnDataAsString}" authtoken="${authToken}" onClick="openPdf(event)" id="downloadBtn"`),
          showCloseButton: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-dark'
          },
          didOpen: function(ele) {
            ele.querySelector('#downloadBtn').addEventListener('click', function(){ dl.close(); }, false);
          }
        })
      }
    }
  }

  const onSend = async (e) => {
    if (validateInputs() === 0) {
      const selectedData = getGridSelection()
      console.log(selectedData)
      
      const selectedRequestData = selectedData.map(data => { return { 
        ExternalRecipientID: data.importRowNum.toString(),
        FirstName: data.firstName,
        LastName: data.lastName,
        Street: data.street,
        City: data.city,
        State: data.state,
        Zip: data.zip, //['ZIP']
        CostCode: state.costCodesEnabled ? data.costCode : null,
        ReturnAddress: {
          Name: data.returnName,
          Street: data.returnStreet,
          City: data.returnCity,
          State: data.returnState,
          Zip: data.returnZip
        }
      }})

      const selectedRecipientIds = selectedData.map(data => data.importRowNum)

      //const estimateResult = await onSendEstimate(selectedRecipientIds)
      const validationResult = await onValidation(selectedRecipientIds, selectedRequestData)
      if (!validationResult.hasEstimateError) {
        if (!showValidationError(validationResult)) {
          console.log('Validation completed successufully!', validationResult);
  
          if (validationResult.hasError) {
            console.log('Validation yielded errors!');
  
            console.log('sendMailResults:', validationResult)
            dispatch({ type: 'SET', name: 'isValidationErrorsOpen', value: true })
            dispatch({ type: 'SET', name: 'recipientsWithoutAddress', value: validationResult.badAddresses })
            dispatch({ type: 'SET', name: 'documentDimensionResult', value: validationResult.documentDimensionResult })
            dispatch({ type: 'SET', name: 'envelopeNumberOfPagesResult', value: validationResult.envelopeNumberOfPagesResult })
            dispatch({ type: 'SET', name: 'clientAddressValidationResult', value: validationResult.clientAddressValidationResult })
          } else {
            const estimate = validationResult.estimateResult.estimate
            const batchInfo = validationResult.estimateResult.batchInfo
            const splitBatchWarning = batchInfo && !batchInfo.useBatching ? '' : 'Due to the number of recipients and/or the size of your file, the ' + batchInfo.recipientCount + ' mailings will be split into ' + batchInfo.batchCount + ' smaller batches.'
            
            PlainSwal.fire({
              title: 'Confirm Mailing Cost Estimate',
              html:  'The total ESTIMATED cost of this mailing for ' + selectedRecipientIds.length + 
                      ' recipient' + (selectedRecipientIds.length === 1 ? '' : 's') + ' is ' + CurrencyFormatter.format(estimate.totalCost) + '.' +
                      (splitBatchWarning === '' ? '' : '<div style="color: red; margin-bottom: 1em; margin-top: 1em">NOTE: ' + splitBatchWarning + '</div>') +
                      '<p class="fw-bold">Are you sure you want to do this?</p>',
              showCloseButton: true,
              showCancelButton: true,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-primary btn-lg me-1',
                cancelButton: 'btn btn-secondary btn-lg ms-1'
              },
              confirmButtonText: 'Confirm',
            }).then(async (result) => {
              if (result.isConfirmed) {
                onSendEstimateConfirmed(selectedRequestData, validationResult.estimateResult)
              }
            })       
          }
        }
      }
    }
  }

  const onValidation = async (selectedRecipientIds, selectedRequestData) => {
    PlainSwal.fire({
      title: 'Validating Submission',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        PlainSwal.showLoading()
      },
    })

    const estimateBody = {
      recipients: selectedRecipientIds,
      ...getSanitizedLetterTemplateFields(),
      ...state.mailingOptions,
      paymentTypeName: state.paymentTypeName
    }
    
    const estimateResult = await sendMailService.GetMailingEstimates(estimateBody).catch(error => { PlainSwal.close(); showEstimateError(error); });
    if (!showEstimateError(estimateResult)) {

      const sendMailBody = {
        recipients: selectedRequestData,
        ...getSanitizedLetterTemplateFields(),
        ...state.mailingOptions,
        estimate: estimateResult,
        paymentTypeName: state.paymentTypeName
      }
  
      const validationResult = await sendMailService.ValidateSendMailToCustomRecipient(sendMailBody).catch(error => { PlainSwal.close(); showValidationError(error); });

      PlainSwal.close()

      return {
        ...validationResult,
        estimateResult
      }
    }

    return {
      hasEstimateError: true
    }
  }

  // const onSendEstimate = async (selectedRecipientIds) => {
  //   PlainSwal.fire({
  //     title: 'Calculating Cost Estimate',
  //     allowEscapeKey: false,
  //     allowOutsideClick: false,
  //     timerProgressBar: true,
  //     didOpen: () => {
  //       PlainSwal.showLoading()
  //     },
  //   })

  //   const estimateBody = {
  //     recipients: selectedRecipientIds,
  //     ...getSanitizedLetterTemplateFields(),
  //     ...state.mailingOptions,
  //     paymentTypeName: state.paymentTypeName
  //   }

  //   console.log('estimateBody', estimateBody)

  //   const result = await sendMailService.GetMailingEstimates(estimateBody).catch(error => { PlainSwal.close(); showEstimateError(error); });

  //   PlainSwal.close()

  //   return result;
  // }

  const showEstimateError = (estimateResult) => {
    if (!estimateResult || estimateResult.returnCode !== 0) {
      showError("Error Getting Cost Estimate", estimateResult && estimateResult.errorMessage)
      return true
    }
    
    return false
  }

  const showValidationError = (validationResult) => {
    if (!validationResult || !validationResult.isSuccess) {
      if (validationResult && validationResult.hasEstimateError) {
        return false
      }

      showError("Error Occurred During Validation", validationResult && validationResult.errorMessage)
      console.log(validationResult)
      return true
    }
    
    return false
  }

  const showPreviewError = (previewResult) => {
    if (!previewResult || previewResult.returnCode !== 0) {
      showError("Error Generating Preview", previewResult && previewResult.errorMessage)
      console.log(previewResult)
      return true
    }
    
    return false
  }


  const onSendEstimateConfirmed = async (selectedRequestData, estimateResults) => {
    console.log('Confirmed')

    const sendMailBody = {
      recipients: selectedRequestData,
      ...getSanitizedLetterTemplateFields(),
      ...state.mailingOptions,
      estimate: estimateResults,
      paymentTypeName: state.paymentTypeName
    }

    console.log('sendMailBody', sendMailBody)

    PlainSwal.fire({
      title: 'Processing',
      html: '<div id="processingText">Submitting</div>',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        PlainSwal.showLoading()
      },
    })

    sendMailService.CallSendMailToCustomRecipient(sendMailBody).then(sendMailResults => {

      PlainSwal.close()
      if (sendMailResults.isSuccess) {
        toast.success('Send Mail Submitted', {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        })
        //setSendMailPollingData(5000, sendMailResults.sendMailId);
      }
      else {
        //PlainSwal.close()

        console.log('sendMailResults:', sendMailResults)
        dispatch({ type: 'SET', name: 'isValidationErrorsOpen', value: true })
        dispatch({ type: 'SET', name: 'recipientsWithoutAddress', value: sendMailResults.badAddresses })
        dispatch({ type: 'SET', name: 'documentDimensionResult', value: sendMailResults.documentDimensionResult })
        dispatch({ type: 'SET', name: 'envelopeNumberOfPagesResult', value: sendMailResults.envelopeNumberOfPagesResult })
        dispatch({ type: 'SET', name: 'clientAddressValidationResult', value: sendMailResults.clientAddressValidationResult })

        // toast.warning('See Validation Errors', {
        //   position: "top-right",
        //   autoClose: 1000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: false,
        //   progress: undefined,
        //   theme: 'colored'
        // })
      }
      
    }).catch(error => { console.log(error); PlainSwal.close() });

    // sendMailService.CallSendMail(sendMailBody).then(sendMailResults => {
    //   PlainSwal.close()

    //   console.log(sendMailResults)

    //   setSendMailResults({
    //       showModal: true,
    //       success: sendMailResults.success,
    //       transactionLog: sendMailResults.transactionLog,
    //       results: sendMailResults.results,
    //       sendMailService
    //   })
    // }).catch(error => { console.log(error); PlainSwal.close() });
  }

  const onOpenHelp = (e) => {
    e.currentTarget.blur()
    dispatch({ type: 'SET', name: 'isHelpOpen', value: true })
  }

  const onCloseHelp = () => {
    dispatch({ type: 'SET', name: 'isHelpOpen', value: false })
  }

  const onUnselect = async () => {
    console.log('onUnselect called')

    if (sendMailCustomGridRef && sendMailCustomGridRef.current) {
      //console.log('call imperative unselectRows on current')
      //console.log(state.recipientsWithoutAddress)
      //console.log(state.recipientsWithoutAddress.map(r => parseInt(r.externalRecipientID)))
      const removedCount = await sendMailCustomGridRef.current.unselectRows(state.recipientsWithoutAddress.map(r => parseInt(r.externalRecipientID)))
      if (removedCount !== 0)
      {
        let s = removedCount == 1 ? '' : 's'
        toast.success(`${removedCount} Recipient${s} Removed From Selection`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        })
      } else {
        toast.warning(`No Recipients Removed`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        })
      }

    }
  }

  return (
    <div>
      <div className="d-none">
        <div ref={downLoadIconRef}>
          <Button color="primary" className="btn-lg" href="#"><Download /> Download Preview</Button>
        </div>
      </div>
      {/* <Container style={{maxWidth: '2000px', width: '1600px'}}> */}
      <Container>
        <Row style={{visibility: state.displayOptions}}>
          <Col md={12} lg={9}>
            <Form>
              <Row>
                <div className="border ml-3 p-3" style={{flex: '0 0 auto', maxWidth: '100%' }}>
                  <Col>
                    <fieldset>
                      <legend>
                        <div className='d-flex flex-row justify-content-between'>
                          <span><Badge><PersonLinesFill /></Badge> Letter Recipients</span>
                          <Button
                            onClick={onOpenHelp}
                            color='primary'
                            style={{ transform: 'scale(0.9)' }}
                            className='btn-circle d-flex flex-column justify-content-center align-items-center'
                          >
                              <InfoLg size={28} style={{width: '28px', height: '28px' }} />
                          </Button>
                        </div>
                      </legend>
                      <hr />
                      <SendMailCustomUpload
                        initialValues={state.recipientSourceFields2}
                        onAppend={onCustomAppend}
                        onRemove={async (fileId) => {return await onCustomRemove(fileId)}}
                        onRemoved={onCustomRemoved}
                        onChange={(files) => {
                          dispatch({ type: 'MERGE_RSF2', value: {
                            files: files
                          }});
                        }}
                      />
                    </fieldset>
                  </Col>
                    {/* <FormGroup
                      check
                      inline
                    >
                      <Input
                        type='checkbox'
                        checked={state.recipientSourceFields.firstRowIsHeader ? 'checked' : ''}
                        name='firstRowIsHeader'
                        id='firstRowIsHeader'
                        onChange={(e) => onFirstRowOptionChange(e.target.checked, state.recipientSourceFields.recipients, undefined)}
                        style={{transform: 'scale(1.3)'}}
                      />
                      <Label for='firstRowIsHeader'>The first row contains the column headers</Label>
                    </FormGroup> */}
                  {/* <Col md={12} lg={5}>
                    <Row>
                      <h6 className="dark">Hint</h6>
                    </Row>
                    <Row>
                      <Col>
                        <div className='help-panel'>
                          <div>
                            Import your recipients from CSV or Excel files. You can also add,
                            remove and edit recipients direcltly in the listing.
                            <br />
                            <a href='https://www.google.ca'>File samples and more hints<BoxArrowUpRight className='ms-1' /></a>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col> */}

                </div>
              </Row>
            </Form>
          
            <Row>
              <div className="m-0 mt-2 p-0" style={{flex: '0 0 auto', maxWidth: '100%' }}>
                {state.isInitialized && (
                  <SendMailCustomGrid
                    ref={sendMailCustomGridRef}
                    isLoading={state.isLoading}
                    listingData={state.recipientSourceFields.recipients}
                    firstRowIsHeader={state.recipientSourceFields.firstRowIsHeader}
                    onFirstRowOptionChanged={(checked) => onFirstRowOptionChange(checked, state.recipientSourceFields.recipients, undefined)}
                    onSelectionChanged={onSelectionChanged}
                    onRowAdded={onCustomAppend}
                    onClear={onCustomClear}
                    onSelectionRemove={onSelectionRemove}
                    onStartEdit={onEditStart}
                    onStopEdit={onStopEdit}
                    onCurrentEditingCell={onCurrentEditingCell}
                    onPreviousEditingCell={onPreviousEditingCell}
                    costCodesEnabled={state.costCodesEnabled}
                  />
                )}
              </div>
            </Row>
          </Col>
          <Col md={12} lg={3}>
            <div className="border ml-3 p-3" style={{flex: '0 0 auto', maxWidth: '100%', height: '100%'}}>
              <Row>
                <Col>
                  <fieldset>
                    <legend><Badge><EnvelopeExclamation /></Badge> Letters</legend>
                    <hr />
                    <h6 className='text-dark'>Static PDF</h6>
                    <SendMailLetterOptions
                        initialValues={state.letterTemplateFields}
                        onChange={value => dispatch({ type: 'MERGE_LTF', value })}
                    >
                    </SendMailLetterOptions>
                  </fieldset>
                </Col>
              </Row>
              <Row>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col>
                  <fieldset className="mt-3">
                    <legend><Badge><GearWideConnected /></Badge> Mail Options</legend>
                    <hr />
                    <MailingFields
                      onChange={onMailingFieldsChange}
                      documentClasses={state.documentClasses}
                      documentClassOptions={state.documentClassOptions}
                    />
                  </fieldset>
                </Col>
              </Row>
              <Row>
                <Col className="w-100 text-center">
                  <Button color="dark" onClick={onCostEstimate} disabled={state.letterTemplateFields.letterUploading}><Calculator /> Cost Estimate</Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                {/* {state.letterTemplateFields.letterOption !== LetterOptions.Fixed ? ( */}
                  <React.Fragment>
                    <Col sm={6} md={12} xxl={6}>
                      <Button color="dark" className="w-100" onClick={ onPreview } disabled={state.letterTemplateFields.letterUploading}><BinocularsFill /> Preview</Button>
                    </Col>
                    <Col sm={6} md={12} xxl={6} className="mt-3 mt-sm-0 mt-md-3 mt-xxl-0">
                      <Button color="dark" className="w-100" onClick={ onSend } disabled={state.letterTemplateFields.letterUploading}><Send /> Send</Button>
                    </Col>
                  </React.Fragment>
                {/* ) : 
                  <Col xs={12}>
                    <Button color="dark" className="w-100" onClick={ onSend } disabled={state.letterTemplateFields.letterUploading}><Send /> Send</Button>
                  </Col>
                } */}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      
      <SendMailValidationResult
        showCanvas={state.isValidationErrorsOpen}
        //recipientsWithoutAddress={state.recipientsWithoutAddress}
        onClose={() => dispatch({ type: 'SET', name: 'isValidationErrorsOpen', value: false })}
      >
        {state.envelopeNumberOfPagesResult && !state.envelopeNumberOfPagesResult.isValid && (
          <WarningLetterNumberPageOfPages
            result={state.envelopeNumberOfPagesResult}
          />
        )}

        {state.documentDimensionResult && !state.documentDimensionResult.validDocumentDimensions && (
          <WarningInvalidDocumentDimensions
            validDocumentDimensions={state.documentDimensionResult.validDocumentDimensions}
            targetDocumentWidth={state.documentDimensionResult.targetDocumentDimensions.documentWidth}
            targetDocumentHeight={state.documentDimensionResult.targetDocumentDimensions.documentHeight}
          />
        )}

        {state.clientAddressValidationResult && !state.clientAddressValidationResult.isValid && (
          <WarningInvalidClientAddress
            result={state.clientAddressValidationResult}
          />
        )}

        {state.recipientsWithoutAddress && state.recipientsWithoutAddress.length !== 0 && (
          <WarningBadAddress
            recipientsWithoutAddress={state.recipientsWithoutAddress}
            onUnselect={onUnselect}
          />
        )}

      </SendMailValidationResult>

      {state.isHelpOpen && state.isInitialized && (
        <SendMailCustomHelp showModal={state.isHelpOpen} onClose={onCloseHelp} costCodesEnabled={state.costCodesEnabled} />
      )}

    </div>
  )
}

export default SendMailCustom
