import React, { useReducer, useEffect } from 'react'
import { Row, Col, Input, InputGroup, InputGroupText } from 'reactstrap'
import OptionYesNo from '../widget/OptionYesNo'

const ChargeResident = ({onChange}) => {

  const initialState = {
     chargeResident: false,
     chargeResidentAmount: 0.0
  }

  const actionReducer = (state, action) => {
    switch (action.type) {
      case 'SET': {
        return {
          ...state,
          [action.name]: action.value
        }
      }
    }

    return state
  }

  const [state, dispatch] = useReducer(actionReducer, initialState)

  useEffect(() => {
    onChange(state)
  }, [state])

  return (
    <React.Fragment>
      <OptionYesNo
        onChange={({chargeResident}) => dispatch({ type: 'SET', name: 'chargeResident', value: chargeResident })}
        id="chargeResident"
        text="Charge Tenant"
        initialValue={state.chargeResident}
      />
      {
        state.chargeResident && (
          <React.Fragment>
            <Row className="mt-2 mb-3">
              <Col>
                <InputGroup>
                  <InputGroupText>$</InputGroupText>
                  <Input
                    className="text-end"
                    name="chargeResidentAmount"
                    type="number"
                    step="0.01"
                    min="0"
                    max="50"
                    value={state.chargeResidentAmount}
                    onChange={e => dispatch({ type: 'SET', name: e.target.name, value: e.target.value })}
                  />
                </InputGroup>
              </Col>
            </Row>
          </React.Fragment>
        )
      }
    </React.Fragment>
  )
}

export default ChargeResident