import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { customStyles } from './Styles'
import { useFetchWrapper } from '../../services/fetch-wrapper'
import { All, Blank } from './DefaultSelectOptions'
import { FormFeedback } from 'reactstrap'

const BaseSelect = ({useEffectDependencies, onChange, onLoaded, onError, disabled, registerMe = () => {}, selectFirst = true, initialValue = undefined, serviceUrl, valueName, labelName, setToPrimary, invalid, valid, message, ...rest}) => {
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [selected, setSelected] = useState()
    //const fetchWrapper = useFetchWrapper();
    const fetchWrapper = useFetchWrapper()
    const combinedCustomStyles = {
      ...customStyles,
      ...rest.customStyles
  };

    const localEffectDependencies = useEffectDependencies ? useEffectDependencies : []

    // useEffect(() => {
    //   if (isFunction(onLoaded)) {
    //     onLoaded(true)
    //   }
    // }, [])

    useEffect(() => {
      console.log('use effect triggered by localEffectDependencies')
      if ((localEffectDependencies.length === 0) || (localEffectDependencies.find(ld => ld !== undefined))) {
          populateDropDown()
      }
    }, localEffectDependencies)

    // const handleError = function (err) {
    //     console.warn(err);

    //     return new Response(JSON.stringify({
    //         code: 400,
    //         message: `Unexpected network error occured calling ${serviceUrl}`
    //     }));
    // };

    const onSelectedValueChange = (selectedOption, action) => {
      if (isFunction(onChange)) {
        if (rest.isMulti && action) {
          //action.action === 'clear'
          console.log(selectedOption)
          console.log(action)
          if (action && action.option)
            console.log(action.option.value)
          console.log(All.value)

          const hasOptions = options && options.length > 0
          const allBeingSelected = action.option && action.option.value && action.option.value.toString() === All.value.toString()
          const blankBeingSelected = action.option && action.option.value && action.option.value.toString() === Blank.value.toString()
          const allCurrentlySelected = selectedOption && selectedOption.length > 0 && selectedOption.some(option => option.value.toString() === All.value.toString())
          const blankCurrentlySelected = selectedOption && selectedOption.length > 0 && selectedOption.some(option => option.value.toString() === Blank.value.toString())
          const allOrBlankBeingSelected = allBeingSelected || blankBeingSelected
          const allOrBlankCurrentlySelected = allCurrentlySelected || blankCurrentlySelected

          console.log('hasOptions: ', hasOptions)
          console.log('allOrBlankBeingSelected: ', allOrBlankBeingSelected)

          const findInitialValue = () => {
            console.log('selectFirst', selectFirst)
            console.log('initialValue', initialValue)
            console.log('options', options)
            var selectedOption = options.find(option => {
              try {
                if (option.value.toString() === initialValue.toString() || (option.value.toString() === initialValue.value.toString()))
                {
                  return true;
                }
                else {
                  return false;
                }
              } catch {
                return false;
              }
            })
            console.log('selectedOption', selectedOption)

            return selectedOption
          }

          //All was selected, so we deselect all other options
          if (action && hasOptions && allOrBlankBeingSelected) {
            if (allBeingSelected) {
              setSelected(All)
              onChange(All) //don't send action.
            }
            else if (blankBeingSelected){
              setSelected(Blank)
              onChange(Blank) //don't send action.
            }
          }
          else if (action && hasOptions && selectedOption && selectedOption.length > 1 && allOrBlankCurrentlySelected)
          {
            if (allCurrentlySelected) {
              console.log('Remove "All" from Selection')
              var everySelectedOptionExceptAll = selectedOption.filter(option => option.value.toString() !== All.value.toString())
              setSelected(everySelectedOptionExceptAll)
              onChange(everySelectedOptionExceptAll, action)
            }
            else if (blankCurrentlySelected) {
              console.log('Remove "Blank" from Selection')
              var everySelectedOptionExceptBlank = selectedOption.filter(option => option.value.toString() !== Blank.value.toString())
              setSelected(everySelectedOptionExceptBlank)
              onChange(everySelectedOptionExceptBlank, action)
            }
          }
          else if (selectedOption.length == 0 && selectFirst && hasOptions)
          {
            setSelected(options[0])
            onChange(options[0]) //don't send action.
          }
          else if (selectedOption.length == 0 && initialValue && hasOptions) {
            const initialOption = findInitialValue()
            if (initialOption) {
              setSelected(initialOption)
              onChange(initialOption) //don't send action.
            }
          }
          else {
            setSelected(selectedOption)
            onChange(selectedOption, action)            
          }
        }
        else {  //not multi
          setSelected(selectedOption)
          onChange(selectedOption, action)
        }
      }
      else { //no onChange, just set the selected value
        setSelected(selectedOption)
      }
    }

    const populateDropDown = () => {
      //fetchWrapper.get(serviceUrl)
      //.then(console.log('GOT RESULTS!!!!'));

      //console.log(fetchWrapper.get);
      //console.log(fetchWrapper.get(serviceUrl));
      //console.log(serviceUrl);
      //console.log(serviceUrl);
      //const twat = fetchWrapper.get(serviceUrl)
      //console.log(twat);
      return fetchWrapper.get(serviceUrl).then((data) => {
        //console.log(data);
        //const data = await response.json()
        var mapped = data.map(x => ({ value: x[valueName], label: x[labelName] }))
        const hasOptions = mapped && mapped.length > 0
        let isSet = false
        setOptions(mapped)
        if (setToPrimary && hasOptions) {
          const primary = data.find(x => x.IsPrimary)
          if (primary) {
            const primaryOption = { value: primary[valueName], label: primary[labelName] }
            onSelectedValueChange(primaryOption)
            isSet = true
          }
        }
        
        if (!isSet)
        {
          if (selectFirst && hasOptions) {
            //setSelected(mapped[0])
            onSelectedValueChange(mapped[0])
          }
          else if (initialValue && hasOptions && !selected) {
            //console.log('selectFirst', selectFirst)
            console.log('initialValue', initialValue)
            //console.log('mapped', mapped)
            var selectedOption = mapped.find(option => {
              try {
                if (option.value.toString() === initialValue.toString() || (option.value.toString() === initialValue.value.toString()))
                {
                  return true;
                }
                else {
                  return false;
                }
              } catch {
                return false;
              }
            })
            console.log('selectedOption', selectedOption)
            if (selectedOption) {
              onSelectedValueChange(selectedOption)
            }
          }
          else {
            setSelected(null)
          }
        }

        if (isFunction(onLoaded)) {
          
          onLoaded({hasOptions})
        }
        
        setLoading(false)
      });

      /*const token = await authService.getAccessToken()
      const response = await fetch(serviceUrl, {
          headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      }).catch(handleError)

      console.log('selectFirst', selectFirst)
      console.log('initialValue', initialValue)

      if (response.ok) {
          const data = await response.json()
          var mapped = data.map(x => ({ value: x[valueName], label: x[labelName] }))
          const hasOptions = mapped && mapped.length > 0
          setOptions(mapped)
          if (selectFirst && hasOptions) {
            //setSelected(mapped[0])
            onSelectedValueChange(mapped[0])
          }
          else if (initialValue && hasOptions && !selected) {
            console.log('selectFirst', selectFirst)
            console.log('initialValue', initialValue)
            console.log('mapped', mapped)
            var selectedOption = mapped.find(option => option.value.toString() === initialValue.toString())
            console.log('selectedOption', selectedOption)
            if (selectedOption) {
              onSelectedValueChange(selectedOption)
              //setSelected(selectedOption)
            }
          }
          else {
            setSelected(null)
          }

          if (isFunction(onLoaded)) {
            
            onLoaded({hasOptions})
          }
          
          setLoading(false)
      } else {
        console.log(response)
        setError(true)

        if (isFunction(onError)) {
          onError(response)  
        }
      }*/
    }

    return (
        <React.Fragment>
            {loading ? (<div></div>) : error ? (<div className="form-control danger">Error</div>) : (
                <Select
                    styles={combinedCustomStyles}
                    options={options}
                    value={selected}
                    onChange={ onSelectedValueChange }
                    placeholder={rest.placeholder ? rest.placeholder : ''}
                    invalid={invalid}
                    valid={valid}
                    {...registerMe}
                    {...rest}
                    className={`${invalid? 'is-invalid': ''}`}
                /> 
            )}
            {invalid && (
              <FormFeedback className='ms-1'>{message}</FormFeedback>
            )}
        </React.Fragment >
    )
}

export default BaseSelect