import { AgGridReact } from 'ag-grid-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { dateTimeFormat, dateFilter } from '../../services/DateFormatter'
import { CustomLoadingOverlay } from '../widget/AGGridCustomOverlay'
import TemplateDetailRowRenderer from './TemplateDetailRowRenderer'

const SendMailAddressWizardManagerGrid = ({listingData, isLoading, onSelectionChanged, onDeleteTemplate}) => {
  const gridRef = useRef()

  useEffect(() => {
    console.log('use effect triggered on isLoading changed to ' + isLoading)
    if (isLoading) {
      onShowLoading()
    }
    else {
      onHideOverlay()
    }
  }, [isLoading])

  const setGridSelection = () => {
    if (gridRef.current) {
      const selectedNodes = gridRef.current.api.getSelectedNodes()
      const selectedData = selectedNodes.map(node => node.data)
      onSelectionChanged(selectedData)
    }
    else {
      onSelectionChanged([])
    }
  }

  const onShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, [])

  const onHideOverlay = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  const [defaultColumnDefs, setDefaultColumnDefs] = useState({
    flex: 1,
    sortable: true,
    filter: true,
    resizable: true,
    //editable: true,
  })

  const [columnDefs, setColumnDefs] = useState([
    { 
      field: 'id',
      editable: false,
      // headerName: '',
      // valueFormatter: () => { return '' },
      // checkboxSelection: true
      hide: true
    },
    { field: 'name', headerName: 'Template Name' },
    // { field: 'clientName', headerName: 'Company' },
    { field: 'createdBy' },
    {
      field: 'createdOn',
      headerClass: "ag-form-header",
      filter: 'agDateColumnFilter',
      valueFormatter: dateTimeFormat,
      filterParams: dateFilter
    },
    { field: 'description' }
  ])

  return (
    <div className="ag-theme-alpine" style={{height: '95vh', minHeight: '400px', width: '100%' }}>
      <AgGridReact
        ref={gridRef}
        rowData={listingData}
        rowSelection="none"
        rowClass='tpl-row'
        getRowHeight={(params) => 125}
        //isFullWidthRow={isFullWidthRow}
        isFullWidthCell={(params) => true}
        fullWidthCellRenderer="templateDetailRowRenderer"
        fullWidthCellRendererParams={{
          onDeleteTemplate: (node) => {
            onDeleteTemplate(node.data.id, node.data.name)
          }
          //joeRowHeight: 200
        }}
        //domLayout="autoHeight"
        paginationPageSize={30}
        columnDefs={columnDefs}
        //isExternalFilterPresent={isExternalFilterPresent}
        //doesExternalFilterPass={doesExternalFilterPass}
        loadingOverlayComponent="customLoadingOverLoad"
        loadingOverlayComponentParams={{
          loadingMessage: 'Loading...'
        }}
        //animateRows={true}
        defaultColDef={defaultColumnDefs}
        pagination={true}
        frameworkComponents={{
          customLoadingOverLoad: CustomLoadingOverlay,
          templateDetailRowRenderer: TemplateDetailRowRenderer
        }}
        onRowDataChanged={(event) => {
          console.log('onRowDataChanged')
          console.log(event)
          setGridSelection()
        }}
        onGridReady={(event) => { 
          //console.log('onGridReady')
          //console.log(event);
          //event.api.sizeColumnsToFit()
          event.columnApi.applyColumnState({
            state: [
              {
                colId: 'name',
                sort: 'asc'
              }
            ]
          })
        }}
        onSelectionChanged={(event) => {
          setGridSelection()
        }}
      >
      </AgGridReact>
    </div>
  )
}

export default SendMailAddressWizardManagerGrid