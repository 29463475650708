import React, { useCallback, useEffect, useReducer, useState } from 'react'
import Select from 'react-select'
import { Button, Col, Label, Row } from 'reactstrap'
import { useSendMailHistoryService } from '../../services/SendMailHistory'
//import { useUserService } from '../../services/UserService'
import DecoratedDateRangePicker from '../widget/DecoratedDateRangePicker'
import SendMailHistoryGrid from './SendMailHistoryGrid'
import authService from '../api-authorization/AuthorizeService';
import { Roles } from '../../services/RoleService'
import { customStyles } from '../dropdowns/Styles'
import SendMailHistoryDetails from './SendMailHistoryDetails'
import { ArrowClockwise, EnvelopeCheck, Inboxes, ListCheck } from 'react-bootstrap-icons'
import SendMailResults from '../send-mail/SendMailResults'
import { useParams } from 'react-router-dom'
import { useSendMailService } from '../../services/SendMailService'
import { useClientService } from '../../services/ClientService';
import { ConstantCodeMailFuzeCostCodesEnabled, useSettingsService } from '../../services/SettingsService'

const SendMailHistory = () => {
  const { sendMailId } = useParams();
  const sendMailHistoryService = useSendMailHistoryService()
  const sendMailService = useSendMailService()
  const clientService = useClientService()
  const settingService = useSettingsService()
  //const userService = useUserService()

  const [sendMailResults, setSendMailResults] = useState({ showModal: false, results: []})

  const initialState = {
    columnSettings: {
      isSystemAdmin: false,
      isAdmin: false,
      isIntegrationPartner: false,
      costCodeEnabled: false
    },
    perPage: { value: 30, label: '30' },
    //selectedSendMailData: {uniqueKey: undefined, nodeData: []},
    detailMailingData: undefined,
    dateSelectionRange: [{
      startDate: new Date().toLocaleDateString() + ' ' + "00:00",
      endDate: new Date().toLocaleDateString() + ' ' + "00:00",
      key: 'selection',
    }],
    isLoading: false,
  }

  const stateReducer = (state, action) => {
    switch (action.type) {
      case 'SET': {
        return {
          ...state,
          [action.name]: action.value
        }
      }
    }

    return state
  }

  const [state, dispatch] = useReducer(stateReducer, initialState)
  const [sendMailData, setSendMailData] = useState([])
  //const [userID, setUserID] = useState(null)

  useEffect(() => {
    //console.log('Load send mail history will be called because dateSelectionRange changed')
    //onShowLoading()
    console.log('Populate state will be called on first load []')
    populateState()
    //loadMailings()
  }, [])

  useEffect(() => {
    console.log('sendMailId: ', sendMailId)
    if (sendMailId) {
      showResults(sendMailId)
    }
  }, [])

  // useEffect(() => {
  //   console.log('Load send mail history will be called because dateSelectionRange changed')
  //   onShowLoading()
  //   populateState()
  //   loadMailings()
  // }, [state.dateSelectionRange])

  const perPageOptionValues = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 150, 200, 300, 400, 500]
  const perPageOptions = perPageOptionValues.map(value => { return { value: value, label: value.toString() }})

  const populateState = async () => {
    console.log('populateState')
    const [isAuthenticated, role, constantsResults] = await Promise.all([
      authService.isAuthenticated(),
      authService.getRole(),
      settingService.GetConstants([
        ConstantCodeMailFuzeCostCodesEnabled
      ])
    ])

    //console.log('role', role)
    //console.log('constantsResults', constantsResults)

    const isSystemAdmin = (role === Roles.SystemAdministrator || (role && role.includes(Roles.SystemAdministrator)))
    const isAdmin = (role === Roles.Administrator || (role && role.includes(Roles.Administrator)))
    //setIsSystemAdmin(role === Roles.SystemAdministrator || (role && role.includes(Roles.SystemAdministrator)))
    //setIsAdmin(role === Roles.Administrator || (role && role.includes(Roles.Administrator)))
    //setUserID(user.sub)

    const constantCodes = constantsResults.map(constant => constant.value)
    const clientSettings = await settingService.GetClientSettings(constantCodes)

    const mailFuzeCostCodesEnabled = settingService.GetConstantValueFromResults(constantsResults, clientSettings, ConstantCodeMailFuzeCostCodesEnabled, (valStr) => {
      return valStr.toLowerCase() === 'true'
    })

    let isIntegrationPartner = false;
    if (isAuthenticated) {
      const myClient = await clientService.GetMyClient()
      isIntegrationPartner = myClient && myClient.IsIntegrationPartner
    }

    //console.log('isSystemAdmin ABC', isSystemAdmin)
    //console.log('isAdmin ABC', isAdmin)
    //console.log('mailFuzeCostCodesEnabled ABC', mailFuzeCostCodesEnabled)

    dispatch({ type: 'SET', name: 'columnSettings', value: {
      isAdmin: isAdmin,
      isSystemAdmin: isSystemAdmin,
      isIntegrationPartner: isIntegrationPartner,
      costCodeEnabled: mailFuzeCostCodesEnabled
    }})
  }

  const onRefresh  = (e) => {
    e.target.parentElement.blur()
    e.target.blur()
    onShowLoading()
    //populateState()
    loadMailings()
  };

  // const onSelectionChanged = useCallback(
  //   (selectedSendMailData) => {
  //     if (selectedSendMailData) {
  //       dispatch({ type: 'SET', name: 'selectedSendMailData', value: selectedSendMailData })
  //     }
  //   }
  // , [])

  const onPageSizeChanged = useCallback((selected) => {
    dispatch({ type: 'SET', name: 'perPage', value: selected })
  })

  const onDateSelect = useCallback((selected) => {
    console.log('Date Selected: ', selected)

    dispatch({ type: 'SET', name: 'dateSelectionRange', value: selected })
  })

  const onShowLoading = () => {
    dispatch({ type: 'SET', name: 'isLoading', value: true })
  }

  const onHideOverlay = () => {
    dispatch({ type: 'SET', name: 'isLoading', value: false })
  }

  const onShowSendMailDetails = useCallback(
    (detailSendMailData) => {
      if (detailSendMailData) {
        console.log('dispatching onShowSendMailDetails', detailSendMailData)
        dispatch({ type: 'SET', name: 'detailSendMailData', value: detailSendMailData })
      }
    }, [])

  const loadMailings = async () => {
    const startDate = new Date(state.dateSelectionRange[0].startDate).toISOString()
    const endDate = new Date(state.dateSelectionRange[0].endDate).toISOString()
    const sendMailData = await sendMailHistoryService.GetHistory({ startDate, endDate })

    setSendMailData(sendMailData)
    onHideOverlay()
  }

  const showResults = (sendMailId) => {
    console.log('sendMailId: ', sendMailId)
    sendMailService.PollSendMailToRentManagerTenants(sendMailId).then(sendMailResults => {

      if (sendMailResults.isComplete)
      {
        setSendMailResults({
            showModal: true,
            success: sendMailResults.results.isSuccess,
            transactionLog: sendMailResults.sendMailId,
            results: sendMailResults.results,
            sendMailService
        })
      }
      else
      {
        alert('Transaction log is not complete, cannot show.')
      }

      console.log('send mail results: ', sendMailResults)
    }).catch(error => { console.log(error); setSendMailPollingData(null, null); });
  }

  const onCloseResults = () => {
    setSendMailResults({showModal: false, results: []})
  }

  return (
    <div>
      <Row>
        <Col xs={12} md={4} lg={3}>
          <div className="d-flex flex-row">
            <Label className="p-2">Mailings/page:</Label>
            <Col>
              <Select
                  styles={customStyles}
                  options={perPageOptions}
                  value={state.perPage}
                  onChange={ onPageSizeChanged }
              />
            </Col>
          </div>
        </Col>
        <Col xs={12} md={8} lg={9}>
          <div className="d-flex flex-row justify-content-start">
            <Label className="p-2">Date:</Label>
            <DecoratedDateRangePicker
              onChange={onDateSelect}
            />
            <Button color="dark" className="mb-2 ms-4" onClick={onRefresh}><ArrowClockwise /> Refresh</Button>
          </div>
        </Col>
      </Row>
      <div className="mb-3">

      </div>
      <SendMailHistoryGrid
        // key={state.gridKey}
        perPage={state.perPage}
        columnSettings={state.columnSettings}
        //dateSelectionRange={state.dateSelectionRange}
        isLoading={state.isLoading}
        /*onSelected={onMailingSelected}*/
        //onSelectionChanged={onSelectionChanged}
        onShowSendMailDetails={onShowSendMailDetails}
        sendMailData={sendMailData}
      />
      <div /*style={{marginBottom: '45vh'}}*/>
        <SendMailHistoryDetails
          sendMailData={state.detailSendMailData}
          columnSettings={state.columnSettings}
          //isOpen={openDetails}
        />
      </div>
      <div>
        {sendMailResults.showModal && (
          <SendMailResults onClose={onCloseResults} {...sendMailResults} />
        )}
      </div>
    </div>
  )
}

export default SendMailHistory