import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react'
import { Button, ButtonGroup } from 'reactstrap'

export default forwardRef((props, red) => {

  const [filterState, setFilterState] = useState('')

  const trueListener = useCallback(() => { setFilterState('true') })
  const falseListener = useCallback(() => { setFilterState('false') })
  const ignoreListener = useCallback(() => { setFilterState('') })
  

  useImperativeHandle(red, () => {
    return {
      isFilterActive() {
        return filterState != '';
      },
      doesFilterPass(params) {
        //return params.data.mailClass == filterState;
        //const field = props.calldef.field
        //return params.data[field] == filterState
        return props.valueGetter(params)?.toString() === filterState;
      },
      getModel() {
        return {
          filterType: 'AGGridBooleanFilter',
          type: 'equals',
          filter: filterState
        }
      },
      setModel(model) {
        if (model) {
          if (model.filter === 'true') {
            setFilterState('true')
          }
          else {
            setFilterState('false')
          }
        }
        else {
          setFilterState('')
        }
      }
    }
  })

  useEffect(() => props.filterChangedCallback(), [filterState])

  return (
    <div className="m-3">
      <div>
        <strong style={{whiteSpace: 'nowrap'}}>{props.title}</strong>
      </div>
      <div className="">
        <ButtonGroup vertical className="d-flex">
          <Button color="primary" className="mr-1 mt-1" onClick={ignoreListener} active={filterState===''}>{props.allText}</Button>
          <Button color="primary" className="mr-1 mt-1" onClick={trueListener} active={filterState==='true'}>{props.trueText}</Button>
          <Button color="primary" className="mr-1 mt-1" onClick={falseListener} active={filterState==='false'}>{props.falseText}</Button>
        </ButtonGroup>
      </div>
    </div>
  )
})