import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import CustomPinnedRowRenderer from './CustomPinnedRowRenderer';
import { CustomLoadingOverlay } from '../widget/AGGridCustomOverlay';
import CustomHeaderGroup from './myCustomGroupHeader';
import { useSendMailGridCommon } from './SendMailGridCommon'
import { ShortDateFormatter } from '../../services/DateFormatter';
import { NumberFormatter } from '../../services/NumberFormatter';
import { nanoid } from 'nanoid'
import NotAddressGroupHeader from './NotAddressGroupHeader';
import AGGridCheckmarkRenderer from '../widget/AGGridCheckmarkRenderer';
import AGGridBooleanFilter from '../widget/AGGridBooleanFilter';

const SendMailTenantsGrid = forwardRef((
  {
    listingData,
    listingUdfMap,
    addressTypes,
    currentAddressType,
    useContacts,
    onUseContactsChanged,
    contactEnvelopOption,
    onContactEnvelopOptionChanged,
    useContactAddress,
    onUseContactAddressChanged,
    contactTypes,
    currentContactType,
    onContactTypeChanged,
    isLoading,
    onAddressTypeChanged,
    onSelectionChanged,
    //showTotalBalance,
    //showRentBalance
    balanceFilter
  }, ref
) => {
  //const [selection, setSelection] = useState([])
  const gridRef = useRef()
  const sendMailGridCommon = useSendMailGridCommon({addressTypes, currentAddressType, onAddressTypeChanged})
  //const [selectionVersion, setSelectionVersion] = useState(nanoid())

  const shortDateFormatter = (params) => {
    try
    {
      if (params.value)
        return ShortDateFormatter.format(new Date(params.value))
      else
        return ''
    }
    catch
    {
      return ''
    }
  }

  const dateFilter = useMemo(() => {
    return {
      debounceMs: 500,
      buttons: ['reset', 'clear'],
      suppressAndOrCondition: true,
      inRangeInclusive: true,
      filterOptions: [
        'equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange' 
      ],
      comparator: function(filterLocalDateAtMidnight, cellValue) {
        if (cellValue == null) {
          return 0;
        }
        var cellDate = new Date(cellValue);
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        } else {
          return 0;
        }
      }
    }
  }, [])

  const getBalanceField = () => {
    if (balanceFilter === 'total')
    {
      return {
        field: 'totalBalance',
        headerClass: 'ag-right-aligned-header',
        cellClass: 'text-end',
        maxWidth: 160,
        suppressSizeToFit: true,
        filter: 'agNumberColumnFilter',
        cellRendererSelector: (params) => {
          return {
            component: 'customPinnedRowRenderer',
            //params: { style: { color: 'blue' } },
            params: { format: 'currency' }
          }
        }
      }
    }

    if (balanceFilter === 'rent') {
      return {
        field: 'rentBalance',
        headerClass: 'ag-right-aligned-header',
        cellClass: 'text-end',
        maxWidth: 160,
        suppressSizeToFit: true,
        filter: 'agNumberColumnFilter',
        cellRendererSelector: (params) => {
          //if (params.node.rowPinned) {
            return {
              component: 'customPinnedRowRenderer',
              params: { format: 'currency' }
            };
          // } else {
          //   return undefined;
          // }
        }
      }
    }
  }

  const initialColumnDef = [
    {
      colId: 'NotAddressGroup',
      headerGroupComponent: "notAddressHeaderGroup",
      headerGroupComponentParams: {
        useContacts: useContacts,
        onUseContactsChanged: onUseContactsChanged,
        useContactAddress: useContactAddress,
        onUseContactAddressChanged: onUseContactAddressChanged,
        contactTypes: contactTypes,
        currentContactType: currentContactType,
        onContactTypeChanged: onContactTypeChanged,
        contactEnvelopOption: contactEnvelopOption,
        onContactEnvelopOptionChanged: onContactEnvelopOptionChanged
      },
      headerClass: "ag-form-header",
      headerName: 'NotAddressGroup',
      children: [
        { field: 'property', minWidth: 140, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true },
        { field: 'unit', maxWidth: 100, suppressSizeToFit: true },
        { field: 'contactTenant', headerName: 'Tenant', minWidth: 150 },
        { field: 'resident', headerName: 'Tenant', minWidth: 150 },
        { field: 'contactType', minWidth: 150 },
        {
          field: 'contactIsOnStatement',
          headerName: 'On Statement',
          maxWidth: 150,
          suppressSizeToFit: true,
          cellRenderer: "AGGridCheckmarkRenderer",
          filter: 'AGGridBooleanFilter',
          filterParams: {
            title: 'Is Contact On Statement',
            allText: 'All',
            trueText: 'Yes',
            falseText: 'No'
          }
        },
        {
          field: 'contactIsPrimary',
          headerName: 'Primary',
          maxWidth: 130,
          suppressSizeToFit: true,
          cellRenderer: "AGGridCheckmarkRenderer",
          filter: 'AGGridBooleanFilter',
          filterParams: {
            title: 'Is Primary Contact',
            allText: 'All',
            trueText: 'Yes',
            falseText: 'No'
          }
        },
        {
          field: 'contactIsActive',
          headerName: 'Active',
          maxWidth: 130,
          suppressSizeToFit: true,
          cellRenderer: "AGGridCheckmarkRenderer",
          filter: 'AGGridBooleanFilter',
          filterParams: {
            title: 'Is Contact Active',
            allText: 'All',
            trueText: 'Yes',
            falseText: 'No'
          }
        }
      ]
    },
    sendMailGridCommon.getAddressColumnDef(),
    {
      colId: 'LeaseInfoGroup',
      headerName: 'Lease Information',
      headerClass: 'uberHeaderLabel',
      marryChildren: true,
      children: [
        getBalanceField(),
        {
          field: 'leaseStartDate',
          headerName: 'Start Date',
          headerClass: "ag-form-header ag-right-aligned-header",
          minWidth: 160,
          filter: 'agDateColumnFilter',
          cellClass: 'text-end',
          valueFormatter: shortDateFormatter,
          filterParams: dateFilter
        },
        {
          field: 'leaseEndDate',
          headerName: 'End Date',
          headerClass: "ag-form-header ag-right-aligned-header",
          minWidth: 155,
          filter: 'agDateColumnFilter',
          cellClass: 'text-end',
          valueFormatter: shortDateFormatter,
          filterParams: dateFilter
        },
        {
          field: 'status',
          headerName: 'Tenant Status',
          headerClass: "ag-form-header",
          minWidth: 185
        }
      ]
    },
    {
      colId: 'UnitTypeGroup',
      headerName: 'Unit Type Information',
      headerClass: 'uberHeaderLabel',
      marryChildren: true,
      children: [
        {
          field: 'unitTypeName',
          headerName: 'Unit Type',
          minWidth: 160,
        },
        {
          field: 'isOtherRentableItem',
          headerName: 'Is ORI',
          minWidth: 155,
          suppressSizeToFit: false,
          cellRenderer: "AGGridCheckmarkRenderer",
          filter: 'AGGridBooleanFilter',
          filterParams: {
            title: 'Is Other Rentable Item',
            allText: 'All',
            trueText: 'Is ORI',
            falseText: 'Is not ORI'
          },
        }
      ]
    },
    {
      colId: 'MorePropertyPropsGroup',
      headerName: 'More Information',
      headerClass: 'uberHeaderLabel',
      marryChildren: true,
      children: [
        {
          field: 'statementMethod',
          minWidth: 250,
          valueFormatter: (params) => {
            if (params && params.value) {
              switch (params.value) {
                case 'UseSystemDefault':
                  return 'Use System Default'
                case 'UsePropertyDefault':
                  return 'Use Property Default'
                case 'NoStatement':
                  return 'No Statement'
                case 'EmailAttachment':
                  return 'Email Attachment'
                case 'Print':
                  return 'Print'
                case 'VPO':
                  return 'VPO'
                default:
                  return params.value
                  break;
              }
            }

            return ''
          }
        },
        {
          field: 'color',
          minWidth: 250
        }
      ]
    }
  ]

  const [columnDefs, setColumnDefs] = useState(initialColumnDef);

  useEffect(() => {
    console.log('use effect triggered on isLoading changed to ' + isLoading)
    if (isLoading) {
      onShowLoading()
    }
    else {
      onHideOverlay()
    }
  }, [isLoading])

  useEffect(() => {
    console.log('CURRENT ADDRESS TYPE CHANGED')
    const indexOf = gridRef.current.props.columnDefs.findIndex(colDef => colDef.headerName === "Address")
    gridRef.current.props.columnDefs[indexOf].headerGroupComponentParams.currentAddressType = currentAddressType
    gridRef.current.api.refreshHeader();
  }, [currentAddressType])

  useEffect(() => {
    console.log('CURRENT CONTACT TYPE CHANGED')
    const indexOf = gridRef.current.props.columnDefs.findIndex(colDef => colDef.headerName === "NotAddressGroup")
    const headerParams = gridRef.current.props.columnDefs[indexOf].headerGroupComponentParams
    headerParams.currentContactType = currentContactType
    headerParams.useContacts = useContacts
    headerParams.contactEnvelopOption = contactEnvelopOption
    headerParams.useContactAddress = useContactAddress

    gridRef.current.api.refreshHeader();
  }, [currentContactType, useContacts, contactEnvelopOption, useContactAddress])

  useEffect(() => {
    //console.log('listingUdfMap', listingUdfMap)
    
    if (gridRef && gridRef.current) {
      const missingUdfColumns = []
      const currentColumnDefs = gridRef.current.api.getColumnDefs()
      const currentNagIndex = currentColumnDefs.findIndex(colDef => colDef.headerName === "NotAddressGroup")
      
      if (currentNagIndex !== -1) {
        const currentNagColDefs = currentColumnDefs[currentNagIndex].children
  
        //gather the missing UDFs (Present in the UDF list, but missing in the current columns)
        if (listingUdfMap) {
          //console.log('currentNagColDefs', currentNagColDefs)
          listingUdfMap.forEach((udf) => {
            //console.log(udf[0])
            if (currentNagColDefs.findIndex(colDef => colDef.field === udf[0]) === -1) {
              //console.log('adding ' + udf[0] + ' to missing columns!')
              missingUdfColumns.push(udf)
            }
          })
        }
  
        //gather the UDFs we are removing or clearing. (Present in the current columns, but not present in the UDF list)
        const initialNagIndex = initialColumnDef.findIndex(colDef => colDef.headerName === "NotAddressGroup")
        const initialNagColCount = initialColumnDef[initialNagIndex].children.length
        const currentNagColCount = currentNagColDefs.length
        const currentNagIndexesToRemove = []
        if (!listingUdfMap && currentNagColCount !== initialNagColCount) {
          //get all UDFS
          //this works as long as all columns that are not part of the initial col defs are only UDF columns.
          for (let i = 0; i < currentNagColCount; i++) {
            const indexMatch = initialColumnDef[initialNagIndex].children.findIndex(colDef => colDef.field === currentNagColDefs[i].field)
            if (indexMatch === -1) {
              currentNagIndexesToRemove.push(i)
            }
          }
        } else if (!!listingUdfMap) {
          //get superfluous UDFS  
          //this works as long as all columns that are not part of the initial col defs are only UDF columns.
          for (let i = 0; i < currentNagColCount; i++) {
            const initialIndexMatch = initialColumnDef[initialNagIndex].children.findIndex(colDef => colDef.field === currentNagColDefs[i].field)
            const currentIndexMatch = listingUdfMap.findIndex(udf => udf[0] === currentNagColDefs[i].field)
            //if it's part of intiial, then it's not a UDF
            //if it's part of current, but not part of listingUdfMap, then it needs to be removed.
            if (initialIndexMatch === -1 && currentIndexMatch === -1) {
              currentNagIndexesToRemove.push(i)
            }
          }
        }

        //do updates first for removing and clearing
        let updateColumns = false
        const newColumnDefs = currentColumnDefs
        if (currentNagIndexesToRemove.length !== 0) {
          console.log('Remove Columns: ', currentNagIndexesToRemove)
  
          //now that we have the indexes of the UDFs, traverse the col array and remove the superfluous udfs.
          const newChidren = []
          currentNagColDefs.forEach((child, childIndex) => {
            if (currentNagIndexesToRemove.findIndex(udfIndex => udfIndex === childIndex) === -1) {
              console.log('keep child', child)
              newChidren.push(child)
            }
          })

          console.log('newChidren', newChidren)

          newColumnDefs[currentNagIndex].children = newChidren
          
          updateColumns = true
        }

        //add missing udfs second
        if (missingUdfColumns.length !== 0) {
          console.log('Add Columns: ', missingUdfColumns)
          const newChidren = [...newColumnDefs[currentNagIndex].children]
          let insertUdfAtIndex = newChidren.length

          missingUdfColumns.forEach(udfCol => {
             const colName = udfCol[0]
             //console.log('Add Column name: ', colName)
             console.log('Add Column type: ', udfCol[1].fieldType)
             //console.log('Add Column display name: ', udfCol[1].displayName)
    
            const colDef = {
              field: colName,
              colId: udfCol[0],
              headerName: udfCol[1].displayName
            }
            
            if (udfCol[1].fieldType === 'Numeric') {
              colDef.filter = 'agNumberColumnFilter'
              colDef.type = 'rightAligned'
              //colDef.headerClass = 'ag-right-aligned-header'
              colDef.cellClass = 'text-end'
              colDef.precisionValue = udfCol[1].precisionValue
              colDef.valueGetter = (params) => {
                if (!params.node.rowPinned) {
                    return params.data[colName + 'Typed']
                    //return params.data[colName]
                }
              }
              colDef.valueFormatter = (params) => {
                try
                {
                  //console.log('params....', params)
                  //console.log('params.colDef.precisionValue', params.colDef.precisionValue)
                  if (params.value)
                      return NumberFormatter(params.colDef.precisionValue).format(params.value)
                  else
                    return ''
                }
                catch
                {
                  return ''
                }
              }
            } else if (udfCol[1].fieldType === 'Date') {
              colDef.filter = 'agDateColumnFilter'
              colDef.headerClass = 'ag-right-aligned-header'
              colDef.cellClass = 'text-end'
              colDef.valueGetter = (params) => {
                if (!params.node.rowPinned) {
                    return params.data[colName + 'Typed']
                }
              }
              colDef.valueFormatter=shortDateFormatter
              colDef.filterParams=dateFilter
            } else if (udfCol[1].fieldType ===  'YesNo') {
              colDef.filter = 'AGGridBooleanFilter'
              colDef.filterParams = {
                title: colDef.headerName,
                allText: 'All',
                trueText: 'Yes',
                falseText: 'No',
              },
              colDef.valueGetter = (params) => {
                if (!params.node.rowPinned) {
                  switch (params.data[colName + 'Typed']?.toLowerCase()) {
                    case 'yes':
                      return 'true'
                    case 'no':
                      return 'false'
                    default:
                      return ''
                  }
                }
              },
              colDef.valueFormatter = (params) => {
                try
                {
                  switch (params.value?.toString()) {
                    case 'true':
                      return 'Yes'
                    case 'false':
                      return 'No'
                    default:
                      return ''
                  }
                }
                catch
                {
                  return ''
                }
              }
            }
    
            newChidren.splice(insertUdfAtIndex, 0, colDef)
    
            insertUdfAtIndex++
          });
          
          newColumnDefs[currentNagIndex].children = newChidren

          updateColumns = true
        }
    
        if (updateColumns == true) {
          setColumnDefs(newColumnDefs)
        }
      }
    }
  }, [listingUdfMap, balanceFilter])

  const contactColumnFields = useMemo(() => {
    return ['contactType', 'contactTenant', 'contactIsOnStatement', 'contactIsPrimary', 'contactIsActive']
  }, [])

  useEffect(() => {
    console.log('useContacts changed, refresh headers')
    if (gridRef && gridRef.current) {
      const residentHeaderName = useContacts ? 'Contact' : 'Tenant'
      const newColumnDefs = gridRef.current.api.getColumnDefs()
      const notAddressGroupIndexOf = newColumnDefs.findIndex(colDef => colDef.headerName === "NotAddressGroup")
      let currentContactFieldIndex = -1;
      if (notAddressGroupIndexOf !== -1) {
        const residentIndexOf = newColumnDefs[notAddressGroupIndexOf].children.findIndex(colDef => colDef.field === 'resident')
        if (residentIndexOf !== -1) {
          newColumnDefs[notAddressGroupIndexOf].children[residentIndexOf].headerName = residentHeaderName

          newColumnDefs[notAddressGroupIndexOf].children.forEach(colDef => {
            currentContactFieldIndex = contactColumnFields.findIndex(field => field === colDef.field)
            if (currentContactFieldIndex !== -1) {
              colDef.hide = !useContacts
            }
          })

          setColumnDefs(newColumnDefs)
        }
      }
    }
  }, [useContacts, contactColumnFields, gridRef])

  /*useEffect(() => {
    gridRef.current.columnApi.setColumnsVisible(['contactType', 'contactTenant', 'contactIsOnStatement', 'contactIsPrimary', 'contactIsActive'], useContacts)
  }, [useContacts])*/

  useImperativeHandle(ref, () => {
    return {
      async unselectRows(tenantIDs) {
        //console.log('unselectRow called', tenantIDs)
        let removedCount = 0;

        if (gridRef.current && tenantIDs && tenantIDs.length !== 0) {
          const gridApi = gridRef.current.api
          const selectedNodes = gridApi.getSelectedNodes()
          
          selectedNodes.forEach((rowNode) => {
            console.log(rowNode.data);
            if(tenantIDs.filter(tenantID => tenantID === rowNode.data.tenantID).length !== 0) {
              //console.log('unselecting row node:', rowNode.data.importRowNum)
              rowNode.setSelected(false);
              removedCount++
            }
          })
        }

        return removedCount
      }
    }
  }, [gridRef])


  const [defaultColumnDefs, setDefaultColumnDefs] = useState({
      sortable: true,
      filter: true,
      resizable: true
  })

  // useEffect(() => {
  //   if (selection) {
  //     onSelectionChanged(selection)
  //   }
  // }, [selection])

  // useEffect(() => {
  //   console.log('APPARENTLY addressTypes CAHNGED?')
  //   setSelectedAddressType(addressTypes.length > 0 ? addressTypes[0] : null)
  // }, [addressTypes])

  const setGridSelection = () => {
    if (gridRef.current) {
      //gridRef.current.api.deselectAllFiltered()
      const selectedData = []
      gridRef.current.api.forEachNodeAfterFilterAndSort(node => {
        if (node.isSelected()) {
          selectedData.push(node.data)
        }
      });
      //const selectedNodes = gridRef.current.api.getSelectedNodes()//.filter(node => node.displayed)
      //const selectedData = selectedNodes.map(node => node.data)
      onSelectionChanged(selectedData)
      //setSelection(selectedData)
    }
    else {
      onSelectionChanged([])
      //setSelection([])
    }
  }

  const generatePinnedBottomData = (api, columnApi, ignoreFilter = false) => {
    // generate a row-data with null values
    let result = {};

    //console.log('generatePinnedBottomData')
    //console.log(columnApi)
    //console.log(columnApi.getAllGridColumns())

    columnApi.getAllGridColumns().forEach(item => {
        result[item.colId] = null;
    });
    return calculatePinnedBottomData(api, result, ignoreFilter);
  }

  const calculatePinnedBottomData = (api, target, ignoreFilter) => {
      //console.log(target);
      //list of columns fo aggregation
      let columnsWithAggregation = ['totalBalance','rentBalance']
      columnsWithAggregation.forEach(element => {
        //console.log('element', element);

        if (ignoreFilter) {
          api.forEachNode((rowNode) => {
            if(rowNode.index < 10){
              console.log(rowNode);
            }
              if (rowNode.data[element])
                  target[element] += Number(rowNode.data[element].toFixed(2));
          });
        }
        else {
          api.forEachNodeAfterFilter((rowNode) => {
            //if(rowNode.index < 10){
              //console.log(rowNode);
            //}
            if (rowNode.data[element])
                target[element] += Number(rowNode.data[element].toFixed(2));
          });
        }

        if (target[element])
            target[element] = `${target[element].toFixed(2)}`;
      })
      //console.log(target);
      //target['athlete'] = 'Total';
      //console.log(target)
      return [target];
  }

  const onShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, [])

  const onHideOverlay = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  // const isExternalFilterPresent = useCallback(() => {
  //   if (gridRef.current) {
  //     const selectedNodes = gridRef.current.api.getSelectedNodes()
  //     console.log('External Filter Present?', selectedNodes && selectedNodes.length !== 0)
  //     return selectedNodes && selectedNodes.length !== 0
  //   }
  //   else {
  //     console.log('External Filter Present? False (Default)')
  //     return false
  //   }
  // }, []);

  // const doesExternalFilterPass = useCallback(
  //   (node) => {
  //     console.log(node)
  //     return node.selected
  //     // switch (ageType) {
  //     //   case 'below25':
  //     //     return node.data.age < 25;
  //     //   case 'between25and50':
  //     //     return node.data.age >= 25 && node.data.age <= 50;
  //     //   case 'above50':
  //     //     return node.data.age > 50;
  //     //   case 'dateAfter2008':
  //     //     return asDate(node.data.date) > new Date(2008, 1, 1);
  //     //   default:
  //     //     return true;
  //     // }
  //   },
  //   [selectionVersion]
  // );

  return (
    <React.Fragment>
      <div className="ag-theme-alpine" style={{height: '95vh', minHeight: '400px', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={listingData}
          rowSelection="multiple"
          //domLayout="autoHeight"
          paginationPageSize={30}
          columnDefs={columnDefs}
          //isExternalFilterPresent={isExternalFilterPresent}
          //doesExternalFilterPass={doesExternalFilterPass}
          loadingOverlayComponent="customLoadingOverLoad"
          loadingOverlayComponentParams={{
            loadingMessage: 'Loading...'
          }}
          //animateRows={true}
          defaultColDef={defaultColumnDefs}
          pagination={true}
          frameworkComponents={{
            customPinnedRowRenderer: CustomPinnedRowRenderer,
            customHeaderGroup: CustomHeaderGroup,
            notAddressHeaderGroup: NotAddressGroupHeader,
            customLoadingOverLoad: CustomLoadingOverlay,
            AGGridCheckmarkRenderer: AGGridCheckmarkRenderer,
            AGGridBooleanFilter: AGGridBooleanFilter,
          }}
          getRowStyle={function (params) {
            if (params.node.rowPinned) {
              return {
                'font-weight': 'bold',
                'pointer-events': 'none',
            };
            }
          }}
          // onCellMouseOver={function (params) {

          //   //do your stuff here
          //   console.log(params)
          // }}
          // onFirstDataRendered ={(event) => {
          //   console.log('onFirstDataRendered')
          //   event.api.setPinnedBottomRowData(generatePinnedBottomData(event.api, event.columnApi))
          // }}
          onRowDataChanged={(event) => {
            //console.log('onRowDataChanged')
            //console.log(event)
            event.api.setPinnedBottomRowData(generatePinnedBottomData(event.api, event.columnApi))
            setGridSelection()
          }}
          onFilterChanged ={(event) => {
            //console.log('onFilterChanged')
            //setSelectionVersion(nanoid())
            event.api.setPinnedBottomRowData(generatePinnedBottomData(event.api, event.columnApi))
            setGridSelection()
          }}
          onSortChanged={setGridSelection}
          // onFilterModified ={(event) => {
          //   console.log('onFilterModified')
          //   event.api.setPinnedBottomRowData(generatePinnedBottomData(event.api, event.columnApi))
          // }}
          onGridReady={(event) => { 
            //console.log('onGridReady')
            //setGridApi(event.api)
            //setGridColumnApi(event.columnApi)
            //console.log(event);
            event.api.sizeColumnsToFit()
            event.api.setPinnedBottomRowData(generatePinnedBottomData(event.api, event.columnApi))
            event.columnApi.applyColumnState({
              state: [
                {
                  colId: 'TotalBalance',
                  sort: 'desc'
                }
              ]
            })
          }}
          onSelectionChanged={(event) => {
            setGridSelection()
          }}
          // onRowSelected = {(event) => {
          //   if (event.node.isSelected()) {
          //     console.log('Selection made')
          //     console.log(event.node.data)
          //     // if (openDetails)
          //     // {
          //     //   toggleDetails()
          //     // }
          //     setGridSelection()
          //     //setSelection(event.node.data)
          //   }
          //}}
        >
        </AgGridReact>
      </div>
    </React.Fragment>
  )
})

export default SendMailTenantsGrid