import React, { useEffect, useState } from 'react'
import { Button, List, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { FiletypeCsv, FiletypeXlsx, X } from 'react-bootstrap-icons';
import CsvDoc from '../../assets/50-contacts.csv'
import ExcelDoc from '../../assets/50-contacts.xlsx'
import CsvWithCodeDoc from '../../assets/50-contacts-with-codes.csv'
import ExcelWithCodeDoc from '../../assets/50-contacts-with-codes.xlsx'
import CsvWithReturnAddressDoc from '../../assets/50-contacts-with-return-addresses.csv'
import ExcelWithReturnAddressDoc from '../../assets/50-contacts-with-codes.xlsx'
import CsvWithCodeAndReturnAddressDoc from '../../assets/50-contacts-with-codes-and-return-addresses.csv'
import ExcelWithCodeAndReturnAddressDoc from '../../assets/50-contacts-with-codes-and-return-addresses.xlsx'

const SendMailCustomHelp = ({ showModal, onClose, costCodesEnabled }) => {

  const [modal, setModal] = useState(showModal)

  useEffect(() => {
    setModal(showModal)
  }, [showModal])

  const toggle = () => {
    if (modal) {
      onClose()
    }
    
    setModal(!modal)
  }

  return (
    <div>
      <Modal
        backdrop="static"
        size="xl"
        isOpen={modal}
        toggle={toggle}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 700 }}
      >
        <ModalHeader toggle={toggle} close={<button className="close btn btn-white" onClick={toggle}><X size={25} /></button>}>
          <div>Letter Recipient Grid Help</div>
        </ModalHeader>
        <ModalBody style={{ minHeight: '150px', maxHeight: '75vh', overflow: 'auto' }}>
          <ListGroup>
            <ListGroupItem>
              <ListGroupItemHeading>Two ways to provide MailFuze with recipients</ListGroupItemHeading>
              <ol>
                <li>The panel above the grid will upload and append the recipients to the grid.</li>
                <li>The "Add Recipient" button will append a blank row at the bottom of the grid.</li>
              </ol>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Once the recipients are in the grid, you may:</ListGroupItemHeading>
              <ul>
                <li>Add More</li>
                <li>Select recipients using the row's checkbox and remove them using the designated button.</li>
                <li>Double-click a recipient row to edit it's content</li>
                <li>Remove an uploaded file and all the recipients that were imported from it.</li>
                <li>Remove all recipients and files, by using the Clear button.</li>
              </ul>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Loading Recipients from files</ListGroupItemHeading>
                <ul>
                  <li>Use the panel above the grid to upload your recipients.</li>
                  <li>You can upload one or more* files.</li>
                  <li>Each row will be added to the grid in the order in which they appear in the file.</li>
                  <li>*Additional files are added to the bottom of the grid.</li>
                  <li>Supported file types include:</li>
                  <ul>
                    <li>Comma separated values (CSV)</li>
                    <li>Excel Workbooks (XLSX)</li>
                  </ul>
                  <li>Lines with missing values will be higlighted in yellow.</li>
                  <ul>
                    <li>You may choose to edit the row directly in the grid</li>
                    <li>At your discretion, you can still send mail to an incomplete line.</li>
                  </ul>
                  <li>Files may contain the column header names in the first row.</li>
                  <ul>
                    <li>You may choose turn on the "The first row contains the column headers" option to automatically remove these lines from the grid.</li>
                    <li>Optionaly, you can choose to select and delete the line manually.</li>
                  </ul>
                </ul>
              <ListGroupItemText>

              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Each file must have the values/columns in the following order.</ListGroupItemHeading>
              <div className='d-flex flex-row justify-content-center mt-1'>
                <div style={{width: '800px', overflowX: 'scroll'}}>
                  <table style={{width: '100%'}} className='border'>
                    <tbody>
                      <tr>
                        <th className='border text-center text-nowrap p-2'>First Name</th>
                        <th className='border text-center text-nowrap p-2'>Last Name</th>
                        <th className='border text-center text-nowrap p-2 ps-5 pe-5'>Street</th>
                        <th className='border text-center text-nowrap p-2'>City</th>
                        <th className='border text-center text-nowrap p-2 ps-3 pe-3'>State</th>
                        <th className='border text-center text-nowrap border-end-0 p-2 ps-3 pe-3'>Zip</th>
                        <th className='border text-center text-nowrap border-light border-end-0 p-2'>Cost Code</th>
                        <th className='border text-center text-nowrap border-info p-2'>Return Name</th>
                        <th className='border text-center text-nowrap border-info p-2'>Return Street</th>
                        <th className='border text-center text-nowrap border-info p-2'>Return City</th>
                        <th className='border text-center text-nowrap border-info p-2 ps-3 pe-3'>Return State</th>
                        <th className='border text-center text-nowrap border-info p-2 ps-3 pe-3'>Return Zip</th>
                      </tr>
                      <tr>
                        <td className='border'>John</td>
                        <td className='border'>Doe</td>
                        <td className='border'>1 Oak st.</td>
                        <td className='border pe-2'>Cumberland</td>
                        <td className='border'>RI</td>
                        <td className='border border-end-0'>03039</td>
                        <td className='border border-light border-end-0'>ABC123</td>
                        <td className='border border-info'>John Smith</td>
                        <td className='border border-info'>1 Sun st.</td>
                        <td className='border border-info'>Manville</td>
                        <td className='border border-info'>RI</td>
                        <td className='border border-info'>03948</td>
                      </tr>
                      <tr>
                        <td className='border'>Jane</td>
                        <td className='border'>Doe</td>
                        <td className='border'>45 Sun st.</td>
                        <td className='border'>Derry</td>
                        <td className='border'>ME</td>
                        <td className='border border-end-0'>02045</td>
                        <td className='border border-light border-end-0'><div>Alpha-Tango Group</div></td>
                        <td className='border border-info'></td>
                        <td className='border border-info'></td>
                        <td className='border border-info'></td>
                        <td className='border border-info'></td>
                        <td className='border border-info'></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <ul className='mt-2'>
                <li>The first 6 fields are to identify the recipient by name and address.</li>
                <li>An optional 7th field is for specifying a Cost Code.</li>
                <ul>
                  <li>Invoices will be grouped by Cost Cost.</li>
                  {costCodesEnabled && (
                    <li>It can be blank for any or all rows. When blank, those rows are also grouped into one invoice.</li>
                  )}
                  {!costCodesEnabled && (
                    <React.Fragment>
                      <li>If you don't see the Cost Code column, it's because the option is turned off.</li>
                      <li>The option is available in the Admin settings.</li>
                    </React.Fragment>
                  )}
                  <li>You can use the Return Address fields, as described below, without specifying a Cost Code and vice-versa.</li>
                </ul>
                <li>A set of optional 5 fields for specifying a Return Address.</li>
                <ul>
                  <li>The return address can be blank for any or all rows. When blank, the default return address from MailFuze settings will be used.</li>
                  <li>When providing the return address within a row, all fields are mandatory.</li>
                  <li>Invalid return addresses will automatically be replaced by the default return address from MailFuze settings.</li>
                  <li>You can use the return address without specifying a Cost Code and vice-versa.</li>
                </ul>
              </ul>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Sample Files</ListGroupItemHeading>
              <div className='d-flex flex-row justify-content-center'>
                <table style={{width: '70%'}} className='border'>
                  <tbody>
                    <tr>
                      <th className='border text-center'>Comma Separated Values (CSV)</th>
                      <th className='border text-center'>Microsoft Excel Workbook (XLSX)</th>
                    </tr>
                    <tr>
                      <td className='border text-center p-3'>
                        <a href={CsvDoc} download="CsvSampleFile" target='_blank'>
                          <Button color='info' className='btn-block'><FiletypeCsv size={48} /></Button>
                        </a>
                      </td>
                      <td className='border text-center p-3'>
                        <a href={ExcelDoc} download="ExcelSampleFile" target='_blank'>
                          <Button color='info' className='btn-block'><FiletypeXlsx size={48} /></Button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ListGroupItem>
            {costCodesEnabled && (
              <ListGroupItem>
                <ListGroupItemHeading>Sample Files (including Cost Codes)</ListGroupItemHeading>
                <div className='d-flex flex-row justify-content-center mt-4'>
                  <table style={{width: '70%'}} className='border'>
                    <tbody>
                      <tr>
                        <th className='border text-center'>Comma Separated Values (CSV)</th>
                        <th className='border text-center'>Microsoft Excel Workbook (XLSX)</th>
                      </tr>
                      <tr>
                        <td className='border text-center p-3'>
                          <a href={CsvWithCodeDoc} download="CsvWithCodeSampleFile" target='_blank'>
                            <Button color='info' className='btn-block'><FiletypeCsv size={48} /></Button>
                          </a>
                        </td>
                        <td className='border text-center p-3'>
                          <a href={ExcelWithCodeDoc} download="ExcelWithCodeSampleFile" target='_blank'>
                            <Button color='info' className='btn-block'><FiletypeXlsx size={48} /></Button>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ListGroupItem>
            )}
            <ListGroupItem>
              <ListGroupItemHeading>Sample Files (including Return Address)</ListGroupItemHeading>
              <div className='d-flex flex-row justify-content-center mt-4'>
                <table style={{width: '70%'}} className='border'>
                <tbody>
                    <tr>
                      <th className='border text-center'>Comma Separated Values (CSV)</th>
                      <th className='border text-center'>Microsoft Excel Workbook (XLSX)</th>
                    </tr>
                    <tr>
                      <td className='border text-center p-3'>
                        <a href={CsvWithReturnAddressDoc} download="CsvWithReturnAddressSampleFile" target='_blank'>
                          <Button color='info' className='btn-block'><FiletypeCsv size={48} /></Button>
                        </a>
                      </td>
                      <td className='border text-center p-3'>
                        <a href={ExcelWithReturnAddressDoc} download="ExcelWithReturnAddressSampleFile" target='_blank'>
                          <Button color='info' className='btn-block'><FiletypeXlsx size={48} /></Button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Sample Files (including Cost Code and Return Address)</ListGroupItemHeading>
              <div className='d-flex flex-row justify-content-center mt-4'>
                <table style={{width: '70%'}} className='border'>
                  <tbody>
                    <tr>
                      <th className='border text-center'>Comma Separated Values (CSV)</th>
                      <th className='border text-center'>Microsoft Excel Workbook (XLSX)</th>
                    </tr>
                    <tr>
                      <td className='border text-center p-3'>
                        <a href={CsvWithCodeAndReturnAddressDoc} download="CsvWithCodeAndReturnAddressSampleFile" target='_blank'>
                          <Button color='info' className='btn-block'><FiletypeCsv size={48} /></Button>
                        </a>
                      </td>
                      <td className='border text-center p-3'>
                        <a href={ExcelWithCodeAndReturnAddressDoc} download="ExcelWithCodeAndReturnAddressSampleFile" target='_blank'>
                          <Button color='info' className='btn-block'><FiletypeXlsx size={48} /></Button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ListGroupItem>
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          <div className="m-auto">
            <Button color="primary" onClick={toggle}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default SendMailCustomHelp