import React from 'react'

const WarningInvalidDocumentDimensions = ({validDocumentDimensions, targetDocumentWidth, targetDocumentHeight}) => {

  if (!validDocumentDimensions)
  {
    return (
      <div className='d-flex flex-fill flex-column justify-content-between'>
        <div>
          <div className='fw-bold d-flex justify-content-center'>Invalid Page Dimensions</div>
          <hr />
          <p>At least one of the pages you are trying to mail is not the proper size. Please reformat all pages to {targetDocumentWidth}" x {targetDocumentHeight}". If you want MailFuze to automatically resize your pages, ask your MailFuze Admin to change the setting.</p>
        </div>
      </div>
    )
  }
  else
  {
    return (<></>)
  }
}

export default WarningInvalidDocumentDimensions