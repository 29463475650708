import React from 'react'

const WarningHistoryCategoryRequired = ({ result: { isValid } }) => {

  if (!isValid)
  {
    return (
      <div className='d-flex flex-fill flex-column justify-content-between'>
        <div>
          <div className='fw-bold d-flex justify-content-center'>Mandatory History Category</div>
          <hr />
          <p>Your Rent Manager System Preferences require every History Note to have a valid History Category. Please select a History Category for these mailings.</p>
        </div>
      </div>
    )
  }
  else
  {
    return (<></>)
  }
}

export default WarningHistoryCategoryRequired