import React from 'react'

const WarningInvalidClientAddress = ({result: { isValid }}) => {

  if (!isValid)
    {
      return (
        <div className='d-flex flex-fill flex-column justify-content-between'>
          <div>
            <div className='fw-bold d-flex justify-content-center'>Invalid Client Address</div>
            <hr />
            <p>MailFuze requires a valid default Return Address. Please set the "Address Saved in MailFuze" in the settings "Return Address" tab.</p>
          </div>
        </div>
      )
    }
    else
    {
      return (<></>)
    }
}

export default WarningInvalidClientAddress