import React, { Component, Fragment, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { NavItem, NavLink, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, Button, Dropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import {  BookmarkCheckFill, BookmarkPlusFill, BookmarkStarFill, BookmarksFill, EnvelopeCheck, FileEarmarkPostFill, FilePost, FilePostFill, Gear, GearFill, Magic, PencilSquare, Person, PersonCircle, PersonFill, Pip, PipFill, Tags, TagsFill, Truck } from 'react-bootstrap-icons'
import { Roles } from '../../services/RoleService'
import LocationMenu from './LocationMenu';
// import { AuthContext } from '../../context/AuthContext';
// import { UserPreferencesContext } from '../../context/UserPreferencesContext';
import { useLocationService } from '../../services/LocationService';
import { useUserService } from '../../services/UserService';
import { useClientService } from '../../services/ClientService';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export { LoginMenu }

const LoginMenu = (props) => {

    let _subscription = null

    const initialState = {
        isLoading: true,
        isAuthenticated: false,
        userName: null,
        role: null,
        userLocation: localStorage.getItem('currentLocation') || -1,
        userLocations: [],
        isIntegrationPartner: false
    }

    // const authContext = useContext(AuthContext)
    // const userPreferencesContext = useContext(UserPreferencesContext)

    // const initialState = {
    //     isAuthenticated: authContext.isAuthenticated,
    //     userName: authContext.user && authContext.user.name,
    //     role: authContext.role,
    //     userLocation: userPreferencesContext.userLocation,
    //     userLocations: userPreferencesContext.userLocations
    // }

    const MySwal = withReactContent(Swal)
    const locationService = useLocationService()
    const userService = useUserService()
    const clientService = useClientService()

    const [state, setState] = useState(initialState)

    const PlainSwal = MySwal.mixin({
        showClass: {
          backdrop: 'swal2-noanimation', // disable backdrop animation
          popup: '',                     // disable popup animation
          icon: ''                       // disable icon animation
        },
        hideClass: {
          popup: '',                     // disable popup fade-out animation
        }
      })

    useEffect(() => {
        const locationChangeHandler = () => {
            const persistedLocation = localStorage.getItem("currentLocation");
        
            console.log('persistedLocation', persistedLocation, state.userLocation.LocationID)
            if (persistedLocation && persistedLocation != 'undefined' && state.userLocation && state.userLocation != 'undefined' && persistedLocation != state.userLocation.LocationID) {
                showLocationChanged()
            }
        };
        window.addEventListener("storage", locationChangeHandler);
        return () => window.removeEventListener("storage", locationChangeHandler);
    }, [state.userLocation.LocationID]);

    const showLocationChanged = async () => {
        await PlainSwal.fire({
            title: 'Location Changed',
            icon: 'warning',
            text: 'You changed locations in a different browser tab. This page will be reloaded to reflect the location change.',
            showCloseButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-primary btn-lg'
            }
          })

        window.location.reload();

        PlainSwal.fire({
            title: 'Reloading Page',
            allowEscapeKey: false,
            allowOutsideClick: false,
            timerProgressBar: true,
            didOpen: () => {
              PlainSwal.showLoading()
            },
          })
    }

    // const {
    //     userLocations
    // } = useContext(UserPreferencesContext)


    // const {
    //     user,
    //     isAuthenticated,
    //     role,
    //     isLoading: isAuthLoading
    // } = useContext(AuthContext)

    // useLayoutEffect(() => {
    //     setState({
    //         isAuthenticated: authContext.isAuthenticated,
    //         userName: authContext.user && authContext.user.name,
    //         role: authContext.role,
    //         userLocation: userPreferencesContext.userLocation,
    //         userLocations: userPreferencesContext.userLocations
    //     })
    // }, [authContext, userPreferencesContext])

    useEffect(() => {
        _subscription = authService.subscribe(() => populateState());
        populateState();

        // Specify how to clean up after this effect:
        return function cleanup() {
            authService.unsubscribe(_subscription);
        };
    }, []);

    const populateState = async () => {
        const [isAuthenticated, user, role] = await Promise.all([
            authService.isAuthenticated(),
            authService.getUser(),
            authService.getRole()
        ])

        let userLocation = state.userLocation;
        let userLocations = state.userLocations;
        let isIntegrationPartner = false;
        if (isAuthenticated) {
            const [locations, userPreferredLocation, myClient] = await Promise.all([
                locationService.GetLocations(),
                userService.GetUserPreferredLocation(),
                clientService.GetMyClient()
            ])
            userLocation = userPreferredLocation
            userLocations = locations //.pop(), //test for 1
            isIntegrationPartner = myClient && myClient.IsIntegrationPartner
        }

        const currentLocation = localStorage.getItem("currentLocation");
        console.log('userLocation', userLocation, currentLocation)

        if (currentLocation !== -1 && currentLocation !== userLocation.LocationID) {
            window.localStorage.setItem("currentLocation", userLocation.LocationID );
        }

        // window.addEventListener("storage", async () => {

        //     const persistedLocation = localStorage.getItem("currentLocation");
            
        //     console.log('persistedLocation', persistedLocation, state.userLocation.LocationID)

        //     if (persistedLocation !== -1 && persistedLocation !== state.userLocation.LocationID) {
        //         await PlainSwal.fire({
        //             title: 'Location Changed',
        //             icon: 'warning',
        //             text: 'You changed locations in a different browser tab. This page will be reloaded to reflect the location change.',
        //             showCloseButton: true,
        //             buttonsStyling: false,
        //             customClass: {
        //               confirmButton: 'btn btn-primary btn-lg'
        //             }
        //           })

        //         window.location.reload();
        //     }
        // });

        setState({
            isLoading: false,
            isAuthenticated,
            userName: user && user.name,
            role,
            userLocation,
            userLocations,
            isIntegrationPartner
        });
    }

    const render = () => {
        const { isAuthenticated, role, isIntegrationPartner, userName, userLocation, userLocations } = state;
        //console.log(role)
        console.log('isAuthenticated: ', isAuthenticated)
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.ChangePassword}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return authenticatedView(userName, role, isIntegrationPartner, userLocation, userLocations, setUserLocation, profilePath, logoutPath);
        }
    }

    const renderMenuItem = (path, text, color, isLast = false) => {
        return (
            <DropdownItem className="m-0 p-0 profile-menu-item">
                <NavLink tag={Link} className="text-dark m-0 p-0" to={path}>
                    <div className={`d-flex flex-row align-items-center border border-${color} ${isLast ? 'border-top-0 rounded-bottom' : ''}`}>
                        <div className={`flex-shrink p-1 bg-${color}`}>&nbsp;</div>
                        <div className="flex-grow ps-2">{text}</div>
                    </div>
                </NavLink>
            </DropdownItem>
        )
    }

    const sendMailView = (isIntegrationPartner) => {
        return (
            <>
                <UncontrolledDropdown>
                    <DropdownToggle caret color="white-outline" style={{marginTop: '1px'}}>Send Mail</DropdownToggle>
                    <DropdownMenu end>
                        {isIntegrationPartner && (
                            <>
                                <DropdownItem tag={Link} to='/send-mail-prospects'>
                                    <BookmarkPlusFill size={20} className="prospects" /> Prospects
                                </DropdownItem>
                                <DropdownItem tag={Link} to='/send-mail-tenants'>
                                    <BookmarkCheckFill size={20} className="tenants" /> Tenants
                                </DropdownItem>
                            </>
                        )}
                        <DropdownItem tag={Link} to='/send-mail-custom'>

<svg
   version="1.1"
   id="Layer_1"
   x="0px"
   y="0px"
   width="23.043201"
   height="16.456599"
   viewBox="0 0 230.43201 164.56599"
   enableBackground="new 0 0 1400 980"
>
<g
   id="g576"
   transform="translate(-441.808,-656.17336)">

	<g
   id="g574">
		<g
   id="g568">
			<path
   fill="var(--bs-yellow)"
   d="m 591.726,764.719 c 16.456,7.163 22.979,4.053 23.248,3.917 0.277,-0.141 0.459,-0.421 0.476,-0.728 0.015,-0.312 -0.139,-0.607 -0.4,-0.772 l -42.601,-27.144 -13.693,8.723 c 13.729,6.774 29.325,14.417 32.97,16.004 z"
   id="path562" />
			<path
   fill="var(--bs-yellow)"
   d="m 536.39,717.019 1.57,-0.928 c 0.229,-0.137 0.38,-0.371 0.418,-0.634 0.032,-0.265 -0.057,-0.529 -0.246,-0.719 -0.096,-0.096 -9.665,-9.707 -10.982,-11.329 -3.721,-4.59 -7.362,-10.35 -10.886,-15.921 -6.42,-10.154 -13.059,-20.655 -21.032,-25.736 -6.045,-3.853 -12.711,-5.825 -18.766,-5.554 -6.17,0.272 -11.104,2.839 -13.894,7.221 0,10e-4 -0.05,0.082 -0.054,0.084 -5.624,8.956 -0.201,22.23 12.347,30.221 4.77,3.038 49.651,27.646 51.561,28.692 0.269,0.146 0.592,0.142 0.855,-0.014 l 0.607,-0.36 7.287,4.642 0.597,9.994 c 2.635,-1.308 9.575,-4.75 17.71,-8.77 z m -56.884,-30.577 c -9.058,-5.771 -12.312,-14.151 -9.67,-18.358 1.396,-2.19 4.396,-3.368 8.248,-3.255 3.992,0.116 8.435,1.61 12.51,4.206 9.056,5.771 12.308,14.151 9.667,18.36 0,0 -0.017,0.025 -0.017,0.026 -2.679,4.203 -11.652,4.811 -20.738,-0.979 z"
   id="path564" />
			<path
   fill="var(--bs-yellow)"
   d="m 495.232,815.16 c 7.973,-5.081 14.612,-15.582 21.032,-25.736 3.523,-5.571 7.165,-11.331 10.886,-15.921 1.317,-1.622 10.886,-11.233 10.982,-11.329 0.189,-0.189 0.278,-0.454 0.246,-0.719 -0.039,-0.264 -0.189,-0.498 -0.418,-0.635 l -1.57,-0.927 78.66,-50.117 c 0.261,-0.165 0.415,-0.461 0.4,-0.772 -0.017,-0.308 -0.199,-0.587 -0.476,-0.728 -0.268,-0.137 -6.792,-3.247 -23.248,3.917 -6.786,2.953 -54.978,26.895 -55.464,27.136 -0.277,0.138 -0.459,0.413 -0.478,0.722 l -0.609,10.178 -7.287,4.642 -0.607,-0.361 c -0.263,-0.155 -0.587,-0.16 -0.855,-0.013 -1.909,1.047 -46.791,25.654 -51.561,28.692 -12.547,7.989 -17.97,21.264 -12.347,30.221 0.003,10e-4 0.054,0.082 0.054,0.084 2.79,4.382 7.724,6.947 13.894,7.221 6.055,0.27 12.722,-1.703 18.766,-5.555 z m 58.635,-76.703 c 0,-1.392 1.129,-2.519 2.519,-2.519 1.39,0 2.518,1.127 2.518,2.519 0,1.39 -1.128,2.518 -2.518,2.518 -1.39,0 -2.519,-1.128 -2.519,-2.518 z m -84.031,70.371 c -2.642,-4.207 0.612,-12.588 9.67,-18.358 9.086,-5.79 18.06,-5.182 20.738,-0.979 0,10e-4 0.017,0.026 0.017,0.026 2.641,4.208 -0.611,12.59 -9.667,18.36 -4.075,2.596 -8.518,4.09 -12.51,4.206 -3.853,0.113 -6.852,-1.064 -8.248,-3.255 z"
   id="path566" />
		</g>
		<path
   fill="#000000"
   d="m 532.485,739.007 h -6.909 c -0.477,0 -0.863,-0.387 -0.863,-0.864 0,-0.477 0.386,-0.863 0.863,-0.863 h 6.909 c 0.478,0 0.864,0.387 0.864,0.863 0,0.477 -0.387,0.864 -0.864,0.864 z m -13.818,0 h -6.909 c -0.477,0 -0.863,-0.387 -0.863,-0.864 0,-0.477 0.386,-0.863 0.863,-0.863 h 6.909 c 0.478,0 0.864,0.387 0.864,0.863 0,0.477 -0.386,0.864 -0.864,0.864 z m -13.817,0 h -6.909 c -0.478,0 -0.864,-0.387 -0.864,-0.864 0,-0.477 0.386,-0.863 0.864,-0.863 h 6.909 c 0.478,0 0.864,0.387 0.864,0.863 0,0.477 -0.386,0.864 -0.864,0.864 z m -13.817,0 h -6.909 c -0.478,0 -0.864,-0.387 -0.864,-0.864 0,-0.477 0.386,-0.863 0.864,-0.863 h 6.909 c 0.477,0 0.864,0.387 0.864,0.863 -10e-4,0.477 -0.387,0.864 -0.864,0.864 z m -13.818,0 h -6.909 c -0.478,0 -0.864,-0.387 -0.864,-0.864 0,-0.477 0.386,-0.863 0.864,-0.863 h 6.909 c 0.477,0 0.863,0.387 0.863,0.863 0.001,0.477 -0.386,0.864 -0.863,0.864 z m -13.818,0 h -6.909 c -0.477,0 -0.863,-0.387 -0.863,-0.864 0,-0.477 0.386,-0.863 0.863,-0.863 h 6.909 c 0.478,0 0.864,0.387 0.864,0.863 0,0.477 -0.386,0.864 -0.864,0.864 z m -13.817,0 h -6.909 c -0.477,0 -0.863,-0.387 -0.863,-0.864 0,-0.477 0.386,-0.863 0.863,-0.863 h 6.909 c 0.478,0 0.864,0.387 0.864,0.863 0,0.477 -0.386,0.864 -0.864,0.864 z"
   id="path570" />
		<path
   fill="#000000"
   d="m 671.377,739.007 h -6.909 c -0.477,0 -0.863,-0.387 -0.863,-0.864 0,-0.477 0.386,-0.863 0.863,-0.863 h 6.909 c 0.477,0 0.863,0.387 0.863,0.863 0,0.477 -0.386,0.864 -0.863,0.864 z m -13.818,0 h -6.909 c -0.477,0 -0.863,-0.387 -0.863,-0.864 0,-0.477 0.386,-0.863 0.863,-0.863 h 6.909 c 0.478,0 0.864,0.387 0.864,0.863 0,0.477 -0.386,0.864 -0.864,0.864 z m -13.817,0 h -6.909 c -0.478,0 -0.864,-0.387 -0.864,-0.864 0,-0.477 0.386,-0.863 0.864,-0.863 h 6.909 c 0.478,0 0.864,0.387 0.864,0.863 -10e-4,0.477 -0.387,0.864 -0.864,0.864 z m -13.818,0 h -6.909 c -0.478,0 -0.864,-0.387 -0.864,-0.864 0,-0.477 0.386,-0.863 0.864,-0.863 h 6.909 c 0.477,0 0.864,0.387 0.864,0.863 0,0.477 -0.387,0.864 -0.864,0.864 z m -13.817,0 h -6.909 c -0.478,0 -0.864,-0.387 -0.864,-0.864 0,-0.477 0.386,-0.863 0.864,-0.863 h 6.909 c 0.477,0 0.863,0.387 0.863,0.863 0,0.477 -0.386,0.864 -0.863,0.864 z m -13.818,0 h -6.909 c -0.477,0 -0.863,-0.387 -0.863,-0.864 0,-0.477 0.386,-0.863 0.863,-0.863 h 6.909 c 0.478,0 0.864,0.387 0.864,0.863 0,0.477 -0.386,0.864 -0.864,0.864 z m -13.817,0 h -6.909 c -0.477,0 -0.863,-0.387 -0.863,-0.864 0,-0.477 0.386,-0.863 0.863,-0.863 h 6.909 c 0.478,0 0.864,0.387 0.864,0.863 -10e-4,0.477 -0.387,0.864 -0.864,0.864 z"
   id="path572" />
	</g>
</g>
</svg><span style={{paddingLeft: '1px'}}>Address Import</span>
                        </DropdownItem>
                        <DropdownItem tag={Link} to='/send-mail-address-wizard'>
                            <Magic size={20} className="address" /> Address Wizard
                        </DropdownItem>
                        {/* <UncontrolledDropdown group direction="end" className='dropdown-menu-item'>
                            <DropdownToggle
                                caret
                                color="white-outline"
                                className='btn-sm'
                            />
                            <DropdownMenu>
                                <DropdownItem tag={Link} to='/send-mail-address-wizard/manager'>
                                    Template Manager
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown> */}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </>
        )
      }

    const historyView = () => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle caret color="white-outline" style={{marginTop: '1px'}}>History</DropdownToggle>
                <DropdownMenu end>
                    <DropdownItem tag={Link} to='/mailing-status'>
                        <Truck size={20} className="uspsTracking" /> USPS Tracking
                    </DropdownItem>
                    <DropdownItem tag={Link} to='/send-mail-history'>
                        <EnvelopeCheck size={20} className="history" /> Send Mail History
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    }

    const adminView = (isIntegrationPartner) => {
        return (
            <UncontrolledDropdown>
                <DropdownToggle caret color="white-outline" style={{marginTop: '1px'}}>Admin</DropdownToggle>
                <DropdownMenu end>
                    <DropdownItem tag={Link} to='/admin/settings'>
                        <GearFill size={20} className="text-muted" /> Settings
                    </DropdownItem>
                    <DropdownItem tag={Link} to='/admin/users'>
                        <PersonFill size={20} className="text-muted" /> Users
                    </DropdownItem>
                    <DropdownItem tag={Link} to='/send-mail-address-wizard/manager'>
                        <Magic size={20} className="address" /> Address Wizard Templates
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    }

    const authenticatedView = (userName, role, isIntegrationPartner, userLocation, userLocations, setUserLocation, profilePath, logoutPath) => {
        return (
            <Fragment>
                {/* <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/send-mail">Send Mail</NavLink>
                </NavItem> */}
                {sendMailView(isIntegrationPartner)}
                {historyView()}
                {/* <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/mailing-status">Mailing Status</NavLink>
                </NavItem> */}
                { role && (role === Roles.Administrator || role.includes(Roles.Administrator)) && (
                    // <NavItem>
                    //     <NavLink tag={Link} className="text-dark" to="/admin">Admin</NavLink>
                    // </NavItem>
                    adminView(isIntegrationPartner)
                )}
                {/* <NavItem>
                    <NavLink tag={Link} className="text-dark" to={profilePath}>Hello {userName}</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>
                </NavItem> */}
                {
                    userLocations && userLocations.length > 1 && (
                        <LocationMenu
                            setUserLocation={setUserLocation}
                            userLocation={userLocation}
                            userLocations={userLocations}
                        />
                    )
                }
                <UncontrolledDropdown>
                    <DropdownToggle
                        data-toggle="dropdown"
                        className="btn btn-primary btn-circle"
                        tag="button"
                        caret
                    >
                        <PersonCircle />
                    </DropdownToggle>
                    <DropdownMenu end className="pb-0 pt-0 border-0">
                        <DropdownItem header className="text-white p-3 bg-primary border border-primary border-bottom-0 rounded-top">
                            <div className="d-flex flex-fill flex-column align-items-center" style={{minWidth: '250px'}}>
                                <PersonCircle size={40} />
                                <div className="mt-2">{userName}</div>
                            </div>
                        </DropdownItem>
                        {/* {renderMenuItem('/admin/settings', 'User Settings', 'light')} */}
                        {renderMenuItem(profilePath, 'Change Password', 'light', true)}
                        {renderMenuItem(logoutPath, 'Logout', 'light', true)}
                    </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
            </NavItem>
            </Fragment>
        );
    }

    const anonymousView = (registerPath, loginPath) => {
        return (<Fragment>
            {/* <NavItem>
                <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>
            </NavItem> */}
            <NavItem>
                <NavLink tag={Link} to={loginPath}><Button color="primary" className="text-white p-3 pe-4"><PersonCircle /> Login</Button></NavLink>
            </NavItem>
        </Fragment>);
    }

    const setUserLocation = async (locationId) => {
        //console.log('setUserLocation called with id: ' + locationId)
        if (state.userLocations && state.userLocations.find((location) => location.LocationID == locationId)) {
          //console.log('setUserLocation called and set to: ' + locationId)
    
          var result = await userService.UpdateUserPreferredLocation(locationId)
          if (result.isSuccess) {
            const newLocation = await locationService.GetLocation(locationId)
    
            //console.log('set location to retrieved value: ', newLocation.LocationID)
      
            localStorage.setItem("currentLocation", newLocation.LocationID);

            setState({
              ...state,
              userLocation: newLocation
            })

            window.location.reload();
          }
        }
      }
    

    return render()
}

// export class LoginMenu extends Component {
//     static contextType = UserPreferencesContext

//     constructor(props) {
//         super(props);

//         this.state = {
//             isAuthenticated: false,
//             userName: null,
//             role: null
//         };
//     }

//     componentDidMount() {
//         this._subscription = authService.subscribe(() => this.populateState());
//         this.populateState();
//     }

//     componentWillUnmount() {
//         authService.unsubscribe(this._subscription);
//     }

//     async populateState() {
//         const [isAuthenticated, user, role] = await Promise.all([authService.isAuthenticated(), authService.getUser(), authService.getRole()])
//         this.setState({
//             isAuthenticated,
//             userName: user && user.name,
//             role
//         });
//         console.log(this.state)
//     }

//     render() {
//         const { isAuthenticated, role, userName } = this.state;
//         //console.log(role)
//         if (!isAuthenticated) {
//             const registerPath = `${ApplicationPaths.Register}`;
//             const loginPath = `${ApplicationPaths.Login}`;
//             return this.anonymousView(registerPath, loginPath);
//         } else {
//             const profilePath = `${ApplicationPaths.Profile}`;
//             const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
//             return this.authenticatedView(userName, role, profilePath, logoutPath);
//         }
//     }

//     renderMenuItem(path, text, color, isLast = false) {
//         return (
//             <DropdownItem className="dropdown-item m-0 p-0">
//                 <NavLink tag={Link} className="text-dark m-0 p-0" to={path}>
//                     <div className={`d-flex flex-row align-items-center border border-${color} ${isLast ? 'border-top-0 rounded-bottom' : ''}`}>
//                         <div className={`flex-shrink p-1 bg-${color}`}>&nbsp;</div>
//                         <div className="flex-grow ps-2">{text}</div>
//                     </div>
//                 </NavLink>
//             </DropdownItem>
//         )
//     }

//     authenticatedView(userName, role, profilePath, logoutPath) {
//         return (
//             <Fragment>
//                 <NavItem>
//                   <NavLink tag={Link} className="text-dark" to="/send-mail">Send Mail</NavLink>
//                 </NavItem>
//                 <NavItem>
//                   <NavLink tag={Link} className="text-dark" to="/mailing-status">Mailing Status</NavLink>
//                 </NavItem>
//                 { role && (role === Roles.Administrator || role.includes(Roles.Administrator)) && (
//                     <NavItem>
//                         <NavLink tag={Link} className="text-dark" to="/admin">Admin</NavLink>
//                     </NavItem>
//                 )}
//                 {/* <NavItem>
//                     <NavLink tag={Link} className="text-dark" to={profilePath}>Hello {userName}</NavLink>
//                 </NavItem>
//                 <NavItem>
//                     <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>
//                 </NavItem> */}
//                 <UncontrolledDropdown>
//                     <DropdownToggle caret>
//                         Location ()
//                     </DropdownToggle>
//                 </UncontrolledDropdown>
//                 <UncontrolledDropdown>
//                     <DropdownToggle
//                         data-toggle="dropdown"
//                         className="btn btn-primary btn-circle"
//                         tag="button"
//                         caret
//                     >
//                         <PersonCircle />
//                     </DropdownToggle>
//                     <DropdownMenu end className="pb-0 pt-0 border-0">
//                         <DropdownItem header className="text-white p-3 bg-primary border border-primary border-bottom-0 rounded-top">
//                             <div className="d-flex flex-fill flex-column align-items-center" style={{minWidth: '250px'}}>
//                                 <PersonCircle size={40} />
//                                 <div className="mt-2">{userName}</div>
//                             </div>
//                         </DropdownItem>
//                         {this.renderMenuItem('/admin/settings', 'User Settings', 'light')}
//                         {this.renderMenuItem(logoutPath, 'Logout', 'light', true)}
//                     </DropdownMenu>
//                 </UncontrolledDropdown>
//             </Fragment>
//         );
//     }

//     anonymousView(registerPath, loginPath) {
//         return (<Fragment>
//             {/* <NavItem>
//                 <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>
//             </NavItem> */}
//             <NavItem>
//                 <NavLink tag={Link} to={loginPath}><Button color="primary" className="text-white p-3 pe-4"><PersonCircle /> Login</Button></NavLink>
//             </NavItem>
//         </Fragment>);
//     }
// }
