import React from 'react'

const Forbidden = () => {
  return (
    <div>
      Forbidden / Insufficient Authorization
    </div>
  )
}

export default Forbidden
