import React, { useEffect, useReducer } from 'react'
import { Row, Col } from 'reactstrap'

const OptionYesNo = ({id, text, onChange, initialValue = false, scale = 1.6, useFlex = false, flexProps = '', colSizeLabel = { sm: 8 }, colSizeSwitch = { sm: 4 }, ...rest}) => {

  const initialState = {
    [id]: initialValue
  }

  const actionReducer = (state, action) => {
    switch (action.type) {
      case 'SET': {
        return {
          ...state,
          [action.name]: action.value
        }
      }
    }

    return state
  }

  const [state, dispatch] = useReducer(actionReducer, initialState)

  useEffect(() => {
    onChange(state)
  }, [state])

  return (
    <React.Fragment>
      {!useFlex ? (
        <Row className="align-items-center">
          <Col {...colSizeLabel}><span style={{cursor: 'pointer'}} onClick={() => dispatch({ type: 'SET', name: id, value: !state[id] })}>{text}</span></Col>
          <Col {...colSizeSwitch} className="pe-4">
            <div className="form-check form-switch d-flex align-items-end justify-content-center flex-column pe-1">
              <input
                className="form-check-input"
                type="checkbox"
                id={id}
                name={id}
                onChange={e => dispatch({ type: 'SET', name: e.target.name, value: e.target.checked })}
                checked={state[id] ? 'checked' : ''}
                {...rest}
                style={{transform: `scale(${scale})`, cursor: 'pointer'}}/>
            </div>
          </Col>
        </Row>
      ) : (
        <div className={`d-flex flex-row justify-content-between ${flexProps}`}>
          <div className='flex-grow-1'><span style={{cursor: 'pointer'}} onClick={() => dispatch({ type: 'SET', name: id, value: !state[id] })}>{text}</span></div>
          <div className="form-check form-switch d-flex align-items-end justify-content-center flex-column flex-grow-0 me-2">
            <input
              className="form-check-input"
              type="checkbox"
              id={id}
              name={id}
              onChange={e => dispatch({ type: 'SET', name: e.target.name, value: e.target.checked })}
              checked={state[id] ? 'checked' : ''}
              {...rest}
              style={{transform: `scale(${scale})`, cursor: 'pointer'}}/>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default OptionYesNo