import custom from '../../custom.scss'

export const customStyles = {
  option: (provided, state) => {
    //console.log('control', provided)
    return ({
      ...provided,
      backgroundColor: state.isSelected ? custom.dark : 'transparent',
      "&:hover": {
        backgroundColor: state.isSelected ? custom.dark : custom.light
      }
    })
  },
  control: (provided, state) => {
    return ({
      ...provided,
      border: state.selectProps.isDisabled ? `${custom.borderWidth} solid ${custom.middleGray}` : state.selectProps.invalid ? `${custom.borderWidth} solid ${custom.danger}` : state.selectProps.valid ? `${custom.borderWidth} solid ${custom.success}` : state.isFocused ? `${custom.borderWidth} solid ${custom.light}` : `${custom.borderWidth} solid ${custom.borderColor}`,
      boxShadow: state.isFocused ? state.selectProps.invalid ? custom.inputFocusBoxShadowDanger : state.selectProps.valid ? custom.inputFocusBoxShadowSuccess : custom.inputFocusBoxShadow : "none",
      "&:hover": {
        border: state.selectProps.isDisabled ? `${custom.borderWidth} solid ${custom.middleGray}` : state.selectProps.invalid ? `${custom.borderWidth} solid ${custom.danger}` : state.selectProps.valid ? `${custom.borderWidth} solid ${custom.success}` : state.isFocused ? `${custom.borderWidth} solid ${custom.light}` : `${custom.borderWidth} solid ${custom.borderColor}`,
      }
    })
  },
  menu: (provided, state) => {
    return ({
      ...provided,
      zIndex: 20
    })
  }
 };