import { useEffect, useRef, useState } from "react";
import { GearFill, LightningFill } from "react-bootstrap-icons";
import { Button } from "reactstrap";

export default props => {
  const [ascSort, setAscSort] = useState('inactive');
  const [descSort, setDescSort] = useState('inactive');
  const [noSort, setNoSort] = useState('inactive');
  const [noFilter, setNoFilter] = useState('inactive');
  const refButton = useRef(null);
  const refConfigButton = useRef(null);

  console.log(props)

  const onMenuClicked = () => {
      props.showColumnMenu(refButton.current);
  }

  const onShowConfigClicked = (e) => {
    e.currentTarget.blur()
    props.onShowConfig(refConfigButton.current)
  }

  const onSortChanged = () => {
      setAscSort(props.column.isSortAscending() ? 'active' : 'inactive');
      setDescSort(props.column.isSortDescending() ? 'active' : 'inactive');
      setNoSort(!props.column.isSortAscending() && !props.column.isSortDescending() ? 'active' : 'inactive');
  }

  const onFilterChanged = () => {
    setNoFilter(props.column.isFilterActive() ? 'active' : 'inactive');
  }

  const onSortRequested = (order, event) => {
    props.setSort(order, event.shiftKey);
  }

  useEffect(() => {
      props.column.addEventListener('sortChanged', onSortChanged);
      onSortChanged()
  }, []);

  useEffect(() => {
    props.column.addEventListener('filterChanged', onFilterChanged);
    onFilterChanged()
  }, []);

  let menu = null;
  if (props.enableMenu) {
      menu =
        <span ref={refButton} onClick={() => onMenuClicked()} className="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"><span className="ag-icon ag-icon-menu" unselectable="on" role="presentation"></span></span>
          // <div ref={refButton}
          //      className="customHeaderMenuButton"
          //      onClick={() => onMenuClicked()}>
          //     {/* <i className={`fa ${props.menuIcon}`}></i> */}
          //     <GearFill />
          // </div>;
  }

  let sort = null;
  if (props.enableSorting) {
      sort =
          <div style={{display: "inline-block"}}>
              <div onClick={event => onSortRequested('asc', event)} onTouchEnd={event => onSortRequested('asc', event)}
                   className={`customSortDownLabel ${ascSort}`}>
                  <i className="fa fa-long-arrow-alt-down"></i>
              </div>
              <div onClick={event => onSortRequested('desc', event)} onTouchEnd={event => onSortRequested('desc', event)}
                   className={`customSortUpLabel ${descSort}`}>
                  <i className="fa fa-long-arrow-alt-up"></i>
              </div>
              <div onClick={event => onSortRequested('', event)} onTouchEnd={event => onSortRequested('', event)}
                   className={`customSortRemoveLabel ${noSort}`}>
                  <i className="fa fa-times"></i>
              </div>
          </div>;
  }

  let filter = null;
  if (props.enableMenu) {
    filter =
      <span className="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true">
        <span className={`ag-icon ag-icon-filter${noFilter === 'active' ? '' : ' ag-hidden'}`} unselectable="on" role="presentation"></span>
      </span>
  }

  const config = 
    <div><Button
      innerRef={refConfigButton}
      onClick={(e) => onShowConfigClicked(e)}
      color='primary'
      style={{width: '20px', height: '26px'}}
      className='btn-circle d-flex flex-column justify-content-center align-items-center'
    >
      <div style={{transform: 'scale(0.70)'}}><LightningFill size={28} /></div>
    </Button></div>
  //<span ref={refConfigButton} onClick={() => onShowConfigClicked()} className="me-2 mb-1 cursor-pointer customHeaderConfigBtn"><LightningFill /></span>

  return (
      <div className="ag-cell-label-container" role="presentation" style={{height: '100%', width: '100%'}}>
        {/* {sort} */}
        {menu}
        <div className="ag-header-cell-label" role="presentation" unselectable="on">
          {config}
          <div className="ag-header-cell-text" style={{whiteSpace: 'normal', display: 'inline-block', maxWidth: 'min-content', textAlign: 'center', margin: 'auto'}}>{props.displayName}</div>
          {filter}
        </div>
      </div>
      // <div>
      //     {sort}
      //     <div className="customHeaderLabel">{props.displayName}</div>
      //     {menu}
      // </div>
  );
};