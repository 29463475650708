import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { EnvelopeCheck, ReceiptCutoff } from 'react-bootstrap-icons'
import { dateFormat,  dateTimeFormat } from '../../services/DateFormatter'
import { CustomLoadingOverlay } from '../widget/AGGridCustomOverlay'
import { MailStatusGridCellMenuRenderer } from './MailStatusGridCellMenu'
import AGGridBooleanFilter from '../widget/AGGridBooleanFilter'
import AGGridCheckmarkRenderer from '../widget/AGGridCheckmarkRenderer'
import { MailStatusConstants } from '../../services/MailStatusService'
import { nanoid } from 'nanoid'

const MailingStatusGrid = ({/*onSelected,*/onSelectionChanged, onShowJobDetails, perPage, /*dateSelectionRange,*/ mailingsData, isLoading, costCodeEnabled }) => {
  //const [selectedRow, setSelectedRow] = useState()
  const gridRef = useRef()

  useEffect(() => {
    let newColDef = initialColumnDef
    const costCodeField = newColDef.find(col => col.field === 'CostCode')
    costCodeField.hide = !costCodeEnabled

    setColumnDefs(newColDef)
    
    //gridRef.current.api.sizeColumnsToFit()
  }, [costCodeEnabled])

  useEffect(() => {
    console.log('use effect triggered on isLoading changed to ' + isLoading)
    if (isLoading) {
      onShowLoading()
    }
    else {
      onHideOverlay()
    }
  }, [isLoading])

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.api.paginationSetPageSize(perPage.value);
    }
  }, [perPage])

  // useEffect(() => {
  //   if (selectedRow) {
  //     onSelected(selectedRow)
  //   }
  // }, [selectedRow])

  // useEffect(() => {
  //   console.log(mailingsData)
  // }, [mailingsData])

  const onShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, [])

  const onHideOverlay = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  const setGridSelection = () => {
    if (gridRef.current) {
      const selectedNodes = gridRef.current.api.getSelectedNodes()
      const selectedData = selectedNodes.map(node => node.data)
      console.log('selectedData', selectedData)
      onSelectionChanged(selectedData)
    }
    else {
      onSelectionChanged([])
    }
  }

  const showDetails = async (nodeData) => {
    console.log('showDetails', nodeData)
    onShowJobDetails({ uniqueKey: nanoid(), nodeData})
  }

  const [defaultColumnDefs, setDefaultColumnDefs] = useState({
    sortable: true,
    filter: true,
    resizable: true
  })

  const initialColumnDef = [
    { 
      field: "id",
      headerName: "Job ID",
      suppressSizeToFit: true,
      maxWidth: 130,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true
    },
    {
      field: "mailClass",
      headerName: "E.R.R.",
      headerTooltip: "Electornic Return Receipt Expected",
      sortable: false,
      filter: 'AGGridBooleanFilter',
      filterParams: {
        title: 'Electronic Return Receipt',
        allText: 'All',
        trueText: 'Expected',
        falseText: 'None',
      },
      suppressSizeToFit: true,
      maxWidth: 110,
      // headerComponentParams: {
      //     template:
      //         '<div class="ag-cell-label-container" role="presentation">' +
      //         '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
      //         '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
      //         '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
      //         '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
      //         '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
      //         '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
      //         '    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" fill="currentColor"><path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"></path><path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z"></path></svg>' +
      //         '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
      //         '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
      //         '  </div>' +
      //         '</div>'
      // },
      valueGetter: (params) => {
        if (!params.node.rowPinned) {
            return params.data.mailClass === MailStatusConstants.ElectronicReturnReceiptMaiLClass
        }
      },
      cellRenderer: "AGGridCheckmarkRenderer",
      cellRendererParams: {
        icon: (<ReceiptCutoff size={24} className="text-muted" />)
      }
    },
    {
      field: "IncludeConfirmationOfMailing",
      headerName: "C.o.M.",
      headerTooltip: "Confirmation of Mailing",
      sortable: false,
      filter: 'AGGridBooleanFilter',
      filterParams: {
        title: 'Confirmation of Mailing',
        allText: 'All',
        trueText: 'Included',
        falseText: 'Not Included',
      },
      suppressSizeToFit: true,
      maxWidth: 115,
      // valueGetter: (params) => {
      //   if (!params.node.rowPinned) {
      //       return params.data.mailClass === MailStatusConstants.ElectronicReturnReceiptMaiLClass
      //   }
      // },
      cellRenderer: "AGGridCheckmarkRenderer",
      cellRendererParams: {
        icon: (<EnvelopeCheck size={24} className="text-muted" />)
      }
    },
    {
      headerName: "Details",
      field: "id",
      filter: false,
      sortable: false,
      suppressSizeToFit: true,
      maxWidth: 80,
      // equals={(job1, job2) => {
      //   const idEqual = job1.id === job2.id;
      //   return idEqual;
      // }}
      cellRenderer: "btnCellRenderer",
      cellRendererParams: {
        onShowDetails(e, nodeData) {
          e.stopPropagation()
          //e.preventDefault()
          e.target.parentElement.blur()
          e.target.blur()
          gridRef.current.api.clearFocusedCell();
          showDetails(nodeData)
          console.log('nodeDatae', nodeData)
        },
      }
    },
    {
      field: "jobStatus",
      headerName: "Status",
      tooltipField: "jobStatus",
      suppressSizeToFit: true,
      maxWidth: 180
    },
    {
      field: "USPSStatus",
      headerName: "USPS Status",
      tooltipField: "USPSStatus",
      suppressSizeToFit: true,
      minWidth: 120
    },
    {
      field: "USPSStatusDateTime",
      headerName: "USPS Status Date / Time",
      tooltipField: "USPSStatusDateTime",
      suppressSizeToFit: true,
      minWidth: 220,
      valueFormatter: dateTimeFormat
    },
    {
      field: "RecipientName",
      headerName: "Recipient",
      suppressSizeToFit: true,
      tooltipField: "RecipientName"
    },
    {
      field: "DocumentName",
      headerName: "Document",
      suppressSizeToFit: true,
      maxWidth: 200,
      tooltipField: "DocumentName"
    },
    {
      field: "submittedDateUTC",
      headerName: "Date / Time Submitted",
      suppressSizeToFit: true,
      maxWidth: 200,
      valueFormatter: dateTimeFormat,
    },
    {
      field: "mailingDate",
      headerName: "Date Mailed",
      suppressSizeToFit: true,
      maxWidth: 150,
      valueFormatter: dateFormat
    },
    // {
    //   field: "barCode",
    //   headerName: "Tracking Number",
    //   tooltipField: "barCode",
    //   suppressSizeToFit: true,
    //   minWidth: 245
    // },
    {
      field: "barCodeTracking",
      headerName: "Tracking Number",
      tooltipField: "barCodeTracking",
      suppressSizeToFit: true,
      minWidth: 245
    },
    {
      field: "barCodeTracing",
      headerName: "Tracing Number",
      tooltipField: "barCodeTracing",
      suppressSizeToFit: true,
      minWidth: 245,
      valueGetter: (params) => {
        return params.data.IncludeConfirmationOfMailing ? params.data.barCodeTracing : ''
      }
    },
    {
      field: "SubmittedBy",
      tooltipField: "SubmittedBy",
      suppressSizeToFit: false,
    },
    {
      field: "CostCode",
      tooltipField: "CostCode",
      maxWidth: 190,
      suppressSizeToFit: true,
    },
    {
      field: "product",
      headerName: "Product",
      tooltipField: "product",
      suppressSizeToFit: false,
    },
    {
      field: "layout",
      headerName: "Layout",
      tooltipField: "layout",
      suppressSizeToFit: false,
    },
    {
      field: "envelope",
      headerName: "Envelope",
      tooltipField: "envelope",
      suppressSizeToFit: false,
    },
    {
      field: "color",
      headerName: "Color",
      tooltipField: "color",
      suppressSizeToFit: false,
    },
    {
      field: "paperType",
      headerName: "Type",
      tooltipField: "paperType",
      suppressSizeToFit: false,
    },
    {
      field: "mailClass",
      headerName: "Mail Class",
      tooltipField: "mailClass",
      suppressSizeToFit: false,
    },
    {
      field: "printOption",
      headerName: "Printing",
      tooltipField: "printOption",
      suppressSizeToFit: false,
    },
    {
      field: "productionTime",
      headerName: "Production Time",
      tooltipField: "productionTime",
      suppressSizeToFit: false,
    }
  ]

  const [columnDefs, setColumnDefs] = useState(initialColumnDef);

  useEffect(() => {
    if (gridRef && gridRef.current) {
      gridRef.current.api.setColumnDefs(columnDefs)
    }
  }, [columnDefs])

  return (
    <div className="ag-theme-alpine" style={{minHeight: '150px', height: 'calc(90vh - 195px)', width: '100%' }}>
      <AgGridReact
        ref={gridRef}
        //domLayout="autoHeight"
        rowData={mailingsData}
        rowSelection="multiple"
        pagination={true}
        showDisabledCheckboxes={true}
        //enableBrowserTooltips={true}
        paginationPageSize={perPage.value}
        frameworkComponents={{
          customLoadingOverLoad: CustomLoadingOverlay,
          btnCellRenderer: MailStatusGridCellMenuRenderer,
          AGGridBooleanFilter: AGGridBooleanFilter,
          AGGridCheckmarkRenderer: AGGridCheckmarkRenderer
        }}
        loadingOverlayComponent="customLoadingOverLoad"
        loadingOverlayComponentParams={{
          loadingMessage: 'Loading...'
        }}
        defaultColDef={defaultColumnDefs}
        //columnDefs={columnDefs}
        onRowDataChanged={(event) => {
          console.log('onRowDataChanged')
          console.log(event)
          setGridSelection()
        }}
        onSelectionChanged={(event) => {
          setGridSelection()
        }}
        isRowSelectable={(params) => {
          //console.log(params)
          //var cellValue = gridRef.current.api.getValue('barCodeTracing', params)
          //console.log(cellValue)
          //var cellValue = gridOptions.api.getValue(colKey, row.node)
          return !!params.data && (!!params.data.barCodeTracking || (!!params.data.IncludeConfirmationOfMailing && !!params.data.barCodeTracing))
        }}
        // onRowSelected = {(event) => {
        //   if (event.node.isSelected()) {
        //     console.log('Selection made')
        //     console.log(event.node.data)
        //     // if (openDetails)
        //     // {
        //     //   toggleDetails()
        //     // }

        //     setSelectedRow(event.node.data)
        //   }
        // }}
        onGridReady={(event) => { 
          //setGridApi(event.api)
          // if (isLoading)
          // {
          //   onShowLoading()
          // }
          // setGridColumnApi(event.columnApi)
          //event.api.sizeColumnsToFit()
        }}>
          {/* <AgGridColumn
            field="id"
            headerName="Job ID"
            suppressSizeToFit={true}
            maxWidth={130}
            checkboxSelection={true}
            headerCheckboxSelection={true}
          >
          </AgGridColumn>
          <AgGridColumn
            headerName="Details"
            field="id"
            filter={false}
            resizable={false}
            sortable={false}
            suppressSizeToFit={true}
            maxWidth={80}
            // equals={(job1, job2) => {
            //   const idEqual = job1.id === job2.id;
            //   return idEqual;
            // }}
            cellRenderer="btnCellRenderer"
            cellRendererParams={{
              onShowDetails(e, nodeData) {
                e.stopPropagation()
                //e.preventDefault()
                e.target.parentElement.blur()
                e.target.blur()
                gridRef.current.api.clearFocusedCell();
                showDetails(nodeData)
                console.log(e)
              },
            }}
          >
          </AgGridColumn>
          <AgGridColumn
            field="DocumentName"
            headerName="Document"
            suppressSizeToFit={true}
            maxWidth={200}
            tooltipField="DocumentName"
          >
          </AgGridColumn>
          <AgGridColumn
            field="RecipientName"
            headerName="Recipient"
            suppressSizeToFit={true}
            tooltipField="RecipientName"
          >
          </AgGridColumn>
          <AgGridColumn
            field="submittedDateUTC"
            headerName="Date / Time Submitted"
            suppressSizeToFit={true}
            maxWidth={200}
            valueFormatter={dateTimeFormat}>
          </AgGridColumn>
          <AgGridColumn field="mailingDate"
            headerName="Date Mailed"
            suppressSizeToFit={true}
            maxWidth={150}
            valueFormatter={dateFormat}>
          </AgGridColumn>
          <AgGridColumn field="jobStatus" headerName="Status" tooltipField="jobStatus" suppressSizeToFit={true} maxWidth={180}></AgGridColumn>
          <AgGridColumn field="barCode" headerName="Tracking Number" tooltipField="barCode" suppressSizeToFit={true} minWidth={245}></AgGridColumn>
          <AgGridColumn field="USPSStatus" headerName="USPS Status" tooltipField="USPSStatus" suppressSizeToFit={true} minWidth={120}></AgGridColumn>
          <AgGridColumn
            field="USPSStatusDateTime"
            headerName="USPS Status Date / Time"
            tooltipField="USPSStatusDateTime"
            suppressSizeToFit={true}
            minWidth={220}
            valueFormatter={dateTimeFormat}>
          </AgGridColumn> */}
      </AgGridReact>
    </div>
  )
// }, (prevProps, nextProps) => {

//   console.log(prevProps.perPage.value !== nextProps.perPage.value)
//   console.log(prevProps.dateSelectionRange.startDate !== nextProps.dateSelectionRange.startDate)
//   console.log(prevProps.dateSelectionRange.endDate !== nextProps.dateSelectionRange.endDate)
//   console.log(prevProps.onSelected !== nextProps.onSelected)
//   console.log(prevProps)
//   console.log(nextProps)

//   if (prevProps.perPage.value !== nextProps.perPage.value) return false;
//   if (prevProps.dateSelectionRange.startDate !== nextProps.dateSelectionRange.startDate) return false;
//   if (prevProps.dateSelectionRange.endDate !== nextProps.dateSelectionRange.endDate) return false;
//   if (prevProps.onSelected !== nextProps.onSelected) return false;
  
//   return true;
// })
      }
      //)

export default MailingStatusGrid