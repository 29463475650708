import React, { Component, useEffect, useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';
import Logo from '../images/Efformance_MailFuze_Logo.webp'

export const NavMenu = () => {

  const [collapsed, setCollapsed] = useState(true)
  const [pageTitle, setPageTitle] = useState('')
  const location = useLocation();
  

  const toggleNavbar = () => {
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    if (location.pathname === '/send-mail-prospects') {
      setPageTitle('Prospects')
    } else if (location.pathname === '/send-mail-tenants') {
      setPageTitle('Tenants')
    } else if (location.pathname === '/send-mail-custom') {
      setPageTitle('Address Import')
    } else if (location.pathname === '/send-mail-address-wizard') {
      setPageTitle('Address Wizard')
    } else if (location.pathname === '/send-mail-address-wizard/manager') {
      setPageTitle('Address Wizard Manager')
    } else if (location.pathname === '/send-mail-history') {
      setPageTitle('History')
    } else if (location.pathname === '/mailing-status') {
      setPageTitle('USPS Tracking')
    } else if (location.pathname === '/admin') {
      setPageTitle('Admin')
    } else if (location.pathname === '/admin/settings') {
      setPageTitle('Admin Settings')
    } else if (location.pathname === '/admin/users') {
      setPageTitle('Admin Users')
    }

    /*const locationBeingsWith = location.pathname.split('/')[1]
    if (locationBeingsWith.startsWith('send-mail')) {
      
    }*/
  }, [location])

  return (
    <header>
      <Navbar className="navbar-expand-lg navbar-toggleable-sm ng-white box-shadow mb-3" light>
        <div className="d-flex flex-column flex-fill">
          <Container className="d-flex">
            <NavbarBrand tag={Link} to="/send-mail"><img className="logo" src={Logo} alt="MailFuze" /></NavbarBrand>
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <Collapse className="d-lg-inline-flex flex-lg-row-reverse" isOpen={!collapsed} navbar>
              <ul className="navbar-nav">
                <LoginMenu>
                </LoginMenu>
              </ul>
            </Collapse>
          </Container>
          <div className='container'>
            {/* <div className='d-flex flex-row justify-content-between text-secondary fw-bold' style={{marginTop: '-28px', marginLeft: '95px', maxWidth: `${pageTitle.length + 2}em`}}>
              {[...pageTitle].map((letter, idx) => (
                <span>{letter}</span>
              ))}
            </div> */}
            <div className='text-secondary fw-bold' style={{marginTop: '-28px', marginLeft: '95px', maxWidth: '300px', textTransform: 'uppercase', letterSpacing: '0.2rem'}}>{pageTitle}</div>
          </div>
        </div>
      </Navbar>
    </header>
  )
}


// export class NavMenu extends Component {
//   static displayName = NavMenu.name;

//   constructor (props) {
//     super(props);

//     this.toggleNavbar = this.toggleNavbar.bind(this);
//     this.state = {
//       collapsed: true
//     };
//   }

//   toggleNavbar () {
//     this.setState({
//       collapsed: !this.state.collapsed
//     });
//   }

//   render () {
//     return (
//       <header>
//         <Navbar className="navbar-expand-lg navbar-toggleable-sm ng-white box-shadow mb-3" light>
//           <div className="d-flex flex-column flex-xl-row flex-fill">
//             <Container className="d-flex">
//               <NavbarBrand tag={Link} to="/send-mail"><img className="logo" src={Logo} alt="MailFuze" /></NavbarBrand>
//               {/* <div className='d-none d-xl-flex flex-fill flex-grow-1 flex-row align-items-center justify-content-center'>Test 1</div> */}
//               <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
//               {/* <Collapse className="d-lg-inline-flex flex-lg-row-reverse flex-xl-grow-0" isOpen={!this.state.collapsed} navbar> */}
//               <Collapse className="d-lg-inline-flex flex-lg-row-reverse flex-xl-grow-0" isOpen={!this.state.collapsed} navbar>
//                 <ul className="navbar-nav">
//                   {/* <NavItem>
//                     <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
//                   </NavItem> */}
//                   <LoginMenu>
//                   </LoginMenu>
//                 </ul>
//               </Collapse>
//             </Container>
//             {/* <div className='container d-xl-none' style={{marginTop: '-28px', marginLeft: '110px'}}>Test 2</div> */}
//             <div className='container' style={{marginTop: '-28px', marginLeft: '110px'}}>Test 2</div>
//           </div>
//         </Navbar>
//       </header>
//     );
//   }
// }
