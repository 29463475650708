import { useFetchWrapper } from '../services/fetch-wrapper'

export { useVendorService };

const useVendorService = () => {

  const fetchWrapper = useFetchWrapper()

  const GetPropertyManagementCompanyVendor = async () => {
    return fetchWrapper.get(`vendor/managementcompany`)
  }

  return { GetPropertyManagementCompanyVendor }
}
