import React from 'react'

const WarningLetterNumberPageOfPages = ({result: {isValid, simplexSupported, duplexSupported, maxNumberOfSheets, maxNumberOfSheetsDuplex }}) => {
  if (!isValid)
  {
    return (
      <div className='d-flex flex-fill flex-column justify-content-between'>
        <div>
          <div className='fw-bold d-flex justify-content-center'>Too Many Pages</div>
          <hr />
          {simplexSupported && duplexSupported && (
            <p>The maximum number of pages is {maxNumberOfSheets} single-sided or {maxNumberOfSheetsDuplex} double-sided.</p>
          )}
          {simplexSupported && !duplexSupported && (
            <p>The maximum number of pages is {maxNumberOfSheets} single-sided.</p>
          )}
          {!simplexSupported && duplexSupported && (
            <p>The maximum number of pages is {maxNumberOfSheetsDuplex} double-sided.</p>
          )}
        </div>
      </div>
    )
  }
  else
  {
    return (<></>)
  }
}

export default WarningLetterNumberPageOfPages