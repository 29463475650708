import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Alert, Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Offcanvas, OffcanvasBody, Row } from 'reactstrap';
import { useJobService } from '../../services/JobService';
import { Download, X } from 'react-bootstrap-icons';

const SendMailHistoryDetails = ({showModal, onClose, sendMailData, columnSettings}) => {
  const initialState = {
    showDetails: false,
    recipientData: [],
    selectedRow: null,
    sendMailSource: 'Custom',
    useContacts: false
  }

  const stateReducer = (state, action) => {
    switch (action.type) {
      case 'SET': {
        return {
          ...state,
          [action.name]: action.value
        }
      }
    }

    return state
  }

  const [state, dispatch] = useReducer(stateReducer, initialState)
  //const [modal, setModal] = useState(showModal)
  const jobService = useJobService()

  const toggleDetails = () => {
    if (state.showDetails) {
      onCloseRelay()
    }
    
    setShowDetails(!state.showDetails)
  }

  const onCloseRelay = () => {
    if (onClose) {
      onClose()
    }
  }

  const setShowDetails = (show) => {
    dispatch({ type: 'SET', name: 'showDetails', value: show })
  }

  useEffect(() => {
    if (sendMailData && sendMailData.uniqueKey) {
      setShowDetails(true)
      dispatch({ type: 'SET', name: 'recipientData', value: []})
      loadRecipientData(sendMailData.nodeData)
    } else {
      setShowDetails(false)
    }
  }, [showModal, sendMailData])

  const loadRecipientData = async (sendMailData) => {
    dispatch({ type: 'SET', name: 'selectedRow', value: null })
    // var data = await jobService.GetJobrecipientData(jobId)
    // console.log(data)
    
    console.log('sendMailData', sendMailData)
    console.log('sendMailSource', sendMailData.SendMailSource)
    console.log('useContacts', sendMailData.UseContacts)


    const request = JSON.parse(sendMailData.Request)
    console.log(request)
    console.log(request.recipients)

    dispatch({ type: 'SET', name: 'sendMailSource', value: sendMailData.SendMailSource })
    dispatch({ type: 'SET', name: 'useContacts', value: request.useContacts })
    dispatch({ type: 'SET', name: 'recipientData', value: request.recipients })
  }

  const [defaultColumnDefs, setDefaultColumnDefs] = useState({
    sortable: true,
    filter: true,
    resizable: true
  })

  return (
    <Modal
    backdrop="static"
    size="xl"
    isOpen={state.showDetails}
    toggle={toggleDetails}
    modalTransition={{ timeout: 700 }}
    backdropTransition={{ timeout: 700 }}
  >
    <ModalHeader toggle={toggleDetails} close={<button className="close btn btn-white" onClick={toggleDetails}><X size={25} /></button>}>
      <div>Recipient Details</div>
      {/* <div style={{fontSize: '60%'}}>Transaction log: {transactionLog}</div> */}
    </ModalHeader>
    <ModalBody style={{ minHeight: '150px', maxHeight: '75vh', overflow: 'auto' }}>
        <Row className="flex-fill">
          {/* <Col xs={{ size: 12, order: 2}} lg={{ size: 4, order: 1 }}>
            {sendMailData && (
              <div className="d-flex flex-column justify-content-between flex-grow-1" style={{height: '100%', maxHeight: '300px'}}>
                {renderDetailItem('Product', sendMailData.DocumentClass)}
                {renderDetailItem('Layout', sendMailData.Layout)}
                {renderDetailItem('Envelope', sendMailData.Envelope)}
                {renderDetailItem('Color', sendMailData.Color)}
                {renderDetailItem('Type', sendMailData.PaperType)}
                {renderDetailItem('Mail Class', sendMailData.MailClass)}
                {renderDetailItem('Printing', sendMailData.PrintOption)}
                {renderDetailItem('Production Time', sendMailData.ProductionTime, true)}
              </div>
            )}
          </Col>
          <Col xs={{ size: 12, order: 1}} lg={{ size: 8, order: 2 }}> */}
          <Col xs={{ size: 12 }}>
            <Row>
              {state.recipientData && (
                <div className="ag-theme-alpine" style={{minHeight: '200px', width: '100%', height: '100%', maxHeight: '75vh' }}>
                  <AgGridReact
                    rowData={state.recipientData}
                    domLayout="autoHeight"
                    rowSelection="none"
                    pagination={false}
                    suppressCellSelection={true}
                    defaultColDef={defaultColumnDefs}
                    className="pb-2"
                    // getRowNodeId={(data) => {
                    //   return data.barCode;
                    // }}
                    // onFirstDataRendered={(event) => {
                    //   const rowNode = event.api.getDisplayedRowAtIndex(0);
                    //   if (rowNode)
                    //   {
                    //     console.log(rowNode)
                    //     rowNode.setSelected(true)
                    //   }
                    // }}
                    // onRowSelected = {(event) => {
                    //   if (event.node.isSelected()) {
                    //     dispatch({ type: 'SET', name: 'selectedRow', value: event.node.data })
                    //   }
                    //   else {
                    //     dispatch({ type: 'SET', name: 'selectedRow', value: null })
                    //   }
                    // }}
                    onGridReady={(event) => { 
                      event.api.sizeColumnsToFit()
                      event.api.deselectAll()
                    }}>
                      <AgGridColumn field="property" minWidth={120} headerName="Property" hide={!columnSettings.isIntegrationPartner || state.sendMailSource === 'Custom' || state.sendMailSource === 'Address Wizard'}></AgGridColumn>
                      <AgGridColumn field="unit" headerName="Unit" hide={!columnSettings.isIntegrationPartner || state.sendMailSource === 'Custom' || state.sendMailSource === 'Address Wizard'}></AgGridColumn>
                      <AgGridColumn field="contactType" minWidth={165} hide={!state.useContacts}></AgGridColumn>
                      <AgGridColumn field="firstName"></AgGridColumn>
                      <AgGridColumn field="lastName"></AgGridColumn>
                      {state.sendMailSource === 'Address Wizard' ? (
                        <AgGridColumn field="street1" headerName="Street"></AgGridColumn>
                      ) : (
                        <AgGridColumn field="street"></AgGridColumn>
                      )}
                      <AgGridColumn field="city"></AgGridColumn>
                      <AgGridColumn field="state"></AgGridColumn>
                      <AgGridColumn field="zip"></AgGridColumn>
                  </AgGridReact>
                </div>
              )}
            </Row>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div className="m-auto">
          <Button color="primary" onClick={toggleDetails}>
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default SendMailHistoryDetails