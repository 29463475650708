import React, { useCallback, useReducer, useState } from 'react'
import { FilterLeft, ListCheck, ListStars, X } from 'react-bootstrap-icons'
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { ReturnAddressActions } from '../../../services/ReturnAddressService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ReturnAddressActionInput = ({ showModal, onClose, applyReturnAddress, useMultilineAddress = false }) => {

  const [modal, setModal] = useState(showModal)
  const [returnAddressOption, setReturnAddressOption] = useState(ReturnAddressActions.BlankOnly)
  const MySwal = withReactContent(Swal)

  const initialState = {
    name: '',
    city: '',
    state: '',
    zip: '',
    ...(useMultilineAddress ? { street1: '', street2: '' } : { street: '' })
  }

  const stateReducer = (state, e) => {
    console.log('set ' + e.target.name + ' to ' + e.target.value)
    return {
      ...state,
      [e.target.name]: e.target.value
    }
  }

  const [state, dispatch] = useReducer(stateReducer, initialState)

  const PlainSwal = MySwal.mixin({
    showClass: {
      backdrop: 'swal2-noanimation', // disable backdrop animation
      popup: '',                     // disable popup animation
      icon: ''                       // disable icon animation
    },
    hideClass: {
      popup: '',                     // disable popup fade-out animation
    }
  })

  const toggle = () => {
    if (modal) {
      onClose()
    }
    
    setModal(!modal)
  }

  const onApplyReturnAddress = useCallback(() => {

    console.log('return address', state)

    if ((!state.name || state.name.trim() === '') 
      && ((
        useMultilineAddress
        && (!state.street1 || state.street1.trim() === '')
        && (!state.street2 || state.street2.trim() === '')
      ) || (
        !useMultilineAddress
        && (!state.street || state.street.trim() === '')
      ))
      && (!state.city || state.city.trim() === '')
      && (!state.state || state.state.trim() === '')
      && (!state.zip || state.zip.trim() === '')
    ) {
      PlainSwal.fire({
        title: 'Blank Return Address',
        html:  '<p>Are you sure you want to set all the return address fields to blank?</p>',
        showCloseButton: true,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          cancelButton: 'btn btn-dark btn-lg me-1',
          confirmButton: 'btn btn-primary btn-lg me-1 swal-order-2',
        },
        confirmButtonText: 'Set to Blank'
      }).then((results) => {
        if (results.isConfirmed) {
          applyReturnAddress({ returnAddress: state, returnAddressOption, useMultilineAddress })
        }
      })
    } else {
      applyReturnAddress({ returnAddress: state, returnAddressOption, useMultilineAddress })
    }
  }, [state, returnAddressOption, applyReturnAddress, useMultilineAddress])

  const ReturnAddressOption = ({value, label, children}) => {
    return (
      <div className='cost-code-option mb-0 d-flex flex-fill flex-row align-items-center'>
        <Input
          type='radio'
          name='returnAddressOption'
          id={`returnAddressOption${value}`}
          checked={returnAddressOption === value}
          onChange={() => setReturnAddressOption(value)}
        />
        <div className={`flex-fill ms-2 p-1 border border-1 border-primary rounded ${returnAddressOption === value ? 'border-primary selected' : 'border-white'}`}>
          <Label className='mb-0 d-flex flex-row align-items-center' for={`returnAddressOption${value}`}>
            <span className='icon' style={{opacity: returnAddressOption === value ? 1 : 0.8}}>{children}</span>
            <span>{label}</span>
          </Label>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Modal
        backdrop="static"
        size='lg'
        isOpen={modal}
        toggle={toggle}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 700 }}
      >
        <ModalHeader toggle={toggle} close={<button className="close btn btn-white" onClick={toggle}><X size={25} /></button>}>
          <div>Set Return Address</div>
        </ModalHeader>
        <ModalBody style={{ minHeight: '150px', maxHeight: '75vh', overflow: 'auto' }}>
          <div className='d-flex justify-content-around align-items-center'>
            <div className='d-flex flex-grow-0' style={{ maxWidth: '33%' }}>
              <div style={{position: 'relative'}}>
                <div className='p-2 bg-light rounded pointy-box small'>
                  <div className='d-flex flex-column align-items-center justify-content-space flex-grow-1' style={{height: '100%'}}>
                    <Label className='text-nowrap me-3 mb-0 ps-3 pt-2 w-100'>Name</Label>
                    <Input type='text' name="name" onChange={dispatch}></Input>

                    {useMultilineAddress ? (
                      <>
                        <Label className='text-nowrap me-3 mb-0 ps-3 pt-2 w-100'>Street1</Label>
                        <Input type='text' name="street1" onChange={dispatch}></Input>

                        <Label className='text-nowrap me-3 mb-0 ps-3 pt-2 w-100'>Street2</Label>
                        <Input type='text' name="street2" onChange={dispatch}></Input>
                      </>
                    ) : (
                      <>
                        <Label className='text-nowrap me-3 mb-0 ps-3 pt-2 w-100'>Street</Label>
                        <Input type='text' name="street" onChange={dispatch}></Input>
                      </>
                    )}

                    <Label className='text-nowrap me-3 mb-0 ps-3 pt-2 w-100'>City</Label>
                    <Input type='text' name="city" onChange={dispatch}></Input>

                    <Label className='text-nowrap me-3 mb-0 ps-3 pt-2 w-100'>State</Label>
                    <Input type='text' name="state" onChange={dispatch}></Input>

                    <Label className='text-nowrap me-3 mb-0 ps-3 pt-2 w-100'>Zip</Label>
                    <Input type='text' name="zip" onChange={dispatch}></Input>
                  </div>
                </div>
              </div>
            </div>
            <div className='ps-5 d-flex flex-grow-1'>
              <div className='p-3 flex-fill'>
                <ReturnAddressOption value={ReturnAddressActions.BlankOnly} label='Only rows with all blank return address fields'>
                  <FilterLeft size={32} />
                </ReturnAddressOption>
                <ReturnAddressOption value={ReturnAddressActions.SelectedOnly} label='Only selected rows'>
                  <ListCheck size={32} />
                </ReturnAddressOption>
                <ReturnAddressOption value={ReturnAddressActions.All} label='All rows'>
                  <ListStars size={32} style={{transform: 'rotate(180deg)'}} />
                </ReturnAddressOption>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-end">
            <Button color="secondary" onClick={toggle} className="me-1">
              Cancel
            </Button>
            <Button color="primary" onClick={onApplyReturnAddress} className="ms-1">
              Apply
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ReturnAddressActionInput