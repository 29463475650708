import { useFetchWrapper } from '../services/fetch-wrapper'

export { useClientService };

const useClientService = () => {

  const fetchWrapper = useFetchWrapper()

  const GetClients = async () => {
    return await fetchWrapper.fetchDropDown('client', 'ClientID', 'CompanyName')
  }

  const GetMyClient = async () => {
    return fetchWrapper.get(`client/me`)
  }

  const UpdateMyClientAddress = async (address) => {
    const response = await fetchWrapper.put(`client/me/address`, address)

    return response.isSuccess
  }

  return { GetClients, GetMyClient, UpdateMyClientAddress }
}