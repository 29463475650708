import { useFetchWrapper } from '../services/fetch-wrapper'

export { useMailStatusService };

export const MailStatusConstants = {
  ElectronicReturnReceiptMaiLClass: 'Cert. w/electronic return receipt'
}

const useMailStatusService = () => {

  const fetchWrapper = useFetchWrapper()

  const GetMailStatusData = async (startDate, endDate) => {
    return fetchWrapper.get(`mailstatus?startDate=${startDate}&endDate=${endDate}`)
  }

  const GetPagedMailStatusData = async (perPage, page, startDate, endDate) => {
    return fetchWrapper.get(`mailstatus/paged?perpage=${perPage}&page=${page}&startDate=${startDate}&endDate=${endDate}`)
    /*const token = await authService.getAccessToken();
  
    const response = await fetch(`mailstatus?perpage=${perPage}&page=${page}`,
      { headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      }).catch((error) => { console.log(error); return error  })
  
    if (response.ok) {
      var data = await response.json();
      return data;
    }
  
    return [];*/
  }

  const MergeElectronicReturnReceipts = async (params) => {
    return fetchWrapper.post("mailstatus/mergeelectronicreturnreceipts", params)
  }

  const MergeConfirmationOfMailing = async (params) => {
    return fetchWrapper.post("mailstatus/mergeconfirmationsofmailing", params)
  }

  const MergeLettersAndStatus = async (params) => {
    return fetchWrapper.post("mailstatus/mergelettersandstatus", params)
  }

  return { GetMailStatusData, MergeConfirmationOfMailing, MergeElectronicReturnReceipts, MergeLettersAndStatus }
}