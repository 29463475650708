import BaseSelect2 from "../dropdowns/BaseSelect2";

// export const RolesSelect = ({onChange, onError, value, ...rest}) => {
//   console.log('RolesSelect initialValue', value)
//   return (
//       <BaseSelect
//         serviceUrl={`role`}
//         valueName="RoleID"
//         labelName="RoleName"
//         onChange={onChange}
//         onError={onError}
//         initialValue={value}
//         selectFirst={false}
//         {...rest}
//       />
//   )
// }

export const MailingHistoryCategoryIDSelect = ({options, onChange, value, ...rest}) => {
  return (
      <BaseSelect2
        options={options}
        onChange={onChange}
        value={value}
        selectFirst={false}
        {...rest}
      />
  )
}

export const EfformanceVendorIDSelect = ({options, onChange, value, ...rest}) => {
  return (
      <BaseSelect2
        options={options}
        onChange={onChange}
        value={value}
        selectFirst={false}
        {...rest}
      />
  )
}