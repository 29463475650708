import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import { useForm, useFormState } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import useSettingsContext from './SettingsContext'
import { doRegisterInput,  doRegisterInputRadio, doRegisterSelect } from './FormHookRegisters' 
import { Blank } from '../dropdowns/DefaultSelectOptions'
import { ConstantCodeMailFuzeReturnAddressMethod,
  ConstantCodeMailFuzeReturnAddressRecipientAddressType,
  ConstantCodeMailFuzeReturnAddressThisProperty,
  ConstantCodeMailFuzeReturnAddressThisPropertyAddressType
} from '../../services/SettingsService'
import { FuzeReturnAddressRecipientAddressTypeSelect, MailFuzeReturnAddressRecipientAddressTypeSelect, MailFuzeReturnAddressStateIDSelect, MailFuzeReturnAddressThisPropertyAddressTypeSelect, MailFuzeReturnAddressThisPropertySelect } from './ReturnAddressDowns'
import { usePropertyService } from '../../services/PropertyService'

export const ReturnAddressSettingsState = {
  mailFuzeReturnAddressMethod: {
    constant: ConstantCodeMailFuzeReturnAddressMethod,
    code: undefined,
    type: 'Integer',
    value: 0
  },
  mailFuzeReturnAddressCustomCompanyName: '',
  mailFuzeReturnAddressCustomStreet1: '',
  mailFuzeReturnAddressCustomCity: '',
  mailFuzeReturnAddressCustomStateID: {
    type: 'Integer',
    value: Blank.value
  },
  mailFuzeReturnAddressCustomZip: '',
  mailFuzeReturnAddressThisProperty: {
    constant: ConstantCodeMailFuzeReturnAddressThisProperty,
    code: undefined,
    type: 'Integer',
    value: Blank.value
  },
  mailFuzeReturnAddressThisPropertyAddressType: {
    constant: ConstantCodeMailFuzeReturnAddressThisPropertyAddressType,
    code: undefined,
    type: 'Integer',
    value: Blank.value
  },
  mailFuzeReturnAddressRecipientAddressType: {
    constant: ConstantCodeMailFuzeReturnAddressRecipientAddressType,
    code: undefined,
    type: 'Integer',
    value: Blank.value
  }
}

export const ReturnAddressSettingsSchema = {
  mailFuzeReturnAddressMethod: yup.object({
    value: yup.number().integer().oneOf([0, 1, 2]).required()
  }),
  mailFuzeReturnAddressCustomCompanyName: yup.object()
    .when(['mailFuzeReturnAddressMethod'], {
      is: (mailFuzeReturnAddressMethod) => { 
        return mailFuzeReturnAddressMethod.value === 0
      },
      then: yup.object({
        value: yup.string().required('Name required')
      }),
      otherwise: yup.object({
        value: yup.mixed().nullable().notRequired()
      })
    }),
  mailFuzeReturnAddressCustomStreet1: yup.object()
    .when(['mailFuzeReturnAddressMethod'], {
      is: (mailFuzeReturnAddressMethod) => { 
        return mailFuzeReturnAddressMethod.value === 0
      },
      then: yup.object({
        value: yup.string().required('Address required')
      }),
      otherwise: yup.object({
        value: yup.mixed().nullable().notRequired()
      })
    }),
  mailFuzeReturnAddressCustomCity: yup.object()
    .when(['mailFuzeReturnAddressMethod'], {
      is: (mailFuzeReturnAddressMethod) => { 
        return mailFuzeReturnAddressMethod.value === 0
      },
      then: yup.object({
        value: yup.string().required('City required')
      }),
      otherwise: yup.object({
        value: yup.string().nullable().notRequired()
      })
    }),
    mailFuzeReturnAddressCustomStateID: yup.object()
    .when(['mailFuzeReturnAddressMethod'], {
      is: (mailFuzeReturnAddressMethod) => { 
        return mailFuzeReturnAddressMethod.value === 0
      },
      then: yup.object({
        value: yup.number().integer().moreThan(0, 'State required')
      }),
      otherwise: yup.object({
        value: yup.mixed().notRequired()
      })
    }),
    mailFuzeReturnAddressCustomZip: yup.object()
    .when(['mailFuzeReturnAddressMethod'], {
      is: (mailFuzeReturnAddressMethod) => { 
        return mailFuzeReturnAddressMethod.value === 0
      },
      then: yup.object({
        value: yup.string().required('Zip required').matches(/^\d{5,10}(?:[-\s]\d{4})?$/, 'Invalid Zip')
      }),
      otherwise: yup.object({
        value: yup.mixed().nullable().notRequired()
      })
    }),
  mailFuzeReturnAddressThisProperty: yup.object()
    .when(['mailFuzeReturnAddressMethod'], {
      is: (mailFuzeReturnAddressMethod) => { 
        return mailFuzeReturnAddressMethod.value === 1 
      },
      then: yup.object({
        value: yup.number().integer().moreThan(0, 'Please select a property.')
      }),
      otherwise: yup.object({
        value: yup.mixed().notRequired()
      })
    }),
    mailFuzeReturnAddressThisPropertyAddressType: yup.object()
    .when(['mailFuzeReturnAddressMethod'], {
      is: (mailFuzeReturnAddressMethod) => { 
        return mailFuzeReturnAddressMethod.value === 1
      },
      then: yup.object({
        value: yup.number().integer().moreThan(0, 'Please select an address type.')
      }),
      otherwise: yup.object({
        value: yup.mixed().notRequired()
      })
    }),
    mailFuzeReturnAddressRecipientAddressType: yup.object()
    .when(['mailFuzeReturnAddressMethod'], {
      is: (mailFuzeReturnAddressMethod) => { 
        return mailFuzeReturnAddressMethod.value === 2
      },
      then: yup.object({
        value: yup.number().integer().moreThan(0, 'Please select an address type.')
      }),
      otherwise: yup.object({
        value: yup.mixed().notRequired()
      })
    }),
}

const InternalSchema = yup.object({
  ...ReturnAddressSettingsSchema
})

const ReturnAddressSettings = () => {

  const propertyService = usePropertyService()

  const formRef = useRef(null)
  const {context: state, contextReducer: dispatchSecond} = useSettingsContext()

  console.log('Loading Return Address Tab with context', state)

  const {
    //setFocus,
    register,
    setValue,
    //getFieldState,
    handleSubmit,
    trigger,
    reset,
    //control
    formState: { errors, isSubmitted }
  } = useForm({
    defaultValues: {
      ...state
    },
    resolver: yupResolver(InternalSchema),
    mode: "onChange",
    context: { state }
  });

  const dispatch = (action) => {
    action.setValue = setValue
     
    dispatchSecond(action)
    if (state.isSubmitted || (action.name === 'isSubmitted' && action.value === true)) {
      trigger()
    }
  }
  
  useEffect(() => {
    reset({
      ...state
    });
    if (state.isSubmitted){
      trigger();
    }
      
    }, [state]);

  useEffect(() => {
    console.log('useEffect triggered by state.isSubmitted', state.isSubmitted, 'isSubmitted', isSubmitted)

    if (state.isSubmitted === false && isSubmitted) {
      dispatch({ type: 'SET', name: 'isSubmitted', value: isSubmitted })
    }
  }, [state.isSubmitted, isSubmitted])

  //trigger will change on the first load. It's only way to run the validation on the default values.
  useEffect(() => {
    if (state.isSubmitted) {
      trigger()
    }
  }, [trigger, state.isSubmitted, state.currentTabIndex])

  return (
    <div>
      <React.Fragment>
        <Form ref={formRef} 
          onSubmit={handleSubmit(state.onSubmit, state.onError)}
        >
          <Row>
            <Col md={11} lg={10} xl={9}>
              <Row className="mt-3">
                <Col xs={12}>
                  <FormGroup
                    tag="fieldset"
                    className="border p-2 mt-3"
                  >
                    <legend className="float-none w-auto ps-1 pe-1 fs-6 text-muted" style={{fontWeight: '500'}}>Which Return Address To Use On Mailings</legend>

                    <div className='ps-3 pe-3 mt-3 d-flex flex-column'>
                      <FormGroup check>
                        <Input
                          {...doRegisterInputRadio(0, register, dispatch, 'mailFuzeReturnAddressMethod', state.isSubmitted, errors, state)}
                          style={{transform: 'scale(1.3)'}}
                        />
                        {' '}
                        <Label check for='mailFuzeReturnAddressMethod0'>
                          Use Address Saved in MailFuze
                        </Label>
                      </FormGroup>

                      <div className='ps-4 mt-2 d-flex flex-row align-items-baseline pb-1'>
                        <Row className='ps-4 d-flex flex-row flex-grow-1'>
                          <Col>
                            <Row>
                              <Col md={8}>
                                <FormGroup>
                                  <Input
                                    {...doRegisterInput(register, dispatch, 'mailFuzeReturnAddressCustomCompanyName', state.isSubmitted, errors, state)}
                                    disabled={state.mailFuzeReturnAddressMethod.value !== 0 }
                                  />
                                  {errors?.mailFuzeReturnAddressCustomCompanyName?.value && (
                                    <FormFeedback className='ms-1'>{errors?.mailFuzeReturnAddressCustomCompanyName?.value?.message}</FormFeedback>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={8}>
                                <FormGroup>
                                  <Input
                                    {...doRegisterInput(register, dispatch, 'mailFuzeReturnAddressCustomStreet1', state.isSubmitted, errors, state)}
                                    disabled={state.mailFuzeReturnAddressMethod.value !== 0 }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={8}>
                                <Row>
                                  <Col md={5}>
                                    <FormGroup>
                                      <Input
                                        {...doRegisterInput(register, dispatch, 'mailFuzeReturnAddressCustomCity', state.isSubmitted, errors, state)}
                                        disabled={state.mailFuzeReturnAddressMethod.value !== 0 }
                                      />
                                      {errors?.mailFuzeReturnAddressCustomCity?.value && (
                                        <FormFeedback className='ms-1'>{errors?.mailFuzeReturnAddressCustomCity?.value?.message}</FormFeedback>
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <MailFuzeReturnAddressStateIDSelect
                                        options={state.mailFuzeReturnAddressCustomStateID.options}
                                        {...doRegisterSelect(register, dispatch, 'mailFuzeReturnAddressCustomStateID', state.isSubmitted, errors, state, '', true)}
                                        isDisabled={state.mailFuzeReturnAddressMethod.value !== 0 }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Input
                                        {...doRegisterInput(register, dispatch, 'mailFuzeReturnAddressCustomZip', state.isSubmitted, errors, state)}
                                        disabled={state.mailFuzeReturnAddressMethod.value !== 0 }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className='ps-3 pe-3 mt-3 d-flex flex-column'>
                      <FormGroup check>
                        <Input
                          {...doRegisterInputRadio(1, register, dispatch, 'mailFuzeReturnAddressMethod', state.isSubmitted, errors, state)}
                          style={{transform: 'scale(1.3)'}}
                        />
                        {' '}
                        <Label check for='mailFuzeReturnAddressMethod1'>
                          Use Address From This Property
                        </Label>
                      </FormGroup>

                      <div className='ps-4 mt-2 d-flex flex-row align-items-baseline pb-1'>
                        <Row className='ps-4 d-flex flex-row flex-grow-1'>
                          <Col>
                            <Row>
                              <Col md={7}>
                                <Row>
                                  <Col md={5}>
                                    Property:
                                  </Col>
                                  <Col md={7}>
                                    <MailFuzeReturnAddressThisPropertySelect
                                      options={state.mailFuzeReturnAddressThisProperty.options}
                                      {...doRegisterSelect(register, async (dispatchOnChangeAction) => { 

                                        const selectedPropertyOption = dispatchOnChangeAction.value
                                        if (selectedPropertyOption.value !== Blank.value) {

                                          const addressesResult = await propertyService.GetPropertyAddresses(selectedPropertyOption.value)

                                          if (addressesResult && addressesResult.length === 1) {
                                            //dispatch.push({ type: 'SET_ALL_OPTIONS', name: 'mailFuzeReturnAddressThisPropertyAddressType', value: propertyAddressTypeSelectOptions })
                                            const filteredAddressTypes = state.mailFuzeReturnAddressThisPropertyAddressType.allOptions.filter(option => addressesResult[0].addresses.find(address => address.addressTypeID === option.value || option.value === Blank.value) !== undefined)

                                            const currentAddressType = filteredAddressTypes.find(option => option.value === state.mailFuzeReturnAddressThisPropertyAddressType.value)
                                            if (currentAddressType && currentAddressType.value !== Blank.value) {
                                              //dispatch({ type: 'SET_OPTIONS', name: 'mailFuzeReturnAddressThisPropertyAddressType', value: filteredAddressTypes })  
                                              const selectedAddress = addressesResult[0].addresses.find(address => address.addressTypeID === currentAddressType.value)

                                              dispatch({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressThisPropertyAddress', value: { propertyName: addressesResult[0].name, address: selectedAddress }})
                                            }
                                            else {
                                              dispatch({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressThisPropertyAddress', value: undefined})
                                            }

                                            dispatch({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressThisPropertyInfo', value: addressesResult[0] })
                                            dispatch({ type: 'SET_OPTIONS', name: 'mailFuzeReturnAddressThisPropertyAddressType', value: filteredAddressTypes })
                                          }
                                        }
                                        else {
                                          dispatch({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressThisPropertyAddress', value: undefined})
                                        }

                                        dispatch(dispatchOnChangeAction);
                                      }, 'mailFuzeReturnAddressThisProperty', state.isSubmitted, errors, state, '', true)}
                                      isDisabled={state.mailFuzeReturnAddressMethod.value !== 1}
                                    />
                                  </Col>
                                </Row>
                                <Row className='mt-1'>
                                  <Col md={5}>
                                    Address Type:
                                  </Col>
                                  <Col md={7}>
                                    <MailFuzeReturnAddressThisPropertyAddressTypeSelect
                                      options={state.mailFuzeReturnAddressThisPropertyAddressType.options}
                                      {...doRegisterSelect(register, async (dispatchOnChangeAction) => {
                                        const propertyInfo = state.mailFuzeReturnAddressThisPropertyInfo?.value
                                        if (propertyInfo) {
                                          const selectedAddressTypeOption = dispatchOnChangeAction.value
                                          const selectedAddress = propertyInfo.addresses.find(address => address.addressTypeID === selectedAddressTypeOption.value)
                                          if (selectedAddress) {
                                            const thisPropertyAddress = {
                                              propertyName: propertyInfo.name,
                                              address: selectedAddress
                                            }

                                            dispatch({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressThisPropertyAddress', value: thisPropertyAddress})
                                          }
                                          else {
                                            dispatch({ type: 'SET_VALUE', name: 'mailFuzeReturnAddressThisPropertyAddress', value: undefined})
                                          }
                                        }
                                        
                                        dispatch(dispatchOnChangeAction);
                                      },
                                      'mailFuzeReturnAddressThisPropertyAddressType', state.isSubmitted, errors, state, '', true)}
                                      isDisabled={state.mailFuzeReturnAddressMethod.value !== 1}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={5} className={`d-flex ${state.mailFuzeReturnAddressMethod.value !== 1 ? ' text-muted' : ''}`}>
                                <div className={`border rounded d-flex flex-column flex-grow-1 justify-content-start ps-3 p-2 ${state.mailFuzeReturnAddressMethod.value !== 1 ? ' text-muted' : ''}`} style={state.mailFuzeReturnAddressMethod.value !== 1 ? {backgroundColor: 'var(--bs-gray-100)'} : {}}>
                                  {!state.mailFuzeReturnAddressThisPropertyAddress?.value ? '' : (
                                    <>
                                      <span className='fs-6 text-muted' style={{fontWeight: 500}}>{state.mailFuzeReturnAddressThisPropertyAddress.value.propertyName}</span>
                                      <div style={{lineHeight: '97%', fontSize: '95%'}}>
                                        {state.mailFuzeReturnAddressThisPropertyAddress.value.address.street}
                                        <br />
                                        {state.mailFuzeReturnAddressThisPropertyAddress.value.address.city}, {state.mailFuzeReturnAddressThisPropertyAddress.value.address.state}, {state.mailFuzeReturnAddressThisPropertyAddress.value.address.postalCode}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className='ps-3 pe-3 mt-3 mb-2 d-flex flex-column'>
                      <FormGroup check>
                        <Input
                          {...doRegisterInputRadio(2, register, dispatch, 'mailFuzeReturnAddressMethod', state.isSubmitted, errors, state)}
                          style={{transform: 'scale(1.3)'}}
                        />
                        {' '}
                        <Label check for='mailFuzeReturnAddressMethod2'>
                          Use Address From The Property of Each Recipient
                        </Label>
                      </FormGroup>
                    </div>








                    <div className='ps-4 mt-2 d-flex flex-row align-items-baseline pb-1'>
                        <Row className='ps-4 d-flex flex-row flex-grow-1'>
                          <Col>
                            <Row>
                              <Col md={7}>
                                <Row>
                                  <Col md={5}>
                                    Address Type:
                                  </Col>
                                  <Col md={7}>
                                    <MailFuzeReturnAddressRecipientAddressTypeSelect
                                      options={state.mailFuzeReturnAddressRecipientAddressType.options}
                                      {...doRegisterSelect(register, dispatch, 'mailFuzeReturnAddressRecipientAddressType', state.isSubmitted, errors, state, '', true)}
                                      isDisabled={state.mailFuzeReturnAddressMethod.value !== 2}
                                    />
                                  </Col>
                                </Row>
                              </Col>

                            </Row>
                          </Col>
                        </Row>
                      </div>

                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    </div>
  )
}

export default ReturnAddressSettings