import React from 'react'

export const MultiLineCellRenderer = ({canvasRef, maxLines, resizeRowCallaback, ...params}) => {
    const canvas = canvasRef.current
    //const maxLines = outerMaxLines

    /**
     * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
     *
     * @param {String} text The text to be rendered.
     * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
     *
     * @see http://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
     */
    const GetTextWidth = (text, font, canvas) => {
        // re-use canvas object for better performance
        //var canvas = this.canvas || (this.canvas = document.createElement("canvas"));
        var context = canvas.getContext("2d");
        context.font = font;
        var metrics = context.measureText(text);
        return parseInt(metrics.width*1.15); // don't ask why I'm adding 15% to the width... it just works. If you can figure out how to make it exact, please let me know.
    }
    
    const GetWidthHeight = (string, AllowedWidth, maxLines, canvas, font, debug) => {

        var words = string.split(" "); // split on spaces
        var LineWidth = 0, CharCounter = 0, StartCounter = 0, MaxWidth = 0;
        var NumLines = 1;
        var OutputLines = [];
    
        for (var i=0; i < words.length; i++) {
            var text = words[i];
            var ThisWidth = GetTextWidth(text, font, canvas);
            if (debug) {console.log(text + " = " + ThisWidth + "px")};
            if (LineWidth + ThisWidth > AllowedWidth) {
                // end this line, begin a new one.
                if (LineWidth > MaxWidth) MaxWidth = LineWidth;
                var LineOut = string.substr(StartCounter, CharCounter);
                i--; // go back one word since this word needs to start on a new line
                if (debug) {
                    console.log("LINE OUT: " + LineOut);
                    console.log("LINE PIXEL WIDTH: " + LineWidth);
                }
                LineWidth = ThisWidth;
                StartCounter = StartCounter + CharCounter;
                OutputLines[NumLines-1] = LineOut;
                if (NumLines == maxLines) {
                    OutputLines[NumLines-1] = string.substr(StartCounter-CharCounter);
                    break;
                }
                CharCounter = 0;
                NumLines++;
            } else {
                LineWidth += ThisWidth;
                CharCounter += text.length + 1;
                if (i == words.length-1) {
                    OutputLines[NumLines-1] = string.substr(StartCounter);
                }
            }
        }
        if (MaxWidth === 0) MaxWidth = AllowedWidth;
        if (OutputLines.length === 0) OutputLines.push(string);
        return {
            OutputLines: OutputLines,
            NumLines: NumLines,
            MaxWidth: MaxWidth
        };
    }

    const renderCell = (params) => {
        var width = params.column.actualWidth - 6;
        var debug = false;
        //if (params.rowIndex === 1) debug = true;
        var font = 'normal 14px "Helvetica Neue",Helvetica,Arial,sans-serif';

        var sanitizedString = params.value ? params.value : "";
        sanitizedString = sanitizedString.replace(/(\r\n|\n|\r)/gm," ");
        var sanitizedWords = sanitizedString.split(" "); // split on spaces

        var trimmedWords = sanitizedWords && sanitizedWords !== "" ? sanitizedWords.filter(word => word && word.length > 0 && word.trim() !== '') : ""
        var brokenWords = []
        var mWidth = GetTextWidth("M", font, canvas);
        var splitInto = Math.ceil(width / mWidth * 0.85)

        for (var i=0; i < trimmedWords.length; i++) {
            var text = trimmedWords[i];
            if (text.length > splitInto) {
                for (var j = 0, charsLength = text.length; j < charsLength; j += splitInto) {
                    brokenWords.push(text.substring(j, Math.min(text.length-1, j + splitInto)))
                }
            } else {
                brokenWords.push(text)
            }
        }

        sanitizedString = brokenWords.join(' ')

        var result = GetWidthHeight(sanitizedString, width, maxLines, canvas, font, debug);
        //console.log(result.OutputLines)

        var trimmedOutputLines = result.OutputLines.filter(line => line && line.length > 0 && line.trim() !== '')
        var lastOutputLine = ''
        if (trimmedOutputLines && trimmedOutputLines.length !== 0)
        {
            lastOutputLine = trimmedOutputLines.pop()
        }

        var currentRowHeight = params.node.rowHeight;
        var newRowHeight = Math.max(42, params.node.rowHeight, (4+((trimmedOutputLines.length+1)*19)))
        if (currentRowHeight !== newRowHeight) {
            params.node.setRowHeight(newRowHeight)
            resizeRowCallaback()
        }


        //console.log(result.OutputLines)
        //console.log('lastOutputLine: ', lastOutputLine)
        //console.log(params.node)

        return (
            <div style={{minHeight: '42px', display: 'flex', flexFlow: 'column', justifyContent: 'space-evenly'}}>
                {trimmedOutputLines.map((line, index) => 
                    <div key={`${params.rowIndex}-${index}`} style={{lineHeight: 'initial', maxWidth: `${width}px`}}>
                        {line}
                    </div>
                    )
                }
                <div style={{lineHeight: 'initial', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: `${width}px`}}>
                    {lastOutputLine}
                </div>
            </div>
        )
        // var out = '<div style="min-height: 42px; display: flex; flex-flow: column; justify-content: space-evenly">';
        
        // for (var i=0; i < result.OutputLines.length-1; i++) {
        //     out += '<div style="line-height: initial; max-width: '+width+'px">'+result.OutputLines[i]+'</div>\n';
        // }
        // out += '<div style="line-height: initial; overflow: hidden; text-overflow: ellipsis; max-width: '+width+'px;">'+result.OutputLines[i]+'</div>';
        // out += '</div>'
        // return out;
    };

    //return renderCell(params)
    return (
        <React.Fragment>
            {renderCell(params)}
        </React.Fragment>
    )
  };