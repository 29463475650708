import React from 'react'
import { Check, ReceiptCutoff, Slash, SlashCircle } from 'react-bootstrap-icons'

const AGGridCheckmarkRenderer = (props) => {
  return (
    <div className="text-center">
      {props.value ? (
        props.icon ? props.icon : <Check size={20} />
      ) : (
        (props.useAltIcon || props.altIcon) && (props.altIcon ? props.altIcon : <SlashCircle Check size={20} />)
      )}
    </div>
  )
}

export default AGGridCheckmarkRenderer