import React, { useEffect, useReducer, useState } from 'react'
import { EnvelopeCheck, EnvelopePaper, PatchCheck } from 'react-bootstrap-icons';
import { Row, Col, Label } from 'reactstrap'
import BaseSelect from "../dropdowns/BaseSelect";
import { useDocumentClassService } from '../../services/DocumentClassService';

export const ColorsSelect = ({onChange, onError, documentClass, orderByName, ...rest}) => {
  return (
      <BaseSelect
        serviceUrl={`documentclass/${documentClass}/colors?orderByName=${orderByName}`}
        valueName="C2MColorID"
        labelName="C2MColorName"
        onChange={onChange}
        onError={onError}
        useEffectDependencies={[documentClass]}
        {...rest}
      />
  )
}

export const EnclosureSelect = ({onChange, onLoaded, onError, documentClass, ...rest}) => {
  const initialState = {
    displayOption: 'none',
    includeEnclosure: false,
    includeEnclosureDisabled: false
  }

  const actionReducer = (state, action) => {
    switch (action.type) {
      case 'SET': {
        if (action.name === 'includeEnclosure') {
          return {
            ...state,
            [action.name]: action.value,
            displayOption: action.value ? 'block' : 'none'
          }
        }
        else {
          return {
            ...state,
            [action.name]: action.value
          }
        }
      }
    }

    return state
  }

  const [state, dispatch] = useReducer(actionReducer, initialState)

  const [selected, setSelected] = useState({value: 0, label: ''})

  useEffect(() => {
    onChange( { enclosureOption: selected, includeEnclosure: state.includeEnclosure })
  }, [state.includeEnclosure])

  const onEnclosureChange = (opt) => {
    onChange( { enclosureOption: opt, includeEnclosure: state.includeEnclosure })
    setSelected(opt)
  }

  const onEnclosureLoaded = (value) => {
    if (!value.hasOptions) {
      dispatch({ type: 'SET', name: 'includeEnclosure', value: false })
    }
    dispatch({ type: 'SET', name: 'includeEnclosureDisabled', value: !value.hasOptions })

    onLoaded(value)
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Row>
            <Col sm={8}><Label><EnvelopePaper />Reply Envelope</Label></Col>
            <Col sm={4} className="pe-4">
              <div className="form-check form-switch d-flex align-items-end flex-column pe-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="includeEnclosure"
                  name="includeEnclosure"
                  onChange={e => dispatch({ type: 'SET', name: e.target.name, value: e.target.checked })}
                  checked={state.includeEnclosure ? 'checked' : ''}
                  disabled={state.includeEnclosureDisabled}
                  style={{transform: 'scale(1.6)'}}/>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div style={{display : state.displayOption}} className="mt-2">
        <BaseSelect
          serviceUrl={`documentclass/${documentClass}/enclosures`}
          valueName="C2MEnclosureID"
          labelName="C2MEnclosureName"
          onChange={onEnclosureChange}
          onLoaded={onEnclosureLoaded}
          onError={onError}
          useEffectDependencies={[documentClass]}
          {...rest}
        />
      </div>
    </React.Fragment>
  )
}

export const ConfirmationOfMailingOption = ({onChange, onLoaded, onError, documentClass, ...rest}) => {

  const documentClassService = useDocumentClassService()

  const initialState = {
    displayOption: 'none',
    includeConfirmationOfMailing: false,
    includeConfirmationOfMailingDisabled: false
  }

  const actionReducer = (state, action) => {
    switch (action.type) {
      case 'SET': {
        return {
          ...state,
          [action.name]: action.value
        }
      }
    }

    return state
  }

  const [state, dispatch] = useReducer(actionReducer, initialState)

  useEffect(() => {
    onChange( { includeConfirmationOfMailing: state.includeConfirmationOfMailing })
  }, [state.includeConfirmationOfMailing])

  useEffect(() => {
    documentClassService.HasConfirmationOfMailing(documentClass).then((data) => {
      console.log(data);
      
      const hasOption = data && data.hasConfirmationOfMailing
      dispatch({ type: 'SET', name: 'includeConfirmationOfMailingDisabled', value: !hasOption })
      if (state.includeConfirmationOfMailing && !hasOption) {
        dispatch({ type: 'SET', name: 'includeConfirmationOfMailing', value: false })
      }
    })
  }, [documentClass]) 

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Row>
            <Col sm={8}><Label><EnvelopeCheck />Confirmation of Mailing</Label></Col>
            <Col sm={4} className="pe-4">
              <div className="form-check form-switch d-flex align-items-end flex-column pe-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="includeConfirmationOfMailing"
                  name="includeConfirmationOfMailing"
                  onChange={e => dispatch({ type: 'SET', name: e.target.name, value: e.target.checked })}
                  checked={state.includeConfirmationOfMailing ? 'checked' : ''}
                  disabled={state.includeConfirmationOfMailingDisabled}
                  style={{transform: 'scale(1.6)'}}/>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export const EnvelopesSelect = ({onChange, onError, documentClass, ...rest}) => {
  return (
      <BaseSelect
        serviceUrl={`documentclass/${documentClass}/envelopes`}
        valueName="C2MEnvelopeID"
        labelName="C2MEnvelopeName"
        setToPrimary={true}
        onChange={onChange}
        onError={onError}
        useEffectDependencies={[documentClass]}
        {...rest}
      />
  )
}

export const DocumentClassSelect = ({onChange, onError, ...rest}) => {
  return (
    <BaseSelect 
      serviceUrl="documentclass"
      valueName="C2MDocumentClassID"
      labelName="C2MDocumentClassName"
      selectFirst={false}
      onChange={onChange}
      onError={onError}
      {...rest}
    />
  )
}
export const LayoutsSelect = ({onChange, onError, documentClass, ...rest}) => {
  return (
      <BaseSelect
        serviceUrl={`documentclass/${documentClass}/layouts`}
        valueName="C2MLayoutID"
        labelName="C2MLayoutName"
        onChange={onChange}
        onError={onError}
        useEffectDependencies={[documentClass]}
        {...rest}
      />
  )
}

export const MailClassesSelect = ({onChange, onError, documentClass, ...rest}) => {
  return (
      <BaseSelect
        serviceUrl={`documentclass/${documentClass}/mailclasses`}
        valueName="C2MMailClassID"
        labelName="C2MMailClassName"
        onChange={onChange}
        onError={onError}
        useEffectDependencies={[documentClass]}
        {...rest}
      />
  )
}

export const PaperTypesSelect = ({onChange, onError, documentClass, ...rest}) => {
  return (
      <BaseSelect
        serviceUrl={`documentclass/${documentClass}/papertypes`}
        valueName="C2MPaperTypeID"
        labelName="C2MPaperTypeName"
        setToPrimary={true}
        onChange={onChange}
        onError={onError}
        useEffectDependencies={[documentClass]}
        {...rest}
      />
  )
}

export const ProductionTimeSelect = ({onChange, onError, documentClass, ...rest}) => {
  return (
      <BaseSelect
        serviceUrl={`documentclass/${documentClass}/productiontime`}
        valueName="C2MProductionTimeID"
        labelName="C2MProductionTimeName"
        onChange={onChange}
        onError={onError}
        useEffectDependencies={[documentClass]}
        {...rest}
      />
  )
}

export const PrintOptionsSelect = ({onChange, onError, documentClass, ...rest}) => {
  return (
      <BaseSelect
        serviceUrl={`documentclass/${documentClass}/printoptions`}
        valueName="C2MPrintOptionID"
        labelName="C2MPrintOptionName"
        onChange={onChange}
        onError={onError}
        useEffectDependencies={[documentClass]}
        {...rest}
      />
  )
}