import React, { Component, Fragment, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useSendMailService } from '../../services/SendMailService'
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import SendMailResults from '../send-mail/SendMailResults';

const SendMailLogs = () => {

  const sendMailService = useSendMailService()

  const [sendMailId, setSendMailId] = useState('')
  const [sendMailResults, setSendMailResults] = useState({ showModal: false, results: []})

  const handleChange = (e) => {
    setSendMailId(e.target.value);
  };

  const onCloseResults = () => {
    setSendMailResults({showModal: false, results: []})
  }

  const getLog = () => {
    sendMailService.PollSendMailToRentManagerTenants(sendMailId).then(sendMailResults => {

      var sendMailStatusEl = document.getElementById('processingText')
      if (sendMailStatusEl && sendMailStatusEl.innerText !== sendMailResults.currentStep) {
        sendMailStatusEl.innerText = sendMailResults.currentStep
      }

      if (sendMailResults.isComplete)
      {
        setSendMailResults({
            showModal: true,
            success: sendMailResults.results.isSuccess,
            transactionLog: sendMailResults.sendMailId,
            results: sendMailResults.results,
            sendMailService
        })
      }
      else
      {
        alert('Transaction log is not complete, cannot show.')
      }

      console.log('send mail results: ', sendMailResults)
    }).catch(error => { console.log(error); PlainSwal.close(); setSendMailPollingData(null, null); });
  }

  return (
    <div>
      <Form>
        <FormGroup>
          <Label for="sendMailId">Send Mail Log ID:</Label>
          <Input
            type="sendMailId"
            name="sendMailId"
            id="sendMailId"
            value={sendMailId}
            onChange={handleChange}
            placeholder="Enter send nail log ID" />
        </FormGroup>
        <Button onClick={getLog}>Get Log</Button>
      </Form>

      {sendMailResults.showModal && (
        <SendMailResults onClose={onCloseResults} {...sendMailResults} />
      )}
    </div>
  )
}

export default SendMailLogs