import React, { Component } from 'react';
import NumberFormat from 'react-number-format'

export default class CustomPinnedRowRenderer extends Component {
  render() {
    {
      if (this.props.format === 'currency') {
        return (<NumberFormat value={this.props.value} defaultValue={0} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}  prefix={'$'} />)
      }
      else {
        return (<span style={this.props.style}>{this.props.value}</span>)
      }
    }
  }
}